import styled, { css } from 'styled-components';

export const PageWrapper = styled.div`
    width: 100%;
    padding: 15px;

    > * {
        > * {
            padding: 20px;
            &:first-child {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
`;

export const RecipeCardContainer = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: ${({ size }) => (size === 'large' ? '335px' : '228px')};
    height: ${({ size, showButtonBlock }) => (size === 'large' ? '358px' : showButtonBlock ? '258px' : '204px')};
    margin-right: 20px;
    :last-child {
        margin-right: 0;
    }
`;

export const ImageContainer = styled.div`
    position: relative;
    border-radius: 8px;
    width: 100%;
    height: 57%;
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: ${({ showButtonBlock }) => (showButtonBlock ? '17%' : '26%')};
    .name {
        font-weight: 500;
        font-size: ${({ size }) => (size === 'large' ? '24px' : '16px')};
        color: #0d2120;
        width: 90%;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .info {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    .ingredient {
        span {
            font-size: ${({ size }) => (size === 'large' ? '16px' : '14px')};
            color: #aab5bc;
            &:first-child {
                color: ${({ isCommonIngredient }) => isCommonIngredient && '#82BD73'};
            }
        }
    }
    .calories {
        display: flex;
        color: #aab5bc;
        align-items: center;
        span {
            padding-left: 5px;
            font-size: 14px;
        }
    }
`;

export const CookingTime = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    width: 59px;
    height: 26px;
    left: 12px;
    bottom: 12px;
    background: #ffffff;
    border-radius: 4px;
    padding: 4px 8px;
    justify-content: space-between;

    svg {
        height: 13px;
        width: 13px;
        color: #82bd73;
    }

    span {
        font-weight: 500;
        font-size: 12px;
        color: #0d2120;
    }
`;

export const FavouriteWrapper = styled.div`
    position: absolute;
    top: ${({ isFavourite }) => (isFavourite ? '8px' : '13px')};
    right: 8px;
    cursor: pointer;

    svg {
        fill: #ffffff;

        &:hover {
            background: rgba(239, 245, 249, 0.5);
        }
        &:focus {
            fill: #82bd73;

            background: rgba(239, 245, 249, 0.5);
        }
    }
`;

export const ServingsExtra = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    position: absolute;
    width: ${({ size }) => (size === 'large' ? '305px' : '205px')};
    height: ${({ size }) => (size === 'large' ? '32px' : '26px')};
    top: ${({ size }) => (size === 'large' ? '-24px' : '-18px')};
    left: 12px;
    background: #fd891b;
    border-radius: 4px;
    span {
        font-weight: 500;
        font-size: ${({ size }) => (size === 'large' ? '16px' : '12px')};
        color: #ffffff;
        white-space: nowrap;
    }
`;

export const CancelBtn = styled.div`
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 13px;

    svg {
        width: 24px;
        height: 24px;
    }
`;

export const SimpleCard = styled.div`
    margin-bottom: 20px;
    width: 144px;
    position: relative;
    &:last-child {
        margin-right: 0;
    }
    @media (max-width: 700px) {
        width: ${(props) => (props.isTextOverlay ? '104px' : '25%')};
        padding: 6px;
    }
`;

export const SimpleCardImage = styled.div`
    border-radius: 8px;
    height: 128px;
    background: url('${(props) => props.image}') no-repeat center center/cover;
    @media (max-width: 700px) {
        height: ${(props) => (props.isTextOverlay ? '104px' : '75px')};
    }
`;

export const SimpleCardName = styled.div`
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 500;
    ${(props) =>
        props.isTextOverlay &&
        css`
            color: #fff !important;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 10px;
        `};

    @media (max-width: 700px) {
        font-size: 12px;
        color: #233747;
    }
`;

export const HorizontalCard = styled.div`
    display: flex;
    margin-bottom: 20px;

    ${(props) =>
        props.arrowIcon &&
        css`
            padding-top: 20px;
            border-top: 1px solid #e5eaed;
        `};
`;
export const HorizontalCardImage = styled.div`
    border-radius: 8px;
    height: 40px;
    width: 40px;
    ${(props) =>
        props.image &&
        css`
            background: url('${(props) => props.image}') no-repeat center center/cover;
        `};
`;
export const HorizontalCardContent = styled.div`
    padding-left: 12px;
    display: flex;
    justify-content: space-between;

    h2 {
        margin-top: 0;
        margin-bottom: 5px;
        font-weight: 500;
        white-space: nowrap;
        width: calc(100vw - 111px);
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;
export const HorizontalCardInfo = styled.div`
    display: flex;
    color: #aab5bc;
    white-space: pre-wrap;
    font-size: 14px;

    div {
        &:first-child {
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            span {
                font-weight: 500;

                &:first-child {
                    color: #82bd73;
                }

                &:last-child {
                    color: #aab5bc;
                }
            }
        }
    }
`;
export const CardIcon = styled.div`
    margin: auto 0;
`;
export const HorizontalCardCookingTime = styled.div`
    flex-shrink: 0;
    &:before {
        content: '\\2022';
        color: #aab5bc;
        text-align: center;
        margin: 0 5px;
    }
`;

export const CartLoadingContainer = styled.div`
    width: ${({ size }) => (size === 'large' ? '335px' : '228px')};
    height: ${({ size, showButtonBlock }) => (size === 'large' ? '358px' : showButtonBlock ? '258px' : '204px')};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const CartImageLoading = styled.div`
    width: 100%;
    height: 57%;
    background: #eff5f9;
`;

export const CartTitleGroup = styled.div`
    height: 17%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const CartTitleLoading = styled.div`
    height: 18px;
    background: #eff5f9;
    width: 75%;
`;

export const CartSubTitleLoading = styled.div`
    height: 18px;
    background: #eff5f9;
    width: 50%;
`;

export const CartButtonLoading = styled.div`
    height: 50px;
    width: 100%;
    background: #eff5f9;
`;
