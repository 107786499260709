import React from 'react';
import { useDispatch } from 'react-redux';
import history from '../../history';
import useScreenType from 'hooks/useScreenType';
import ReactPlayer from 'react-player/lazy';

import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';

import { showHowItWorksPopUp } from 'store/actions/ui-settings.actions';
import { ModalContainer, ModalContent } from './styles';

const HowItWorksVideoPopup = ({}) => {
    const dispatch = useDispatch();
    const { isMobile, isTabletOrMobile } = useScreenType();

    const back = (e) => {
        dispatch(showHowItWorksPopUp(false));
    };
    return (
        <ModalContainer open={true} onClose={back} scroll="body" $isTabletOrMobile={isTabletOrMobile} $isMobile={isMobile}>
            <ModalContent>
                <ReactPlayer
                    className={'react-vedio-player'}
                    width={'100%'}
                    url={'https://www.youtube.com/watch?v=ysz5S6PUM-U'}
                />
            </ModalContent>
        </ModalContainer>
    );
};

export default HowItWorksVideoPopup;
