import React, { Component, lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { CircularProgress } from '@material-ui/core';

import HeaderContainer from './components/HeaderContainer';

import ShareModal from './components/modals/ShareModal/ShareMenuModal';
import ShareModalHeader from './components/common/Share/ShareMenuHeader';

import GlobalUIComponents from './components/GlobalUIComponents';
import Footer from 'components/common/Footer';

const LandingPage = lazy(() => import(/* webpackChunkName: "LandingPage" */ /* webpackPrefetch: 0 */ './pages/LandingPage'));

const EmailVerification = lazy(() =>
    import(/* webpackChunkName: "EmailVerification" */ /* webpackPrefetch: 0 */ './pages/EmailVerification'),
);
const ForgotPass = lazy(() => import(/* webpackChunkName: "ForgotPass" */ /* webpackPrefetch: 0 */ './pages/ForgotPass'));
const ResetPass = lazy(() => import(/* webpackChunkName: "ResetPass" */ /* webpackPrefetch: 0 */ './pages/ResetPass'));
const SignIn = lazy(() => import(/* webpackChunkName: "SignIn" */ /* webpackPrefetch: 0 */ './pages/SignIn'));
const SignUp = lazy(() => import(/* webpackChunkName: "SignUp" */ /* webpackPrefetch: 0 */ './pages/SignUp'));
const AdminSignIn = lazy(() => import(/* webpackChunkName: "AdminSignIn" */ /* webpackPrefetch: 0 */ './pages/AdminSignIn'));
const Faq = lazy(() => import(/* webpackChunkName: "Faq" */ /* webpackPrefetch: 0 */ './pages/Faq'));
const TOS = lazy(() => import(/* webpackChunkName: "Tos" */ /* webpackPrefetch: 0 */ './pages/About/Tos'));
const Privacy = lazy(() => import(/* webpackChunkName: "Privacy" */ /* webpackPrefetch: 0 */ './pages/About/Privacy'));
const Delivery = lazy(() => import(/* webpackChunkName: "Delivery" */ /* webpackPrefetch: 0 */ './pages/Delivery'));

const RecipePrint = lazy(() => import(/* webpackChunkName: "RecipePrint" */ /* webpackPrefetch: 0 */ './pages/RecipePrint'));
const RecipesPage = lazy(() => import(/* webpackChunkName: "RecipePage" */ /* webpackPrefetch: 2 */ './pages/RecipesPage'));
const MinimumOrder = lazy(() => import(/* webpackChunkName: "MinimumOrder" */ /* webpackPrefetch: 0 */ './pages/MinimumOrder'));

const Checkout = lazy(() => import(/* webpackChunkName: "Checkout" */ /* webpackPrefetch: 3 */ './pages/Checkout'));
const FavoritePage = lazy(() => import(/* webpackChunkName: "FavoritePage" */ /* webpackPrefetch: 3 */ './pages/FavoritePage'));
const CommonIngredientsPage = lazy(() =>
    import(/* webpackChunkName: "CommonIngredientsPage" */ /* webpackPrefetch: 0 */ './pages/CommonIngredients'),
);

const Settings = lazy(() => import(/* webpackChunkName: "Dashboard" */ /* webpackPrefetch: 3 */ './pages/Settings'));

const RecentOrder = lazy(() =>
    import(/* webpackChunkName: "Dashboard" */ /* webpackPrefetch: 3 */ './components/common/DashBoardTabs/RecentOrder/index'),
);

const DigitalKitchen = lazy(() =>
    import(/* webpackChunkName: "DigitalKitchen" */ /* webpackPrefetch: 3 */ './pages/GroceryManagement'),
);

const OnboardingAllergies = lazy(() =>
    import(/* webpackChunkName: "AllergiesPage" */ /* webpackPrefetch: 0 */ './pages/Onboarding/Allergies'),
);
const OnboardingDiet = lazy(() => import(/* webpackChunkName: "DietPage" */ /* webpackPrefetch: 0 */ './pages/Onboarding/Diet'));
const OnboardingServings = lazy(() =>
    import(/* webpackChunkName: "ServingsPage" */ /* webpackPrefetch: 0 */ './pages/Onboarding/Servings'),
);
const OnboardingCreateAccount = lazy(() =>
    import(/* webpackChunkName: "CreateAccountPage" */ /* webpackPrefetch: 0 */ './pages/Onboarding/CreateAccount'),
);
const OnboardingMakeAccount = lazy(() =>
    import(/* webpackChunkName: "MakeAccountPage" */ /* webpackPrefetch: 0 */ './pages/Onboarding/MakeAccount'),
);
const OnboardingGroceries = lazy(() =>
    import(/* webpackChunkName: "OnboardingGroceries" */ /* webpackPrefetch: 0 */ './pages/Onboarding/Groceries'),
);
const CollectionItem = lazy(() =>
    import(/* webpackChunkName: "collectionsPage" */ /* webpackPrefetch: 0 */ './pages/CollectionsItem/CollectionItem'),
);
const CollectionsPage = lazy(() =>
    import(/* webpackChunkName: "collectionsPage" */ /* webpackPrefetch: 0 */ './pages/CollectionsPage/CollectionsPage'),
);
const CategoriesPage = lazy(() =>
    import(/* webpackChunkName: "categoriesPage" */ /* webpackPrefetch: 0 */ './pages/Category/CategoryPage'),
);

const AboutUs = lazy(() => import(/* webpackChunkName: "OnboardingGroceries" */ /* webpackPrefetch: 0 */ './pages/AboutUs'));

const MenuPage = lazy(() => import('./pages/MenuPage'));

const RecipeItem = lazy(() => import('./pages/RecipeItem'));
const GroceriesPage = lazy(() => import('./pages/GroceriesPage'));
const AuthRedirection = lazy(() => import('./pages/Redirection'));
const Cook = lazy(() => import('./pages/Cook'));
const NutritionProgram = lazy(() => import('./pages/BuildMenu/filterQuestion'));
const MenuDiets = lazy(() => import('./pages/BuildMenu/dietQuestion'));
const MealTypes = lazy(() => import('./pages/BuildMenu/mealTypeQuestion'));
const BuildMenuResult = lazy(() => import('./pages/BuildMenu/result'));
const LinkeinBioPage = lazy(() => import('./pages/LinkeinBioPage/LinkeinBioPage'));

const RoutesWithFooter = ({ location: { pathname } }) => {
    return (
        <>
            <Switch>
                <Route exact path="/recipes" component={RecipesPage} />
                <Route exact path="/about-us" component={AboutUs} />
                <Route exact path="/recipes/favorites" component={FavoritePage} />
                <Route exact path="/use-pantry" component={CommonIngredientsPage} />
                <Route exact path="/recipes/:id" component={WaitingComponent(RecipeItem)} />
                <Route exact path="/:influencerId/recipes/:id" component={WaitingComponent(RecipeItem)} />
                <Route exact path="/categories/:id" component={WaitingComponent(CategoriesPage)} />
                <Route exact path="/meal-plan" component={CollectionsPage} />
                <Route exact path="/:influencerId/meal-plan" component={CollectionsPage} />
                <Route exact path="/meal-plan/:id" component={CollectionItem} />
                <Route exact path="/:influencerId/meal-plan/:id" component={CollectionItem} />
                <Route exact path="/:influencerId" component={LandingPage} />
                <Route exact path="/:influencerId/recipes" component={RecipesPage} />
                <Route exact path="/" component={LandingPage} />
                <Redirect to="/" />
            </Switch>
            <Footer />
        </>
    );
};
const RoutesWithHeader = ({ location: { pathname } }) => {
    const { isCheckoutSuccess } = useSelector((state) => state.orderReducer);

    return (
        <>
            <HeaderContainer />
            <GlobalUIComponents />
            <Switch>
                <Route exact path="/groceries" component={GroceriesPage} />
                <Route exact path="/checkout" component={Checkout} />
                <Route exact path="/faq" component={Faq} />
                <Route exact path="/tos" component={TOS} />
                <Route exact path="/privacy" component={Privacy} />
                <Route exact path="/delivery" component={Delivery} />
                <Route exact path="/signup">
                    <Redirect to="/onboarding/account" />
                </Route>
                <Route exact path="/signin" component={SignIn} />
                <Route exact path="/admin/signin" component={AdminSignIn} />
                <Route exact path="/forgot-password" component={ForgotPass} />
                <Route exact path="/resetPassword/:code" component={ResetPass} />
                <Route exact path="/menu/:orderId" component={MenuPage} />
                <Route exact path="/verify-email" component={EmailVerification} />
                <Route exact path="/verifyEmail/:token" component={EmailVerification} />
                <Route exact path="/landingPage" component={LandingPage} />
                <Route exact path="/min-order" component={MinimumOrder} />
                <Route exact path="/manage-groceries" component={DigitalKitchen} />
                <Route exact path="/orders" component={RecentOrder} />
                <Route exect path="/orders/:orderId" component={RecentOrder} />
                <Route exact path="/settings" component={Settings} />
                <Route exact path="/signin/redirection" component={AuthRedirection} />
                <Route exact path="/onboarding/allergies" component={OnboardingAllergies} />
                <Route exact path="/onboarding/diets" component={OnboardingDiet} />
                <Route exact path="/onboarding/servings" component={OnboardingServings} />
                <Route exact path="/onboarding/account" component={OnboardingCreateAccount} />
                <Route exact path="/onboarding/begin" component={OnboardingMakeAccount} />
                <Route exact path="/onboarding/groceries" component={OnboardingGroceries} />
                <Route exact path="/recipes/:id/cook" component={Cook} />
                <Route exact path="/build-menu/nutrition-program" component={NutritionProgram} />
                <Route exact path="/build-menu/diets" component={MenuDiets} />
                <Route exact path="/build-menu/meal-types" component={MealTypes} />
                <Route exact path="/build-menu" component={BuildMenuResult} />
                <Route exact path="/:influencerId/build-menu/nutrition-program" component={NutritionProgram} />
                <Route exact path="/:influencerId/build-menu/diets" component={MenuDiets} />
                <Route exact path="/:influencerId/build-menu/meal-types" component={MealTypes} />
                <Route exact path="/:influencerId/build-menu" component={BuildMenuResult} />
                <Route component={WaitingComponent(RoutesWithFooter)} location={location} />
            </Switch>
        </>
    );
};
const WaitingComponent = (Component) => {
    return (props) => (
        <Suspense
            fallback={
                <div>
                    <CircularProgress color="primary" />
                </div>
            }
        >
            <Component {...props} />
        </Suspense>
    );
};
class Routes extends Component {
    componentWillUpdate() {
        this.previousLocation = this.props.location;
    }

    render() {
        const { location } = this.props;
        const isModal = location.state && location.state.modal && this.previousLocation !== location;
        const hasBackBtn = location.state && location.state.hasBackBtn;
        return (
            <>
                <Switch location={isModal ? this.previousLocation : location}>
                    <Route exact path="/shop-recipes" component={WaitingComponent(LinkeinBioPage)} />
                    <Route exact path="/recipes/:id/print" component={WaitingComponent(RecipePrint)} />
                    <Route component={WaitingComponent(RoutesWithHeader)} location={location} />
                </Switch>
                <Suspense
                    fallback={
                        <div>
                            <CircularProgress color="primary" />
                        </div>
                    }
                >
                    {/* <Route path="/recipes/:id" render={(props) => <RecipePreview hasBackBtn={hasBackBtn} {...props} />} /> */}
                    <Route
                        exact
                        path="/menu/:orderId/share"
                        render={(props) => <ShareModalHeader anchorEl={location.state.anchorEl} {...props} />}
                    />
                    <Route exact path="/menu/:orderId/share/:id" render={(props) => <ShareModal open {...props} />} />
                </Suspense>
            </>
        );
    }
}

Routes.propTypes = {
    location: PropTypes.object.isRequired,
};

export default Routes;
