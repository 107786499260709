import styled, { css } from 'styled-components';

export const IngredientSingleContainer = styled.div`
    text-align: left;
    border: 1px solid #eff5f9;
    dispaly: flex;
    margin: 5px;
    padding: 10px;
    position: relative;
    ${(props) =>
        props.isMobile &&
        css`
            position: relative;
            padding: 8px;
            width: 92%;
            border: 1px solid #e5eaed;
            box-sizing: border-box;
            margin-bottom: 5px;
        `};

    .mobile-ingredient-substitute-block {
        ${(props) =>
            props.unRequired &&
            css`
                opacity: 0.4;
            `};
    }
`;

export const Ingredient = styled.div`
    display: flex;
    flex-direction: column;
    width: 210px;
    height: 175px;
    ${(props) =>
        props.isMobile &&
        css`
            width: 100%;
            height: auto;
        `};
`;

export const IngredientInfo = styled.div`
    width: 100%;
    display: flex;
    height: 50%;
    align-items: flex-start;
    ${(props) =>
        props.isMobile &&
        css`
            width: 100%;
            min-height: 50px;
            margin: 8px 0;
        `};

    ${(props) =>
        props.unRequired &&
        css`
            opacity: 0.4;
        `};
`;

export const ImageContainer = styled.div`
    width: 40px;
    height: 40px;

    ${(props) =>
        props.isMobile &&
        css`
            width: 35px;
            height: 35px;
        `};
`;

export const Image = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background: url(${({ image }) => image}) #d8d8d8 no-repeat center center/cover;

    ${(props) =>
        props.isMobile &&
        css`
            width: 35px;
            height: 35px;
        `};
`;

export const NameGroup = styled.div`
    padding: 0 0 0 10px;
    span {
        font-size: 12px;
        text-align: left;
    }

    ${(props) =>
        !props.isMobile &&
        css`
            width: 100%;
        `};

    ${(props) =>
        props.isMobile &&
        css`
            padding: 0 8px;
        `};
`;

export const Name = styled.div`
    color: #233747;
    font-weight: 400;
    font-family: 'Work Sans';
    font-size: 16px;
    line-height: 124%;
    max-width: 120px;
    ${(props) =>
        props.isMobile &&
        css`
            font-size: 14px;
            line-height: 146%;
            padding-top: 0;
        `};
    ${(props) =>
        props.required &&
        css`
            text-decoration: line-through;
        `}
`;

export const SubName = styled.div`
    color: #aab5bc;
    font-size: 10px;
    line-height: 146%;

    ${(props) =>
        props.isMobile &&
        css`
            font-size: 12px;
            line-height: 150%;
        `};
`;

export const QuantityAvailable = styled.div`
    height: 20px;
    display: flex;
    margin: 0 0 22px 0;
    justify-content: space-between;
    padding: 0 12px;
    flex-wrap: wrap;
    align-items: center;
    span {
        display: flex;
        font-size: 14px;
        color: #ff6b6b;
    }
    ${(props) =>
        props.isMobile &&
        css`
            justify-content: center;
            span {
                font-size: 12px;
            }
        `};
`;
export const QuantityNumber = styled.div`
    border: 1.5px solid #eff5f9;
    display: flex;
    border-radius: 6px;
    font-size: 14px;
    color: #233747;
    padding: 5px;
    opacity: 0.4;
`;

export const SubstituteTag = styled.div`
    color: #82bd73;
    font-size: 14px;
    line-height: 146%;

    ${(props) =>
        props.isMobile &&
        css`
            font-size: 12px;
            height: 30px;
            line-height: 150%;
        `};
`;

export const DropdownContainer = styled.div`
    ${(props) =>
        props.disabled &&
        css`
            opacity: 0.5;
        `};
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
    background: #ecf5f0;
    padding: 2px 3px;
    height: 28px;
    border-radius: 4px;
    margin-top: 2px;
    & > div:nth-child(1) {
        width: 100%;
    }
    span {
        white-space: nowrap;
        font-size: 12px;
        width: 100%;
        text-align: center;
        margin-right: 3px;
        &:hover {
            color: #82bd73;
        }
    }
    ${(props) =>
        props.info &&
        css`
            background: none;
            border: 1px solid #ecf5f0;
        `};
`;

export const QuantityContainer = styled.div`
    width: 100%;
    height: 42%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    & > div:nth-child(3) {
        position: initial;
        height: 30px;
        width: 100%;
        align-items: center;
        padding: 5px 10px;
    }
    ${(props) =>
        props.isMobile &&
        css`
            flex-direction: column;
            align-items: center;
            margin: 12px 0px 0 0;
            width: 100%;
            & > div:nth-child(3) {
                padding: 0px;
            }
        `};

    ${(props) =>
        props.unRequired &&
        css`
            & > div:nth-child(1)
        `};
`;
export const ShowLabel = styled.div`
    color: #bfc8cd;
    font-size: 12px;
    font-family: Work Sans;
    text-decoration-line: underline;
    width: 70%;
    text-align: center;
    cursor: pointer;
`;

export const DonotHaveLabel = styled.div`
    color: #ff6b6b;
    font-size: 13px;
    font-family: Work Sans;
    align-self: center;
    text-decoration-line: underline;
    margin: 5px 0px;
    cursor: pointer;
    padding-top: 10px;
`;

export const ProgressContainer = styled.div`
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${(props) =>
        props.isMobile &&
        css`
            width: 100%;
        `};
`;

export const Progress = styled.div`
    background: #e5eaed;
    border-radius: 4px;
    height: 3px;
    width: 100%;

    & > div {
        background: #82bd73;
        border-radius: 4px;
        width: ${({ progress }) => progress}%;
        height: 3px;
    }

    ${(props) =>
        props.isMobile &&
        css`
            height: 3px;
            width: 100%;
            & > div {
                height: 3px;
            }
        `};
`;

export const QuantityPercent = styled.div`
    padding-top: 7px;
    letter-spacing: -0.4px;
    color: #bfc8cd;
    font-weight: 500;
    font-size: 12px;
    line-height: 146%;
    text-align: center;
    font-family: Work Sans
        ${(props) =>
            props.isMobile &&
            css`
                padding-top: 9px;
                font-size: 12px;
            `};
`;

export const Quantity = styled.div`
    font-size: 14px;
    line-height: 146%;
    color: #233747;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    & > div {
        border: 1px solid #dbe2e8;
        border-radius: 8px;
        color: #233747;
        letter-spacing: -0.4px;
        font-weight: 500;
        font-size: 14px;
        line-height: 146%;
        min-width: 25px;
        text-align: center;
        text-align: center;
        width: max-content;
        margin-right: 5px;
        padding: 4px 6px;
        cursor: pointer;
    }

    ${(props) =>
        props.recipeId &&
        css`
            margin-left: auto;
            justify-content: center;
        `};

    ${(props) =>
        props.isMobile &&
        css`
            color: #000000;
            font-weight: 600;
            font-size: 12px;
            justify-content: flex-start;

            & > div {
                color: #000000;
                letter-spacing: -0.4px;
                font-weight: 600;
                font-size: 12px;
            }
        `};

    & > input {
        border: 1px solid #dbe2e8;
        border-radius: 8px;
        padding: 6px;
        color: #233747;
        letter-spacing: -0.4px;
        font-weight: 500;
        font-size: 14px;
        line-height: 146%;
        width: 20px;
        text-align: center;
    }

    ${(props) =>
        props.quantity &&
        css`
            & > input {
                width: ${(props.quantity.toString().length + 2) * 8}px;
            }
        `};

    ${(props) =>
        props.isMobile &&
        css`
            color: #000000;
            font-weight: 600;
            font-size: 12px;

            & > input {
                color: #000000;
                letter-spacing: -0.4px;
                font-weight: 600;
                font-size: 12px;
            }
        `};
`;

export const Error = styled.div`
    color: #ff6b6b;
    font-size: 12px;
`;

export const RemoveButton = styled.div`
    color: #aab5bc;
    cursor: pointer;
    right: 15px;
    div {
        position: absolute;
        top: 10px;
        right: 10px;
    }
    & > div:nth-child(2) {
        display: none;
    }
    &:hover {
        & > div:nth-child(2) {
            display: block;
        }
    }

    ${(props) =>
        props.isMobile &&
        css`
            position: absolute;
            top: -6px;
            right: -6px;
        `};

    ${(props) =>
        !props.required &&
        css`
            color: #82bd73 !important;
            opacity: 1 !important;
        `};
`;

export const HaveQuantity = styled.div`
    padding-top: 7px;
    display: flex;
    align-items: center;

    & > div:nth-child(1) {
        padding-right: 5px;
        color: #000000;
        letter-spacing: -0.4px;
        font-size: 12px;
        line-height: 146%;
    }

    ${(props) =>
        props.unRequired &&
        css`
            opacity: 0.4;
        `};
`;

export const Dropdowns = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 47%);
    justify-content: space-between;
    ${(props) =>
        props.isMobile &&
        css`
            grid-template-columns: 40% 56%;
        `};
    ${(props) =>
        props.isOpen &&
        css`
            grid-template-columns: repeat(1, 100%);
            & > div {
                width: 100%;
                & > div {
                    min-width: 100%;
                    width: fit-content;
                }
            }
        `}
`;

export const DetailsDropdown = styled.div`
    width: 100%;
    background-color: white;
    z-index: 1;
    box-shadow: rgb(13 33 32 / 10%) 0px 4px 18px;
    border-radius: 8px;
    & > div {
        padding: 12px 8px;
    }
    .detail-title {
        color: #82bd73;
        font-size: 15px;
        border-bottom: 1px solid #ecf5f0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .options {
        display: flex;
        flex-wrap: wrap;
        font-size: 12px;
        justify-content: space-between;
        .option-title {
            color: #aab5bc;
        }
        .option-value {
            color: #82bd73;
            display: flex;
            align-items: end;
            & > div {
                margin-left: 5px;
                cursor: pointer;
            }
        }
    }
`;
