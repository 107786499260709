const Messages = {
    REGISTER: 'Your account has been successfully created, check inbox to verify your email',
    SEND_EMAIL_VERIFICATION: 'Email has been sent to you, please check inbox',
    VERIFY_ACCOUNT: 'Your account has been verified successfully',
    SET_PASSWORD: 'Your password has been set successfully',
    LOGIN: 'You have been logged in successfully',
    ACCESS_USER_ACCOUNT: 'You have been logged in user accounr successfully',
    LOGOUT: 'You have been logged out successfully',
    APPLY_PROMO_CODE: 'Promo code has been applied successfully',
    EDIT_USER: 'Updated user successfully',
    CHANGE_PASSWORD: 'Password has been changed successfully',
    ADD_PAYMENT_METHOD: 'Added the payment method successfully',
    UPDATE_PAYMENT_METHOD: 'Updated the payment method successfully',
    DELETE_PAYMENT_METHOD: 'Detached the payment method successfully',
    STRIPE_INTEGRATION:
        'Oops! Something went wrong. Our technical staff has been notified and we will be looking with the utmost urgency.',
};

export { Messages };
