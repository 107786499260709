import moment from 'moment-timezone';

export const prepareDataUserPantry = (data) => {
    data.forEach((item) => {
        item.expiryDate = moment(item.expiryDate).valueOf();
    });
    return data;
};

export const prepareUserPantry = (userPantryItems) => {
    let listCategories = [];
    userPantryItems.forEach((userPantry) => {
        listCategories.push(userPantry.category?.name);
    });
    listCategories = listCategories.filter((item, index) => listCategories.indexOf(item) === index);

    let newUserPantryItems = [];
    listCategories.forEach((category) => {
        newUserPantryItems.push({
            category: category,
            userPantryItem: userPantryItems.filter((userPantry) => userPantry.category?.name === category),
        });
    });
    return newUserPantryItems;
};
