/* eslint-disable css-modules/no-unused-class */
import React from 'react';
import PropTypes from 'prop-types';
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon } from 'react-share';
import { withRouter } from 'react-router-dom';
import Copy from '@material-ui/icons/FileCopyOutlined';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import config from 'config';
import styles from './ShareMenu.module.scss';

const ShareMenuHeader = ({ location, anchorEl, handleClose }) => {
    const shareUrl = `${config[process.env.REACT_APP_ENV].FRONTEND_DOMAIN}${location.pathname}`;
    // const handleCopy = () => {
    //     copyToClipboard(shareUrl);
    //     handleClose();
    // };

    return (
        <Popper
            id="share-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            transition
            disablePortal
            className={styles.popper}
            placement="bottom-end"
        >
            {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                    <Paper classes={{ root: styles.shareBtnContainerMenu }}>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList>
                                <TwitterShareButton url={shareUrl} title="Check out my menu:">
                                    <MenuItem onClick={handleClose}>
                                        <ListItemIcon className={styles.listItemIcon}>
                                            <TwitterIcon
                                                size={32}
                                                logoFillColor="#00ACED"
                                                iconBgStyle={{ fill: 'transparent' }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText inset primary="Share on Twitter" />
                                    </MenuItem>
                                </TwitterShareButton>
                                <FacebookShareButton url={shareUrl} quote="Check out my menu:">
                                    <MenuItem onClick={handleClose}>
                                        <ListItemIcon className={styles.listItemIcon}>
                                            <FacebookIcon size={20} borderRadius={10} />
                                        </ListItemIcon>
                                        <ListItemText inset primary="Share on Facebook" />
                                    </MenuItem>
                                </FacebookShareButton>
                                <CopyToClipboard text={shareUrl}>
                                    <MenuItem aria-label="Social Button Copy" onClick={() => handleClose()}>
                                        <ListItemIcon className={styles.listItemIcon}>
                                            <Copy variant="outlined" className={styles.copyIcon} />
                                        </ListItemIcon>
                                        <ListItemText inset primary="Copy Link" />
                                    </MenuItem>
                                </CopyToClipboard>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Fade>
            )}
        </Popper>
    );
};

ShareMenuHeader.propTypes = {
    location: PropTypes.object.isRequired,
    anchorEl: PropTypes.object,
    handleClose: PropTypes.func.isRequired,
};

ShareMenuHeader.defaultProps = {
    anchorEl: null,
};

export default withRouter(ShareMenuHeader);
