export const EXTRA_LARGE_DESKTOP = 'EXTRA_LARGE_DESKTOP';
export const LARGE_DESKTOP = 'LARGE_DESKTOP';
export const DESKTOP = 'DESKTOP';
export const TABLET = 'TABLET';
export const MOBILE = 'MOBILE';

export default {
    EXTRA_LARGE_DESKTOP,
    LARGE_DESKTOP,
    DESKTOP,
    TABLET,
    MOBILE,
};
