module.exports = {
    production: {
        API_DOMAIN: 'https://easierchef.com/api/v1',
        FRONTEND_DOMAIN: 'https://easierchef.com',
        STRIPE_PUBLISHABLE_KEY: 'pk_live_dYKBIg8zx9upjcnV0CBuDfCl00hijxXJ1x',
        ENABLE_GTM: true,
    },
    development: {
        API_DOMAIN: 'https://dev.easierchef.com/api/v1',
        FRONTEND_DOMAIN: 'https://dev.easierchef.com',
        STRIPE_PUBLISHABLE_KEY: 'pk_test_huhfpQtBs3aQJNegItXGCGIa001mhSkWle',
        ENABLE_GTM: true,
    },
    local: {
        API_DOMAIN: 'http://localhost:3600/api/v1',
        // API_DOMAIN: 'http://192.168.1.186:3600/api/v1',
        FRONTEND_DOMAIN: 'http://localhost:3000',
        STRIPE_PUBLISHABLE_KEY: 'pk_test_huhfpQtBs3aQJNegItXGCGIa001mhSkWle',
        ENABLE_GTM: false,
    },
};
