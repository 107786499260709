import * as constants from '../constants';

export const getRecipesRequest = (page = 0, forceCall = false) => ({ type: constants.GET_RECIPES_REQUEST, page, forceCall });
export const getRecipesSuccess = (data) => ({
    type: constants.GET_RECIPES_SUCCESS,
    data,
});
export const getRecipesFailure = (error) => ({ type: constants.GET_RECIPES_FAILURE, error });
export const getSearchRecipes = (data) => ({ type: constants.GET_SEARCH_RESULT_SUCCESS, data });

export const setRecipesToPrint = (orderId, selectedId) => ({ type: constants.SET_RECIPES_TO_PRINT, orderId, selectedId });
export const setRecipesToPrintSuccess = (menuRecipes) => ({ type: constants.SET_RECIPES_TO_PRINT_SUCCESS, menuRecipes });

export const getRecipesToPrintRequest = (recipes) => ({ type: constants.GET_RECIPES_TO_PRINT_REQUEST, payload: recipes });
export const getRecipesToPrintSuccess = (recipes) => ({ type: constants.GET_RECIPES_TO_PRINT_SUCCESS, payload: recipes });
export const getRecipesToPrintFailure = () => ({ type: constants.GET_RECIPES_TO_PRINT_FAILURE });
export const getMenu = (orderId) => ({ type: constants.GET_MENU_REQUEST, orderId });
export const getMenuSuccess = (data) => ({
    type: constants.GET_MENU_SUCCESS,
    data,
});

export const getMenuFailure = (error) => ({ type: constants.GET_MENU_FAILURE, error });

export const getUnusedIngredientsRecipeRequest = (useId) => ({ type: constants.GET_UNUSED_INGREDIENTS_RECIPE_REQUEST, useId });
export const getUnusedIngredientsRecipeSuccess = (payload) => ({
    type: constants.GET_UNUSED_INGREDIENTS_RECIPE_SUCCESS,
    payload,
});
export const getUnusedIngredientsRecipeFailure = (error) => ({ type: constants.GET_UNUSED_INGREDIENTS_RECIPE_FAILURE, error });

export const setFilterInfo = (filterInfo) => ({
    type: constants.SET_FILTER_INFO,
    filterInfo,
});
export const clearIsChangeCookingTime = () => ({ type: constants.CLEAR_FLASH_IS_CHANGE_COOKING_TIME });
export const getCommonIngredientsRequest = (forceCall = false) => ({ type: constants.GET_COMMON_INGREDIENTS_REQUEST, forceCall });
export const getCommonIngredientsSuccess = (commonIngredients) => ({
    type: constants.GET_COMMON_INGREDIENTS_SUCCESS,
    commonIngredients,
});
export const getAllRecipesSuccess = () => ({ type: constants.GET_ALL_RECIPES_SUCCESS });
export const getCommonIngredientsFailure = (error) => ({ type: constants.GET_COMMON_INGREDIENTS_FAILURE, error });
export const setFetchingCommonIngredient = (payload) => ({ type: constants.SET_FETCHING_COMMON_INGREDIENT, payload });
export const setIsLoadedRecipes = (payload) => ({ type: constants.SET_IS_LOADED_RECIPES, payload });
