export const addIngredientToList = (ingredientList = [], ingredient) => {
    if (ingredientList.find((i) => i.ingredient === ingredient._id))
        return ingredientList.filter((i) => i.ingredient !== ingredient._id);
    return [...ingredientList, { ingredient: ingredient._id }];
};

export const flattenRequiredProducts = (requiredProducts) =>
    requiredProducts
        .map((categoryItems) => {
            categoryItems.ingredients.forEach((ingredient) => {
                ingredient.category = categoryItems.category;
            });
            return categoryItems.ingredients;
        })
        .flat();

export const adjustRequiredIngredientStatus = (
    { requiredProducts = [], nonRequiredProducts = [], substitutes = [] },
    updatedIngredientList,
) => {
    const flatIngredients = flattenRequiredProducts(requiredProducts);
    updatedIngredientList.map(({ ingredient }) => {
        const removeIngredient = flatIngredients.find(({ _id }) => _id === ingredient);
        if (removeIngredient) {
            requiredProducts = requiredProducts.map((categoryItems) =>
                categoryItems.category._id === removeIngredient.category._id
                    ? {
                          ...categoryItems,
                          ingredients: categoryItems.ingredients.map((ingredient) =>
                              ingredient._id === removeIngredient._id ? { ...ingredient, required: false } : ingredient,
                          ),
                      }
                    : categoryItems,
            );
        }
    });
    return { requiredProducts, nonRequiredProducts, substitutes };
};

export const adjustNonRequiredIngredientStatus = (
    { requiredProducts = [], nonRequiredProducts = [], substitutes = [] },
    updatedNonRequiredProductList,
) => {
    updatedNonRequiredProductList.map(({ ingredient }) => {
        nonRequiredProducts = nonRequiredProducts.map((i) => (i._id === ingredient ? { ...i, requiredToUser: true } : i));
    });
    return { requiredProducts, nonRequiredProducts, substitutes };
};

export const prepareFreeServings = (commonRecipes = []) =>
    commonRecipes.filter(({ recipeScore: { saveUpto } }) => saveUpto === 100);

export const emptyCostPayload = {
    totalPriceExtra: 0,
    totalPrice: 0,
    pricePerServing: 0,
    totalServings: 0,
    priceOfRequiredProducts: 0,
    priceOfUnusedProductsWithRoundingCorrection: 0,
    otherRecipesKitsCost: 0,
    totalAmountOfRecipes: 0,
    userSaveAmount: 0,
    paidPerServing: 0,
    usedGroceriesInPercentage: 0,
    extraProductTaxes: 0,
    actualCostWithoutDiscount: 0,
    deliveryFee: 0,
    serviceFee: 0,
    waiveOffDeliveryFee: false,
};
