import styled, { css } from 'styled-components';
export const HeaderMobileContainer = styled.div`
    padding: 0 20px !important;
    height: 64px !important;
    position: relative;
    & > div {
        display: flex;
        width: 100% !important;
        & > div:nth-child(2) {
            display: flex;
            align-items: center;
            margin: auto;
            cursor: pointer;
        }
        & > div:nth-child(3) {
            margin-left: unset !important;
            cursor: pointer;
        }
    }
`;
