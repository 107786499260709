import * as constants from '../constants';

const initialState = {
    isInitialDataFetched: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case constants.GET_INITIAL_DATA_REQUEST:
            return {
                ...state,
            };
        case constants.GET_INITIAL_DATA_SUCCESS: {
            return {
                ...state,
                isInitialDataFetched: true,
            };
        }
        default:
            return state;
    }
}
