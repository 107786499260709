import * as constants from '../constants';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import moment from 'moment-timezone';
const initialState = {
    loading: false,
    error: '',
    userDetails: null,
    editUser: null,
    dietList: [],
    changePassword: null,
    paymentMethods: [],
    editUserSuccess: false,
    recipeFavorites: [],
    isShowRecipeFavorites: false,
    isLoadedUser: false,
    isLoadedDiet: false,
    orderHistory: [],
    sortOrder: 'Date ordered',
    isLoadedFavorite: false,
    isLoadingUser: false,
    isLoadingOrderHistory: false,
    isDietsFetched: false,
};
export const filterOrder = (orderHistory, filter) => {
    let response = [];
    if (filter === 'Date ordered') {
        response = orderBy(
            orderHistory,
            (item) => {
                return moment(item.createdAt);
            },
            ['desc'],
        );
    }
    if (filter === 'Delivery scheduled') {
        response = sortBy(orderHistory, (item) => item.status !== 'Pending Delivery');
    }
    if (filter === 'Low to high price') {
        response = orderBy(
            orderHistory,
            (item) => {
                return item.cost.totalPrice;
            },
            ['acs'],
        );
    }
    if (filter === 'High to low price') {
        response = orderBy(
            orderHistory,
            (item) => {
                return item.cost.totalPrice;
            },
            ['desc'],
        );
    }
    return response;
};
export default function(state = initialState, action) {
    switch (action.type) {
        case constants.GET_USER_REQUEST:
            return {
                ...state,
                loading: true,
                isLoadingUser: true,
            };
        case constants.GET_USER_SUCCESS:
            localStorage.setItem('selectedAllergies', JSON.stringify(get(action.data, 'shopperInfo.allergies', [])));
            return {
                ...state,
                loading: false,
                userDetails: action.data,
                isLoadedUser: true,
                isLoadingUser: false,
            };
        case constants.SET_IS_LOADED_USER:
            return {
                ...state,
                isLoadedUser: false,
            };
        case constants.GET_USER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
                isLoadingUser: false,
            };
        case constants.EDIT_USER_REQUEST:
            return {
                ...state,
                loading: true,
                error: '',
                editUserSuccess: false,
            };
        case constants.EDIT_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                editUser: action.data,
                editUserSuccess: true,
                isLoadedUser: false,
            };
        case constants.EDIT_USER_FAILURE:
            return {
                ...state,
                loading: false,
                editUserSuccess: false,
                error: action.error,
            };
        case constants.TOGGLE_ALLERGY:
            return {
                ...state,
                isLoadedUser: false,
            };
        case constants.CHANGE_SHOPPERINFO:
            return {
                ...state,
                loading: false,
                userDetails: action.data,
            };
        case constants.GET_DIET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case constants.GET_DIET_SUCCESS:
            return {
                ...state,
                loading: false,
                isLoadedDiet: true,
                isDietsFetched: true,
                dietList: action.data,
            };
        case constants.GET_DIET_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case constants.CHANGE_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true,
                error: '',
            };
        case constants.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                changePassword: action.data,
                error: '',
            };
        case constants.CHANGE_PASSWORD_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case constants.GET_ORDER_HISTORY_REQUEST:
            return {
                ...state,
                loading: true,
                isLoadingOrderHistory: true,
            };
        case constants.GET_ORDER_HISTORY_SUCCESS:
            return {
                ...state,
                loading: false,
                orderHistory: action.data,
                isLoadingOrderHistory: false,
            };
        case constants.GET_ORDER_HISTORY_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
                isLoadingOrderHistory: false,
            };

        case constants.GET_PAYMENT_METHODS_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case constants.GET_PAYMENT_METHODS_SUCCESS:
            return {
                ...state,
                loading: false,
                paymentMethods: action.data,
            };

        case constants.GET_PAYMENT_METHODS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case constants.DELETE_PAYMENT_METHOD_REQUEST:
            return {
                ...state,
                loading: true,
                deletePaymentMethod: false,
                error: '',
            };
        case constants.DELETE_PAYMENT_METHOD_SUCCESS:
            return {
                ...state,
                loading: false,
                deletePaymentMethod: action.data.success,
            };
        case constants.DELETE_PAYMENT_METHOD_FAILURE:
            return {
                ...state,
                loading: false,
                deletePaymentMethod: false,
                error: action.error,
            };
        case constants.ADD_PAYMENT_METHOD_REQUEST:
            return {
                ...state,
                loading: true,
                addPaymentMethod: false,
                error: '',
            };
        case constants.ADD_PAYMENT_METHOD_SUCCESS:
            return {
                ...state,
                loading: false,
                addPaymentMethod: action.data.success,
            };
        case constants.ADD_PAYMENT_METHOD_FAILURE:
            return {
                ...state,
                loading: false,
                addPaymentMethod: false,
                error: action.error,
            };
        case constants.UPDATE_PAYMENT_METHOD_REQUEST:
            return {
                ...state,
                loading: true,
                editPaymentMethod: false,
            };
        case constants.UPDATE_PAYMENT_METHOD_SUCCESS:
            return {
                ...state,
                loading: false,
                editPaymentMethod: action.data.success,
            };
        case constants.UPDATE_PAYMENT_METHOD_FAILURE:
            return {
                ...state,
                loading: false,
                editPaymentMethod: false,
                error: action.error,
            };
        case constants.UPDATE_RECIPE_FAVORITE_REQUEST:
            return {
                ...state,
                loading: true,
                error: '',
            };
        case constants.UPDATE_RECIPE_FAVORITE_SUCCESS:
            return {
                ...state,
                loading: false,
                recipeFavorites: action.recipeFavorites,
            };
        case constants.UPDATE_RECIPE_FAVORITE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case constants.GET_RECIPE_FAVORITE:
            return {
                ...state,
            };
        case constants.GET_RECIPE_FAVORITE_SUCCESS:
            return {
                ...state,
                recipeFavorites: action.recipeFavorites,
                isLoadedFavorite: true,
            };
        case constants.GET_RECIPE_FAVORITE_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case constants.TOGGLE_SHOW_RECIPE_FAVORITE:
            return {
                ...state,
                isShowRecipeFavorites: action.isShowRecipeFavorites,
            };
        case constants.SET_SORT_ORDERS:
            return {
                ...state,
                sortOrder: action.sortOrder,
                orderHistory: filterOrder(state.orderHistory, action.sortOrder),
            };
        case constants.CLEAR_STATE_LOCAL_STORAGE:
            return {
                ...state,
                userDetails: null,
                paymentMethods: [],
            };
        default:
            return state;
    }
}
