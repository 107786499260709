import * as constants from '../constants';

export const setServings = (servings, selectedServings) => ({ type: constants.SET_SERVINGS, servings, selectedServings });
export const setServingsRecipe = (recipeId, servings, curatedBoxId, mealTypeId) => ({
    type: constants.SET_SERVINGS_RECIPE,
    recipeId,
    servings,
    curatedBoxId,
    mealTypeId,
});
export const setLeftovers = (leftovers) => ({ type: constants.SET_LEFTOVERS, payload: leftovers });
export const setDinnersCount = (dinnersCount) => ({ type: constants.SET_DINNERS_COUNT, dinnersCount });

export const setSelectedRecipeServings = (recipeId, servings) => ({
    type: constants.SET_SELECTED_RECIPE_SERVINGS,
    recipeId,
    servings,
});

export const setSelectedRecipeServingsSuccess = (payload) => ({
    type: constants.SET_SELECTED_RECIPE_SERVINGS_SUCCESS,
    payload,
});

export const setRecipeServings = (recipe, servings) => ({
    type: constants.SET_RECIPE_SERVINGS,
    data: {
        recipe,
        servings,
    },
});

export const userWantSelectMoreRecipes = {
    request: () => ({ type: USER_WANT_SELECT_MORE_RECIPES }),
};

export const setDiet = (diet) => ({
    type: constants.SET_DIET,
    payload: diet,
});
export const clearIsUpdateDiet = () => ({
    type: constants.CLEAR_IS_UPDATE_DIET,
});

export const setBulkType = (type) => ({ type: constants.SET_BULK_TYPE, bulkType: type });

export const setBulkTypeSuccess = (data) => ({ type: constants.SET_BULK_TYPE_SUCCESS, data });

export const setCurrentRecipeServings = (servings) => ({
    type: constants.SET_CURRENT_RECIPE_SERVINGS,
    payload: servings,
});

export const USER_WANT_SELECT_MORE_RECIPES = 'USER_WANT_SELECT_MORE_RECIPES';

export const handleAddRecipe = (recipe, mealTypeId, freeServings) => ({
    type: constants.ADD_RECIPE,
    recipe,
    mealTypeId,
    freeServings,
});
export const handleAddAllRecipe = (recipes, curatedBoxId) => ({
    type: constants.ADD_ALL_RECIPE,
    recipes,
    curatedBoxId,
});
export const addRecipeSuccess = (data) => ({ type: constants.ADD_RECIPE_SUCCESS, data });

export const selectedRecipes = (data) => ({ type: constants.SELECTED_RECIPES, data });

export const handleDeleteRecipe = (recipeId, mealTypeId) => ({ type: constants.DELETE_RECIPE, recipeId, mealTypeId });

export const deleteRecipeSuccess = (data) => ({ type: constants.DELETE_RECIPE_SUCCESS, data });

export const getRecipeByIdSuccess = (recipe) => ({
    type: constants.GET_RECIPE_BY_ID_SUCCESS,
    recipe,
});
export const setRecipeToPreview = (_id, orderId) => ({
    type: constants.GET_RECIPE_REQUEST,
    payload: { _id, orderId },
});

export const getRecipeSuccess = (recipe, category) => ({
    type: constants.GET_RECIPE_SUCCESS,
    recipe,
    category,
});

export const getRecipeFailure = (error) => ({ type: constants.GET_RECIPE_FAILURE, error });
export const startLoadingRecipeItemPage = () => ({ type: constants.LOADING_RECIPE_ITEM_PAGE });
export const stopLoadingRecipeItemPage = () => ({ type: constants.LOADED_RECIPE_ITEM_PAGE });

export const getGroceriesRequest = () => ({
    type: constants.GET_GROCERIES_REQUEST,
});

export const getGroceriesSuccess = (payload) => ({
    type: constants.GET_GROCERIES_SUCCESS,
    payload,
});

export const getGroceriesFailure = (error) => ({
    type: constants.GET_GROCERIES_FAILURE,
    error,
});

export const clearStateLocalStorage = () => ({
    type: constants.CLEAR_STATE_LOCAL_STORAGE,
});

export const resetProductAmountSuccess = (payload) => ({
    type: constants.RESET_PRODUCT_AMOUNT_SUCCESS,
    payload,
});

export const removedIngredientList = (data) => ({
    type: constants.REMOVED_INGREDIENT_LIST,
    data,
});

export const updateNonRequiredProductList = (data) => ({
    type: constants.ADDED_NON_REQUIRED_PRODUCT_LIST,
    data,
});

export const makeGroceryRequiredToUser = (id, required, selected) => ({
    type: constants.MAKE_GROCERY_REQUIRED_TO_USER,
    payload: {
        id,
        required,
        selected,
    },
});

export const makeGroceryRequiredToUserApi = () => ({ type: constants.MAKE_GROCERY_REQUIRED_TO_USER_API });

export const getStateLocalStorage = () => ({
    type: constants.GET_STATE_FROM_LOCAL_STORAGE,
});

export const searchRecipes = (value) => ({ type: constants.SET_RECIPES_SEARCH, payload: value });
export const removeSearchRecipes = () => ({ type: constants.REMOVE_RECIPES_SEARCH });
export const clearIsUpdateSearchRecipes = () => ({
    type: constants.CLEAR_IS_UPDATE_SEARCH_RECIPES,
});

export const filterRecipes = (categoryId) => ({ type: constants.FILTER_RECIPES, categoryId });
export const getCart = (data) => ({
    type: constants.GET_CART,
    data,
});

export const searchCommonIngredients = (value) => ({ type: constants.SEARCH_COMMON_INGREDIENTS, value });
export const filterCommonIngredients = () => ({ type: constants.FILTER_COMMON_INGREDIENTS });

export const setItemNoneRequiredToCart = (payload) => ({ type: constants.SET_ITEM_NONE_REQUIRED, payload });

export const setIsEditing = (data) => ({ type: constants.SET_IS_EDITING, data });
export const addRecipesAPI = (recipes) => ({ type: constants.ADD_RECIPE_API, recipes });
export const getRecipesFilterRequest = (influencer) => ({ type: constants.GET_RECIPES_FILTER_REQUEST, influencer });
export const getRecipesFilterSuccess = (payload) => ({ type: constants.GET_RECIPES_FILTER_SUCCESS, payload });
export const getRecipesFilterFailure = () => ({ type: constants.GET_RECIPES_FILTER_FAILURE });
export const setWaiveOffDeliveryFee = () => ({ type: constants.SET_WAIVEOFF_DELIVERY_FEE });
