import * as constants from '../constants';
import React from 'react';
import config from 'config';

const initialState = {
    questions: [
        {
            id: 1,
            question: 'What is EasierChef?',
            answer: [
                {
                    title:
                        'We help busy people know what to cook every day to reach their health goals, build good habits, or simply save money and time. We deliver on this by providing <%= totalRecipes %>+ recipes, various diet and cuisine choices, while delivering all the groceries you need directly to you, fresh from your local grocery store. If you prefer, you may also use EasierChef to email yourself a summarized list if you prefer to shop directly at the store.',
                    plugIn: [],
                },
            ],
            categoryId: 1,
        },
        // {
        //     id: 2,
        //     question: 'Why EasierChef?',
        //     answer: [
        //         {
        //             title: 'Ideally, all of the FAQs should be answering this question in one way or another. What we shouldn’t do is list a whole bunch of features. So I’d suggest to get rid of this question entirely and just beef up the other questions with benefits.',
        //             plugIn: [
        //                 'We have free, same day delivery for all orders.',
        //                 'Choose from over 250 recipes with easy instructions - and new recipes are added all the time.',
        //                 'Not everyone eats the same - we have options for diets and allergy restrictions, like Vegan, Vegetarian, Gluten Free, Dairy Free, Low Fat, Halal, and more.',
        //                 'Automatically generated grocery lists that are easy to customize and you can Simply remove ingredients you already have.',
        //                 'Digital Pantry remembers what you ordered and how much you used. Reduce your food waste and save money by searching recipes that use groceries you already have at home.',
        //             ],
        //         },
        //     ],

        //     categoryId: 1,
        // },
        {
            id: 35,
            question: 'How does it work?',
            answer: [
                {
                    title: 'Step 1: Pick recipes that you like',
                    plugIn: [
                        'Choose from <%= totalRecipes %>+ recipes personalized for your diet, allergies, and other preferences.',
                    ],
                },
                {
                    title: 'Step 2: We do the grocery shopping',
                    plugIn: [
                        'We provide 1-day delivery at your doorstep by shopping from grocery partners like Instacart, Walmart, Loblaws, and Sobeys.',
                    ],
                },
                {
                    title: 'Step 3: Cook & enjoy healthy food',
                    plugIn: [
                        'Use our FREE guided cooking feature available for every recipe that provides step-by-step instructions. Cooking has never been easier!',
                    ],
                },
                {
                    title: 'Step 4: Bonus: save money',
                    plugIn: [
                        'After cooking, you’ll have some groceries unused. We’ll suggest new recipes you can choose from to reduce your food waste and costs.',
                    ],
                },
            ],
            categoryId: 1,
        },
        {
            id: 3,
            question: 'How do you differ from recipe boxes and meal kits such as HelloFresh or MakeGoodFood?',
            answer: [
                {
                    title:
                        'We’re not a meal-kit delivery service! EasierChef helps you shop for fresh, full-sized groceries so you can save money cooking delicious recipes at home while cutting your bill and prep time in half. ',
                    plugIn: [],
                },
                {
                    title: 'You get all the benefits of cooking at home, but with the convenience of meal-kits.',
                    plugIn: [],
                },
            ],
            categoryId: 1,
        },
        {
            id: 4,
            question: 'What is Digital Pantry?',
            answer: [
                {
                    title:
                        'If you’ve ever forgotten what was in your fridge or wondered how to use leftover ingredients, Digital Pantry can help. We keep track of what you have at home so you only buy what you need. We also suggest new recipes that help you turn spare ingredients into delicious meals, ensuring you make the best use of everything you buy.',
                    plugIn: [],
                },
            ],
            categoryId: 1,
        },
        {
            id: 5,
            question: 'How do you price your products?',
            answer: [
                {
                    title:
                        'All of our products are priced at market value. We update prices daily and keep them competitive to what is found in your neighborhood.',
                    plugIn: [],
                },
            ],
            categoryId: 1,
        },
        {
            id: 6,
            question: 'Do I have to order every week/month?',
            answer: [
                {
                    title: 'Nope! You can order as frequently as you’d like.',
                    plugIn: [],
                },
            ],
            categoryId: 1,
        },
        {
            id: 7,
            question: 'What is the delivery fee?',
            answer: [
                {
                    title: 'Delivery fees are subject to the delivery location. They typically range between 0 - 5$.',
                    plugIn: [],
                },
            ],
            categoryId: 2,
        },
        {
            id: 8,
            question: 'Who will deliver my order?',
            answer: [
                {
                    title:
                        'Trained personal shoppers who are screened and professionally trained to shop and deliver your products in a safe and clean process.',
                    plugIn: [],
                },
            ],
            categoryId: 2,
        },
        {
            id: 9,
            question: 'When do you deliver?',
            answer: [
                {
                    title: 'Our hours of delivery are between 9am and 9pm.',
                    plugIn: [],
                },
            ],
            categoryId: 2,
        },
        {
            id: 10,
            question: 'Do I have to be home when my groceries are delivered?',
            answer: [
                {
                    title:
                        'Since we order from local suppliers, our groceries are not kept in insulated packaging. While we can leave your order on the porch, it’s recommended to pickup the groceries ASAP!',
                    plugIn: [],
                },
            ],
            categoryId: 2,
        },
        {
            id: 11,
            question: 'How will I be notified of my delivery?',
            answer: [
                {
                    title: 'Our driver will send you a text message or give you a call when your order is at your door.',
                    plugIn: [],
                },
            ],
            categoryId: 2,
        },
        {
            id: 12,
            question: 'Can I have my order delivered to a condo/apartment?',
            answer: [
                {
                    title:
                        'Yes, if your building allows delivery directly to your door, our team will deliver the groceries to you for the simplest convenience. If your building doesn’t allow us to deliver to your unit, we will let you know and we will safely leave the groceries at the front leasing office or main lobby for you to receive.',
                    plugIn: [],
                },
            ],
            categoryId: 2,
        },
        {
            id: 13,
            question: 'Can I have it delivered to a work address?',
            answer: [
                {
                    title:
                        'As long as you’re able to come outside to meet our driver, and our driver will not be blocked by security on site, you’re able to get your delivery at corporate locations.',
                    plugIn: [],
                },
            ],
            categoryId: 2,
        },
        {
            id: 14,
            question: 'Do you service my area?',
            answer: [
                {
                    title: (
                        <p>
                            We service almost all cities in Canada. Check out the list of cities we operate in by accessing the
                            link below: <br />
                            <a href={`${config[process.env.REACT_APP_ENV].FRONTEND_DOMAIN}/delivery/`} target="_blank">
                                {`${config[process.env.REACT_APP_ENV].FRONTEND_DOMAIN}/delivery/`}
                            </a>
                        </p>
                    ),
                    plugIn: [],
                },
            ],
            categoryId: 2,
        },
        {
            id: 15,
            question: 'Can I shop for groceries without adding full recipes to my cart?',
            answer: [
                {
                    title:
                        'Yes. You can add additional groceries (ie: oatmeal, laundry detergent, etc) outside of selected recipes when reviewing your grocery list.',
                    plugIn: [],
                },
            ],
            categoryId: 3,
        },
        {
            id: 16,
            question: 'How do I keep my groceries fresh?',
            answer: [
                {
                    title:
                        'Our vegetables and fruit should be kept in the fridge to keep them fresh as long as possible! Breads can be kept in a cupboard or some kind of dry storage, or frozen for longer use.',
                    plugIn: [],
                },
            ],
            categoryId: 3,
        },
        {
            id: 17,
            question: 'How long does it take to cook your recipes?',
            answer: [
                {
                    title: 'Most of our recipes are fast and easy, taking 20-30 minutes each.',
                    plugIn: [],
                },
                {
                    title:
                        'We also make it easy to find recipes based on length of time — simply browse recipes and use the cook time bar to select how long (or little) you’d like to cook for.',
                    plugIn: [],
                },
            ],

            categoryId: 3,
        },
        {
            id: 18,
            question: 'Which recipe should I cook first?',
            answer: [
                {
                    title:
                        'It’s up to you! We give you menus for breakfast, lunch and dinner, you can pick based on the recipes in your menu on what you prefer for the day.',
                    plugIn: [],
                },
            ],
            categoryId: 3,
        },
        {
            id: 19,
            question: ' I am picky when shopping for my produce!',
            answer: [
                {
                    title:
                        'We are too! We ensure our staff are trained properly in picking and packing the best product and perishables. We also ensure we pick products that have the latest ‘expiry’ date when selecting items. If you are unhappy with a selection one of our shoppers has made, please reach out to us at hello@easierchef.com.',
                    plugIn: [],
                },
            ],
            categoryId: 3,
        },
        {
            id: 20,
            question: 'Can I choose the brands of the groceries?',
            answer: [
                {
                    title:
                        'To simplify your shopping experience we pick the best groceries that fit your recipe requirements with a balanced focus on price, food waste, and quality.',
                    plugIn: [],
                },
            ],
            categoryId: 3,
        },
        {
            id: 21,
            question: 'Do you have vegan options?',
            answer: [
                {
                    title: 'Yes, you can choose the vegan diet filter and you will see many recipes to choose from.',
                    plugIn: [],
                },
            ],
            categoryId: 3,
        },
        {
            id: 22,
            question: 'Are there vegetarian options?',
            answer: [
                {
                    title: 'Yes, you can choose the vegetarian diet filter and you will see many recipes to choose from.',
                    plugIn: [],
                },
            ],
            categoryId: 3,
        },
        {
            id: 23,
            question: 'Is the produce organic?',
            answer: [
                {
                    title:
                        'We try to source organic groceries for produce, meats, and dairy products. However, depending on the availability, you may get regular products.',
                    plugIn: [],
                },
            ],
            categoryId: 3,
        },

        {
            id: 24,
            question: 'Is a subscription required to use the service?',
            answer: [
                {
                    title:
                        'Nope, our service is on-demand with no subscription requirement. You can even email yourself the grocery list and recipes with step-by-step instructions, for free.',
                    plugIn: [],
                },
            ],
            categoryId: 3,
        },
        {
            id: 25,
            question: 'Will I have recipes to follow on how to prepare the meals?',
            answer: [
                {
                    title:
                        'Yes, you will receive easy step-by-step instructions on how to prepare the meals by email and after checkout.',
                    plugIn: [],
                },
            ],
            categoryId: 3,
        },
        {
            id: 26,
            question: 'Will you add new recipes?',
            answer: [
                {
                    title:
                        'Absolutely! We frequently add new, fresh recipes for you to try. Make sure to sign up for our email list to be the first to try our new recipes.',
                    plugIn: [],
                },
            ],
            categoryId: 3,
        },
        {
            id: 27,
            question: 'I have a food allergy. Can I still order from EasierChef?',
            answer: [
                {
                    title:
                        'We do our best to accommodate food allergies and dietary requirements - and we can guarantee major allergies are covered. Select from gluten-free, dairy-free, fat-free, low-sodium, nut-free, halal, vegan, and vegetarian options to omit recipes that have ingredients that cannot be replaced with allergen-free alternatives. We also have a drop down selection to swap out ingredients for allergen-free alternatives.',
                    plugIn: [],
                },
            ],
            categoryId: 3,
        },
        {
            id: 28,
            question: 'I don’t eat gluten/dairy/fat/meat/only eat Halal. Do you have options for me?',
            answer: [
                {
                    title:
                        'Yes! We have allergen and diet friendly options. Simply select your dietary requirements from our filter menu to have your groceries and recipes automatically adjusted to remove those ingredients from your meal plan.',
                    plugIn: [],
                },
            ],
            categoryId: 3,
        },
        {
            id: 29,
            question: 'Where can I find my past recipes?',
            answer: [
                {
                    title:
                        'If you created an account after checking out, you can find your previously ordered recipe menus within your account settings or profile pages. You will also have the option to view them through email upon checkout if you do not create an account.',
                    plugIn: [],
                },
            ],
            categoryId: 3,
        },
        {
            id: 30,
            question: 'Do you provide nutritional information for your recipes?',
            answer: [
                {
                    title:
                        'Yes! In your recipe card, look for the “Nutritional Information” tab. You can also find the average macro breakdown in your cart while you browse recipes.',
                    plugIn: [],
                },
            ],
            categoryId: 3,
        },
        {
            id: 31,
            question: 'Can I customize my meal and make substitutions?',
            answer: [
                {
                    title:
                        'Absolutely! Use any ingredient you have at home in your meals, or add it in the Finalize Groceries screen when you’re shopping. This way, we don’t deliver the ingredient you don’t need, and you save money plus reduce food waste.',
                    plugIn: [],
                },
            ],
            categoryId: 3,
        },
        {
            id: 32,
            question: 'I am missing some items from my order. How do I request a refund or launch an inquiry?',
            answer: [
                {
                    title:
                        'Please contact us at hello@easierchef.com with your order number and missing items and we will look into processing your refund for you.',
                    plugIn: [],
                },
            ],
            categoryId: 4,
        },
        {
            id: 33,
            question: 'How do I contact someone from EasierChef to discuss an order, partnership, or any additional inquiries?',
            answer: [
                {
                    title:
                        'If you are an influencer, partner, or brand interested in working with us, please reach out to us at hello@easierchef.com and we will gladly point you in the right direction. We try our best to reply within a few hours up to 24 hours.',
                    plugIn: [],
                },
            ],

            categoryId: 4,
        },
        {
            id: 34,
            question: 'Can I cancel or change my order?',
            answer: [
                {
                    title:
                        'Please reach out to us as soon as you can and one of our customer service representatives will help. Normally, we require a 24-hour notice before your scheduled delivery date to cancel or change your order.',
                    plugIn: [],
                },
            ],
            categoryId: 4,
        },
        {
            id: 35,
            question: 'How do I use Easierchef for planning my clients’ meals?',
            answer: [
                {
                    title:
                        'Easierchef makes it convenient for you to design a meal plan according to your clients’ fitness goals. Simply click on the ‘Build Menu’ tab and choose a particular goal, meal type, and diet plan. You can then order for the client or use our ‘copy’ link to share the customized menu plan with the client where all recipes are automatically added for them.',
                    plugIn: [],
                },
            ],
            categoryId: 5,
        },
        {
            id: 36,
            question: 'How did you compute the recipes’ nutrition information?',
            answer: [
                {
                    title: (
                        <p>
                            Using the <a href="https://fdc.nal.usda.gov/">U.S. Department of Agriculture</a> (USDA) database, we
                            meticulously computed each ingredient to get the caloric equivalent and nutrient amount of a recipe.
                            The nutrition information shown is on a per serving basis.
                        </p>
                    ),
                },
            ],
            categoryId: 5,
        },
        {
            id: 37,
            question: 'How are the diet plans selected?',
            answer: [
                {
                    title: (
                        <div>
                            <p>
                                We chose the most common diet plans followed by the general public. Each one is reviewed to
                                include ingredients only specified for a particular diet plan.
                            </p>
                            <ul>
                                <li key="category-5-id-37_1">
                                    Vegan: Strictly plant-based recipes with no animal and animal products.
                                </li>
                                <li key="category-5-id-37_2">Vegetarian: For vegetarians who eat dairy products but omit egg.</li>
                                <li key="category-5-id-37_3">Pescatarian: For vegetarians who eat fish, seafood, and egg.</li>
                                <li key="category-5-id-37_4">Gluten-Free: Excludes food items containing gluten.</li>
                                <li key="category-5-id-37_5">Sugar-Free: Recipes with no added sugar.</li>
                                <li key="category-5-id-37_6">Paleo: Includes whole, unprocessed foods.</li>
                                <li key="category-5-id-37_7">
                                    Keto: Low carbohydrate recipes with good quality protein and fat sources.
                                </li>
                            </ul>
                        </div>
                    ),
                },
            ],
            categoryId: 5,
        },
        {
            id: 38,
            question: 'I follow a diet plan not in your list. Can I still get Easierchef recipes and meal plan?',
            answer: [
                {
                    title:
                        'Yes. Let us know which diet plan you are following and we’ll select recipes for you. Click the ‘Contact Us’ link found in the FAQs tab to email us at hello@easierchef.com.',
                },
            ],
            categoryId: 5,
        },
        {
            id: 39,
            question: 'In the Build Menu function, how did you choose the recipes for each goal?',
            answer: [
                {
                    title: (
                        <div>
                            <p>
                                Easierchef ensures that each function is carefully reviewed and sourced from credible sources. The
                                basis for the ‘Build Menu’ functionality is the Daily Values Percentage (%DV) of each recipe.
                            </p>
                            <p>
                                We abide by the Food and Drug Administration (FDA) rules on{' '}
                                <a href="https://www.fda.gov/food/new-nutrition-facts-label/lows-and-highs-percent-daily-value-new-nutrition-facts-label">
                                    Nutrition Facts Label
                                </a>
                                . The %DV used determines if a serving of a recipe is high or low in a particular nutrient in
                                which:
                            </p>
                            <ul>
                                <li key="category-5-id-39_1">5% DV or less of a nutrient per serving is considered low.</li>
                                <li key="category-5-id-39_2">20% DV or more of a nutrient per serving is considered high.</li>
                            </ul>
                        </div>
                    ),
                },
            ],
            categoryId: 5,
        },
        {
            id: 40,
            question: 'How did you choose recipes for the Build Muscle goal?',
            answer: [
                {
                    title: (
                        <div>
                            <p>
                                Using the %DV, we chose recipes with high protein (more than 20% DV), low to moderate carbohydrate
                                (less than 20% DV), and moderate fat (about 20% DV).
                            </p>
                            <p>
                                We set these percentages to consider ALL physical activities in this particular health goal. This
                                means, we understand the complexity of your clients macronutrient so we made sure that every
                                client’s needs are well-accommodated.
                            </p>
                            <p>Easierchef has hundreds of recipes with different %DV you can choose from.</p>
                        </div>
                    ),
                },
            ],
            categoryId: 5,
        },
        {
            id: 41,
            question: 'How did you choose recipes for the Lose Weight goal?',
            answer: [
                {
                    title: (
                        <div>
                            <p>
                                Using the %DV, we chose recipes with moderate to high protein (about 20% DV), low to moderate
                                carbohydrate (less than 20% DV), and low to moderate fat (less than 20% DV).
                            </p>
                            <p>
                                We set these percentages to consider ALL physical activities in this particular health goal. This
                                means, we understand the complexity of your clients macronutrient so we made sure that every
                                client’s needs are well-accommodated.
                            </p>
                            <p>Easierchef has hundreds of recipes with different %DV you can choose from.</p>
                        </div>
                    ),
                },
            ],
            categoryId: 5,
        },
        {
            id: 42,
            question: 'How did you choose recipes for the Strength & Endurance goal?',
            answer: [
                {
                    title: (
                        <div>
                            <p>
                                Using the %DV, we chose recipes with moderate protein (about 20% DV), moderate carbohydrate (about
                                20% DV), and moderate fat (about 20% DV).
                            </p>
                            <p>
                                We set these percentages to consider ALL physical activities in this particular health goal. This
                                means, we understand the complexity of your clients macronutrient so we made sure that every
                                client’s needs are well-accommodated.
                            </p>
                            <p>Easierchef has hundreds of recipes with different %DV you can choose from.</p>
                        </div>
                    ),
                },
            ],
            categoryId: 5,
        },
        {
            id: 43,
            question: 'How did you choose recipes for the Weight Maintenance goal?',
            answer: [
                {
                    title: (
                        <div>
                            <p>
                                Weight maintenance assumes that your client has already achieved a fitness goal. As such, we did
                                not assign a strict %DV for this as we want to provide a more lenient choice of which
                                macronutrient ratio they want to maintain. This goal abides by the recommendation set by The Food
                                and Nutrition Board of the Institutes of Medicine.
                            </p>
                            <p>
                                We set these percentages to consider ALL physical activities in this particular health goal. This
                                means, we understand the complexity of your clients macronutrient so we made sure that every
                                client’s needs are well-accommodated.
                            </p>
                            <p>Easierchef has hundreds of recipes with different %DV you can choose from.</p>
                        </div>
                    ),
                },
            ],
            categoryId: 5,
        },
        {
            id: 44,
            question: 'How did you choose recipes for the Health Improvement goal?',
            answer: [
                {
                    title: (
                        <div>
                            <p>
                                Using the %DV, we chose the recipes with moderate protein (about 20% DV), low to moderate
                                carbohydrates (less than about 20% DV), and low to moderate fats (less than 20% DV).
                            </p>
                            <p>
                                We set these percentages to consider ALL physical activities in this particular health goal. This
                                means, we understand the complexity of your clients macronutrient so we made sure that every
                                client’s needs are well-accommodated.
                            </p>
                            <p>Easierchef has hundreds of recipes with different %DV you can choose from.</p>
                        </div>
                    ),
                },
            ],
            categoryId: 5,
        },
        {
            id: 45,
            question: 'How do I use the caloric averaging function?',
            answer: [
                {
                    title: (
                        <div>
                            <p>
                                The caloric averaging formula is a rough estimate of how much your client is getting from chosen
                                recipes. This can also be used as a guide on how the calories are distributed every time a recipe
                                is added.
                            </p>
                            <p>
                                For instance, a client with a 1600 calorie goal should roughly have 400 calories per serving,
                                considering that all meal types are included (Breakfast, Lunch, Dinner, and Snacks).
                            </p>
                        </div>
                    ),
                },
            ],
            categoryId: 5,
        },
        {
            id: 46,
            question: 'Why use caloric averaging instead of adding the total calories of recipes per day?',
            answer: [
                {
                    title: (
                        <div>
                            <p>
                                Easierchef gives everyone the freedom to choose their own meals regardless of meal types and
                                schedules. Therefore, users can add in as many recipes as they want for any kind of meal, number
                                of days, and serving portions.
                            </p>
                            <p>
                                For example, a client only adds breakfast recipes and skips choosing lunch and dinner. This would
                                disrupt the usual count of caloric intake per day. The same scenarios apply to those with
                                different serving portions and/or the number of days they opt to choose.
                            </p>
                        </div>
                    ),
                },
            ],
            categoryId: 5,
        },
    ],
    categories: [
        {
            id: 1,
            key: 'aboutEasierChef',
            title: 'About EasierChef',
        },
        {
            id: 2,
            key: 'delivery',
            title: 'Delivery',
        },
        {
            id: 3,
            key: 'ourFood',
            title: 'Our Food',
        },
        {
            id: 4,
            key: 'myOrder',
            title: 'My Order',
        },
        {
            id: 5,
            key: 'nutritionProgram',
            title: 'Nutrition',
        },
    ],
    search: {
        key: '',
        result: [12, 4],
    },
};

export default function(state = initialState, action) {
    switch (action.type) {
        default:
            return state;
    }
}
