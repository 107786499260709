import styled, { css } from 'styled-components';

export const ServingsContainer = styled.div`
    display: flex;
    margin: 0;
    padding: 0;
    align-items: center;
`;

export const Minus = styled.span`
    cursor: pointer;

    ${(props) =>
        css`
            color: ${props.color.grey4};
            &:hover,
            &:active,
            &:focus {
                color: ${props.color.grey3};
            }
        `};
`;

export const Plus = styled.span`
    cursor: pointer;
    color: #82bd73;

    &:hover,
    &:active,
    &:focus {
        background: #82bd73;
        border-radius: 50%;

        & > div > svg > path {
            color: #ffffff;
        }
    }
`;

export const Value = styled.span`
    margin: 0 12px;
    font-size: 16px;

    ${(props) =>
        css`
            color: ${props.color.grey2};
        `};
`;
