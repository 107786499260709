import { call, put, select, takeEvery } from 'redux-saga/effects';
import * as constants from '../constants';
import * as actions from '../actions/featureFlags.actions';
import serverAPI from 'services/featureFlags.service';
import history from '../../history';
import { errorNotification } from 'helpers/errorNotification';
export const selectIsFetchedFeatureFlags = () => select(({ featureFlagsReducer }) => featureFlagsReducer.isFetched);
export const selectDataFeatureFlags = () => select(({ featureFlagsReducer }) => featureFlagsReducer.data);
export function* getFeatureFlags() {
    try {
        const isFetched = yield selectIsFetchedFeatureFlags();
        const data = yield selectDataFeatureFlags();
        if (!isFetched) {
            const response = yield call(serverAPI.getFeatureFlags);
            if (response.data) {
                yield put(actions.getFeatureFlagsSuccess(response.data));
            }
        }
    } catch (error) {
        const data = {};
        const text = 'Error in getFeatureFlags saga';
        errorNotification(error, data, text);
    }
}
export default function* () {
    yield takeEvery(constants.GET_FEATURE_FLAGS_REQUEST, getFeatureFlags);
}
