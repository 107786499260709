import * as constants from '../constants';
import get from 'lodash/get';

const setSteps = (currentStep, steps, payload) => {
    switch (currentStep) {
        case 1:
            if (steps[1].info.diets === payload) {
                steps[1].info.diets = '';
            } else {
                steps[1].info.diets = payload;
            }
            break;
        case 2:
            steps[2].info.allergies = payload;
            break;
        case 3:
            steps[3].info.servings = payload;
            break;
        case 4:
            steps[4].info.groceries = payload;
            break;
        default:
            break;
    }
    localStorage.setItem(
        'onboarding',
        JSON.stringify({
            ...JSON.parse(localStorage.getItem('onboarding')),
            steps: steps,
            isUpdating: true,
        }),
    );
    return steps;
};

const onboarding = localStorage.getItem('onboarding') ? JSON.parse(localStorage.getItem('onboarding')) : {};

const initialState = {
    currentStep: get(onboarding, 'currentStep', 0),
    steps: get(onboarding, 'steps', [
        {
            name: 'BEGIN',
            order: 0,
        },
        {
            name: 'CHOOSE_DIETS',
            order: 1,
            info: {
                diets: '',
            },
        },
        {
            name: 'CHOOSE_ALLERGIES',
            order: 2,
            info: {
                allergies: [],
            },
        },
        {
            name: 'CHOOSE_SERVINGS',
            order: 3,
            info: {
                servings: 2,
            },
        },
        {
            name: 'CHOOSE_GROCERIES',
            order: 4,
            info: {
                groceries: [],
            },
        },
    ]),
    isUpdating: get(onboarding, 'isUpdating', false),
};

export default function (state = initialState, action) {
    switch (action.type) {
        case constants.SET_CURRENT_STEP:
            localStorage.setItem(
                'onboarding',
                JSON.stringify({
                    ...JSON.parse(localStorage.getItem('onboarding')),
                    currentStep: action.stepIndex,
                }),
            );
            return {
                ...state,
                currentStep: action.stepIndex,
            };
        case constants.ADD_INFO_ONBOARDING:
            return {
                ...state,
                steps: setSteps(state.currentStep, state.steps, action.payload),
                isUpdating: true,
            };
        case constants.UPDATED_PAYLOAD_ONBOARDING_SUCCESS:
            return {
                currentStep: 0,
                steps: [
                    {
                        name: 'BEGIN',
                        order: 0,
                    },
                    {
                        name: 'CHOOSE_DIETS',
                        order: 1,
                        info: {
                            diets: '',
                        },
                    },
                    {
                        name: 'CHOOSE_ALLERGIES',
                        order: 2,
                        info: {
                            allergies: [],
                        },
                    },
                    {
                        name: 'CHOOSE_SERVINGS',
                        order: 3,
                        info: {
                            servings: 2,
                        },
                    },
                    {
                        name: 'CHOOSE_GROCERIES',
                        order: 4,
                        info: {
                            groceries: [],
                        },
                    },
                ],
                isUpdating: false,
            };
        default:
            return state;
    }
}
