import Cookies from 'js-cookie';
export default {
    name: 'v1.1',
    up: () => {
        const diet = localStorage.getItem('dietPlan');
        if (diet && diet.toLowerCase() === 'meat & veggies') {
            localStorage.setItem('dietPlan', 'everything');
        }
    },
};
