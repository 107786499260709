import { useMediaQuery } from 'react-responsive';
import screenTypes from 'constants/screenTypes';
import screensConfig from 'constants/screensConfig';

export default () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: `(min-width: ${screensConfig[screenTypes.DESKTOP].minBreakpoint}px)`,
    });

    const isLargeDesktop = useMediaQuery({
        query: `(min-width: ${screensConfig[screenTypes.LARGE_DESKTOP].minBreakpoint}px)`,
    });

    const isBigScreen = useMediaQuery({
        query: `(min-width: ${screensConfig[screenTypes.EXTRA_LARGE_DESKTOP].minBreakpoint}px)`,
    });

    const isTabletOrMobile = useMediaQuery({
        query: `(max-width: ${screensConfig[screenTypes.TABLET].maxBreakpoint}px)`,
    });

    const isMobile = useMediaQuery({
        query: `(max-width:${screensConfig[screenTypes.MOBILE].maxBreakpoint}px)`,
    });

    return {
        isDesktopOrLaptop,
        isLargeDesktop,
        isBigScreen,
        isTabletOrMobile,
        isMobile,
        screensConfig,
    };
};
