import * as constants from '../constants';

export const login = ({ email, password }) => ({
    type: constants.LOGIN_REQUEST,
    email,
    password,
});

export const adminAccessUserAccount = ({ emailAddress, password, userEmail }) => ({
    type: constants.ADMIN_ACCESS_USER_ACCOUNT_REQUEST,
    emailAddress,
    password,
    userEmail,
});

export const register = (data) => ({
    type: constants.REGISTER_REQUEST,
    ...data,
});

export const logout = () => ({
    type: constants.LOGOUT_REQUEST,
});

export const forgotPass = ({ email }) => ({
    type: constants.FORGOTPASS_REQUEST,
    email,
});

export const resetPass = (data) => ({
    type: constants.RESETPASS_REQUEST,
    data,
});

export const sendEmailVerification = (data) => ({
    type: constants.SEND_EMAIL_VERIFICATION_REQUEST,
    ...data,
});

export const verifyEmail = (data) => ({
    type: constants.VERIFY_EMAIL_REQUEST,
    ...data,
});

export const setPassword = (data) => ({
    type: constants.SET_PASSWORD_REQUEST,
    payload: data,
});
export const unauthenticatedUser = () => ({
    type: constants.UNAUTHORISED_USER_REQUEST,
});

export const unauthorisedUserSuccess = () => ({
    type: constants.UNAUTHORISED_USER_SUCCESS,
});

export const loginGoogle = () => ({
    type: constants.LOGIN_GOOGLE_REQUEST,
});

export const loginFacebook = (data) => ({
    type: constants.LOGIN_FACEBOOK_REQUEST,
    ...data,
});

export const loginSuccess = (data) => ({ type: constants.LOGIN_SUCCESS, data });
export const loginFailure = (error) => ({ type: constants.LOGIN_FAILURE, error });

export const adminAccessUserAccountSuccess = (data) => ({ type: constants.ADMIN_ACCESS_USER_ACCOUNT_SUCCESS, data });
export const adminAccessUserAccountFailure = (error) => ({ type: constants.ADMIN_ACCESS_USER_ACCOUNT_FAILURE, error });

export const registerSuccess = (data) => ({ type: constants.REGISTER_SUCCESS, data });
export const registerFailure = (error) => ({ type: constants.REGISTER_FAILURE, error });

export const forgotPassSuccess = (data) => ({ type: constants.FORGOTPASS_SUCCESS, data });
export const forgotPassFailure = (error) => ({ type: constants.FORGOTPASS_FAILURE, error });

export const resetPassSuccess = (data) => ({ type: constants.RESETPASS_SUCCESS, data });
export const resetFailure = (error) => ({ type: constants.RESETPASS_FAILURE, error });

export const logoutSuccess = (data) => ({ type: constants.LOGOUT_SUCCESS, data });
export const logoutFailure = (error) => ({ type: constants.LOGOUT_FAILURE, error });

export const sendEmailVerificationSuccess = (data) => ({ type: constants.SEND_EMAIL_VERIFICATION_SUCCESS, data });
export const sendEmailVerificationFailure = (error) => ({ type: constants.SEND_EMAIL_VERIFICATION_FAILURE, error });

export const verifyEmailSuccess = (data) => ({ type: constants.VERIFY_EMAIL_SUCCESS, data });
export const verifyEmailFailure = (error) => ({ type: constants.VERIFY_EMAIL_FAILURE, error });

export const setPasswordSuccess = (data) => ({ type: constants.SET_PASSWORD_SUCCESS, data });
export const setPasswordFailure = (error) => ({ type: constants.SET_PASSWORD_FAILURE, error: error.errors });

export const loginGoogleSuccess = (data) => ({ type: constants.LOGIN_GOOGLE_SUCCESS, data });
export const loginGoogleFailure = (error) => ({ type: constants.LOGIN_GOOGLE_FAILURE, error });

export const loginFacebookSuccess = (data) => ({ type: constants.LOGIN_FACEBOOK_SUCCESS, data });
export const loginFacebookFailure = (error) => ({ type: constants.LOGIN_FACEBOOK_FAILURE, error });
export const closeIsLogining = () => ({ type: constants.CLOSE_IS_LOGINING });
export const clearStateRegisterSuccess = () => ({ type: constants.CLEAR_STATE_REGISTER_SUCCESS });
