import * as constants from '../constants';

export const getExtraProductsCategoriesRequest = () => ({ type: constants.GET_EXTRA_PRODUCTS_CATEGORIES_REQUEST });
export const getExtraProductsCategoriesSuccess = (extraProductsCategories) => ({
    type: constants.GET_EXTRA_PRODUCTS_CATEGORIES_SUCCESS,
    extraProductsCategories,
});
export const getExtraProductsCategoriesFailure = (error) => ({ type: constants.GET_EXTRA_PRODUCTS_CATEGORIES_FAILURE, error });

export const toggleExtraCategory = (categoryName) => ({ type: constants.TOGGLE_EXTRA_CATEGORY, categoryName });
export const addExtraCategory = (categoryName) => ({ type: constants.ADD_EXTRA_CATEGORY, categoryName });
export const deleteExtraCategory = (categoryName) => ({ type: constants.DELETE_EXTRA_CATEGORY, categoryName });
export const clearFlagIsChangeExtraProduct = () => ({
    type: constants.CLEAR_FLAGS_IS_CHANGE_EXTRA_CATEGORIES,
});
