const colorMap = {
    brandGreen: '#82BD73',
    orange: '#F7BA50',
    darkOrange: '#fd891b',
    green: '#00BE82',
    greenHover: '#01AB75',
    greenPressed: '#01AB75',
    greenDisabled: '#00BE82',
    greenDisabledOpacity: 0.3,
    grey0: '#0D2120',
    grey1: '#233747',
    grey2: '#909DA6',
    grey3: '#AAB5BC',
    grey4: '#BFC8CD',
    grey5: '#E5EAED',
    grey6: '#F7FAFC',
    gray7: '#4F5F6C',
    grey8: '#909DAE',
    white: '#FFFFFF',
};

export default function () {
    return colorMap;
}
