const https = require('https');

const errorWebHookURL = process.env.REACT_APP_SLACK_ERROR_WEB_HOOK_URL;
const successWebHookURL = process.env.REACT_APP_SLACK_UPDATES_WEB_HOOK_URL;
const userAccountNotification = {
    username: 'Deployment successful', // This will appear as user name who posts the message
    text: `Deployment successful - ${process.env.REACT_APP_ENV}`, // text
    icon_emoji: ':bangbang:', // User icon, you can also use custom icons here
    attachments: [
        {
            // this defines the attachment block, allows for better layout usage
            color: '#eed140', // color of the attachments sidebar.
            fields: [
                // actual fields
                {
                    title: 'Environment', // Custom field
                    value: process.env.REACT_APP_ENV, // Custom value
                    short: true, // long fields will be full width
                },
            ],
        },
    ],
};

/**
 * Handles the actual sending request.
 * We're turning the https.request into a promise here for convenience
 * @param webhookURL
 * @param messageBody
 * @return {Promise}
 */
function sendSlackMessage(webhookURL, messageBody) {
    // make sure the incoming message body can be parsed into valid JSON
    try {
        messageBody = JSON.stringify(messageBody);
    } catch (e) {
        throw new Error('Failed to stringify messageBody', e);
    }

    const requestOptions = {
        method: 'POST',
        header: {
            'Content-Type': 'application/json',
        },
        body: messageBody,
    };

    return fetch(webhookURL, requestOptions);
}

// main
export const sendMessage = async (content) => {
    if (!errorWebHookURL) {
        console.error('Please fill in your Webhook URL');
    }
    try {
        const slackResponse = await sendSlackMessage(errorWebHookURL, content);
    } catch (e) {
        console.error('There was a error with the request', e);
    }
};

export const sendSuccessMessage = async (content) => {
    if (!successWebHookURL) {
        console.error('Please fill in your Webhook URL');
    }
    try {
        const slackResponse = await sendSlackMessage(successWebHookURL, content);
    } catch (e) {
        console.error('There was a error with the request', e);
    }
};

// sendSuccessMessage(userAccountNotification);
