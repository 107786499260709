import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useScreenType from 'hooks/useScreenType';

import IconButton from '@material-ui/core/IconButton';
import { Secondary } from '../common/Buttons';
import Close from '@material-ui/icons/Close';

import RecipeCard from '../common/RecipeCard/index';
import ExtraProduct from './ExtraProduct';

import history from '../../../src/history';

import { updateStateSummaryPopup } from 'store/actions/ui-settings.actions';

import {
    SummaryContainer,
    SummaryContent,
    HeaderContainer,
    SummaryTittle,
    ContentContainer,
    ListRecipes,
    RecipeCardContainer,
    FooterContainer,
    ServingContainer,
    Serving,
    ListExtraProducts,
    ExtraProductTitle,
    Notification,
    LinkText,
    AddRecipesButton,
    ReviewButton,
} from './styles';
import { Button } from '@material-ui/core';
import { handleDeleteRecipe } from 'store/actions/recipe.action';
import Icons from 'components/common/Icons/Icons';
import Links from '../../../src/components/common/Links/Links';
import { showLoginPopup } from 'store/actions/loginModal.action';

const SummaryPopupContainer = () => {
    const { isMobile, isDesktopOrLaptop, isTabletOrMobile } = useScreenType();
    const dispatch = useDispatch();
    const selectedRecipes = useSelector((state) => state.recipeReducer.Cart.selectedRecipes);
    const selectedRecipesLength = useSelector((state) => state.recipeReducer.Cart.selectedRecipesLength);
    const pricePerServing = useSelector((state) => state.recipeReducer.Cart.pricePayload.pricePerServing);
    const selectedExtraProducts = useSelector((state) => state.extraProductReducer.selected);
    const { loggedInUser } = useSelector((state) => state.authenReducer);
    const [onClose, setClose] = useState(false);

    const back = (e) => {
        e.stopPropagation();
        dispatch(updateStateSummaryPopup(false));
    };

    return (
        <SummaryContainer
            scroll="body"
            open={true}
            onClose={back}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            $isTabletOrMobile={isTabletOrMobile}
        >
            <SummaryContent>
                <HeaderContainer>
                    <IconButton onClick={back} size="small" aria-label="Add" variant="outline">
                        <Close />
                    </IconButton>
                    <div onClick={() => dispatch(handleDeleteRecipe())}>Clear cart</div>
                    <SummaryTittle>All your meals</SummaryTittle>
                </HeaderContainer>
                <ContentContainer isDesktopOrLaptop={isDesktopOrLaptop}>
                    <ListRecipes isMobile={isMobile} isDesktopOrLaptop={isDesktopOrLaptop}>
                        {selectedRecipes &&
                            selectedRecipesLength > 0 &&
                            selectedRecipes.map((recipe, index) => (
                                <RecipeCardContainer isMobile={isMobile} isDesktopOrLaptop={isDesktopOrLaptop} key={index}>
                                    <RecipeCard
                                        key={index}
                                        isCancel={true}
                                        size={isMobile ? 'large' : 'medium'}
                                        recipe={recipe}
                                        mealTypeId={recipe.mealTypeId}
                                    />
                                </RecipeCardContainer>
                            ))}
                        {selectedRecipesLength === 0 && <Notification>You haven't selected any recipes yet</Notification>}
                    </ListRecipes>
                    {selectedRecipesLength === 0 && (
                        <AddRecipesButton>
                            <Links
                                label="Discover recipes"
                                size="medium"
                                type="secondary"
                                name="plus"
                                onClick={() => {
                                    history.push('/recipes');
                                    dispatch(updateStateSummaryPopup(false));
                                }}
                            />
                        </AddRecipesButton>
                    )}
                    {selectedExtraProducts.length !== 0 && (
                        <ListExtraProducts isMobile={isMobile}>
                            <ExtraProductTitle>Extra products</ExtraProductTitle>
                            {selectedExtraProducts.map((extraProduct, index) => (
                                <ExtraProduct key={index} extraProduct={extraProduct} />
                            ))}
                        </ListExtraProducts>
                    )}
                </ContentContainer>
                <FooterContainer isMobile={isMobile}>
                    <ServingContainer isDesktopOrLaptop={isDesktopOrLaptop}>
                        <Serving>Price per serving :{` $${pricePerServing ? pricePerServing.toFixed(2) : 0}`}</Serving>
                        <ReviewButton>
                            <Secondary
                                size="medium"
                                disabled={selectedRecipes.length === 0}
                                title={'Review & Checkout'}
                                onClick={() => {
                                    dispatch(updateStateSummaryPopup(false));
                                    if (!loggedInUser) {
                                        const url = new URL(window.location);
                                        url.searchParams.set('loginmode', 'email');
                                        window.history.pushState({}, '', url);
                                        dispatch(showLoginPopup(true));
                                    } else history.push('/groceries');
                                }}
                            />
                        </ReviewButton>
                    </ServingContainer>
                </FooterContainer>
            </SummaryContent>
        </SummaryContainer>
    );
};

export default SummaryPopupContainer;
