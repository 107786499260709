import styled, { css } from 'styled-components';

export const IngredientManage = styled.div`
    ${(props) =>
        props.isRemoved &&
        css`
            opacity: 0.4;
        `};

    ${(props) =>
        props.isMobile &&
        css`
            padding: 12px;
            box-sizing: border-box;
            border-radius: 8px;
            border: 1px solid #e5eaed;
        `};
`;

export const IngredientContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const IngredientInfo = styled.div`
    display: flex;
    align-items: center;
    width: 35%;

    ${(props) =>
        props.isMobile &&
        css`
            width: 50%;
        `};
`;

export const Image = styled.div`
    width: 88px;
    height: 56px;
    background: url(${({ image }) => image}) #d8d8d8 no-repeat center center/cover;
    border-radius: 8px;

    ${(props) =>
        props.isMobile &&
        css`
            width: 32px;
            height: 32px;
            border-radius: 4px;
        `};
`;

export const IngredientName = styled.div`
    padding: 0 24px;

    ${(props) =>
        props.isMobile &&
        css`
            padding: 0 8px;
        `};
`;

export const Name = styled.div`
    color: #233747;
    font-weight: 500;
    font-size: 16px;
    line-height: 124%;
    padding-bottom: 2px;

    ${(props) =>
        props.isMobile &&
        css`
            font-size: 14px;
            line-height: 146%;
        `};
`;

export const DatePurchase = styled.div`
    color: #909da6;
    letter-spacing: -0.4px;
    font-size: 14px;
    line-height: 146%;

    & > span {
        color: #233747;
    }
`;

export const CustomIngredient = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 65%;
    padding-right: 100px;
    position: relative;

    ${(props) =>
        props.isMobile &&
        css`
            padding: unset;
            width: 50%;
        `};
`;

export const GrocerySize = styled.div`
    display: flex;
    align-items: center;
    width: 50%;
    justify-content: center;

    ${(props) =>
        props.isEdit &&
        css`
            padding: 14px 0;
            margin-top: 14px;
            border-top: 1px solid #e5eaed;
        `};
`;

export const ButtonMinus = styled.div`
    padding-right: 16px;
    cursor: pointer;
    color: #82bd73;

    ${(props) =>
        props.disabled &&
        css`
            cursor: unset;
            color: #bfc8cd;
        `};
`;

export const Quantity = styled.div`
    border: 1px solid #e5eaed;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 8px 16px;
    color: #233747;
    letter-spacing: -0.4px;
    font-size: 14px;
    line-height: 146%;
    min-width: 88px;
    text-align: center;

    & > input {
        border: none;
        background: none;
        text-align: center;
        min-width: 56px;
        width: 56px;
        ${(props) =>
            props.valueQuantity &&
            css`
                width: ${(props.valueQuantity.toString().length + 1) * 8}px;
            `};
    }

    ${(props) =>
        props.isEdit &&
        css`
            width: 100%;
        `};
`;

export const ButtonPlus = styled.div`
    padding-left: 16px;
    cursor: pointer;
    color: #82bd73;

    ${(props) =>
        props.disabled &&
        css`
            cursor: unset;
            color: #bfc8cd;
        `};
`;

export const Progress = styled.div`
    display: flex;
    align-items: center;
    min-width: 278px;
    padding: 0 10px;
    width: 50%;
    justify-content: center;

    & > div:nth-child(1) > div {
        display: flex;
        align-items: center;
    }

    ${(props) =>
        props.isMobile &&
        css`
            justify-content: space-between;
            width: 100%;
            min-width: max-content;
            padding: unset;
            padding-left: 10px;
            & > div:nth-child(1) > div {
                display: block;
            }
        `};
`;

export const ProgressBar = styled.div`
    width: 94px;
    height: 2px;
    background: #e5eaed;
    border-radius: 4px;
    margin-right: 16px;

    & > div {
        width: 84px;
        height: 2px;
        background: #ff6b6b;
        border-radius: 4px;
    }

    ${(props) =>
        props.isMobile &&
        css`
            margin-bottom: 8px;
            font-size: 12px;
            line-height: 150%;
        `};
`;

export const DayLeft = styled.div`
    padding-right: 10px;
    color: #233747;
    letter-spacing: -0.4px;
    font-size: 14px;
    line-height: 146%;

    ${(props) =>
        props.isMobile &&
        css`
            padding: 0;
            font-size: 12px;
            line-height: 150%;
        `};
`;

export const ButtonEdit = styled.div`
    color: #82bd73;
    cursor: pointer;

    ${(props) =>
        !props.isMobile &&
        props.isEdit &&
        css`
            align-self: flex-start;
            padding-left: 10px;
        `};

    ${(props) =>
        props.isMobile &&
        css`
            color: #aab5bc;
        `};

    ${(props) =>
        props.disabled &&
        css`
            cursor: unset;
            color: #bfc8cd;
        `};
`;

export const ButtonRemove = styled.div`
    color: #aab5bc;
    cursor: pointer;
    position: absolute;
    right: 0;
`;

export const CustomDate = styled.div`
    padding-top: 14px;
    border-top: 1px solid #e5eaed;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div:nth-child(1) {
        color: #233747;
        letter-spacing: -0.4px;
        font-size: 14px;
        line-height: 146%;
    }

    ${(props) =>
        !props.isMobile &&
        css`
            margin-top: 14px;
        `};
`;

export const Calendar = styled.div`
    padding-left: 10px;

    & > div {
        width: 163px;
        height: 40px;

        & > div:nth-child(1) {
            height: 100%;

            input {
                height: 100%;
                color: #233747;
                font-size: 16px;
                border: 1px solid #e5eaed;
            }

            & > div > div {
                width: 300px;
                left: unset;
                right: 0;
                bottom: 40px;

                ${(props) =>
                    !props.isMobile &&
                    css`
                        width: 365px;

                        & > div > div > div:nth-child(2) {
                            padding: 20px 43px 0px 43px;
                        }
                    `};
            }
        }

        & > div:nth-child(2) {
            top: 13px;
            right: 12px;
        }
    }
`;
