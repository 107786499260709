import { apiRequest } from 'helpers/request';
import { redirectionRequest } from 'helpers/redirectionRequest';

async function login(data) {
    return apiRequest({ url: '/auth/login', method: 'post', data });
}

async function adminAccessUserAccount(data) {
    return apiRequest({ url: '/auth/login/access-customer-account', method: 'post', data });
}

async function register(data) {
    return apiRequest({ url: '/auth/register', method: 'post', data });
}
async function resetPass(data) {
    return apiRequest({ url: '/auth/resetPassword', method: 'post', data });
}
async function forgotPass(data) {
    return apiRequest({ url: '/auth/sendResetPasswordLink', method: 'post', data });
}
async function logout(headers) {
    return apiRequest({ url: '/auth/logout', method: 'post', headers });
}
async function sendEmailVerification(data) {
    return apiRequest({ url: '/auth/sendEmailVerificationLink', method: 'post', data });
}
async function verifyEmail(data) {
    return apiRequest({ url: '/auth/verify', method: 'put', data });
}
async function setPassword(data) {
    return apiRequest({ url: '/auth/setPassword', method: 'put', data });
}

async function loginGoogle(data) {
    redirectionRequest('/auth/login-google');
}

async function loginFacebook(data) {
    return apiRequest({ url: '/auth/login-facebook', method: 'post', data });
}

async function getLoginInfo(data) {
    return apiRequest({ url: '/auth/find-email-accounts', method: 'post', data });
}

export default {
    login,
    adminAccessUserAccount,
    register,
    resetPass,
    logout,
    forgotPass,
    sendEmailVerification,
    verifyEmail,
    setPassword,
    loginGoogle,
    loginFacebook,
    getLoginInfo,
};
