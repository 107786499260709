export const getProgressStep = (pathname) => {
    switch (pathname) {
        case '/recipes':
            return 0;
        case '/groceries/print':
            return 1;
        case '/groceries':
            return 1;
        case '/min-order':
            return 1;
        case '/checkout':
            return 2;
        default:
            return 0;
    }
};
