import Cookies from 'js-cookie';
export default {
    name: 'v1.0',
    up: () => {
        const guestUserId = localStorage.getItem('guestUserId');
        const token = Cookies.get('token');
        // EC user don't have any migration version for local storage, thus clear the values.
        const onboarding = localStorage.getItem('onboarding');
        const url = new URL(window.location);
        if (!onboarding && !guestUserId && !token && !url.pathname.includes('build-menu')) {
            localStorage.clear();
        }
    },
};
