import styled, { css } from 'styled-components';

export const MobileBarWrapper = styled.div`
    display: block;
    position: fixed;
    overflow: visible;
    width: calc(100vw - 40px);
    margin: 10px 20px;
    left: 0;
    bottom: 0;
    z-index: 998;
    ${({ dull }) =>
        css`
            background-color: ${!dull ? '#82bd73' : '#d5ebd5'};
            box-shadow: 0px 0px 2px 2px ${!dull ? '#eff5f9' : '#d5ebd5'};
        `}

    border-radius: 8px;

    .discount-wrapper {
        background: #233747;
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        top: -96px;
        left: 0;
        width: 100%;
        border-radius: 8px;
        float: bottom;

        .title-block {
            display: flex;
            align-items: center;
            color: #fff;

            div:nth-child(2) {
                margin: 0 6px;
            }
        }

        .links {
            div {
                color: #fff;
            }
        }
    }
    .mobile-wrapper {
        position: relative;
        // position: sticky;
        // background: red;
    }
`;

export const ServingsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    margin: 0 20px 10px;
    border-bottom: 1px solid #aab5bc;
`;

export const PerServing = styled.div`
    display: flex;
    font-size: 14px;
    color: #233747;
    flex: 2;
    justify-content: flex-start;
    align-items: center;
    .ml-5 {
        padding-left: 5px;
    }
    .font-normal {
        font-weight: 400;
        justify-content: flex-end;
        text-decoration: none;
        color: #233747;
    }
    .font-normal-count {
        justify-content: flex-end;
        text-decoration: none;
        color: #909da6;
        font-weight: 400;
    }

    ${({ currentStep }) =>
        currentStep > 1 &&
        css`
            // font-size: 12px;
            font-weight: 400;
        `}
`;

export const Servings = styled.span`
    font-size: 14px;
    color: #bfc8cd;
    text-align: start;
`;

export const Cost = styled.span`
    font-weight: normal;
    font-size: 16px;
    color: #233747;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .serving-text {
        font-size: 14px;
        color: #909da6;
        margin-left: 5px;
    }
    ${({ isBold }) =>
        isBold &&
        css`
            font-weight: 600;
        `}
    ${({ currentStep }) =>
        currentStep > 1 &&
        css`
            font-size: 14px;
            font-weight: 400;
        `}
    ${({ isHorizontalBar }) =>
        isHorizontalBar &&
        css`
            -webkit-text-decoration-line: line-through; /* Safari */
            text-decoration-line: line-through;
            text-decoration-color: #ff6b6b;
            margin-right: 4px;
            color: #4f5f6c;
            font-weight: 600;
            font-size: 14px;
        `}
`;

export const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 15px;

    > div {
        display: flex;
        align-items: center;
        > div {
            margin: 0 9px 0 0;
        }
        h5 {
            margin: unset;
        }
        button {
            padding: 0;
            background: transparent;
            height: unset;
        }
    }

    .build-menu-CTA {
        width: 100%;
        display: flex;
        justify-content: space-between;
        > div {
            margin: unset !important;
        }
    }

    .collapse-price-per-serving {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: 1.5;
        span {
            font-size: 14px;
            font-weight: 600;
            color: #fff;
        }
    }
    
    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.3;
            cursor: alias;
        `};

    ${({ isCheckout }) =>
        isCheckout &&
        css`
            > div {
                >div: first-child {
                    svg {
                        width: 16px;
                        min-width: 16px;
                        height: 16px;
                    }
                }
                h5 {
                    font-size: 14px !important;
                }
                > div {
                    margin: 0 6px 0 0;
                }
            }
        `}
    }
`;

export const RecipeTotal = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
`;
