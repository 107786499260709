import { errorNotification } from 'helpers/errorNotification';
import HttpRequest from './HttpRequest';

class ApiBaseRequest {
    defaultConfig = {
        ignoreOldRequests: true,
        shouldAddTokenToHeaders: true,
    };

    constructor(method, config) {
        this.method = method;
        this.config = {
            ...this.defaultConfig,
            config,
        };
    }

    shouldCall = async (data) => true;

    beforeCall = async (data) => {};

    generateUrl = async (data) => '';

    preparePayload = async (data) => data;

    prepareQuery = async (data) => data;

    addTokenToHeaders = () => {
        if (this.config.addTokenToHeaders) {
            return {
                Authorization: Cookies.get('token'),
            };
        }
        return {};
    };

    prepareHeaders = async (data) => {
        return this.addTokenToHeaders();
    };

    afterCall = async (data) => {};

    handleError = async (error, data) => {};

    handleGenericError = async (error, data) => {
        errorNotification(error, data, this.constructor.name, true);
    };

    parseReponse = (output) => output;

    make = async (data, requestConfig = { forceCall: false }) => {
        const shouldCall = await this.shouldCall(data);

        if (!shouldCall && !requestConfig.forceCall) {
            return Promise.resolve();
        }

        await this.beforeCall(data);

        this.httpRequestInfo = {
            url: await this.generateUrl(data),
            payload: await this.preparePayload(data),
            query: await this.prepareQuery(data),
            headers: await this.prepareHeaders(data),
        };

        return HttpRequest.perform(this.method, this.httpRequestInfo)

            .then((output) => {
                if (this.config.ignoreOldRequests && output.httpRequestInfo !== this.httpRequestInfo) {
                    return Promise.reject('DISCARDING_OLD_REQUEST');
                }
                return output;
            })

            .then((output) => this.parseReponse(output))

            .then((output) => this.afterCall(output))

            .catch((error) => {
                if (error === 'DISCARDING_OLD_REQUEST') {
                    return Promise.resolve();
                }
                this.handleGenericError(error, data);
                this.handleError(error, data);
            });
    };
}

export default ApiBaseRequest;
