import React from 'react';
import PropTypes from 'prop-types';
import useTheme from 'hooks/useTheme';

import Icons from '../Icons/Icons';
import TypographyD from '../TypographyD/TypographyD';
import PlaceHolder from 'assets/images/placeHolder.png';

import { ExtraProductWrapper } from './styles';

const ExtraProduct = ({ extraProducts, handleDelete }) => {
    const color = useTheme();
    return (
        <ExtraProductWrapper>
            <div className="title">
                <TypographyD type="bodySmall" fontWeight="500" color={color.grey1}>
                    Additional groceries
                </TypographyD>
            </div>
            <div className="product-list">
                {extraProducts.map((i) => (
                    <div className="single-row" key={`extra-product-${i._id}`}>
                        <div className="product-info">
                            <div className="image">
                                <img src={i.image || PlaceHolder} alt={'extra product image'} />
                            </div>
                            <div className="description">
                                <TypographyD type="h6" className="name">
                                    {i.name}
                                </TypographyD>
                                <TypographyD type="bodyMedium" fontWeight="normal" color={color.grey4}>
                                    {`${i.value} pack (${i.quantity} ${i.units})`}
                                </TypographyD>
                            </div>
                        </div>
                        <div className="price">
                            <TypographyD type="bodyBig" fontWeight="500" color={color.grey0}>
                                ${i.price}
                            </TypographyD>
                        </div>
                        <div className="trash=icon" onClick={() => handleDelete(i._id)}>
                            <Icons name="trash" size="24" color={color.grey3} />
                        </div>
                    </div>
                ))}
            </div>
        </ExtraProductWrapper>
    );
};

ExtraProduct.propTypes = {
    extraProducts: PropTypes.array.isRequired,
    handleDelete: PropTypes.func.isRequired,
};

ExtraProduct.defaultProps = {
    extraProducts: [],
};

export default ExtraProduct;
