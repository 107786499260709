import { call, put, takeEvery, select } from 'redux-saga/effects';
import * as constants from '../constants';
import * as actions from '../actions/authentication.actions';
import * as actionsRecipe from '../actions/recipe.action';
import * as actionsExtraProduct from '../actions/extraProduct.actions';
import * as actionsUser from '../actions/user.actions';
import * as actionsOrder from '../actions/order.actions';
import * as actionsUI from '../actions/ui-settings.actions';
import * as actionsMenu from '../actions/orderMenu.actions';
import * as actionsOnboarding from '../actions/onboarding.actions';
import * as actionsLogin from '../actions/loginModal.action';
import serverAPI from 'services/authentication.service';
import Cookies from 'js-cookie';
import { customNotification } from 'components/common/Notification';
import { errorNotification } from 'helpers/errorNotification';
import { pushToGTM } from 'helpers/dataLayer';
import { identifyUser } from 'helpers/trackEvents';
import { Events } from 'constants/events';
import { Messages } from 'constants/notificationMessages';
import jwt_decode from 'jwt-decode';
import get from 'lodash/get';
import history from '../../history';

export const selectIsUpdating = () => select(({ onboardingReducer }) => onboardingReducer.isUpdating);
export const selectIsLoginPopupOpen = () => select(({ loginModalReducer }) => loginModalReducer.isLoginPopupOpen);

export function* login({ email, password }) {
    const data = {
        username: email,
        password: password,
    };
    try {
        const isUpdatingOnboarding = yield selectIsUpdating();
        const isLoginPopupOpen = yield selectIsLoginPopupOpen();
        const response = yield call(serverAPI.login, data);
        yield put(actions.loginSuccess(response.data));
        yield put(
            actionsUI.setActiveButton({
                today: false,
                tomorrow: false,
                custom: false,
            }),
        );
        if (isLoginPopupOpen) {
            yield put(actionsLogin.showLoginPopup(false));
            history.push('/groceries');
        }
        localStorage.removeItem('deliveryAddress');
        yield put(actionsUser.getUserRequest());
        yield put(actionsMenu.setFetchingCommonIngredient(false));
        if (isUpdatingOnboarding) {
            yield put(actionsOnboarding.updatedPayloadOnboarding());
        }
        customNotification('success', Messages.LOGIN);
    } catch (error) {
        const data = { email };
        const text = 'Error in login saga';
        errorNotification(error, data, text, true);
        yield put(actions.loginFailure(error));
    }
}

export function* adminAccessUserAccount({ emailAddress, password, userEmail }) {
    const data = {
        emailAddress: emailAddress,
        password: password,
        userEmail: userEmail,
    };
    try {
        const response = yield call(serverAPI.adminAccessUserAccount, data);
        yield put(actions.adminAccessUserAccountSuccess(response.data));
        customNotification('success', Messages.ACCESS_USER_ACCOUNT);
    } catch (error) {
        const data = { emailAddress, password, userEmail };
        const text = 'Error in access user account saga';
        errorNotification(error, data, text, true);
        yield put(actions.adminAccessUserAccountFailure(error));
    }
}

export function* register(data) {
    try {
        const influencerId = yield select((state) => state.influencerReducer.data._id);
        data = { ...data, influencer: influencerId };
        const response = yield call(serverAPI.register, data);
        yield put(actions.registerSuccess(response.data));
        if (response) {
            const dataLogin = {
                email: data.emailAddress,
                password: data.password,
            };
            yield put(actions.login(dataLogin));
        }
        customNotification('success', Messages.REGISTER);
    } catch (error) {
        const errData = { email: get(data, 'emailAddress') };
        const text = 'Error in register saga';
        errorNotification(error, errData, text, true);
        yield put(actions.registerFailure(error));
    }
}
export function* resetPass({ data }) {
    try {
        const response = yield call(serverAPI.resetPass, data);
        yield put(actions.resetPassSuccess(response));
    } catch (error) {
        const data = {};
        const text = 'Error in reset password saga';
        errorNotification(error, data, text, true);
        yield put(actions.resetFailure(error));
    }
}
export function* logout() {
    const token = Cookies.get('token');
    const headers = {
        Authorization: token,
    };
    try {
        const response = yield call(serverAPI.logout, headers);
        yield put(actions.logoutSuccess(response.data));
        yield put(
            actionsUI.setActiveButton({
                today: false,
                tomorrow: false,
                custom: false,
            }),
        );
        localStorage.removeItem('deliveryAddress');
        yield put(actionsUser.setIsLoadedUser());
        yield put(actionsOrder.clearStateOrder());
        yield put(actionsUI.clearActiveButton());
        yield put(actionsRecipe.clearStateLocalStorage());
        yield put(actionsExtraProduct.clearSelectedExtraProducts());
        yield put(actionsMenu.setFetchingCommonIngredient(false));
        // if (location.pathname === '/recipes') {
        //      yield put(actionsMenu.getCommonIngredientsRequest());
        // }
        customNotification('success', Messages.LOGOUT);
    } catch (error) {
        const data = {};
        const text = 'Error in logout saga';
        errorNotification(error, data, text, true);
        yield put(actions.logoutFailure(error));
    }
}

export function* forgotPass({ email }) {
    const data = {
        emailAddress: email,
    };
    try {
        const response = yield call(serverAPI.forgotPass, data);
        yield put(actions.forgotPassSuccess(response.data));
        customNotification('success', response.data.message);
    } catch (error) {
        const data = { email };
        const text = 'Error in forgotPass saga';
        errorNotification(error, data, text, true);
        yield put(actions.forgotPassFailure(error));
    }
}

export function* sendEmailVerification(data) {
    try {
        const response = yield call(serverAPI.sendEmailVerification, data);
        yield put(actions.sendEmailVerificationSuccess());
        customNotification('success', Messages.SEND_EMAIL_VERIFICATION);
    } catch (error) {
        const errorData = { email: get(data, 'email') };
        const text = 'Error in sendEmailVerification saga';
        errorNotification(error, errorData, text, true);
        yield put(actions.sendEmailVerificationFailure(error));
    }
}

export function* verifyEmail(data) {
    try {
        const response = yield call(serverAPI.verifyEmail, data);
        yield put(actions.verifyEmailSuccess());
        customNotification('success', Messages.VERIFY_ACCOUNT);
    } catch (error) {
        const data = {};
        const text = 'Error in verifyEmail saga';
        errorNotification(error, data, text, true);
        yield put(actions.verifyEmailFailure(error));
    }
}

export function* setPassword(action) {
    try {
        const response = yield call(serverAPI.setPassword, action.payload);
        yield put(actions.setPasswordSuccess(response.data));
        customNotification('success', Messages.SET_PASSWORD);
    } catch (error) {
        const data = {};
        const text = 'Error in setPassword saga';
        errorNotification(error, data, text);
        yield put(actions.setPasswordFailure(error));
    }
}

export function* unauthenticatedUser() {
    Cookies.remove('token');
    yield put(actions.unauthorisedUserSuccess());
}

export function* loginGoogle({ token }) {
    const data = {
        token,
    };
    try {
        const isUpdatingOnboarding = yield selectIsUpdating();
        yield call(serverAPI.loginGoogle, data);

        if (isUpdatingOnboarding) {
            yield put(actionsOnboarding.updatedPayloadOnboarding());
        }

        yield put(
            actionsUI.setActiveButton({
                today: false,
                tomorrow: false,
                custom: false,
            }),
        );
        localStorage.removeItem('deliveryAddress');
        yield put(actionsMenu.setFetchingCommonIngredient(false));
    } catch (error) {
        const data = {};
        const text = 'Error in loginGoogle saga';
        errorNotification(error, data, text, true);
        yield put(actions.loginGoogleFailure(error));
    }
}

export function* loginFacebook(data) {
    try {
        const isUpdatingOnboarding = yield selectIsUpdating();
        const response = yield call(serverAPI.loginFacebook, data);
        const jwtTokenFacebook = get(response, 'data.jwtToken');
        const isRegistered = get(response, 'data.isRegistered');
        const user = jwt_decode(jwtTokenFacebook);
        const influencer = localStorage.getItem('influencer') && JSON.parse(localStorage.getItem('influencer')).handle;
        if (isRegistered === 'true') {
            pushToGTM({
                event: Events.EVENT_SIGNUP,
                email: user.username,
            });
            identifyUser({
                username: user.username,
                firstName: user.firstName,
                lastName: user.lastName,
            });
        }
        pushToGTM({
            event: Events.EVENT_LOGIN,
            email: user.username,
        });
        identifyUser(user, influencer);
        yield put(actions.loginFacebookSuccess(response.data));
        if (isUpdatingOnboarding) {
            yield put(actionsOnboarding.updatedPayloadOnboarding());
        }
        yield put(
            actionsUI.setActiveButton({
                today: false,
                tomorrow: false,
                custom: false,
            }),
        );
        localStorage.removeItem('deliveryAddress');
        yield put(actionsMenu.setFetchingCommonIngredient(false));
        customNotification('success', Messages.LOGIN);
    } catch (error) {
        const data = {};
        const text = 'Error in loginFacebook saga';
        errorNotification(error, data, text, true);
        yield put(actions.loginFacebookFailure(error));
    }
}

export function* getLoginInfoByEmail(data) {
    try {
        const url = new URL(window.location);
        const response = yield call(serverAPI.getLoginInfo, data);
        if (response.data) {
            const accountData = response.data.data;
            yield put(actionsLogin.getLoginInfoByEmailSuccess(accountData));
            yield put(actionsLogin.setEmailFromModal(data.email));
            if (accountData.hasAccount) history.push(`${url.pathname}?loginmode=signin`);
            else if (!accountData.hasAccount) history.push(`${url.pathname}?loginmode=signup`);
        }
    } catch (error) {
        const data = {};
        const text = 'Error in loginFacebook saga';
        errorNotification(error, data, text, true);
        yield put(actions.loginFacebookFailure(error));
    }
}

export default function* () {
    yield takeEvery(constants.LOGIN_REQUEST, login);
    yield takeEvery(constants.ADMIN_ACCESS_USER_ACCOUNT_REQUEST, adminAccessUserAccount);
    yield takeEvery(constants.REGISTER_REQUEST, register);
    yield takeEvery(constants.RESETPASS_REQUEST, resetPass);
    yield takeEvery(constants.LOGOUT_REQUEST, logout);
    yield takeEvery(constants.FORGOTPASS_REQUEST, forgotPass);
    yield takeEvery(constants.SEND_EMAIL_VERIFICATION_REQUEST, sendEmailVerification);
    yield takeEvery(constants.VERIFY_EMAIL_REQUEST, verifyEmail);
    yield takeEvery(constants.SET_PASSWORD_REQUEST, setPassword);
    yield takeEvery(constants.UNAUTHORISED_USER_REQUEST, unauthenticatedUser);
    yield takeEvery(constants.LOGIN_GOOGLE_REQUEST, loginGoogle);
    yield takeEvery(constants.LOGIN_FACEBOOK_REQUEST, loginFacebook);
    yield takeEvery(constants.GET_LOGIN_INFO_BY_EMAIL, getLoginInfoByEmail);
}
