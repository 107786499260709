import React from 'react';
import { PopupButton } from './styles';

const PopUp = ({ disabled, state = 'InActive', title = 'Filters', onClick }) => {
    return (
        <div>
            <PopupButton state={state === 'Active'} disabled={disabled} onClick={onClick}>
                {title}
            </PopupButton>
        </div>
    );
};

export default PopUp;
