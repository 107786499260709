import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import HeaderCenter from '../../common/headers/HeaderCenter';
import HeaderLeft from '../../common/headers/HeaderLeft';
import HeaderRight from '../../common/headers/HeaderRight';
import { HeaderDesktopContainer } from './styles';
const HeaderDesktop = props => {
    return (
        <HeaderDesktopContainer {...props}>
            <HeaderLeft {...props} />
            <HeaderCenter {...props} />
            <HeaderRight {...props} influencer={props.influencer.handle} />
        </HeaderDesktopContainer>
    );
};
export default HeaderDesktop;
