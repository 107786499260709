import ApiBaseRequest from '../networkRequests/ApiBaseRequest';
import * as actions from './curatedRecipes.action';
import store from '../store';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

class GetCuratedRecipe extends ApiBaseRequest {
    constructor() {
        super('GET');
    }

    generateUrl = ({ id }) => `/curated-recipes/${id}`;

    shouldCall = () => {
        return true;
    };

    prepareQuery = () => {
        const state = store.getState();
        const {
            configReducer: {
                config: { countryCode },
            },
            influencerReducer: { isDefaultInfluencer },
        } = state;
        const query = {
            countryCode,
            isDefaultInfluencer,
        };
        return query;
    };

    afterCall = ({ response }) => {
        const categories1 = [];
        const recipesLocal = [];
        const collections = response.data;
        const state = store.getState();
        const {
            recipeCategoryReducer: { all: allCategories },
        } = state;

        if (!collections) return;

        if (!collections.showRecipesByCategory) {
            store.dispatch(actions.getCuratedRecipeSuccess(response.data));
            return;
        }
        const recipes = collections?.recipes?.map((recipe) => recipe.recipeId);
        collections?.recipes?.map((recipe) => {
            if (!categories1.includes(recipe.recipeId.categories[0])) categories1.push(recipe.recipeId.categories[0]);
        });
        categories1?.forEach((i) => {
            const category = allCategories.find((c) => c._id === i);
            if (!isEmpty(recipes.filter((r) => r.categories[0] === i)) && get(category, 'name', '')) {
                recipesLocal.push({
                    category: category && category.name,
                    recipes: recipes.filter((r) => r.categories[0] === i),
                });
            }
        });
        store.dispatch(actions.getCuratedRecipeSuccess({ ...response.data, categorizedRecipes: recipesLocal }));
    };
}

export default new GetCuratedRecipe();
