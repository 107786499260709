const Events = {
    EVENT_IDENTIFY_USER: 'identify',
    EVENT_ADD_TO_CART: 'Added to Cart',
    EVENT_VIEWED_PRODUCT: 'Viewed Product',
    EVENT_STARTED_CHECKOUT: 'Started Checkout',
    EVENT_PURCHASE: 'purchase',
    EVENT_LOGIN: 'userLogin',
    EVENT_SIGNUP: 'signup',
    EVENT_PRINT_GROCERIES: 'Print groceries',
    EVENT_VIEW_COLLECTION: 'View Collection',
    EVENT_ADD_COLLECTION_TO_CART: 'Added Collection',
    EVENT_INITIALIZE_USER: 'initialize user',
};

export { Events };
