import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useScreenType from 'hooks/useScreenType';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, EmailShareButton } from 'react-share';

import Icons from '../Icons/Icons';
import logo from 'assets/images/logo.png';
import styles from './ShareRecipePopup.module.scss';
import { setShareRecipePopup } from 'store/actions/ui-settings.actions';
import { setWaiveOffDeliveryFee } from 'store/actions/recipe.action';

import {
    ShareRecipeDialogueContainer,
    ShareRecipeDialogueContent,
    HeaderContainer,
    ContentContainer,
    SubTitle,
    RecipeName,
    ImageContainer,
    CardContainer,
} from './styles';

const ShareRecipePopup = () => {
    const dispatch = useDispatch();
    const { isMobile } = useScreenType();
    const cartLink = useSelector((state) => state.cartReducer.sharedCartUrl);
    const { isShareRecipeDialogueOpen } = useSelector((state) => state.uiSettingsReducer);
    const { selectedRecipes } = useSelector((state) => state.recipesReducer.Cart);
    const userDetails = useSelector((state) => state.userReducer);
    const url = new URL(window.location);
    const shareMenu = url.searchParams.get('sharemenu');
    const userName = userDetails?.userDetails?.firstName;

    const back = (e) => {
        e.stopPropagation();
        const url = new URL(window.location);
        url.searchParams.delete('sharemenu');
        window.history.pushState({}, '', url);
        dispatch(setShareRecipePopup(false));
    };

    const handleClickSocialIcon = () => {
        if (cartLink) dispatch(setWaiveOffDeliveryFee());
    };

    return (
        <ShareRecipeDialogueContainer
            scroll="body"
            open={isShareRecipeDialogueOpen && shareMenu}
            onClose={back}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            $isMobile={isMobile}
        >
            <ShareRecipeDialogueContent>
                <HeaderContainer isMobile={isMobile}>
                    <div>
                        <img src={logo} className={styles.titleLogo} />
                    </div>
                    <div className={styles.closeButton} onClick={back}>
                        <Icons name="close" color="white" />
                    </div>
                </HeaderContainer>
                <div className={styles.imageWrapper}>
                    <div className={styles.imgLogo}>
                        <Icons name="deliveryLogo" size="60" />
                    </div>
                </div>
                <ContentContainer isMobile={isMobile}>
                    <SubTitle>
                        Share menu with your friends to get <strong>FREE DELIVERY</strong>
                    </SubTitle>

                    <div className={styles.line}></div>
                    {userName && <div className={styles.userInfo}>{userName}'s menu</div>}
                    <div className={styles.listWrapper}>
                        <div className={styles.listItem}>
                            {selectedRecipes &&
                                selectedRecipes.map((recipes, index) => (
                                    <CardContainer key={index} isMobile={isMobile}>
                                        <ImageContainer src={recipes.images?.large[0]?.url}></ImageContainer>
                                        <RecipeName isMobile={isMobile}>{recipes.name}</RecipeName>
                                    </CardContainer>
                                ))}
                        </div>
                        <div className={styles.socialLink}>
                            <div className={styles.socialLinkText}>Share Via </div>
                            <div className={styles.shareButtons}>
                                <div onClick={handleClickSocialIcon}>
                                    <TwitterShareButton url={cartLink} title="Check out my menu:">
                                        <Icons name="twitterFill" size="32" className={styles.fab} />
                                    </TwitterShareButton>
                                </div>
                                <div onClick={handleClickSocialIcon}>
                                    <FacebookShareButton url={cartLink} quote="Check out my menu:">
                                        <Icons name="facebook" size="32" className={styles.fab} />
                                    </FacebookShareButton>
                                </div>
                                <div onClick={handleClickSocialIcon}>
                                    <WhatsappShareButton url={cartLink} title="Check out my menu:">
                                        <Icons name="whatsapp" size="42" className={styles.fab} />
                                    </WhatsappShareButton>
                                </div>
                                <div onClick={handleClickSocialIcon}>
                                    <EmailShareButton url={cartLink} subject="Check out my menu:" body="body">
                                        <Icons name="mail" size="42" className={styles.fab} />
                                    </EmailShareButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </ContentContainer>
            </ShareRecipeDialogueContent>
        </ShareRecipeDialogueContainer>
    );
};

export default ShareRecipePopup;
