import * as constants from '../constants';
import get from 'lodash/get';
import { getPrimaryDeliveryAddress } from 'utility/userUtility';

export const setDeliveryAddress = (values) => ({
    type: constants.SET_DELIVERY_ADDRESS,
    payload: values,
});
export const setDeliveryAddressSuccess = (address) => ({
    type: constants.SET_DELIVERY_ADDRESS_SUCCESS,
    address,
});
export const setDeliveryType = (deliveryType) => ({
    type: constants.SET_DELIVERY_TYPE,
    deliveryType,
});

export const getDay = (dayTimestamp, deliveryType, city) => ({
    type: constants.GET_DAY_REQUEST,
    timestamp: dayTimestamp,
    deliveryType,
    city,
});

export const getDaySuccess = (timeSlots, currentTime) => ({
    type: constants.GET_DAY_SUCCESS,
    timeSlots,
    currentTime,
});

export const setDeliveryDate = (deliveryDate) => ({
    type: constants.SET_DELIVERY_DATE,
    deliveryDate,
});

export const getCurrentTime = () => ({
    type: constants.GET_CURRENT_TIME_REQUEST,
});

export const setDayAvailability = (dayAvailability) => ({
    type: constants.SET_DAY_AVAILABILITY,
    dayAvailability,
});

export const getSummary = (cb) => ({
    type: constants.GET_SUMMARY_REQUEST,
    cb,
});

export const getSummarySuccess = (summary) => {
    return {
        type: constants.GET_SUMMARY_SUCCESS,
        summary,
    };
};

export const createOrder = () => ({
    type: constants.CREATE_ORDER_REQUEST,
});

export const addPaymentMethodOnCheckout = ({
    stripe,
    cardHolderName,
    billingAddress,
    cardElementOrId,
    isSaveCard,
    isStepOpen,
}) => ({
    type: constants.ADD_PAYMENT_METHOD_ON_CHECKOUT,
    stripe,
    cardHolderName,
    billingAddress,
    cardElementOrId,
    isSaveCard,
    isStepOpen,
});

export const createOrderSuccess = (payload) => ({ type: constants.CREATE_ORDER_SUCCESS, payload });
export const createOrderFailure = (error) => ({ type: constants.CREATE_ORDER_FAILURE, error });

export const setPromoCode = (promoCode) => {
    return {
        type: constants.SET_PROMO_CODE,
        promoCode,
    };
};
export const setDeliveryFee = (deliveryFee) => {
    return {
        type: constants.SET_DELIVERY_FEE,
        deliveryFee,
    };
};

export const checkPromoCode = (promoCode) => {
    return {
        type: constants.CHECK_PROMO_REQUEST,
        promoCode,
    };
};

export const checkPromoSuccess = (data) => {
    return {
        type: constants.CHECK_PROMO_SUCCESS,
        data,
    };
};
export const checkPromoFailure = (error) => ({ type: constants.CHECK_PROMO_FAILURE, error });

export const setOrderInformation = (extraProducts, body) => ({
    type: constants.SET_ORDER_INFORMATION,
    extraProducts,
    body,
});

export const CLEAR_CHECKOUT_STATE = 'CLEAR_CHECKOUT_STATE';

export const clearCheckoutState = {
    clear: { type: CLEAR_CHECKOUT_STATE },
};

export const setCurrentPage = (path) => ({
    type: constants.SET_CURRENT_PAGE,
    payload: path,
});

export const getDeliveryAddressRequest = () => ({
    type: constants.GET_DELIVERY_ADDRESS_REQUEST,
});

export const getDeliveryAddressSuccess = (userDetails, countries) => {
    const primaryAddress = getPrimaryDeliveryAddress(userDetails, countries);
    return {
        type: constants.GET_DELIVERY_ADDRESS_SUCCESS,
        data: {
            lineOne: get(primaryAddress, 'lineOne', ''),
            lineTwo: get(primaryAddress, 'lineTwo', ''),
            city: get(primaryAddress, 'city', ''),
            province: get(primaryAddress, 'province', ''),
            postalCode: get(primaryAddress, 'postalCode', ''),
            optional: get(primaryAddress, 'optional', ''),
            email: get(userDetails, 'emailAddress', ''),
            firstName: get(userDetails, 'firstName', ''),
            lastName: get(userDetails, 'lastName', ''),
            phoneNumber: get(userDetails, 'phoneNumber', ''),
            deliveryInstructions: get(primaryAddress, 'deliveryInstructions', ''),
            country: get(primaryAddress, 'country', ''),
        },
    };
};
export const getDeliveryAddressError = (error) => ({ type: constants.GET_DELIVERY_ADDRESS_ERROR, error });
export const editDeliveryAddress = (deliveryAddress) => ({ type: constants.EDIT_DELIVERY_ADDRESS, deliveryAddress });
export const clearStateOrder = () => ({
    type: constants.CLEAR_STATE_ORDER,
});

export const setIsValidDeliveryForm = (payload) => ({ type: constants.SET_IS_VALID_DELIVERY_FORM, payload });

export const clearPromoCodeDiscount = (isRemove) => ({ type: constants.CLEAR_PROMO_CODE_DISCOUNT, isRemove });
