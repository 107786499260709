import { call, put, select, takeLatest, debounce, delay } from 'redux-saga/effects';
import * as constants from '../constants';
import { customNotification } from 'components/common/Notification';
import * as actions from '../actions/recipe.action';
import * as orderAction from '../actions/order.actions';
import * as cartActions from '../actions/cart.actions';
import * as orderActions from '../actions/order.actions';
import * as recipeActions from '../actions/recipe.action';
import * as uiSettingActions from '../actions/ui-settings.actions';
import cartAPI from 'services/cart.service';
import shareUrlAPI from 'services/shareUrl.service';
import { errorNotification } from 'helpers/errorNotification';
import { preparePayloadFromLocalStorage, prepareCartDeliveryAddressPayload } from 'helpers/cart';
import { pushLearnqEvent } from 'helpers/trackEvents';
import { adjustRequiredIngredientStatus, adjustNonRequiredIngredientStatus } from '../../utility/cartUtil';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import { Events } from 'constants/events';
import { prepareIngredientSubstituteList } from 'utility/groceryUtil';
import config from 'config';
import { prepareCollectionPayload, prepareRecipePayload } from 'helpers/klaviyoEvent';
import { increaseServings } from 'utility/recipeUtil';

export function* addRecipeToCart({ recipe, mealTypeId, freeServings }) {
    try {
        const selectedRecipesData = yield select((state) => state.recipeReducer.Cart.selectedRecipes);
        const customServingsData = selectedRecipesData?.find(({ _id }) => _id.toString() === recipe?._id.toString());
        const defaultServings = yield select((state) => state.uiSettingsReducer.defaultServings);
        recipe = {
            ...recipe,
            defaultServings: recipe.servings,
            customServings: increaseServings(
                freeServings
                    ? customServingsData?.customServings
                        ? customServingsData?.customServings + freeServings
                        : freeServings
                    : defaultServings,
                recipe.minServings,
                recipe.incrServings,
            ),
        };
        if (mealTypeId) recipe = { ...recipe, mealTypeId: mealTypeId };
        else
            recipe = {
                ...recipe,
                mealTypeId: recipe.mealTypes.length ? recipe.mealTypeId : undefined,
            };
        const influencerHandle = yield select((state) => state.influencerReducer.handle);
        const cart = yield select((state) => state.recipeReducer.Cart);
        let selectedRecipes = get(cart, 'selectedRecipes', []);
        const recipeIndex = findIndex(selectedRecipes, (item) => item._id === recipe._id && item.mealTypeId == mealTypeId);

        if (recipeIndex === -1) {
            selectedRecipes = [recipe, ...selectedRecipes];
        } else {
            selectedRecipes[recipeIndex] = recipe;
        }
        yield put(actions.selectedRecipes(selectedRecipes));
        yield put(actions.addRecipesAPI());
        pushLearnqEvent(Events.EVENT_ADD_TO_CART, prepareRecipePayload(recipe), influencerHandle);
    } catch (error) {
        const data = { recipe };
        const text = 'Error in addRecipeToCart saga';
        errorNotification(error, data, text);
    }
}
export function* resaveCart() {
    try {
        yield put(actions.addRecipesAPI());
    } catch (error) {
        const data = { recipe };
        const text = 'Error in resaveCart saga';
        errorNotification(error, data, text);
    }
}
export function* addAllRecipeToCart({ recipes, curatedBoxId }) {
    try {
        const defaultServings = yield select((state) => state.uiSettingsReducer.defaultServings);
        const influencerHandle = yield select((state) => state.influencerReducer.handle);
        const curatedRecipe = yield select((state) => state.curatedRecipesReducer.curatedRecipe);
        const cart = yield select((state) => state.recipeReducer.Cart);
        let selectedRecipes = get(cart, 'selectedRecipes', []);
        (recipes || []).map((item) => {
            let recipe = {
                ...item.recipeId,
                defaultServings: item.recipeId.servings,
                customServings: increaseServings(defaultServings, item.recipeId.minServings, item.recipeId.incrServings),
                ingredientsAmount: item.recipeId.ingredients.length,
                curatedBoxId: curatedBoxId,
                mealTypeId: item.recipeId.mealTypes[0],
            };
            const recipeIndex = findIndex(selectedRecipes, (i) => i._id === item.recipeId._id);

            if (recipeIndex === -1) {
                selectedRecipes = [recipe, ...selectedRecipes];
            }
        });
        yield put(actions.selectedRecipes(selectedRecipes));
        yield put(actions.addRecipesAPI());
        recipes.forEach((item) => {
            pushLearnqEvent(Events.EVENT_ADD_TO_CART, prepareRecipePayload(item), influencerHandle);
        });
        pushLearnqEvent(Events.EVENT_ADD_COLLECTION_TO_CART, prepareCollectionPayload(curatedRecipe), influencerHandle);
    } catch (error) {
        const data = { recipes };
        const text = 'Error in addRecipeToCart saga';
        errorNotification(error, data, text);
    }
}
export function* addMealPlanToCart({ mealPlan }) {
    try {
        const defaultServings = yield select((state) => state.uiSettingsReducer.defaultServings);
        const influencerHandle = yield select((state) => state.influencerReducer.handle);
        const cart = yield select((state) => state.recipeReducer.Cart);
        let selectedRecipes = get(cart, 'selectedRecipes', []);
        mealPlan.recipes.forEach((obj) => {
            const recipe = {
                ...obj.recipeId,
                defaultServings: obj.recipeId.servings,
                customServings: increaseServings(defaultServings, obj.recipeId.minServings, obj.recipeId.incrServings),
                curatedBoxId: mealPlan._id,
            };
            const recipeIndex = findIndex(
                selectedRecipes,
                (item) => item.curatedBoxId && item.curatedBoxId === recipe.curatedBoxId && item._id === recipe._id,
            );
            if (recipeIndex === -1) {
                selectedRecipes = [recipe, ...selectedRecipes];
            }
        });
        yield put(actions.selectedRecipes(selectedRecipes));
        yield put(actions.addRecipesAPI());
        mealPlan.recipes.forEach((obj) => {
            pushLearnqEvent(Events.EVENT_ADD_TO_CART, prepareRecipePayload(obj.recipeId), influencerHandle);
        });
    } catch (error) {
        const data = { mealPlan };
        const text = 'Error in addMealPlanToCart saga';
        errorNotification(error, data, text);
    }
}
export function* swapMealBoxRecipe({ originalRecipe, newRecipe, curatedBoxId }) {
    try {
        const defaultServings = yield select((state) => state.uiSettingsReducer.defaultServings);
        const influencerHandle = yield select((state) => state.influencerReducer.handle);
        const cart = yield select((state) => state.recipeReducer.Cart);
        let selectedRecipes = get(cart, 'selectedRecipes', []);
        const recipeIndex = findIndex(
            selectedRecipes,
            (item) => item.curatedBoxId === curatedBoxId && item._id === originalRecipe._id,
        );
        if (recipeIndex !== -1) {
            newRecipe = {
                ...newRecipe,
                defaultServings: newRecipe.servings,
                customServings: defaultServings,
                curatedBoxId: originalRecipe.curatedBoxId,
                originalRecipeId: originalRecipe._id,
            };
            selectedRecipes.splice(recipeIndex, 1, newRecipe);
            yield put(actions.selectedRecipes(selectedRecipes));
            yield put(actions.addRecipesAPI());
            pushLearnqEvent(Events.EVENT_ADD_TO_CART, prepareRecipePayload(newRecipe), influencerHandle);
        }
    } catch (error) {
        const data = { originalRecipe, newRecipe };
        const text = 'Error in swap MealBox Recipe saga';
        errorNotification(error, data, text);
    }
}

export function* addRecipeToCartAPI({ recipes }) {
    const loggedInUser = yield select((state) => state.authenReducer.loggedInUser);
    const cart = yield select((state) => state.recipeReducer.Cart);
    try {
        // yield put(actionsOrderMenu.getCommonIngredientsRequest(true));
        yield put(actions.setIsEditing(true));
        const response = yield call(
            cartAPI.addItemToCart,
            preparePayloadFromLocalStorage(recipes ? { ...cart, selectedRecipes: recipes } : cart),
            { getGroceries: true },
        );
        yield put(actions.setIsEditing(false));
        if (!response) return;
        const { data } = response;
        if (!loggedInUser) {
            localStorage.setItem('guestUserId', data.guestUserId);
        }
        let groceriesLocal = data.groceries;
        if (!isEmpty(data.removedIngredients) || !isEmpty(data.nonRequiredIngredients)) {
            groceriesLocal = adjustRequiredIngredientStatus(groceriesLocal, data.removedIngredients);
            groceriesLocal = adjustNonRequiredIngredientStatus(groceriesLocal, data.nonRequiredIngredients);
        }
        yield put(actions.addRecipeSuccess({ data: { ...data, groceries: groceriesLocal } }));
        yield put(cartActions.setIngredientsToCost(data.ingredientsToCost));
        yield put(cartActions.setCartDeliveryInfoSuccess(data));
        yield put(cartActions.shareCartUrlRequest());
        yield put(recipeActions.setBulkTypeSuccess(data.cost));
    } catch (error) {
        const data = { loggedInUser };
        const text = 'Error in addRecipeToCartAPI saga';
        errorNotification(error, data, text);
    }
}
export function* removeRecipeToCart({ recipeId, mealTypeId }) {
    try {
        const cart = yield select((state) => state.recipeReducer.Cart);
        let selectedRecipes = get(cart, 'selectedRecipes', []);
        let updatedSelectedRecipes = [];
        if (recipeId) {
            if (mealTypeId)
                updatedSelectedRecipes = selectedRecipes.filter((r) => {
                    if (r._id == recipeId) return r.mealTypeId !== mealTypeId;
                    else return true;
                });
            else updatedSelectedRecipes = selectedRecipes.filter((r) => r._id !== recipeId);
        }
        yield put(actions.selectedRecipes(updatedSelectedRecipes));
        yield put(actions.addRecipesAPI());
    } catch (error) {
        const data = { recipeId };
        const text = 'Error in removeRecipeToCart saga';
        errorNotification(error, data, text);
    }
}

export function* setBulkType() {
    try {
        const loggedInUser = yield select((state) => state.authenReducer.loggedInUser);
        const groceries = yield select((state) => state.recipeReducer.Cart.groceries);
        const cart = yield select((state) => state.recipeReducer.Cart);
        const response = yield call(cartAPI.addItemToCart, preparePayloadFromLocalStorage(cart));
        if (!response) return;
        const { data } = response;
        if (!loggedInUser) {
            localStorage.setItem('guestUserId', data.guestUserId);
        }
        yield put(actions.setBulkTypeSuccess(data));
    } catch (error) {
        const data = {};
        const text = 'Error in setBulkType saga';
        errorNotification(error, data, text);
    }
}
export function* setServings({ recipeId, servings, curatedBoxId, mealTypeId }) {
    try {
        const cart = yield select((state) => state.recipeReducer.Cart);
        let selectedRecipes = get(cart, 'selectedRecipes', []);
        let recipeIndex;
        if (mealTypeId) recipeIndex = findIndex(selectedRecipes, (i) => i._id === recipeId && i.mealTypeId === mealTypeId);
        else recipeIndex = findIndex(selectedRecipes, (i) => i._id === recipeId);
        if (recipeIndex === -1) {
            return;
        }
        const setSelectedRecipes = [
            ...selectedRecipes.slice(0, recipeIndex),
            {
                ...selectedRecipes[recipeIndex],
                customServings: servings,
            },
            ...selectedRecipes.slice(recipeIndex + 1),
        ];
        yield put(actions.selectedRecipes(setSelectedRecipes));
        yield put(actions.addRecipesAPI());
    } catch (error) {
        const data = { recipeId, servings };
        const text = 'Error in setServings saga';
        errorNotification(error, data, text);
    }
}
export function* setSelectedRecipeServing({ recipeId, servings }) {
    try {
        const loggedInUser = yield select((state) => state.authenReducer.loggedInUser);
        const cart = yield select((state) => state.recipeReducer.Cart);
        const response = yield call(cartAPI.addItemToCart, preparePayloadFromLocalStorage(cart), { getGroceries: true });
        if (!response) return;
        const { data } = response;
        if (!loggedInUser) {
            localStorage.setItem('guestUserId', data.guestUserId);
        }
        let groceriesLocal = data.groceries;
        if (!isEmpty(data.removedIngredients) || !isEmpty(data.nonRequiredIngredients)) {
            groceriesLocal = adjustRequiredIngredientStatus(groceriesLocal, data.removedIngredients);
            groceriesLocal = adjustNonRequiredIngredientStatus(groceriesLocal, data.nonRequiredIngredients);
        }
        yield put(actions.setSelectedRecipeServingsSuccess({ data: { ...data, groceries: groceriesLocal } }));
    } catch (error) {
        const data = { recipeId, servings };
        const text = 'Error in setSelectedRecipeServing saga';
        errorNotification(error, data, text);
    }
}

export function* getCartDetails({ forceCall, getGroceries, adjustDigitalPantry }) {
    try {
        let isFetching = yield select((state) => state.cartReducer.isFetching);
        const isFetched = yield select((state) => state.cartReducer.isFetched);
        const guestUserId = localStorage.getItem('guestUserId');
        const loggedInUser = yield select((state) => state.authenReducer.loggedInUser);
        let data, groceriesLocal;

        if (!loggedInUser && !guestUserId) {
            yield put(cartActions.setFetchingCart(false));
            return;
        }
        if (!isFetching && !forceCall) {
            yield put(cartActions.setFetchingCart(true));
            isFetching = true;
        }

        if (forceCall || !isFetching || !isFetched) {
            data = (yield call(cartAPI.get, true, adjustDigitalPantry)).data;
        }

        if (!data) {
            yield put(cartActions.setFetchingCart(false));
            yield put(cartActions.setFetchedCart(true));
            return;
        }
        data.ingredientSubstitutes = prepareIngredientSubstituteList(data.ingredientsToCost);
        if (data.groceries) {
            groceriesLocal = data.groceries;
        }
        if (!isEmpty(data.removedIngredients) || !isEmpty(data.nonRequiredIngredients)) {
            groceriesLocal = adjustRequiredIngredientStatus(groceriesLocal, data.removedIngredients);
            groceriesLocal = adjustNonRequiredIngredientStatus(groceriesLocal, data.nonRequiredIngredients);
        }
        yield put(orderActions.setDeliveryFee(data.cost.deliveryFee));
        yield put(cartActions.getCartSuccess({ ...data, groceries: groceriesLocal }));
        yield put(orderAction.setPromoCode(data.promoCode));
        yield put(cartActions.setCartDeliveryInfoSuccess(data));
        yield put(cartActions.shareCartUrlRequest());
    } catch (error) {
        const data = { forceCall, getGroceries, adjustDigitalPantry };
        const text = 'Error in getCartDetails saga';
        errorNotification(error, data, text);
    }
}
export function* getCartGroceriesDetails(data) {
    try {
        // add kleviyo print groceries events
        const influencerHandle = yield select((state) => state.influencerReducer.handle);
        const payload = { email: data.data.emailAddress };
        const loggedInUser = yield select((state) => state.authenReducer.loggedInUser);
        const user = yield select((state) => state.userReducer.userDetails);
        const guestUserId = localStorage.getItem('guestUserId');
        if (loggedInUser && user) payload.userId = user._id;
        if (guestUserId) payload.guestUserId = guestUserId;
        pushLearnqEvent(Events.EVENT_PRINT_GROCERIES, payload, influencerHandle);
        yield call(cartAPI.getCartGroceries, data);
        yield put(uiSettingActions.setConformationDialogue(true));
    } catch (error) {
        customNotification('error', 'Something went wrong, please try again!');
        const data = data;
        const text = 'Error in getCartDetails sagag';
        errorNotification(error, data, text);
    }
}

export function* updateUserLocationInCart({ payload, isCountryChange, forceCall }) {
    try {
        const cart = yield select((state) => state.recipeReducer.Cart);
        const userDetails = yield select((state) => state.userReducer.userDetails);
        if (forceCall) {
            yield call(cartAPI.addItemToCart, preparePayloadFromLocalStorage(cart, isCountryChange));
            if (isCountryChange) {
                window.location.reload();
                if (userDetails) {
                    const countries = yield select((state) => state.citiesReducer.countries);
                    yield put(orderAction.getDeliveryAddressSuccess(userDetails, countries));
                }
                localStorage.removeItem('deliveryAddress');
            }
        }
    } catch (error) {
        const data = { payload };
        const text = 'Error in updateCountryConfigICart saga';
        errorNotification(error, data, text);
    }
}

export function* addShareCartUrl() {
    try {
        const influencerHandle = yield select((state) => state.influencerReducer.handle);
        const selectedRecipes = yield select((state) => state.recipeReducer.Cart.selectedRecipes);
        const domain = config[process.env.REACT_APP_ENV].FRONTEND_DOMAIN;
        let longUrl = '';
        let baseUrl = domain;
        if (selectedRecipes.length === 0) {
            return;
        }
        const cartData = selectedRecipes.map((recipe) => `${recipe._id}:${recipe.mealTypeId}:${recipe.customServings}`);
        if (influencerHandle && influencerHandle !== 'easierchef') {
            longUrl = `${domain}/${influencerHandle}/recipes?addRecipes=${cartData}`;
            baseUrl = `${domain}/${influencerHandle}`;
        } else longUrl = `${domain}/recipes?addRecipes=${cartData}`;

        const { data } = yield call(shareUrlAPI.addShareUrl, { baseUrl, longUrl });
        if (data) {
            yield put(cartActions.shareCartUrl(data.shortUrl));
        } else {
            yield put(cartActions.shareCartUrl(''));
        }
    } catch (error) {
        const data = {};
        const text = 'Error in generating add Share Cart link';
        errorNotification(error, data, text);
    }
}

export function* getShareCartUrl({ id }) {
    try {
        if (id) {
            const { data } = yield call(shareUrlAPI.getShareUrl, id);
            if (data.longUrl) {
                yield put(cartActions.shareCartUrl(data.longUrl));
            } else {
                yield put(cartActions.shareCartUrl(''));
            }
        }
    } catch (error) {
        const data = {};
        const text = 'Error in get Share Cart link';
        errorNotification(error, data, text);
    }
}

export function* setCartDeliveryInfo(data) {
    let deliveryAddress = yield select((state) => state.orderReducer.deliveryAddress);
    let deliveryDate = yield select((state) => state.orderReducer.deliveryDate);
    let deliveryFee = yield select((state) => state.orderReducer.deliveryFee);
    const config = yield select((state) => state.configReducer.config);
    const countries = yield select((state) => state.citiesReducer.countries);
    if (!deliveryFee) {
        const cities = yield select((state) => state.citiesReducer.cities);
        const deliveryCity = cities.find((city) => city._id == deliveryAddress.city);
        if (deliveryCity) deliveryFee = deliveryCity.deliveryPrice;
    }
    try {
        deliveryAddress.country = countries.find(({ countryCode }) => countryCode === (config.countryCode || 'CA'))._id;
        const orderDetail = { ...deliveryAddress, ...deliveryDate, deliveryFee };
        const response = yield call(cartAPI.addDeliveryAddress, prepareCartDeliveryAddressPayload(orderDetail));
        // yield put(orderActions.setDeliveryFee(response.data.cost.deliveryFee));
        yield put(cartActions.setCartDeliveryInfoSuccess(response.data));
    } catch (error) {
        const text = 'Error in setting Cart Delivery Info';
        errorNotification(error, data, text);
    }
}

export function* setWaiveoffDeliveryFee() {
    try {
        yield put(actions.addRecipesAPI());
    } catch (error) {
        const data = {};
        const text = 'Error in setting Cart Delivery Info';
        errorNotification(error, data, text);
    }
}

export function* setCalorieInfo({ calorieInfo }) {
    try {
        const { _id: cartId } = yield select((state) => state.cartReducer.cart);
        if (cartId) {
            yield put(cartActions.setCalorieInfoFetching(true));
            const { data } = yield call(cartAPI.setCalorieInfo, { calorieInfo, cartId });
            yield delay(500);
            if (data) {
                yield put(cartActions.setCalorieInfoAPI(data));
            }
            yield put(cartActions.setCalorieInfoFetching(false));
        }
    } catch (error) {
        yield put(cartActions.setCalorieInfoFetching(false));
        const data = {};
        const text = 'Error in set total days';
        errorNotification(error, data, text);
    }
}

// const CustomDebounce = (ms, pattern, saga, ...args) =>
//     fork(function* () {
//         while (true) {
//             let action = yield take(pattern);
//             let wait = true;
//             let check = false;
//             while (true) {
//                 if (wait) {
//                     yield fork(saga, ...args, action);
//                     wait = false;
//                 }
//                 const { debounced, latestAction } = yield race({
//                     debounced: delay(ms),
//                     latestAction: take(pattern),
//                 });
//                 if (latestAction) {
//                     check = true;
//                 }
//                 if (debounced && check) {
//                     yield fork(saga, ...args, action);
//                     break;
//                 }
//                 if (!latestAction) {
//                     break;
//                 }
//                 action = latestAction;
//             }
//         }
//     });

export default function*() {
    yield takeLatest(constants.ADD_RECIPE, addRecipeToCart);
    yield takeLatest(constants.RESAVE_CART, resaveCart);
    yield takeLatest(constants.ADD_ALL_RECIPE, addAllRecipeToCart);
    yield takeLatest(constants.ADD_MEAL_PLAN, addMealPlanToCart);
    yield takeLatest(constants.SWAP_MEAL_PLAN_RECIPE, swapMealBoxRecipe);
    yield debounce(1000, constants.ADD_RECIPE_API, addRecipeToCartAPI);
    yield takeLatest(constants.DELETE_RECIPE, removeRecipeToCart);
    yield takeLatest(constants.SET_BULK_TYPE, setBulkType);
    yield takeLatest(constants.SET_SELECTED_RECIPE_SERVINGS, setSelectedRecipeServing);
    yield takeLatest(constants.SET_SERVINGS_RECIPE, setServings);
    yield takeLatest(constants.GET_CART_DETAILS, getCartDetails);
    yield takeLatest(constants.SET_USER_LOCATION, updateUserLocationInCart);
    yield takeLatest(constants.SET_EMAIL_SHOPPING_LIST, getCartGroceriesDetails);
    yield takeLatest(constants.SET_CART_DELIVERY_INFO, setCartDeliveryInfo);
    yield takeLatest(constants.SET_WAIVEOFF_DELIVERY_FEE, setWaiveoffDeliveryFee);
    yield takeLatest(constants.GENERATE_SHARE_CART_URL, addShareCartUrl);
    yield takeLatest(constants.GET_SHARE_CART_URL, getShareCartUrl);
    yield takeLatest(constants.SET_CALORIE_INFO, setCalorieInfo);
}
