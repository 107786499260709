import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useScreenType from 'hooks/useScreenType';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { Secondary } from 'components/common/Buttons';
import Icons from 'components/common/Icons/Icons';
import Input from 'components/common/Fields/Input';
import HeaderContainer from '../../components/HeaderContainer';

import { showPrintShoppingPopup, setConformationDialogue } from 'store/actions/ui-settings.actions';
import { getCartGroceries } from 'store/actions/cart.actions';

import { ShoppingMailContainer, Title, GoBack, ContentContainer, EmailInput, SubTitle, Button, BackIcon } from './styles';

const PrintShoppingPopup = () => {
    const dispatch = useDispatch();
    const { isMobile } = useScreenType();
    const isPrintShoppingPopupOpen = useSelector((state) => state.uiSettingsReducer.isPrintShoppingPopupOpen);

    const back = (e) => {
        dispatch(showPrintShoppingPopup(false));
    };
    const pickupFormSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Required'),
    });

    const pickupForm = useFormik({
        initialValues: {
            email: '',
        },
        onSubmit: (values) => {
            const pickupFormSubmit = {
                emailAddress: values.email.toLowerCase(),
            };
            dispatch(getCartGroceries(pickupFormSubmit));
            back();
        },
        validationSchema: pickupFormSchema,
        isInitialValid: false,
        validateOnChange: true,
    });

    return (
        <ShoppingMailContainer scroll="body" open={isPrintShoppingPopupOpen} onClose={back} fullScreen={true} $isMobile={isMobile}>
            {isMobile && <HeaderContainer />}
            {!isMobile ? (
                <BackIcon>
                    <Icons name="closePopup" onClick={back}></Icons>
                </BackIcon>
            ) : (
                <GoBack onClick={back}>
                    <Icons name="arrowLeft" size="25" />
                    <span>Go Back</span>
                </GoBack>
            )}
            <Title $isMobile={isMobile}>Please, enter your email</Title>

            <ContentContainer $isMobile={isMobile}>
                <SubTitle $isMobile={isMobile}>
                    Get your recipe list and ingredients emailed to grocery shop and cook on your own.
                    <span> But, we can’t track your unused groceries with this.</span>
                </SubTitle>
                <form onSubmit={pickupForm.handleSubmit}>
                    <EmailInput $isMobile={isMobile}>
                        <Input
                            placeHolder="Enter your email"
                            id="email"
                            name="email"
                            value={pickupForm.values.email || ''}
                            error={pickupForm.touched.email && pickupForm.errors.email}
                            onChange={pickupForm.handleChange}
                            onBlur={() => pickupForm.setFieldTouched('email', true)}
                        />
                    </EmailInput>
                    <Button type="submit" disabled={!pickupForm.isValid}>
                        <Secondary size="medium" title="Send" />
                    </Button>
                </form>
            </ContentContainer>
        </ShoppingMailContainer>
    );
};

export default PrintShoppingPopup;
