import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CustomDialog = ({ open, children, ...props }) => {
    return (
        <Dialog
            open={open}
            className="dialog-wrapper"
            TransitionComponent={Transition}
            aria-labelledby="simple-dialog-title"
            fullWidth
            {...props}
        >
            {children}
        </Dialog>
    );
};

CustomDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    children: PropTypes.element,
};

export default CustomDialog;
