import React from 'react';
import { useDispatch } from 'react-redux';
import history from '../../history';
import useScreenType from 'hooks/useScreenType';
import config from '../../config';

import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import { Tertiary } from 'components/common/Buttons';

import { updateStateMinimumPopup } from 'store/actions/ui-settings.actions';

import {
    MinimumContainer,
    MinimumContent,
    HeaderContainer,
    HeaderTitle,
    ContentContainer,
    FooterContainer,
    ButtonClose,
    ButtonShow,
} from './styles';

const MinimumPopup = () => {
    const dispatch = useDispatch();
    const { isMobile, isTabletOrMobile } = useScreenType();

    const back = (e) => {
        e.stopPropagation();
        dispatch(updateStateMinimumPopup(false));
    };

    const handleShowProposals = () => {
        history.push({
            pathname: '/min-order',
            state: { from: true },
        });
        dispatch(updateStateMinimumPopup(false));
    };

    return (
        <MinimumContainer
            scroll="body"
            open={true}
            onClose={back}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            $isTabletOrMobile={isTabletOrMobile}
            $isMobile={isMobile}
        >
            <MinimumContent>
                <HeaderContainer isMobile={isMobile}>
                    <IconButton onClick={back} size="small" aria-label="Add" variant="outline">
                        <Close />
                    </IconButton>
                    <HeaderTitle isMobile={isMobile}>Minimum order for delivery is $10.00</HeaderTitle>
                </HeaderContainer>
                <ContentContainer isMobile={isMobile}>Add more servings, more recipes, or extra products.</ContentContainer>
                <FooterContainer>
                    <ButtonClose>
                        <Tertiary onClick={() => dispatch(updateStateMinimumPopup(false))} size="medium" title="No thanks" />
                    </ButtonClose>
                    <ButtonShow>
                        <Tertiary onClick={handleShowProposals} size="medium" title="Show suggestions" />
                    </ButtonShow>
                </FooterContainer>
            </MinimumContent>
        </MinimumContainer>
    );
};

export default MinimumPopup;
