import * as constants from '../constants';
const initialState = {
    loading: false,
    currentCity: {},
    cities: [],
    isLoadedCities: false,
    countries: [],
    states: [],
};
export default function (state = initialState, action) {
    switch (action.type) {
        case constants.SET_CURRENT_CITY:
            return {
                ...state,
                currentCity: action.currentCity,
            };
        case constants.GET_CITIES_REQUEST:
            return {
                loading: true,
                ...state,
            };
        case constants.GET_CITIES_SUCCESS:
            return {
                ...state,
                cities: action.payload.cities,
                countries: action.payload.countries,
                states: action.payload.states,
                loading: false,
                isLoadedCities: true,
            };
        default:
            return state;
    }
}
