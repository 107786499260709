import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import { pushToGTM } from './dataLayer';
import { Events } from 'constants/events';
import config from 'config';

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const klaviyoEvents = getRetroActiveEvents() ?? [];

const sanitizeDataForKlaviyo = (payload) => {
    const payloadToSanitize = Object.assign({}, payload);
    delete payloadToSanitize.description; // Klaviyo has problem with description field
    if (payloadToSanitize._id) {
        payloadToSanitize.id = payloadToSanitize._id;
    }
    return payloadToSanitize;
};

const KLAVIYO_EVENTS_KEY = 'KLAVIYO_EVENTS';
function storeRetroActiveEvents(eventName, eventPayload) {
    const storedEvents = window.localStorage.getItem(KLAVIYO_EVENTS_KEY);
    let eventsToStore;
    try {
        eventsToStore = JSON.parse(storedEvents);
        if (!eventsToStore) {
            eventsToStore = [];
        }
        eventsToStore.push({ eventName, eventPayload });
    } catch (e) {
        eventsToStore = [{ eventName, eventPayload }];
    }
    klaviyoEvents.push(eventsToStore);
    window.localStorage.setItem(KLAVIYO_EVENTS_KEY, JSON.stringify(eventsToStore));
}

function getRetroActiveEvents() {
    const storedEvents = window.localStorage.getItem(KLAVIYO_EVENTS_KEY);
    try {
        return JSON.parse(storedEvents);
    } catch (e) {
        return null;
    }
}

function clearRetroActiveEvents() {
    window.localStorage.removeItem(KLAVIYO_EVENTS_KEY);
}

const generateUniqueEventId = (recipes) => {
    return (
        new Date().getTime() +
        '_' +
        recipes
            .map((recipe) => recipe._id)
            .sort()
            .join()
    );
};

export const identifyUser = async (userInfo, influencer) => {
    if (!userInfo) {
        const token = Cookies.get('token');
        userInfo = jwt_decode(token);
    }

    if (config[process.env.REACT_APP_ENV].ENABLE_GTM) {
        let baseURL = config[process.env.REACT_APP_ENV].FRONTEND_DOMAIN;
        if (influencer) {
            baseURL = config[process.env.REACT_APP_ENV].FRONTEND_DOMAIN + '/' + influencer;
        }
        window._learnq = window._learnq || [];
        window._learnq.push([
            Events.EVENT_IDENTIFY_USER,
            {
                $email: userInfo.username,
                $first_name: userInfo.firstName,
                $last_name: userInfo.lastName,
                baseURL: baseURL,
            },
        ]);
    }

    // check if we need to publish historical events
    if (klaviyoEvents.length > 0) {
        await delay(5000);
        klaviyoEvents.forEach((event) => {
            const success = window._learnq.push(['track', event.eventName, sanitizeDataForKlaviyo(event.eventPayload)]);
            if (!success) {
                return console.error(`Failed to publish event`, event);
            }
        });
        clearRetroActiveEvents();
    }
};

export const pushLearnqEvent = (eventName, payload, influencerHandle) => {
    let data = payload;
    if (eventName === Events.EVENT_STARTED_CHECKOUT) {
        data = {
            Items: payload,
        };
    }

    let baseURL = config[process.env.REACT_APP_ENV].FRONTEND_DOMAIN;
    if (influencerHandle && influencerHandle != 'easierchef') {
        baseURL = config[process.env.REACT_APP_ENV].FRONTEND_DOMAIN + '/' + influencerHandle;
    }

    data.baseURL = baseURL;
    if (config[process.env.REACT_APP_ENV].ENABLE_GTM) {
        window._learnq = window._learnq || [];
        const pushed = window._learnq.push(['track', eventName, sanitizeDataForKlaviyo(data)]);
        if (!pushed) {
            storeRetroActiveEvents(eventName, sanitizeDataForKlaviyo(data));
        }
        const eventData = {
            event: eventName,
            cartvalue: data,
        };
        pushToGTM(eventData);
    }
};
