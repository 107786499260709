import * as constants from '../constants';

const initialState = {
    loading: false,
    error: '',
    all: [],
    selected: [],
    isloadedExtraCategories: false,
    isChangeExtraCategories: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case constants.GET_EXTRA_PRODUCTS_CATEGORIES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case constants.GET_EXTRA_PRODUCTS_CATEGORIES_SUCCESS:
            return {
                ...state,
                all: action.extraProductsCategories,
                loading: false,
                isloadedExtraCategories: true,
            };
        case constants.GET_EXTRA_PRODUCTS_CATEGORIES_FAILURE:
            return {
                ...state,
                error: action.error,
            };

        case constants.ADD_EXTRA_CATEGORY:
            return {
                ...state,
                selected: [...state.selected, action.categoryName],
                isChangeExtraCategories: true,
            };
        case constants.DELETE_EXTRA_CATEGORY:
            return {
                ...state,
                selected: state.selected.filter((category) => category !== action.categoryName),
                isChangeExtraCategories: true,
            };
        case constants.CLEAR_FLAGS_IS_CHANGE_EXTRA_CATEGORIES:
            return {
                ...state,
                isChangeExtraCategories: false,
            };
        default:
            return state;
    }
}
