import BaseRecipesApi from './BaseRecipesAPI';
import store from 'store/store';
import * as actions from '../recipe.action';
import { prepareRecipesCategoryWise } from 'utility/recipeUtil';
import { queryFilterRecipes } from 'helpers/queryFilterRecipes';
class GetAllRecipesApi extends BaseRecipesApi {
    shouldCall = ({ forceCall }) => {
        const state = store.getState();
        const {
            recipeReducer: { pagination },
            orderMenuReducer: { fetching, isloadedRecipe },
        } = state;
        if (forceCall && (pagination.nextPage > pagination.totalPage || pagination.totalCount == 0)) {
            return false;
        }
        if (forceCall || !isloadedRecipe) {
            return true;
        }
        return false;
    };

    prepareQuery = () => {
        const state = store.getState();
        const {
            recipeCategoryReducer: { selected: categories, excludeCategoryFilters },
            allergyReducer: { selectedAllergies: allergies },
            recipeReducer: { diet, pagination },
            ingredientReducer: { selectedIngredients },
            orderMenuReducer: { recipesFilter, filterInfo },
            influencerReducer: { data: influencer, isDefaultInfluencer },
        } = state;
        const query = queryFilterRecipes({
            selectedCategories: categories,
            selectedAllergies: allergies,
            diet,
            isSelectedIngredients: selectedIngredients,
            excludeCategoryFilters,
            pagination,
            influencer,
            isDefaultInfluencer,
            recipesFilter,
            filterInfo,
            recipeLimit: 10,
        });
        return query;
    };

    afterCall = ({ response }) => {
        const {
            data: { recipes, categories, totalCount },
            pagination,
        } = response.data;
        const state = store.getState();
        const allRecipeCategories = state.recipeCategoryReducer.all;
        const storedRecipes = state.orderMenuReducer.recipes;
        store.dispatch(
            actions.getRecipesSuccess({
                recipes: [...storedRecipes, ...prepareRecipesCategoryWise(allRecipeCategories, categories, recipes)],
                pagination,
                totalCount,
            }),
        );
    };
}

export default new GetAllRecipesApi();
