import { call, put, takeEvery, select, takeLatest, debounce } from 'redux-saga/effects';
import orderMenuAPI from '../../services/orderMenu.service';
import * as constants from '../constants';
import * as actions from '../actions/orderMenu.actions';
import * as actionsOrder from '../actions/order.actions';
import * as actionUI from '../actions/ui-settings.actions';

import { queryFilterCommonIngredients } from 'helpers/queryFilterRecipes';
import { errorNotification } from 'helpers/errorNotification';
import { formatRecipesSelected } from 'utility/recipeUtil';
import { keyBy } from 'lodash';

const selectOrderMenu = () =>
    select(({ orderMenuReducer: { menuRecipes } }) => ({
        menuRecipes,
    }));

export const selectSelectedCategories = () => select(({ recipeCategoryReducer }) => recipeCategoryReducer.selected);
export const selectSearchValue = () => select(({ recipeSearchReducer }) => recipeSearchReducer.search);
export const selectIsSelectedRecipes = () => select(({ recipeReducer }) => recipeReducer.Cart.selectedRecipes);
export const selectedSelectedAllergies = () => select(({ allergyReducer }) => allergyReducer.selectedAllergies);
export const selectIsChangeCookingTime = () => select(({ orderMenuReducer }) => orderMenuReducer.isChangeCookingTime);
export const selectedDefaultServings = () => select(({ uiSettingsReducer }) => uiSettingsReducer.defaultServings);
export const selectedRecipesFilter = () => select(({ orderMenuReducer }) => orderMenuReducer.recipesFilter);
export const selectedFilterInfo = () => select(({ orderMenuReducer }) => orderMenuReducer.filterInfo);
export const selectedInfluencer = () => select(({ influencerReducer }) => influencerReducer.data);

export function* setRecipesToPrint({ orderId, selectedId }) {
    try {
        yield getMenu({ orderId });
        const { menuRecipes } = yield selectOrderMenu();

        if (selectedId) {
            let menuRecipe = null;

            menuRecipes.forEach((category) => {
                const currentRecipe = category.recipes.find((recipe) => {
                    return recipe._id === selectedId;
                });

                menuRecipe = {
                    category,
                    recipes: [currentRecipe],
                };
            });

            if (menuRecipe) {
                yield put(actions.setRecipesToPrintSuccess([menuRecipe]));
            }
        } else yield put(actions.setRecipesToPrintSuccess(menuRecipes));
    } catch (error) {
        const data = { orderId, selectedId };
        const text = 'Error in setRecipesToPrint saga';
        errorNotification(error, data, text);
    }
}
export function* getRecipesToPrint({ payload: { order, id } }) {
    try {
        const response = yield call(orderMenuAPI.getRecipesToPrint, { order, id });
        yield put(actions.getRecipesToPrintSuccess(response.data));
    } catch (error) {
        yield put(constants.GET_RECIPES_TO_PRINT_FAILURE);
        const data = { order, id };
        const text = 'Error in getRecipesToPrint saga';
        errorNotification(error, data, text);
    }
}

export function* getMenu({ orderId }) {
    try {
        const menuRecipes = yield call(orderMenuAPI.getMenu, orderId);
        yield put(actions.getMenuSuccess(menuRecipes.data));
        yield put(actionsOrder.clearStateOrder());
    } catch (error) {
        yield put(actions.getMenuFailure(error));
        const data = { orderId };
        const text = 'Error in getMenu saga';
        errorNotification(error, data, text);
    }
}

let createHashesWithOrder = (commonIngredients, cartRecipes) => {
    let cartHash = {};
    cartRecipes.map((recipe) => (cartHash[recipe['_id']] = 1));
    let hashWithOrderAsKey = {};
    let hashWithIdAsKey = {};
    for (let i = 0; i < commonIngredients.length; i++) {
        if (commonIngredients[i]['recipe']['_id'] in cartHash) {
            hashWithOrderAsKey[i] = commonIngredients[i];
            hashWithIdAsKey[commonIngredients[i]['recipe']['_id']] = {
                order: i,
            };
        }
    }
    return [hashWithOrderAsKey, hashWithIdAsKey];
};

const sortCommonIngredients = (cartRecipes, oldResult, newResult) => {
    if (newResult.length === 0) {
        return [];
    }
    let [hashWithOrderAsKey, hashWithIdAsKey] = createHashesWithOrder(oldResult, cartRecipes);
    let newRecipesHash = keyBy(newResult, (obj) => obj['recipe']['_id']);
    let finalResult = [];
    let i = 0;
    let order = 0;
    while (i < newResult.length) {
        order = finalResult.length;
        let newRecipeId = newResult[i]['recipe']['_id'];
        // console.log('position: ', order, ' element: ', i, ' recipe: ', newResult[i]['recipe']['name']);
        //if we found the previous recipe in that order, push that
        if (order in hashWithOrderAsKey) {
            // console.log('found old result at order: ' + order + hashWithOrderAsKey[order]['recipe']['_id']);
            if (!(hashWithOrderAsKey[order]['recipe']['_id'] in newRecipesHash)) {
                if (!hashWithOrderAsKey[order]['recipeScore'].previousServings) {
                    hashWithOrderAsKey[order]['recipeScore'].previousServings =
                        hashWithOrderAsKey[order]['recipeScore'].freeServings;
                }
                hashWithOrderAsKey[order]['recipeScore'].freeServings = 0;
            }
            finalResult.push(hashWithOrderAsKey[order]);
        }
        //if new recipe was present in previous list
        else if (newRecipeId in hashWithIdAsKey) {
            // console.log('found previously at ', hashWithIdAsKey[newRecipeId].order);
            // if old recipe is already added, update the list object
            if (hashWithIdAsKey[newRecipeId].order < order) {
                // console.log('updating final array with latest data:' + hashWithIdAsKey[newRecipeId].order);
                finalResult[hashWithIdAsKey[newRecipeId].order] = newResult[i];
            }
            // if new recipe has the same order as the new recipe, add the new recipe in the list
            else if (hashWithIdAsKey[newRecipeId].order === order) {
                // console.log('same order. adding now');
                finalResult.push(newResult[i]);
            }
            // if old recipe is not added yet, update the content in the hash
            else {
                // console.log('updating hash: ', hashWithIdAsKey[newRecipeId].order);
                hashWithOrderAsKey[hashWithIdAsKey[newRecipeId].order] = newResult[i];
            }
            i++;
        }
        //if new recipe is not present in previous list
        else {
            // console.log('new recipe is not present in previous list');
            finalResult.push(newResult[i]);
            i++;
        }
    }

    order = finalResult.length;
    while (order < oldResult.length) {
        if (order in hashWithOrderAsKey) {
            // console.log('appending at the end ' + order + hashWithOrderAsKey[order]['recipe']['_id']);
            finalResult.push(hashWithOrderAsKey[order]);
        }
        order++;
    }

    return finalResult;
};

export function* getCommonIngredients({ forceCall }) {
    try {
        const isFetching = yield select((state) => state.orderMenuReducer.isFetchingCommonRecipes);
        const isFetched = yield select((state) => state.orderMenuReducer.isFetchedCommonRecipes);
        const commonIngredients = yield select((state) => state.orderMenuReducer.commonIngredients);
        if (!isFetching) {
            yield put(actions.setFetchingCommonIngredient(true));
        }
        const selectedCategories = yield selectSelectedCategories();
        const selectedAllergies = yield selectedSelectedAllergies();
        const searchValue = yield selectSearchValue();
        const selectedRecipes = yield selectIsSelectedRecipes();
        const defaultServings = yield selectedDefaultServings();
        const diet = yield select((state) => state.recipeReducer.diet);
        const influencer = yield selectedInfluencer();
        const isChangeCookingTime = yield selectIsChangeCookingTime();
        const recipesFilter = yield selectedRecipesFilter();
        const filterInfo = yield selectedFilterInfo();
        const isSubstitutionOn = yield select((state) => state.ingredientReducer.isSubstitutionOn);
        let response;
        const removedIngredientList = yield select((state) => state.recipeReducer.Cart.removedIngredientList);
        const config = yield select((state) => state.configReducer.config);
        const ingredientSubstitutes = yield select((state) => state.recipeReducer.Cart.ingredientSubstitutes);
        const data = {
            cartRecipes: formatRecipesSelected(selectedRecipes, defaultServings),
            removedIngredientList,
            config,
            ingredientSubstitutes,
        };

        if ((forceCall || !isFetching || !isFetched || isChangeCookingTime) && influencer) {
            response = yield call(
                orderMenuAPI.getCommonIngredients,
                data,
                queryFilterCommonIngredients(
                    selectedCategories,
                    selectedAllergies,
                    searchValue,
                    diet,
                    influencer,
                    recipesFilter,
                    filterInfo,
                    isSubstitutionOn,
                ),
            );
        }

        if (response && response.data) {
            const previousCommonIngredietns = yield select((state) => state.orderMenuReducer.commonIngredients);
            const cartRecipes = yield select((state) => state.recipeReducer.Cart.selectedRecipes);
            const sortedCommonIngredients = sortCommonIngredients(cartRecipes, previousCommonIngredietns, response.data);
            yield put(actions.getCommonIngredientsSuccess(sortedCommonIngredients));
            if (sortedCommonIngredients.length === 0) {
                yield put(actionUI.setIsShowCommon([]));
            }
        } else {
            yield put(actions.getCommonIngredientsSuccess(commonIngredients));
        }
    } catch (error) {
        yield put(actions.getCommonIngredientsFailure(error));
        const data = {};
        const text = 'Error in getCommonIngredients saga';
        errorNotification(error, data, text);
    }
}

export function* getUnusedIngredientsRecipe(action) {
    try {
        const config = yield select((state) => state.configReducer.config);
        const query = {
            userId: action.useId,
            countryCode: config && config.countryCode,
        };
        const response = yield call(orderMenuAPI.getUnusedIngredientsRecipe, query);
        yield put(actions.getUnusedIngredientsRecipeSuccess(response.data));
    } catch (error) {
        const data = { userId: action.useId };
        const text = 'Error in getUnusedIngredientsRecipe saga';
        errorNotification(error, data, text);
        yield put(actions.getUnusedIngredientsRecipeFailure(error));
    }
}

export default function* () {
    yield takeEvery(constants.SET_RECIPES_TO_PRINT, setRecipesToPrint);
    yield takeEvery(constants.GET_RECIPES_TO_PRINT_REQUEST, getRecipesToPrint);
    yield takeEvery(constants.GET_MENU_REQUEST, getMenu);
    yield debounce(
        2000,
        [constants.GET_COMMON_INGREDIENTS_REQUEST, constants.FILTER_COMMON_INGREDIENTS, constants.SEARCH_COMMON_INGREDIENTS],
        getCommonIngredients,
    );
    yield takeEvery(constants.GET_UNUSED_INGREDIENTS_RECIPE_REQUEST, getUnusedIngredientsRecipe);
}
