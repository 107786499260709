import { call, put, select, takeEvery } from 'redux-saga/effects';
import * as constants from '../constants';
import * as actions from '../actions/linkeinBio.actions';
import serverAPI from 'services/linkeinBio.service';
import { errorNotification } from 'helpers/errorNotification';

export const selectIsLoadedCities = () => select(({ citiesReducer }) => citiesReducer.isLoadedCities);

export function* getLinkeinBio() {
    try {
        const response = yield call(serverAPI.get);
        yield put(actions.getLinkeinBioSuccess(response.data));
    } catch (error) {
        const data = {};
        const text = 'Error in getLinkeinBio saga';
        errorNotification(error, data, text);
    }
}
export default function* () {
    yield takeEvery(constants.GET_LINKEINBIO_REQUEST, getLinkeinBio);
}
