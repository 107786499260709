import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PieChart from './PieChart';
import useTheme from 'hooks/useTheme';
import useScreenType from 'hooks/useScreenType';
import { isEqual, get, unionBy, range } from 'lodash';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import TypographyD from '../common/TypographyD/TypographyD';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Tooltips from 'components/common/Controls/Tooltips/Tooltips';
import Icons from '../common/Icons/Icons';

import { setCalorieInfo } from 'store/actions/cart.actions';

import { calculateMealTypeCalorie } from 'utility/recipeUtil';

import './styles.scss';
import { CircularProgress, MenuItem, Select } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import Loading from 'pages/Checkout/components/paymentDetails/StatusDialogs/loading';
import RadioNumberOptions from 'components/RadioNumberOptions';

function areEqual(prevProps, nextProps) {
    return isEqual(prevProps.nutrition, nextProps.nutrition);
}

const dummyArray = [];
const dummyObject = {
    mealTypes: [],
};

export default React.memo(function({ nutrition, caloriesGoal }) {
    const color = useTheme();
    const dispatch = useDispatch();
    const { isTabletOrMobile } = useScreenType();
    const [expanded, setExpanded] = useState(false);
    const [nutritionData, setNutritionData] = useState([]);
    const [totalCalorieHave, setTotalCalorieHave] = useState();
    const [dailyNutrition, setDailyNutrition] = useState();
    const { selectedRecipes } = useSelector((state) => state.recipeReducer.Cart);
    const { noOfPeople, noOfDays } = useSelector((state) => state.buildMenuReducer);
    const { mealTypes } = useSelector((state) => state.mealTypesReducer);
    const cart = useSelector((state) => state.cartReducer);
    const calorieInfo = get(cart, 'cart.calorieInfo', dummyObject);
    const [days, setDays] = useState(get(cart, 'cart.calorieInfo.totalDays', noOfDays));
    const [isDaysSet, setIsDaysSet] = useState(true);
    const [members, setMembers] = useState(get(cart, 'cart.calorieInfo.members', noOfPeople));
    const [caloriesOutside, setCaloriesOutside] = useState(get(cart, 'cart.calorieInfo.caloriesOutside', 0));
    const [showForIndividual, setShowForIndividual] = useState(get(cart, 'cart.calorieInfo.showForIndividual', true));
    const [updateButtonActive, setUpdateButtonActive] = useState(false);

    useEffect(() => {
        setDays(noOfDays);
    }, noOfDays);

    useEffect(() => {
        setMembers(noOfPeople);
    }, noOfPeople);

    useEffect(() => {
        if (!mealTypes || !selectedRecipes) return;

        const [mealTypeNutrition, dailyNutrition] = calculateMealTypeCalorie(
            mealTypes,
            selectedRecipes,
            calorieInfo.mealTypes,
            nutrition,
            noOfDays,
            showForIndividual ? 1 : members,
        );

        !isDaysSet && setDays(noOfDays);
        setNutritionData(Object.values(mealTypeNutrition));
        setTotalCalorieHave(dailyNutrition.calories);
        setDailyNutrition(dailyNutrition);
    }, [mealTypes, selectedRecipes, calorieInfo]);

    const viewIcon = () => {
        return (
            <div className="viewMore">
                <div className="view">{expanded ? 'Less' : 'More'}</div>
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </div>
        );
    };
    const handleChangeAddTotalDays = (e) => {
        if (!isNaN(e.target.value)) {
            setDays(e.target.value);
            setIsDaysSet(true);
            setUpdateButtonActive(true);
        } else {
            setDays('');
        }
    };

    const handleChangeMembers = (e) => {
        if (!isNaN(e.target.value)) {
            setMembers(e.target.value);
            setUpdateButtonActive(true);
        } else {
            setMembers('');
        }
    };

    const updateCalorieInfo = () => {
        dispatch(
            setCalorieInfo({
                ...calorieInfo,
                caloriesOutside: parseInt(caloriesOutside),
                members: parseInt(members),
                totalDays: parseInt(days),
            }),
        );
        setUpdateButtonActive(false);
    };

    const handleCaloriesOutside = (e) => {
        if (!isNaN(e.target.value)) {
            setCaloriesOutside(e.target.value);
            setUpdateButtonActive(true);
            dispatch(
                setCalorieInfo({
                    ...calorieInfo,
                    caloriesOutside: parseInt(e.target.value),
                    members: parseInt(members),
                    totalDays: parseInt(days),
                }),
            );
        } else {
            setCaloriesOutside('');
        }
    };

    const onToggleShowForIndividual = (value) => {
        setShowForIndividual(!showForIndividual);
        setUpdateButtonActive(true);
        dispatch(
            setCalorieInfo({
                ...calorieInfo,
                caloriesOutside,
                members: parseInt(members),
                totalDays: parseInt(days),
                showForIndividual: !showForIndividual,
            }),
        );
    };

    const handleChangeServings = (mealTypeId, servings) => {
        let found = 0;
        const mealTypes = calorieInfo.mealTypes.map((cal) => {
            if (cal.mealTypeId === mealTypeId) {
                found = 1;
                return {
                    ...cal,
                    servings,
                };
            }
            return cal;
        });
        if (found === 0) {
            mealTypes.push({
                mealTypeId,
                servings,
            });
        }

        dispatch(
            setCalorieInfo({
                ...calorieInfo,
                caloriesOutside,
                totalDays: days,
                members,
                mealTypes,
            }),
        );
    };
    const totalCaloriesCombinedPerDayPerPerson =
        parseInt(totalCalorieHave) + parseInt(calorieInfo.caloriesOutside ? calorieInfo.caloriesOutside : 0);

    let peopleText = showForIndividual ? 'you' : 'they';
    let daysText = noOfDays ? ` over ${noOfDays} days` : '';
    let calorieText = `How many servings will ${peopleText} eat${daysText}?`;
    return (
        <Accordion
            className="container"
            expanded={expanded}
            onChange={(event, newExpanded) => {
                setExpanded(newExpanded ? true : false);
            }}
            style={{
                padding: isTabletOrMobile ? (expanded ? '10px 0 0' : '10px 20px 0') : '0 8px',
            }}
        >
            <AccordionSummary expandIcon={viewIcon()} aria-controls="content" id="header" className="accordion">
                <div className="heading">
                    <Icons name="pieChart" size="22" />
                    <TypographyD className="heading__text" type={'h6'} color={color.grey1}>
                        <div className="goalHeader">
                            <div>{caloriesGoal ? 'Daily Calorie Goal : ' : 'Daily Calories : '}</div>
                            {cart.isCalorieInfoFetching ? (
                                <CircularProgress style={{ marginLeft: 10 }} size={16} />
                            ) : (
                                <div
                                    className="goalValue"
                                    style={{
                                        color: `${
                                            caloriesGoal
                                                ? totalCaloriesCombinedPerDayPerPerson <= caloriesGoal
                                                    ? '#82BD73'
                                                    : '#FF6B6B'
                                                : '#82BD73'
                                        }`,
                                    }}
                                >
                                    {' ' +
                                        (totalCaloriesCombinedPerDayPerPerson ? totalCaloriesCombinedPerDayPerPerson : '') +
                                        (caloriesGoal > 0 ? '/' + caloriesGoal : '')}
                                </div>
                            )}
                        </div>
                    </TypographyD>
                </div>
            </AccordionSummary>

            {noOfPeople > 1 ? (
                <div className="calorie-toggle-container" style={{ marginTop: isTabletOrMobile ? 10 : 0 }}>
                    <RadioNumberOptions
                        value={showForIndividual ? 1 : noOfPeople}
                        style={{ margin: '5px 0' }}
                        options={[1, noOfPeople]}
                        title="Planning for"
                        size="small"
                        onChange={onToggleShowForIndividual}
                    />
                    <span style={{ fontSize: 15 }}>&nbsp;&nbsp;ppl</span>
                </div>
            ) : null}
            {!noOfDays ? (
                <div className="buildMenuLink">
                    <a href="/build-menu">Enter your calorie target & goal</a>
                </div>
            ) : null}
            <div className="calorieIntro">
                {calorieText}
                &nbsp;
                <Tooltips label="Select how many servings to include in your individual or family plan to estimate your average daily calorie intake. Make sure to add any calories consumed outside of the recipes added to EasierChef. ">
                    <Icons name="help" size="16" color="#909da6" />
                </Tooltips>
            </div>
            {/* <span className="daysAndPeople">
                <TodayIcon style={{ fontSize: 20 }} /> &nbsp;{noOfDays} days
                <PeopleIcon style={{ marginLeft: 15, fontSize: 20 }} fontSize="12px" /> &nbsp;{noOfPeople} people
            </span> */}
            {/* <div className="addDayContainer">
                <span className="addDayTitle">Number of days</span>

                <input className="addDayInput" type="text" value={days} onChange={handleChangeAddTotalDays} />
            </div> */}
            {/* <RadioNumberOptions
                size="small"
                value={members}
                options={[1, 2, 3]}
                title="Number of people"
                onChange={handleChangeMembers}
            />
            <RadioNumberOptions
                value={members}
                options={[3, 4, 5]}
                size="small"
                title="Number of days&nbsp;&nbsp;&nbsp;"
                onChange={handleChangeAddTotalDays}
            /> */}
            {/* <div className="addDayContainer">
                <span className="addDayTitle">Number of people</span>
                <input className="addDayInput" type="text" value={members} onChange={handleChangeMembers} />
            </div> */}
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <div className="goleDetails">
                    <div className="goleContainer">
                        {nutritionData.length > 0 &&
                            nutritionData.map((mealType, i) =>
                                mealType.servings ? (
                                    <div key={i} className="goleWrapper">
                                        <div className="goalTitle" style={{ display: 'flex' }}>
                                            <Select
                                                value={mealType.selectedServings ? mealType.selectedServings : mealType.servings}
                                                style={{ height: 18, fontSize: 14, color: '#909da6', padding: 0, marginRight: 5 }}
                                                autoWidth
                                                className="goalServingInput"
                                                onChange={(e) => handleChangeServings(mealType.mealType._id, e.target.value)}
                                                id="servings-select"
                                            >
                                                {range(1, mealType.servings + 1).map((option, i) => (
                                                    <MenuItem style={{ color: '#909da6' }} key={i} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <div className="goalTitle">{`${mealType.mealType.name}`}</div>
                                            <div>{Math.round(mealType.nutrition.calories / mealType.servings)} cal/serv</div>
                                        </div>
                                    </div>
                                ) : null,
                            )}
                    </div>
                </div>
                <div className="addDayContainer">
                    <span className="addDayTitle">Calories from other sources</span>
                    <input className="addOutsideCalories" type="text" value={caloriesOutside} onChange={handleCaloriesOutside} />/
                    day
                    {/* <div className="updateButton">
                        <Secondary size="xsmall" disabled={!updateButtonActive} title={'Update'} onClick={updateCalorieInfo} />
                    </div> */}
                </div>
                {/* <div className="addDayContainer"></div> */}
                <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
                    <PieChart nutrition={nutrition} dailyNutrition={dailyNutrition} expanded={expanded} />
                </AccordionDetails>
            </div>
        </Accordion>
    );
}, areEqual);
