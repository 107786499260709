import styled, { css } from 'styled-components';
export const StyledComponent = styled.div`
    font-family: Work Sans;
    text-align: center;
    font-style: normal;
    line-height: 150%;
    color: ${({ color }) => color};
    h1 {
        font-size: 50px;
        font-weight: 600;
    }
    h2 {
        font-weight: bold;
        font-size: 40px;
    }
    h3 {
        font-weight: bold;
        font-size: 32px;
    }
    h4 {
        font-weight: 500;
        font-size: 24px;
    }
    h5 {
        font-weight: 500;
        font-size: 16px;
    }
    h6 {
        font-weight: 500;
        font-size: 14px;
    }
    p {
        font-size: ${({ type, fontSize }) =>
            (fontSize && fontSize) ||
            (type === 'bodyExtra' && '20px') ||
            (type === 'bodyBig' && '16px') ||
            (type === 'bodyMedium' && '14px') ||
            (type === 'bodySmall' && '12px')};

        font-weight: ${({ fontWeight }) => fontWeight || '500'};
    }
    span {
        font-weight: ${({ fontWeight }) => fontWeight || '500'};
        font-size: ${({ type }) =>
            (type === 'helpBig' && '24px') ||
            (type === 'buttonBig' && '20px') ||
            ((type === 'buttonMedium' || type === 'helpMedium') && '16px') ||
            (type === 'buttonSmall' && '14px')};
    }
    a {
        font-weight: ${({ fontWeight }) => fontWeight || '500'};
        font-size: ${({ type }) => (type === 'linkBig' && '20px') || (type === 'linkMedium' && '16px')};
    }
`;
