import { call, put, select, takeEvery } from 'redux-saga/effects';
import * as constants from '../constants';
import * as actions from '../actions/influencer.action';
import * as actionsOrderMenu from '../actions/orderMenu.actions';
import serverAPI from 'services/influencer.service';
import history from '../../history';
import { errorNotification } from '../../helpers/errorNotification';

export const selectIsFetchedInfluencer = () => select(({ influencerReducer }) => influencerReducer.isFetched);
export const selectDataInfluencer = () => select(({ influencerReducer }) => influencerReducer.data);
export const selectedIsLoadingUsers = () => select(({ userReducer }) => userReducer.isLoadingUser);
export function* getInfluencer({ handle, pathname }) {
    try {
        const isFetched = yield selectIsFetchedInfluencer();
        const data = yield selectDataInfluencer();
        if (!isFetched) {
            const response = yield call(serverAPI.getInfluencer, handle);
            if (response.data) {
                yield put(actions.getInfluencerSuccess(response.data));
            } else {
                if (handle === 'easierchef') {
                    yield put(actions.getInfluencerSuccess(response.data));
                } else {
                    history.push('/');
                }
            }
        }
    } catch (error) {
        const data = { handle, pathname };
        const text = 'Error in getInfluencer saga';
        errorNotification(error, data, text);
    }
}
export default function* () {
    yield takeEvery(constants.GET_INFLUENCER_REQUEST, getInfluencer);
}
