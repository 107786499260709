import * as constants from '../constants';

const initialState = {
    loading: false,
    error: '',
    config: localStorage.getItem('config') ? JSON.parse(localStorage.getItem('config')) : { countryCode: 'CA', currency: 'CAD' },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case constants.SET_USER_LOCATION:
            localStorage.setItem('config', JSON.stringify(action.payload));
            return {
                ...state,
                config: action.payload,
            };

        default:
            return state;
    }
}
