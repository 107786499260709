import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import ReactGoogleTagManager from 'react-gtm-module';
import { trackUserActivity } from './trackUserActivity';

export const withTracker = (WrappedComponent, options) => {
    const trackPage = (page) => {
        trackUserActivity({ type: 'page', value: page });
    };

    if (process.env.REACT_APP_ENV === 'production') {
        ReactGoogleTagManager.initialize({
            gtmId: 'GTM-W58CQBR',
        });
    }

    return (props) => {
        useEffect(() => {
            trackPage(props.location.pathname);
        }, [props.location.pathname]);

        return <WrappedComponent {...props} />;
    };
};
