import screenTypes from 'constants/screenTypes';

export default {
    [screenTypes.EXTRA_LARGE_DESKTOP]: {
        minBreakpoint: 1665,
    },
    [screenTypes.LARGE_DESKTOP]: {
        minBreakpoint: 1440,
        maxBreakpoint: 1920,
    },
    [screenTypes.DESKTOP]: {
        minBreakpoint: 780,
        maxBreakpoint: 1440,
    },
    [screenTypes.TABLET]: {
        minBreakpoint: 580,
        maxBreakpoint: 1024,
    },
    [screenTypes.MOBILE]: {
        maxBreakpoint: 480,
    },
};
