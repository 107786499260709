import get from 'lodash/get';
import { Provider } from 'react-redux';

export const preparePayloadFromLocalStorage = (cart, isCountryChange) => {
    const selectedRecipesLocal = get(cart, 'selectedRecipes', []);
    const bulkTypeLocal = localStorage.getItem('bulkType');
    const ingredientSubstitutes = get(cart, 'ingredientSubstitutes', []);
    const extraGroceriesLocal = get(cart, 'extraGroceries', []);
    const nonRequiredProductList = get(cart, 'noneRequiredProductList', []);
    const removedProductsList = get(cart, 'removedIngredientList', []);
    const waiveOffDeliveryFee = get(cart?.pricePayload, 'waiveOffDeliveryFee', false);
    const config = localStorage.getItem('config')
        ? JSON.parse(localStorage.getItem('config'))
        : { countryCode: 'CA', currency: 'CAD' };
    return {
        payload: {
            recipes: selectSelectedRecipesIds(selectedRecipesLocal),
            brandType: bulkTypeLocal ? bulkTypeLocal : 'LOW_COST',
            extraGroceries: extraGroceriesLocal,
            nonRequiredProductsToCost: nonRequiredProductList.filter((ing) => ing.ingredient),
            removedIngredientList: removedProductsList,
            config,
            isIngredientSubstitutionOn: ingredientSubstitutes.length > 0,
            ingredientSubstitutes: ingredientSubstitutes,
            waiveOffDeliveryFee: waiveOffDeliveryFee,
            isCountryChange,
        },
    };
};

export const prepareCartDeliveryAddressPayload = (order) => {
    return {
        address: {
            emailAddress: order.emailAddress,
            firstName: order.firstName,
            lastName: order.lastName,
            lineOne: order.lineOne,
            lineTwo: order.lineTwo,
            city: order.city || null,
            province: order.province || null,
            postalCode: order.postalCode,
            phone: order.phoneNumber,
            phoneNumber: order.phoneNumber,
            optional: order.optional,
            country: order.country || null,
            deliveryInstructions: order.deliveryInstructions,
            date: order.date,
            timeSlot: order.timeSlot,
        },
        deliveryFee: order.deliveryFee || 0,
    };
};

export const selectSelectedRecipesIds = (selectedRecipes) => {
    let selectedRecipesIds = [];
    selectedRecipes.forEach((r) => {
        selectedRecipesIds.push({
            id: r._id,
            servings: r.customServings,
            curatedBoxId: r.curatedBoxId,
            originalRecipeId: r.originalRecipeId,
            mealTypeId: r.mealTypeId,
        });
    });
    return selectedRecipesIds;
};

export const prepareFreeServingPayload = (freeServingsList = []) => ({
    freeServingsList,
    totalAmountOfFreeServings: freeServingsList.length,
});

export const preparePricePayload = ({
    totalPriceExtra,
    totalPrice,
    pricePerServing,
    totalServings,
    priceOfRequiredProducts,
    priceOfUnusedProductsWithRoundingCorrection,
    otherRecipesKitsCost,
    totalAmountOfRecipes,
    userSaveAmount,
    paidPerServing,
    extraProductTaxes,
    actualCostWithoutDiscount,
    usedGroceriesInPercentage,
    promoDiscount,
    subTotal,
    deliveryFee,
    serviceFee,
    waiveOffDeliveryFee,
}) => ({
    totalPriceExtra,
    totalPrice,
    pricePerServing,
    totalServings,
    priceOfRequiredProducts,
    priceOfUnusedProductsWithRoundingCorrection,
    otherRecipesKitsCost,
    totalAmountOfRecipes,
    userSaveAmount,
    paidPerServing,
    extraProductTaxes,
    actualCostWithoutDiscount,
    usedGroceriesInPercentage,
    promoDiscount,
    subTotal,
    deliveryFee,
    serviceFee,
    waiveOffDeliveryFee,
});
