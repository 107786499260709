import React, { useState, useRef } from 'react';
import useOnClickOutside from 'hooks/useClickOutside';
import PropTypes from 'prop-types';
import useScreenType from 'hooks/useScreenType';

import Icons from 'components/common/Icons/Icons';

import { SearchContainer, ButtonEnter } from './styles';

const InputSearch = ({ value, onSubmit, onChange, placeholder, handleKeyDown }) => {
    const ref = useRef();
    const { isMobile } = useScreenType();

    const [activeInput, setActiveInput] = useState(false);

    useOnClickOutside(ref, () => setActiveInput(false));

    return (
        <SearchContainer isMobile={isMobile} ref={ref} activeInput={activeInput}>
            <Icons name="search" size="20"></Icons>
            <div onClick={() => setActiveInput(true)}>
                <input value={value || ''} onChange={onChange} type="text" placeholder={placeholder} onKeyDown={handleKeyDown} />
            </div>
            <ButtonEnter onClick={onSubmit}>
                <Icons name="cornerDownLeft" size="20"></Icons>
            </ButtonEnter>
        </SearchContainer>
    );
};

InputSearch.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onSubmit: PropTypes.func,
    placeholder: PropTypes.string,
    handleKeyDown: PropTypes.func,
};

InputSearch.defaultProps = {
    onChange: () => {},
    onSubmit: () => {},
    placeholder: 'Type here what do you need',
    handleKeyDown: () => {},
    value: '',
};

export default InputSearch;
