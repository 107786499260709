import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import './customPolyfill';
import 'normalize.css';

import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route } from 'react-router-dom';
import FastClick from 'react-fastclick-alt';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Elements } from '@stripe/react-stripe-js';

import history from './history';
import config from './config';

import './index.scss';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './App';
import store from './store/store';
import { withTracker } from './helpers/withTracker';
import * as serviceWorker from './serviceWorker';
import theme from './muiTheme';
import ErrorBoundary from './helpers/ErrorBoundary';
import ScrollToTop from 'components/common/scrollToTop/ScrollToTop';

if (process.env.REACT_APP_ENV !== 'local') {
    Sentry.init({
        dsn: 'https://029768c157b14d64b2a84c78667e28bb@o1017894.ingest.sentry.io/5983832',
        integrations: [new Integrations.BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        environment: process.env.REACT_APP_ENV,
    });
}

if (process.env.REACT_APP_ENV == 'production') {
    const script = document.createElement('script');
    script.innerHTML = `(function(h, o, t, j, a, r) {
                h.hj =
                    h.hj ||
                    function() {
                        (h.hj.q = h.hj.q || []).push(arguments);
                    };
                h._hjSettings = { hjid: 1816226, hjsv: 6 };
                a = o.getElementsByTagName('head')[0];
                r = o.createElement('script');
                r.async = 1;
                r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
                a.appendChild(r);
            })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
`;
    document.head.appendChild(script);
}

const stripePromise = loadStripe(config[process.env.REACT_APP_ENV].STRIPE_PUBLISHABLE_KEY);
/* eslint-disable no-undef, react/jsx-filename-extension */
ReactDOM.render(
    <FastClick timeThreshold={50}>
        <MuiThemeProvider theme={theme}>
            <Provider store={store}>
                <Elements stripe={stripePromise}>
                    <Router history={history}>
                        <ScrollToTop history={history} />
                        <Sentry.ErrorBoundary>
                            <ErrorBoundary>
                                <Route component={process.env.REACT_APP_ENV === 'production' ? withTracker(App, {}) : App} />
                            </ErrorBoundary>
                        </Sentry.ErrorBoundary>
                    </Router>
                </Elements>
            </Provider>
        </MuiThemeProvider>
    </FastClick>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
