import React, { useState, useEffect, useCallback } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import history from '../../../history';
import { useDebouncedCallback } from 'use-debounce';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import useTheme from 'hooks/useTheme';

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import cancel from 'assets/images/cancelWithBackground.svg';

import Icons from '../Icons/Icons';
import { Primary } from '../Buttons';

import {
    handleAddRecipe,
    handleDeleteRecipe,
    setSelectedRecipeServings,
    setIsEditing,
    setServingsRecipe,
} from 'store/actions/recipe.action';
import { updateRecipeFavorite } from 'store/actions/user.actions';
import { updateStateSummaryPopup } from 'store/actions/ui-settings.actions';
import { getCommonIngredientsRequest } from 'store/actions/orderMenu.actions';

import {
    checkIsRecipeSelected,
    checkIsRecipeFavorite,
    checkSelectedRecipeServings,
    increaseServings,
    decreaseServings,
} from 'utility/recipeUtil';
import { pushLearnqEvent } from 'helpers/trackEvents';
import { formatRecipeImages } from 'helpers/formatRecipeImages';
import { Events } from 'constants/events';

import {
    CancelBtn,
    CookingTime,
    RecipeCardContainer,
    ImageContainer,
    TitleContainer,
    FavouriteWrapper,
    ServingsExtra,
} from './styles';

const RecipeCard = ({
    recipe,
    size = 'medium',
    isCancel = false,
    selectedRecipes,
    recipeFavorites,
    loggedInUser,
    handleAdd,
    addRecipeCallback,
    handleRemove,
    handleFavourite,
    showButtonBlock = true,
    isCommonIngredient = false,
    updateStateSummaryPopup,
    defaultServings,
    commonIngredients,
    orderId = '',
    handleShowCommon,
    category,
    setServingsRecipe,
    isMealPlan = false,
    mealTypeId,
    isLoadingCommonIng,
}) => {
    const [totalServings, setTotalServings] = useState(0);
    const color = useTheme();

    useEffect(() => {
        if (checkIsRecipeSelected(selectedRecipes, recipe, mealTypeId)) {
            setTotalServings(recipeServings(selectedRecipes, recipe, defaultServings, mealTypeId));
        } else {
            setTotalServings(0);
        }
    }, [commonIngredients, recipe, selectedRecipes, defaultServings, mealTypeId, recipe.customServings]);

    const { _id, name, ingredientsAmount, cookingTime, freeServings, customServings, images, curatedBoxId } = recipe;

    const influencerHandle = useSelector((state) => state.influencerReducer.handle);

    const [debouncedCallback] = useDebouncedCallback((value) => {
        setServingsRecipe(_id, value, curatedBoxId, mealTypeId);
    }, 500);

    const handleRecipeClick = useCallback(() => {
        updateStateSummaryPopup(false);
        if (orderId) {
            history.push({ pathname: `/recipes/${_id}?orderId=${orderId}`, state: { from: true } });
        } else {
            if (influencerHandle && influencerHandle !== 'easierchef') {
                history.push({ pathname: `/${influencerHandle}/recipes/${_id}`, state: { from: true } });
            } else {
                history.push({ pathname: `/recipes/${_id}`, state: { from: true } });
            }
        }
    }, [recipe, influencerHandle, orderId]);

    const handleAddRecipeClick = useCallback(() => {
        handleAdd(recipe, mealTypeId, freeServings);
        if (!freeServings) {
            setTotalServings(defaultServings);
        } else {
            setTotalServings(freeServings);
        }
        handleShowCommon && handleShowCommon(category);
        const customServingsData = selectedRecipes?.find(({ _id }) => _id.toString() === recipe?._id.toString());
        const customServings = increaseServings(
            freeServings
                ? customServingsData?.customServings
                    ? customServingsData?.customServings + freeServings
                    : freeServings
                : defaultServings,
            recipe.minServings,
            recipe.incrServings,
        );
        addRecipeCallback && addRecipeCallback(recipe, mealTypeId, customServings);
    }, [recipe, defaultServings, mealTypeId, addRecipeCallback, category, freeServings]);

    const handleChangeServings = useCallback(
        (value) => {
            setTotalServings(value);
            if (value === 0) {
                handleRemove(_id, mealTypeId);
            }
            addRecipeCallback && addRecipeCallback(recipe, mealTypeId, value - totalServings);
            // debouncedCallback(value);
            setServingsRecipe(_id, value, curatedBoxId, mealTypeId);
        },
        [_id, totalServings, addRecipeCallback, curatedBoxId, mealTypeId],
    );

    const handleCancel = useCallback(
        (e) => {
            e.stopPropagation();
            if (history.location.pathname === '/recipes/favorites') {
                return handleFavouriteClick(e);
            }
            handleRemove(_id);
        },
        [history.location.pathname, _id],
    );

    const handleFavouriteClick = useCallback(
        (e) => {
            e.stopPropagation();
            if (loggedInUser) {
                handleFavourite(recipe);
            } else {
                history.push('/signin');
            }
        },
        [loggedInUser, recipe],
    );

    const image = formatRecipeImages(images);
    const message = freeServings > 0 ? `You can get ${freeServings} free servings` : `Save ${recipe.saveUpto}% per serving`;

    const handleButtonTitle = () => {
        let title = 'Add Recipe';
        if (!totalServings) {
            if (isMealPlan) {
                title = 'Add';
            } else if (orderId) {
                title = 'Add Again';
            } else if (isCommonIngredient && freeServings > 0) {
                if (selectedRecipes.freeServings === selectedRecipes.customServings) {
                    title = ` Add ${freeServings} Free Servings`;
                } else {
                    title = `${totalServings} Servings`;
                }
            }
        } else {
            title = `${totalServings} Servings`;
        }
        return title;
    };
    return (
        <RecipeCardContainer size={size} showButtonBlock={showButtonBlock} isCommonIngredient={isCommonIngredient}>
            <ImageContainer onClick={handleRecipeClick}>
                <img src={image} loading="lazy" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                {isCommonIngredient && (recipe.saveUpto > 50 || freeServings > 0) && (
                    <ServingsExtra size={size}>
                        <span>{message}</span>
                    </ServingsExtra>
                )}

                {isCancel && showButtonBlock && (
                    <CancelBtn isCancel={isCancel} src={cancel} onClick={debounce(handleCancel, 1000, { leading: true })}>
                        <img src={cancel} alt={'cancel button'} />
                    </CancelBtn>
                )}
                {!isCancel && (
                    <FavouriteWrapper isFavourite={checkIsRecipeFavorite(recipeFavorites, recipe)} onClick={handleFavouriteClick}>
                        {checkIsRecipeFavorite(recipeFavorites, recipe) ? (
                            <Icons name="favourited" size="32" />
                        ) : (
                            <Icons name="heart" size="18" color={color.brandGreen} />
                        )}
                    </FavouriteWrapper>
                )}
                <CookingTime>
                    <AccessTimeIcon />
                    <span>{cookingTime}m</span>
                </CookingTime>
            </ImageContainer>
            <TitleContainer showButtonBlock={showButtonBlock} size={size} isCommonIngredient={isCommonIngredient}>
                <div className="name">{name}</div>
                <div className="info">
                    {isCommonIngredient && (
                        <div className="ingredient">
                            <span>{`${recipe.ingredientsAvailableAlready}/${recipe.totalIngredients}`}</span>
                            <span> ingredients</span>
                        </div>
                    )}
                    {!isCommonIngredient && (
                        <div className="ingredient">
                            <span>{ingredientsAmount}</span>
                            <span> ingredients</span>
                        </div>
                    )}
                    <div className="calories">
                        <Icons name="fire" size="18" />
                        <span>{`${Math.round(get(recipe, 'nutrition.calories.value', ''))} ${get(
                            recipe,
                            'nutrition.calories.unit',
                            '',
                        )}`}</span>
                    </div>
                </div>
            </TitleContainer>

            {showButtonBlock && (
                <Primary
                    title={handleButtonTitle()}
                    state={
                        totalServings
                            ? freeServings > 0 &&
                              isLoadingCommonIng === false &&
                              selectedRecipes.freeServings !== selectedRecipes.customServings
                                ? 'InActive'
                                : 'Active'
                            : 'InActive'
                    }
                    onClick={handleAddRecipeClick}
                    onAdd={() =>
                        handleChangeServings(increaseServings(totalServings + 1, recipe.minServings, recipe.incrServings))
                    }
                    onRemove={() =>
                        handleChangeServings(decreaseServings(totalServings, recipe.minServings, recipe.incrServings))
                    }
                />
            )}
        </RecipeCardContainer>
    );
};

function recipeServings(selectedRecipes, recipe, defaultServings, mealTypeId) {
    const servings = checkSelectedRecipeServings(selectedRecipes, recipe, mealTypeId);
    if (servings) {
        return servings;
    }
    return defaultServings;
}

RecipeCard.propTypes = {
    handleAdd: PropTypes.func.isRequired,
    handleFavourite: PropTypes.func.isRequired,
    selectedRecipes: PropTypes.array,
    favouriteRecipes: PropTypes.array,
    updateStateSummaryPopup: PropTypes.func.isRequired,
    defaultServings: PropTypes.number,
    setIsEditing: PropTypes.func.isRequired,
    commonIngredients: PropTypes.array.isRequired,
    orderId: PropTypes.string,
    getCommonIngredientsRequest: PropTypes.func.isRequired,
};

RecipeCard.defaultProps = {
    selectedRecipes: [],
    recipeFavorites: [],
};

const mapStateToProps = ({
    recipeReducer: {
        Cart: { selectedRecipes },
    },
    userReducer: { recipeFavorites },
    authenReducer: { loggedInUser },
    uiSettingsReducer: { defaultServings },
    orderMenuReducer: { commonIngredients, isLoadingCommonIng },
}) => ({
    selectedRecipes,
    recipeFavorites,
    loggedInUser,
    defaultServings,
    commonIngredients,
    isLoadingCommonIng,
});

export default connect(mapStateToProps, {
    handleAdd: handleAddRecipe,
    handleRemove: handleDeleteRecipe,
    changeServings: setSelectedRecipeServings,
    handleFavourite: updateRecipeFavorite,
    updateStateSummaryPopup,
    setIsEditing,
    getCommonIngredientsRequest,
    setServingsRecipe,
})(RecipeCard);
