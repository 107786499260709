import React from 'react';
import { HeaderLeftContainer } from './styles';
import useScreenType from '../../../hooks/useScreenType';
import history from '../../../history';
import Icons from '../Icons/Icons';
const HeaderLeft = ({ icons, setOpen, open, isDefaultInfluencer, influencer }) => {
    const { isTabletOrMobile } = useScreenType();
    return (
        <HeaderLeftContainer isTabletOrMobile={isTabletOrMobile}>
            {icons ? (
                icons
            ) : (
                <>
                    {isTabletOrMobile ? (
                        <Icons name="menu" size="24" onClick={() => setOpen(!open)} />
                    ) : (
                        <Icons
                            name="logo2"
                            size="40"
                            onClick={() => {
                                if (!isDefaultInfluencer) {
                                    history.push(`/${influencer.handle}`);
                                } else {
                                    history.push(`/`);
                                }
                            }}
                        />
                    )}
                </>
            )}
        </HeaderLeftContainer>
    );
};
export default HeaderLeft;
