import { apiRequest } from '../helpers/request';

async function addShareUrl(data) {
    return apiRequest({ url: `/share-url/`, method: 'post', data });
}

async function getShareUrl(id) {
    return apiRequest({ url: `/share-url/${id}`, method: 'get' });
}

export default {
    addShareUrl,
    getShareUrl,
};
