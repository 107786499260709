import * as constants from '../constants';

export const getCollectionPageData = data => ({ type: constants.GET_COLLECTION_PAGE_DATA, data });
export const getRecipesPageData = data => ({ type: constants.GET_RECIPE_PAGE_DATA, data });
export const getRecipeItemPageData = data => ({ type: constants.GET_RECIPE_ITEM_PAGE_DATA, data });
export const getCategoryPageData = data => ({ type: constants.GET_CATEGORY_PAGE_DATA, data });
export const getMinimumOrderPageData = data => ({ type: constants.GET_MINIMUM_ORDER_PAGE_DATA, data });
export const getBuildMenuPageData = data => ({ type: constants.GET_BUILD_MENU_PAGE_DATA, data });
export const getUsePantryPageData = data => ({ type: constants.GET_USE_PANTRY_PAGE_DATA, data });
export const loadCollectionPage = data => ({ type: constants.GET_COLLECTIONS_PAGE, data });
export const getCheckoutPageData = data => ({ type: constants.GET_CHECKOUT_PAGE, data });
export const getGroceriesPlanData = data => ({ type: constants.GET_GROCERIES_PLAN_PAGE, data });
export const getGroceriesManagementData = data => ({ type: constants.GET_GROCERIES_MANAGEMENT_PAGE, data });
export const getRecentOrdersData = data => ({ type: constants.GET_RECENT_ORDERS_PAGE, data });
export const getSettingsPageData = data => ({ type: constants.GET_SETTINGS_PAGE, data });
