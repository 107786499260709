import get from 'lodash/get';
import * as constants from '../constants';
const influencer = localStorage.getItem('influencer') && JSON.parse(localStorage.getItem('influencer'));
const initialState = {
    isInfluencer: false,
    isDefaultInfluencer: get(influencer, 'handle', 'easierchef') === 'easierchef',
    data: null,
    handle: get(influencer, 'handle', 'easierchef'),
    name: '',
    id: get(influencer, '_id'),
    couponCode: get(influencer, 'couponCode'),
    isFetched: false,
    loading: false,
    isInfluencerFatched: false,
};
export default function (state = initialState, action) {
    switch (action.type) {
        case constants.GET_INFLUENCER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case constants.GET_INFLUENCER_SUCCESS:
            localStorage.setItem('influencer', JSON.stringify(action.data));
            return {
                ...state,
                loading: false,
                data: action.data,
                handle: get(action.data, 'handle', 'easierchef'),
                name: get(action.data, 'name', 'easierchef'),
                id: get(action.data, '_id'),
                couponCode: get(action.data, 'couponCode'),
                isInfluencer: true,
                isFetched: true,
                isInfluencerFatched: true,
                isDefaultInfluencer: get(action.data, 'handle') === 'easierchef',
            };
        case constants.GET_INFLUENCER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
}
