import history from '../../../history';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { Secondary, Primary } from '../Buttons';
import Icons from '../Icons/Icons';

import { logout } from 'store/actions/authentication.actions';

import { MenuMobileContainer, MenuItem, Menu, MenuPaper } from './styles';

const MenuMobile = (props) => {
    const { open, setOpen, isTabletOrMobile, isDefaultInfluencer, influencer, diet } = props;
    const loggedInUser = useSelector((state) => state.authenReducer.loggedInUser);
    const ref = useRef();
    const [isSetOpen, setIsSetOpen] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        const listener = (event) => {
            if (!ref.current || ref.current.contains(event.target)) {
                setIsSetOpen(false);
            } else {
                setIsSetOpen(true);
            }
        };
        document.addEventListener('mousedown', listener);
        return () => {
            document.removeEventListener('mousedown', listener);
        };
    }, [ref]);
    return (
        <MenuMobileContainer
            onClick={() => {
                if (isSetOpen) {
                    setOpen(false);
                }
            }}
            open={open}
        >
            {open && isTabletOrMobile && (
                <Menu>
                    <MenuPaper ref={ref} loggedInUser={loggedInUser} isTabletOrMobile={isTabletOrMobile}>
                        {/* <MenuItem>
                            <NavLink
                                to={
                                    isDefaultInfluencer
                                        ? diet
                                            ? `/recipes?diet=${diet}`
                                            : '/recipes'
                                        : diet
                                        ? `/${influencer}/recipes?diet=${diet}`
                                        : `/${influencer}/recipes`
                                }
                                activeClassName="active"
                            >
                                <Icons name="book" size="24" color="#82BD73" />
                                <span>Recipes</span>
                            </NavLink>
                        </MenuItem> */}
                        <MenuItem>
                            <NavLink
                                to={isDefaultInfluencer ? '/build-menu' : `/${influencer}/build-menu`}
                                activeClassName="active"
                            >
                                <Icons name="listIcon" size="24" color="#82BD73" />
                                <span>Weekly Plan</span>
                            </NavLink>
                        </MenuItem>
                        <MenuItem>
                            <NavLink to={isDefaultInfluencer ? '/recipes' : `/${influencer}/recipes`} activeClassName="active">
                                <Icons name="saveGroceries" size="24" color="#82BD73" />
                                <span>Explore recipes</span>
                            </NavLink>
                        </MenuItem>
                        {loggedInUser && (
                            <MenuItem>
                                <NavLink to="/manage-groceries" activeClassName="active">
                                    <Icons name="ingredients" size="24" color="#82BD73" />
                                    <span>Digital Pantry</span>
                                </NavLink>
                            </MenuItem>
                        )}
                        {loggedInUser && (
                            <MenuItem>
                                <NavLink to="/orders" activeClassName="active">
                                    <Icons name="calenderActive" size="24" color="#82BD73" />
                                    <span>Orders</span>
                                </NavLink>
                            </MenuItem>
                        )}
                        {loggedInUser && (
                            <MenuItem>
                                <NavLink to="/settings" activeClassName="active">
                                    <Icons name="settingsActive" size="24" color="#82BD73" />
                                    <span>Settings</span>
                                </NavLink>
                            </MenuItem>
                        )}
                        {loggedInUser && (
                            <MenuItem>
                                <NavLink to="/recipes/favorites" activeClassName="active">
                                    <Icons name="heart" size="24" color="#82BD73" />
                                    <span>Favorites</span>
                                </NavLink>
                            </MenuItem>
                        )}
                        <MenuItem>
                            <NavLink to={isDefaultInfluencer ? '' : `/${influencer}`} activeClassName="active">
                                <Icons name="help" size="24" color="#82BD73" />
                                <span>How it works</span>
                            </NavLink>
                        </MenuItem>
                        <MenuItem>
                            <NavLink to="/faq" activeClassName="active">
                                <Icons name="faq" size="24" color="#82BD73" />
                                <span>FAQ</span>
                            </NavLink>
                        </MenuItem>
                        <MenuItem>
                            <NavLink to="/about-us" activeClassName="active">
                                <Icons name="team" size="24" color="#82BD73" />
                                <span>Team</span>
                            </NavLink>
                        </MenuItem>

                        {!loggedInUser && (
                            <MenuItem logInBtn={true}>
                                <Secondary
                                    size="medium"
                                    title="Sign up"
                                    onClick={() =>
                                        history.push({
                                            pathname: '/onboarding/account',
                                            state: { from: true },
                                        })
                                    }
                                />
                            </MenuItem>
                        )}
                        {loggedInUser && (
                            <MenuItem logOutBtn={true}>
                                <Primary size="medium" title="Log Out" onClick={() => dispatch(logout())} />
                            </MenuItem>
                        )}
                    </MenuPaper>
                </Menu>
            )}
        </MenuMobileContainer>
    );
};
export default MenuMobile;
