import { call, put, takeEvery, select } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import * as constants from '../constants';
import * as actions from '../actions/extraProduct.actions';
import * as actionsRecipe from '../actions/recipe.action';
import * as actionsExtraCategories from '../actions/extraProductCategory.actions';
import serverAPI from 'services/extraProduct.service';
import cartAPI from 'services/cart.service';

import { preparePayloadFromLocalStorage } from 'helpers/cart';
import { errorNotification } from 'helpers/errorNotification';

export const selectSelectedCategories = () => select(({ recipeCategoryReducer }) => recipeCategoryReducer.selected);
export const selectSelectedExtraCategories = () =>
    select(({ extraProductCategoryReducer }) => extraProductCategoryReducer.selected);
export const selectSearchValue = () => select(({ extraProductReducer }) => extraProductReducer.search);
export const selectIsLoadedExtraProduct = () => select(({ extraProductReducer }) => extraProductReducer.isloadedExtraProduct);
export const selectIsChangeParams = () => select(({ extraProductReducer }) => extraProductReducer.isChangeParams);
export const selectIsChangeExtraCategories = () =>
    select(({ extraProductCategoryReducer }) => extraProductCategoryReducer.isChangeExtraCategories);

export function* getExtraProducts() {
    try {
        const selectedExtraCategories = yield selectSelectedExtraCategories();
        const searchValue = yield selectSearchValue();
        const isLoadedExtraProduct = yield selectIsLoadedExtraProduct();
        const isChangeParams = yield selectIsChangeParams();
        const isChangeExtraCategories = yield selectIsChangeExtraCategories();
        if (!isLoadedExtraProduct || isChangeParams || isChangeExtraCategories) {
            const response = yield call(serverAPI.getExtraProducts, {
                categories: selectedExtraCategories.join(','),
                search: searchValue,
                perPage: 99999,
            });
            if (response) {
                yield put(actions.getExtraProductsSuccess(response.data.extraProducts));
                yield put(actionsExtraCategories.clearFlagIsChangeExtraProduct());
                yield put(actionsRecipe.getStateLocalStorage());
            }
        }
    } catch (error) {
        yield put(actions.getExtraProductsFailure('getExtraProducts'));
        const data = {};
        const text = 'Error in getExtraProducts saga';
        errorNotification(error, data, text, false);
    }
}

export function* addExtraProduct({ product }) {
    try {
        const loggedInUser = yield select((state) => state.authenReducer.loggedInUser);
        const extraProducts = yield select((state) => state.extraProductReducer.selected);
        const cart = yield select((state) => state.recipeReducer.Cart);
        let productIndex;
        let productList = [];
        if (product && !isEmpty(extraProducts)) {
            productIndex = findIndex(extraProducts, (item) => item._id === product._id);
        }

        if (productIndex >= 0) {
            productList = [
                ...extraProducts.slice(0, productIndex),
                { ...extraProducts[productIndex], value: extraProducts[productIndex].value + 1 },
                ...extraProducts.slice(productIndex + 1),
            ];
        } else {
            productList = [...extraProducts, { ...product, value: 1 }];
        }
        const { data } = yield call(
            cartAPI.addItemToCart,
            preparePayloadFromLocalStorage({ ...cart, extraGroceries: productList }),
        );
        if (!loggedInUser) {
            localStorage.setItem('guestUserId', data.guestUserId);
        }
        yield put(actions.addExtraProductSuccess({ productList, cost: data.cost }));
        yield put(actionsRecipe.getStateLocalStorage());
    } catch (error) {
        const data = { product };
        const text = 'Error in addExtraProduct saga';
        errorNotification(error, data, text);
    }
}

export function* deleteExtraProduct({ productId }) {
    try {
        const extraProducts = yield select((state) => state.extraProductReducer.selected);
        const productIndex = findIndex(extraProducts, (product) => product._id === productId);
        const cart = yield select((state) => state.recipeReducer.Cart);
        let productList = [];
        if (extraProducts[productIndex] && extraProducts[productIndex].value > 1) {
            productList = [
                ...extraProducts.slice(0, productIndex),
                { ...extraProducts[productIndex], value: extraProducts[productIndex].value - 1 },
                ...extraProducts.slice(productIndex + 1),
            ];
        } else {
            productList = [...extraProducts.slice(0, productIndex), ...extraProducts.slice(productIndex + 1)];
        }
        const { data } = yield call(
            cartAPI.addItemToCart,
            preparePayloadFromLocalStorage({ ...cart, extraGroceries: productList }),
        );
        yield put(actions.deleteExtraProductSuccess({ productList, cost: data.cost }));
        yield put(actionsRecipe.getStateLocalStorage());
    } catch (error) {
        const data = { productId };
        const text = 'Error in deleteExtraProduct saga';
        errorNotification(error, data, text);
    }
}

export default function* () {
    yield takeEvery(
        [
            constants.GET_EXTRA_PRODUCTS_REQUEST,
            constants.ADD_EXTRA_CATEGORY,
            constants.DELETE_EXTRA_CATEGORY,
            constants.SET_EXTRA_PRODUCTS_SEARCH,
        ],
        getExtraProducts,
    );
    yield takeEvery(constants.ADD_EXTRA_PRODUCT, addExtraProduct);
    yield takeEvery(constants.DELETE_EXTRA_PRODUCT, deleteExtraProduct);
}
