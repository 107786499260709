import config from '../config';

const apiURL = config[process.env.REACT_APP_ENV].API_DOMAIN;

export const redirectionRequest = (path) => {
    const influencer = localStorage.getItem('influencer') ? JSON.parse(localStorage.getItem('influencer')).handle : 'easierchef';
    const guestUserId = localStorage.getItem('guestUserId');
    let url = (window.location.href = `${apiURL + path}?influencer=${influencer}`);

    if (guestUserId) url += `&guestUserId=${guestUserId}`;
    window.location.href = url;
};
