import * as constants from '../constants';
import get from 'lodash/get';

const settings = localStorage.getItem('settings') ? JSON.parse(localStorage.getItem('settings')) : {};

const initialState = {
    isSummaryPopupOpen: false,
    isMobileSummaryPopupOpen: false,
    defaultServings: 2,
    minimumCommonIngredientRecipes: 15,
    isFullScreenModal: false,
    isServingPopupOpen: false,
    isFiltersPopupOpen: false,
    isSearchPopupOpen: false,
    isMobileBarExpanded: false,
    isExtraGroceriesPopupOpen: false,
    valueInputExtraGroceriesPopup: '',
    isReviewProductsPopupOpen: false,
    isAnyPantryItemAddedInCart: false,
    isSubscriptionSectionOpen: false,
    isMinimumPopupOpen: false,
    isPaymentSuccess: false,
    isPaymentLoading: false,
    isPaymentError: false,
    temporaryOrderInfo: {
        billing_details: {
            country: 'CA',
        },
        createdCardElement: [],
        showCardElements: false,
    },
    isAddIngredientPopupOpen: false,
    ingredientDefault: '',
    isOnboarding: false,
    ingredientDefaultEdit: '',
    isRemoveIngredientPopupOpen: false,
    isOpenModalShare: false,
    isEditPantryPopupOpen: false,
    isDrawerOpen: false,
    orderIdShare: '',
    isShowCustomerReview: true,
    isReviewGroceriesPopupOpen: false,
    showGroceriesPopupOpen: get(settings, 'showGroceriesPopup', true),
    showRemoveGroceriesButton: get(settings, 'showRemoveGroceriesButton', true),
    activeButton: get(JSON.parse(localStorage.getItem('deliveryAddress')), 'active', {
        today: false,
        tomorrow: false,
        custom: false,
    }),
    isShowCommon: ['first'],
    isClosedCommon: false,
    isNoStatusPopupOpen: false,
    showNoActionPopupOpen: get(settings, 'showNoActionPopupOpen', true),
    showSummaryDraw: false,
    isShowHeader: true,
    isShowDiscountRecipesBanner: get(settings, 'showDiscountRecipesBanner', true),
    isPrintShoppingPopupOpen: false,
    isBuildMenuFiltersPopupOpen: false,
    showHowItWorksPopup: false,
    loadingPage: false,
    groceriesPlan: 'delivery',
    isCalorieGoalPopupOpen: false,
    isConfirmationDialogueOpen: false,
    isShareRecipeDialogueOpen: false,
    showGroceriesSection: get(settings, 'showGroceriesSection', false),
};

export default function(state = initialState, action) {
    switch (action.type) {
        case constants.UPDATE_STATE_SUMMARY_POPUP:
            return {
                ...state,
                isSummaryPopupOpen: action.payload,
            };
        case constants.UPDATE_STATE_MOBILE_SUMMARY_POPUP:
            return {
                ...state,
                isMobileSummaryPopupOpen: action.payload,
            };
        case constants.SET_DEFAULT_SERVINGS:
            return {
                ...state,
                defaultServings: action.servings,
            };
        case constants.UPDATE_STATE_SERVINGS_POPUP:
            return {
                ...state,
                isFullScreenModal: action.payload,
                isServingPopupOpen: action.payload,
            };
        case constants.UPDATE_STATE_FILTERS_POPUP:
            return {
                ...state,
                isFullScreenModal: action.payload,
                isFiltersPopupOpen: action.payload,
            };
        case constants.UPDATE_STATE_SEARCH_POPUP:
            return {
                ...state,
                isFullScreenModal: action.payload,
                isSearchPopupOpen: action.payload,
            };
        case constants.SHOW_MOBILE_BAR_EXPANDED:
            return {
                ...state,
                isMobileBarExpanded: action.payload,
            };
        case constants.SHOW_PAYMENT_SUCCESS_POPUP:
            return {
                ...state,
                isPaymentSuccess: action.payload,
            };
        case constants.SHOW_PAYMENT_LOADING_POPUP:
            return {
                ...state,
                isPaymentLoading: action.payload,
            };
        case constants.SHOW_PAYMENT_ERROR_POPUP:
            return {
                ...state,
                isPaymentError: action.payload,
            };
        case constants.SET_TEMPORARY_ORDER_INFORMATION:
            return {
                ...state,
                temporaryOrderInfo: action.payload,
            };
        case constants.UPDATE_STATE_EXTRA_GROCERIES_POPUP:
            return {
                ...state,
                isExtraGroceriesPopupOpen: action.payload,
            };
        case constants.SET_VALUE_INPUT_EXTRA_GROCERIES_POPUP:
            return {
                ...state,
                valueInputExtraGroceriesPopup: action.payload,
            };
        case constants.UPDATE_STATE_REVIEW_PRODUCTS_POPUP:
            return {
                ...state,
                isReviewProductsPopupOpen: action.payload,
            };
        case constants.ANY_PANTRY_ITEM_ADDED_IN_CART:
            return {
                ...state,
                isAnyPantryItemAddedInCart: action.payload,
            };
        case constants.UPDATE_STATE_SUBSCRIPTION_SECTION:
            return {
                ...state,
                isSubscriptionSectionOpen: action.payload,
            };
        case constants.UPDATE_STATE_MINIMUM_POPUP:
            return {
                ...state,
                isMinimumPopupOpen: action.payload,
            };
        case constants.UPDATE_STATE_ADD_INGREDIENT_POPUP:
            return {
                ...state,
                isAddIngredientPopupOpen: action.isOpen,
                ingredientDefault: action.ingredient ? action.ingredient : '',
                isOnboarding: action.isOnboarding ? action.isOnboarding : false,
            };
        case constants.UPDATE_STATE_REMOVE_INGREDIENT_POPUP:
            return {
                ...state,
                isRemoveIngredientPopupOpen: action.payload,
            };
        case constants.SET_OPEN_MODAL_SHARE:
            return {
                ...state,
                isOpenModalShare: action.isOpenModalShare,
                orderIdShare: action.orderId,
            };
        case constants.UPDATE_STATE_EDIT_PANTRY_POPUP:
            return {
                ...state,
                isEditPantryPopupOpen: action.isOpen,
                ingredientDefaultEdit: action.ingredient,
            };
        case constants.UPDATE_DRAWER_STATE:
            return {
                ...state,
                isDrawerOpen: action.payload,
            };
        case constants.UPDATE_STATE_REVIEW_GROCERIES_POPUP:
            return {
                ...state,
                isReviewGroceriesPopupOpen: action.payload,
            };
        case constants.SHOW_REVIEW_GROCERIES_POPUP:
            localStorage.setItem(
                'settings',
                JSON.stringify({
                    ...JSON.parse(localStorage.getItem('settings')),
                    showGroceriesPopup: action.payload,
                }),
            );
            return {
                ...state,
                showGroceriesPopupOpen: action.payload,
            };
        case constants.SET_ACTIVE_BUTOTON:
            // if (localStorage.getItem('deliveryAddress')) {
            //     const address = JSON.parse(localStorage.getItem('deliveryAddress'));
            //     localStorage.setItem('deliveryAddress', JSON.stringify({ ...address, active: action.payload }));
            // }
            return {
                ...state,
                activeButton: action.payload,
            };
        case constants.CLEAR_ACTIVE_BUTOTON:
            // if (localStorage.getItem('deliveryAddress')) {
            //     const address = JSON.parse(localStorage.getItem('deliveryAddress'));
            //     localStorage.setItem(
            //         'deliveryAddress',
            //         JSON.stringify({ ...address, active: { today: false, tomorrow: false, custom: false } }),
            //     );
            // }
            return {
                ...state,
                activeButton: {
                    today: false,
                    tomorrow: false,
                    custom: false,
                },
            };
        case constants.SET_IS_SHOW_COMMON_INGREDIENTS:
            return {
                ...state,
                isShowCommon: action.payload,
            };
        case constants.SET_IS_CLOSED_COMMON_INGREDIENTS:
            return {
                ...state,
                isClosedCommon: action.payload,
            };
        case constants.SHOW_CREATE_CARD_ELEMENT_ERROR:
            return {
                ...state,
                temporaryOrderInfo: { ...state.temporaryOrderInfo, isPaymentComplete: action.isComplete },
            };
        case constants.LOGIN_SUCCESS:
        case constants.CLEAR_STATE_LOCAL_STORAGE: {
            return {
                ...state,
                temporaryOrderInfo: {
                    billing_details: {
                        country: 'CA',
                    },
                    createdCardElement: [],
                    showCardElements: false,
                },
                showNoActionPopupOpen: true,
            };
        }
        case constants.UPDATE_NO_ACTION_POPUP:
            return {
                ...state,
                isNoStatusPopupOpen: action.payload,
                showNoActionPopupOpen: false,
            };

        case constants.SHOW_NO_ACTION_POPUP: {
            return {
                ...state,
                showNoActionPopupOpen: action.payload,
            };
        }
        case constants.SHOW_SUMMARY_DRAW: {
            return {
                ...state,
                showSummaryDraw: action.payload,
            };
        }
        case constants.SET_SHOW_HEADER_RECIPE_PAGE: {
            return {
                ...state,
                isShowHeader: action.payload,
            };
        }
        case constants.SHOW_DISCOUNT_RECIPES_BANNER:
            localStorage.setItem(
                'settings',
                JSON.stringify({
                    ...JSON.parse(localStorage.getItem('settings')),
                    showDiscountRecipesBanner: action.payload,
                }),
            );
            return {
                ...state,
                isShowDiscountRecipesBanner: action.payload,
            };
        case constants.SHOW_PRINT_SHOPPING_POPUP: {
            return {
                ...state,
                isPrintShoppingPopupOpen: action.payload,
            };
        }
        case constants.SHOW_BUILD_MENU_FILTERS_POPUP:
            return {
                ...state,
                isBuildMenuFiltersPopupOpen: action.payload,
            };
        case constants.SHOW_HOW_IT_WOKRS_POPPUP:
            return {
                ...state,
                showHowItWorksPopup: action.payload,
            };
        case constants.LOADING_PAGE:
            return {
                ...state,
                loadingPage: true,
            };

        case constants.LOADED_PAGE:
            return {
                ...state,
                loadingPage: false,
            };

        case constants.UPDATE_GROCERIES_PLAN:
            return {
                ...state,
                groceriesPlan: action.payload,
            };
        case constants.SHOW_CALORIE_GOAL_POPUP:
            return {
                ...state,
                isCalorieGoalPopupOpen: action.payload,
            };
        case constants.SHOW_REMOVE_GROCERIES_BUTTON:
            localStorage.setItem(
                'settings',
                JSON.stringify({
                    ...JSON.parse(localStorage.getItem('settings')),
                    showRemoveGroceriesButton: action.payload,
                }),
            );
            return {
                ...state,
                showRemoveGroceriesButton: action.payload,
            };
        case constants.SET_CONFIRMOTION_DIALOGUE_OPEN:
            return {
                ...state,
                isConfirmationDialogueOpen: action.payload,
            };
        case constants.SET_SHARERECIPE_DIALOGUE_OPEN:
            return {
                ...state,
                isShareRecipeDialogueOpen: action.payload,
            };
        case constants.UPDATE_SHOW_GROCERIES_SECTION:
            return {
                ...state,
                showGroceriesSection: action.payload,
            };
        default:
            return state;
    }
}
