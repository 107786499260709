import React from 'react';
import TypographyD from '../common/TypographyD/TypographyD';
import { useSelector } from 'react-redux';

import './styles.scss';

const IngredientList = () => {
    const groceries = useSelector((state) => state.recipeReducer.Cart.groceries);
    const ingredientsToCost = useSelector((state) => state.recipeReducer.Cart.ingredientsToCost);

    return (
        <div className="groceriesDetails">
            <TypographyD>
                {ingredientsToCost.map((i) => (
                    <div className="groceriesContainerBox">
                        <img src={i.image} className="groceriesImg" />
                        <div className="groceriesContainer">
                            <div className="groceriesName">{i.name}</div>
                            <div className="groceriesQuantity">
                                {`${Math.round(i.ingredientAmountDelivered)} ${i.baseMeasurableUnits}`}
                            </div>
                        </div>
                    </div>
                ))}
            </TypographyD>
        </div>
    );
};

export default IngredientList;
