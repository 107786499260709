import * as constants from '../constants';

export const getCart = (forceCall = false, getGroceries = false, adjustDigitalPantry = false) => ({
    type: constants.GET_CART_DETAILS,
    forceCall,
    getGroceries,
    adjustDigitalPantry,
});
export const resaveCart = () => ({
    type: constants.RESAVE_CART,
});
export const shareCartUrlRequest = () => ({ type: constants.GENERATE_SHARE_CART_URL });
export const shareCartUrl = (url) => ({ type: constants.SHARE_CART_URL_SUCCESS, url });
export const getCartSuccess = (data) => ({ type: constants.GET_CART_DETAILS_SUCCESS, data });
export const setIngredientsToCost = (cost) => ({ type: constants.SET_INGREDIENTS_TO_COST, cost });
export const setFetchingCart = (data) => ({ type: constants.SET_FETCHING_CART, data });
export const setFetchedCart = (data) => ({ type: constants.SET_FETCHED_CART, data });
export const getCartGroceries = (data) => ({ type: constants.SET_EMAIL_SHOPPING_LIST, data });
export const setCartDeliveryInfo = (values) => ({ type: constants.SET_CART_DELIVERY_INFO, values });
export const setCartDeliveryInfoSuccess = (data) => ({ type: constants.CART_DELIVERY_INFO_SUCCESS, data });
export const getShareCartUrl = (id) => ({ type: constants.GET_SHARE_CART_URL, id });
export const setCalorieInfo = (calorieInfo) => ({ type: constants.SET_CALORIE_INFO, calorieInfo });
export const setCalorieInfoFetching = (isFetching) => ({ type: constants.SET_CALORIE_INFO_FETCHING, isFetching });
export const setCalorieInfoAPI = (data) => ({ type: constants.SET_CALORIE_INFO_SUCCESS, data });
