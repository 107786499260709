import styled, { css } from 'styled-components';

export const BuildMenuBenefitsContainer = styled.div`
    padding: 20px 30px 30px 30px;
    height: calc(100% - 80px);
    ${(props) =>
        !props.isMobile &&
        css`
            background: aliceblue;
        `};
    .container {
        width: 100%;
        height: 100%;
    }
`;
export const LoginBenefitsHeader = styled.div`
    font-family: 'Work Sans';
    color: black;
    font-weight: 600;
    display: flex;
    & > div {
        & > div {
            height: unset;
        }
    }
`;
export const BenefitTitle = styled.div`
    font-size: 24px;
    font-weight: 500;
    padding: 10px 0;
`;

export const Title = styled.div`
    font-size: 26px;
    margin-left: 5px;
`;
export const Desc = styled.div`
    font-size: 40px;
    margin-top: 10px;
    color: #82bd73;
    font-weight: bold;
    line-height: 40px;
`;
export const BenefitsContent = styled.div`
    .benefits {
        margin: 30px 0 10px;
    }
`;
export const EasierChefLogoContainer = styled.div`
    background-color: #233747;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const CreditCardInfo = styled.div`
    color: green;
    font-weight: bold;
    padding-left: 22px;
    padding-top: 10px;
`;
export const Benefit = styled.span`
    font-size: 16px;
    display: flex;
    color: rgb(44, 53, 69);
    align-items: center;
    font-weight: 500;
    margin-left: 10px;
    line-height: 190%;
`;

export const BenefitRow = styled.div`
    display: flex;
`;

export const Arrow = styled.span`
    margin-left: -20px;
`;
