import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import ProgressButton from '../Summary/button';
import NextMealTypeButton from '../Summary/nextMealTypeButton';
import Icons from '../Icons/Icons';
import Links from 'components/common/Links/Links';

import { getProgressStep } from 'helpers/progressStep';
import history from '../../../history';

import { MobileBarWrapper } from './styles';
import NotEnoughServingsButton from '../Summary/NotEnoughServingsButton';

const MobileBar = ({
    disabled,
    showDiscountRecipesBanner,
    isShowDiscountRecipesBanner,
    isMobileSummaryPopupOpen,
    nextMealType,
    step,
    setCurrentMealTypeStep,
    handleNextClick,
    currentMealType,
    pricePayload,
    selectedMealTypes,
    reviewedMealTypes,
    mealTypesInCart,
    mealData,
    suggestedServings,
    unfulfilledMealTypes,
    expandedMealTypes,
}) => {
    const ref = useRef();
    const {
        location: { pathname },
    } = history;
    const [skipToCheckout, setSkipToCheckout] = useState(false);

    const currentStep = getProgressStep(pathname);
    const showNextButton = !!nextMealType;
    const enoughServingsAdded = mealData ? mealData.selectedRecipesServings >= suggestedServings : undefined;

    return (
        <>
            {isShowDiscountRecipesBanner && !isMobileSummaryPopupOpen && pathname.includes('/recipes') && (
                <MobileBarWrapper
                    id={'mobile-bar'}
                    ref={ref}
                    className={`mobile-container  collapse-mobile-bar`}
                    // onScroll={debounce(onScroll, 2000, { leading: true, trailing: false })}
                    currentStep={currentStep}
                    disabled={disabled}
                >
                    <div className="discount-wrapper">
                        <div className="title-block">
                            <Icons name="close" size="20" color={'#ffffff'} onClick={() => showDiscountRecipesBanner(false)} />
                            <Icons name="discount" />
                            <span>Discounted recipes</span>
                        </div>
                        <div
                            className="links"
                            onClick={() =>
                                history.push({
                                    pathname: '/use-pantry',
                                    search: '?source=recipes',
                                    state: { from: true },
                                })
                            }
                        >
                            <Links label="See all" size="medium" type="secondary" />
                        </div>
                    </div>
                </MobileBarWrapper>
            )}
            {(unfulfilledMealTypes && unfulfilledMealTypes.length === 0) ||
            (expandedMealTypes && expandedMealTypes.length === 0) ? (
                <MobileBarWrapper
                    id={'mobile-bar'}
                    ref={ref}
                    className={`mobile-container  collapse-mobile-bar`}
                    // onScroll={debounce(onScroll, 2000, { leading: true, trailing: false })}
                    currentStep={currentStep}
                    disabled={disabled}
                >
                    <ProgressButton disabled={disabled} />
                </MobileBarWrapper>
            ) : showNextButton && enoughServingsAdded ? (
                <MobileBarWrapper
                    id={'mobile-bar'}
                    ref={ref}
                    className={`mobile-container  collapse-mobile-bar`}
                    // onScroll={debounce(onScroll, 2000, { leading: true, trailing: false })}
                    currentStep={currentStep}
                    disabled={disabled}
                >
                    <NextMealTypeButton
                        nextMealType={nextMealType}
                        setCurrentMealTypeStep={setCurrentMealTypeStep}
                        step={step}
                        cost={pricePayload}
                        handleNextClick={handleNextClick}
                        currentMealType={currentMealType}
                        mealData={mealData}
                        suggestedServings={suggestedServings}
                    />
                </MobileBarWrapper>
            ) : showNextButton && enoughServingsAdded === false ? (
                <MobileBarWrapper
                    id={'mobile-bar'}
                    ref={ref}
                    className={`mobile-container  collapse-mobile-bar`}
                    // onScroll={debounce(onScroll, 2000, { leading: true, trailing: false })}
                    currentStep={currentStep}
                    disabled={disabled}
                    dull
                >
                    <NotEnoughServingsButton
                        nextMealType={nextMealType}
                        setCurrentMealTypeStep={setCurrentMealTypeStep}
                        step={step}
                        cost={pricePayload}
                        handleNextClick={() => {
                            handleNextClick(nextMealType._id, step + 1);
                        }}
                        currentMealType={currentMealType}
                        mealData={mealData}
                        suggestedServings={suggestedServings}
                        nextTitle="Next >"
                    />
                </MobileBarWrapper>
            ) : enoughServingsAdded === false ? (
                <MobileBarWrapper
                    id={'mobile-bar'}
                    ref={ref}
                    className={`mobile-container  collapse-mobile-bar`}
                    // onScroll={debounce(onScroll, 2000, { leading: true, trailing: false })}
                    currentStep={currentStep}
                    disabled={disabled}
                    dull
                >
                    <NotEnoughServingsButton
                        nextMealType={nextMealType}
                        setCurrentMealTypeStep={setCurrentMealTypeStep}
                        step={step}
                        cost={pricePayload}
                        handleNextClick={() => {
                            setSkipToCheckout(true);
                            handleNextClick(mealData.mealType._id);
                        }}
                        currentMealType={currentMealType}
                        mealData={mealData}
                        suggestedServings={suggestedServings}
                        nextTitle="Checkout >"
                    />
                </MobileBarWrapper>
            ) : (
                <MobileBarWrapper
                    id={'mobile-bar'}
                    ref={ref}
                    className={`mobile-container  collapse-mobile-bar`}
                    // onScroll={debounce(onScroll, 2000, { leading: true, trailing: false })}
                    currentStep={currentStep}
                    disabled={disabled}
                >
                    <ProgressButton disabled={disabled} />
                </MobileBarWrapper>
            )}
        </>
    );
};

function getHeight(step, isExtraProducts, promoError) {
    switch (step) {
        case 0:
            return '150px';
        case 1:
            return isExtraProducts ? '322px' : '289px';
        case 2:
        case 3:
            return isExtraProducts ? (promoError ? '405px' : '389px') : promoError ? '355px' : '3327px';
        default:
            return '150px';
    }
}

MobileBar.protoTypes = {
    recipesLength: PropTypes.number.isRequired,
    handleNext: PropTypes.func.isRequired,
    pricePayload: PropTypes.func.isRequired,
    expandBar: PropTypes.bool.isRequired,
    setExpandBar: PropTypes.func.isRequired,
};

MobileBar.defaultProps = {
    showCartModal: false,
    recipes: [],
};

export default MobileBar;
