import React, { useState } from 'react';
import { connect } from 'react-redux';
import useTheme from 'hooks/useTheme';
import TypographyD from '../TypographyD/TypographyD';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import get from 'lodash/get';

import { setPromoCode as setPromoCodeAction } from 'store/actions/order.actions';

import { PromoCodeWrapper } from './styles';

const PromoCodeDiscount = ({ discount, promoValue }) => {
    const color = useTheme();
    const influencerData = JSON.parse(localStorage.getItem('influencer'));
    const [promoCode, setPromoCode] = useState(get(influencerData, 'couponCode', ''));

    return (
        <PromoCodeWrapper>
            <div className="active-promo-code">
                <div className="discount-info">
                    <TypographyD type="bodyMedium" fontWeight="500" color={color.gray1}>
                        Discount
                    </TypographyD>
                    <LocalOfferIcon />
                    <TypographyD type="bodyMedium" fontWeight="500" color={color.brandGreen}>
                        {(promoValue || promoCode || '').toUpperCase()}{' '}
                    </TypographyD>
                </div>
                <TypographyD type="helpMedium" fontWeight="500" color={color.brandGreen}>
                    - ${Number(discount).toFixed(2) || 0.0}
                </TypographyD>
            </div>
        </PromoCodeWrapper>
    );
};

const mapStateToProps = ({
    orderReducer: { promoCode },
    recipeReducer: {
        Cart: {
            pricePayload: { promoDiscount },
        },
    },
}) => ({
    promoValue: promoCode,
    discount: promoDiscount,
});

export default connect(mapStateToProps, {
    setPromoValue: setPromoCodeAction,
})(PromoCodeDiscount);
