import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import useTheme from 'hooks/useTheme';

import { CircularProgress } from '@material-ui/core';
import TypographyD from 'components/common/TypographyD/TypographyD';

import CustomDialog from 'components/common/Dialog';

import { LoadingWrapper } from './style';

const LoadingDialog = ({ open }) => {
    const color = useTheme();
    return (
        <>
            <CustomDialog open={open}>
                <LoadingWrapper>
                    <CircularProgress size={56} />
                    <TypographyD type="bodyMedium" fontWeight="500" color={color.gray0}>
                        Please wait for the payment to be made
                    </TypographyD>
                </LoadingWrapper>
            </CustomDialog>
        </>
    );
};

LoadingDialog.propTypes = {
    open: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ uiSettingsReducer: { isPaymentLoading } }) => ({ open: isPaymentLoading });

export default connect(mapStateToProps, {})(LoadingDialog);
