import history from '../history';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { CommonIngredients } from 'components/CommonIngredients/styles';
import keyBy from 'lodash/keyBy';
import { ContactSupportOutlined } from '@material-ui/icons';

export const checkIsRecipeSelected = (selectedRecipes, recipe, mealTypeId) => {
    let recipeIndex;
    if (mealTypeId) recipeIndex = findIndex(selectedRecipes, (rec) => rec._id === recipe._id && rec.mealTypeId == mealTypeId);
    else recipeIndex = findIndex(selectedRecipes, (rec) => rec._id === recipe._id);
    return recipeIndex !== -1;
};

export const checkIsRecipeFavorite = (recipeFavorites, recipe) => {
    if (recipeFavorites) {
        const index = recipeFavorites.filter((item) => item.recipeId._id === get(recipe, '_id', ''));
        if (index.length) {
            return true;
        }
        return false;
    }
};

export const checkSelectedRecipeServings = (selectedRecipes = [], recipe = [], mealTypeId) => {
    let selectedRecipe;
    if (mealTypeId) selectedRecipe = find(selectedRecipes, (rec) => rec._id === recipe._id && rec.mealTypeId == mealTypeId);
    else selectedRecipe = find(selectedRecipes, (rec) => rec._id === recipe._id);
    if (selectedRecipe) {
        return selectedRecipe.customServings;
    }
    return;
};

export const checkRecipeServings = (recipe) => {
    if (recipe.customServings) return recipe.customServings;
};

export const prepareRecipeListBySlice = (isDesktop, recipes, currentStep, showMore) => {
    if (showMore) {
        return recipes.length;
    }
    if (!isDesktop) {
        return 5;
    }
    if (currentStep === 0) {
        return 6;
    }
    if (currentStep === 2) {
        return 3;
    }
    return 4;
};
export const prepareRecipesCategoryWise = (allCategories, categories, recipes) => {
    const recipesLocal = [];
    categories.forEach((category) => {
        if (!isEmpty(recipes.filter((r) => r.categories === category._id))) {
            recipesLocal.push({
                category: category,
                recipes: recipes.filter((r) => r.categories === category._id),
            });
        }
    });
    return recipesLocal;
};

export const prepareRecipesMealTypeWise = (mealTypes, recipes = [], selectedRecipes = []) => {
    const mealTypeHash = {};
    Object.values(mealTypes).forEach((mealType) => {
        mealTypeHash[mealType._id] = {
            mealType,
            recipes: [],
            selectedRecipes: [],
            selectedRecipesServings: 0,
        };
    });
    recipes.map((recipe) => {
        recipe.mealTypes.map((mealTypeId) => {
            if (mealTypeId in mealTypeHash) mealTypeHash[mealTypeId].recipes.push(recipe);
        });
    });
    selectedRecipes.map((recipe) => {
        recipe.mealTypes.map((mealTypeId) => {
            if (mealTypeId in mealTypeHash) mealTypeHash[mealTypeId].selectedRecipes.push(recipe);
        });

        if (recipe.mealTypeId in mealTypeHash) mealTypeHash[recipe.mealTypeId].selectedRecipesServings += recipe.customServings;
    });
    return Object.values(mealTypeHash).sort((a, b) => (a.mealType.order > b.mealType.order ? 1 : -1));
};

export const prepareCommonIngredientsCategoryWise = (commonIngredients, allCategories) => {
    const commonIngredientsLocal = [];
    allCategories.forEach((category) => {
        if (commonIngredients.filter((r) => get(r, 'recipe.categories[0]._id', '') === category._id).length) {
            commonIngredientsLocal.push({
                category: category && category.name,
                recipes: commonIngredients.filter((r) => get(r, 'recipe.categories[0]._id', '') === category._id),
            });
        }
    });
    return commonIngredientsLocal;
};

export const prepareCategory = (recipes, categoryId) => {
    return recipes.find((categoryObj) => categoryObj.category._id === categoryId);
};

export const getCategoryImage = (categories, category, isMobile) => {
    const categoryObj = categories.find(({ name }) => name === category) || { image: '' };
    if (isMobile) {
        return get(categoryObj, 'image.small', '');
    } else {
        return get(categoryObj, 'image.large', '');
    }
};
export const getCategoryId = (categories, category) => {
    const categoryObj = categories.find(({ name }) => name === category);
    return categoryObj._id;
};

export const checkIngredientPresentInRecipe = (recipes, recipeId) => {
    const recipeIndex = findIndex(recipes, (rec) => rec._id === recipeId);
    return recipeIndex !== -1;
};

export const formatRecipesSelected = (recipesSelected, defaultServings) => {
    let newRecipesSelected = recipesSelected.map((r) => ({
        recipe: r._id,
        servings: r.customServings || defaultServings,
    }));
    return newRecipesSelected;
};
// export const filterAvailable = (allRecipeCategories, categories) => {
//     const categoriesHash = {};
//     categories.map((cat) => (categoriesHash[cat] = 1));
//     const categoriesAvailable = allRecipeCategories.filter((item) => item._id in categoriesHash);
//     return categoriesAvailable;
// };

// export const calculateCalorieAverage = (recipes) => {
//     let calories = 0;
//     let servings = 0;
//     recipes.forEach((recipe) => {
//         calories += recipe.nutrition?.calories.value * recipe.customServings;
//         servings += recipe.customServings;
//     });
//     return calories / servings;
// };

export const calculateCalorie = (recipes) => {
    let calories = 0;
    recipes.forEach((recipe) => {
        calories += Math.round(recipe.nutrition?.calories.value) * recipe.customServings;
    });
    return calories;
};

//get selected mealTypes and recipes from cart and categorize them based on mealTypeId
// and call another function to calculate average calorie per mealType
export const calculateMealTypeCalorie = (mealTypes, recipes, calorieMealTypes, nutrition, noOfDays, noOfPeople) => {
    if (!Object.values(mealTypes).length || !nutrition) return [[], 0, 0, 0];
    const mealTypeNutrition = {};
    const mealTypeConfig = keyBy(calorieMealTypes, 'mealTypeId');
    const initialNutrition = {};
    Object.keys(nutrition).map((key) => (initialNutrition[key] = 0));
    const dailyNutrition = { ...initialNutrition };
    Object.values(mealTypes).map((m) => {
        mealTypeNutrition[m._id] = {
            mealType: m,
            recipes: [],
            nutrition: {
                ...initialNutrition,
            },
            adjustedNutrition: {
                ...initialNutrition,
            },
            goalNutrition: {
                ...initialNutrition,
            },
            selectedServings: mealTypeConfig[m._id] ? mealTypeConfig[m._id]['servings'] : 0,
            servings: 0,
        };
    });
    const mealTypesCountHash = {};
    let totalServings = 0;
    recipes.map((recipe) => {
        const mealTypeId = recipe.mealTypeId ? recipe.mealTypeId : recipe.mealTypes[0];
        const mealType = mealTypeNutrition[mealTypeId];
        mealTypesCountHash[mealType.mealType.key] = 1;
        mealType.recipes.push(recipe);
        Object.keys(recipe.nutrition).map((nutritionKey) => {
            const nutritionPerRecipe = recipe.nutrition?.[nutritionKey].value * recipe.customServings;
            mealType.nutrition[nutritionKey] += nutritionPerRecipe;
        });

        mealType.servings += recipe.customServings;
        totalServings += recipe.customServings;
    });
    const mealTypesCount = Object.values(mealTypesCountHash).length + ('lunch & dinner' in mealTypesCountHash ? 1 : 0);
    let days = noOfDays ? noOfDays : Math.round(totalServings / (mealTypesCount ? mealTypesCount : 1));
    days = days ? days : 1;
    Object.values(mealTypeNutrition).map((mealType) => {
        if (!mealType.servings) return mealType;
        if (!mealType.selectedServings) {
            mealType.selectedServings = mealType.servings;
        }
        Object.keys(mealType.nutrition).map((nutritionKey) => {
            const adjusted = (mealType.nutrition[nutritionKey] / mealType.servings) * mealType.selectedServings;
            mealType.adjustedNutrition[nutritionKey] = Math.round(adjusted);
            mealType.goalNutrition[nutritionKey] = Math.round(adjusted / (days * noOfPeople));
            dailyNutrition[nutritionKey] += (mealType.key === 'lunch & dinner' ? 2 : 1) * mealType.goalNutrition[nutritionKey];
        });
    });

    return [mealTypeNutrition, dailyNutrition];
};

export const calculateDailyNutrition = (mealTypes, recipes, calorieMealTypes) => {
    if (!Object.values(mealTypes).length) return [[], 0, 0, 0];
    const mealTypeCalories = {};
    const mealTypeConfig = keyBy(calorieMealTypes, 'mealTypeId');
    Object.values(mealTypes).map((m) => {
        mealTypeCalories[m._id] = {
            mealType: m,
            recipes: [],
            totalCalories: 0,
            calories: 0,
            calorieServings: mealTypeConfig[m._id] ? mealTypeConfig[m._id]['servings'] : 0,
            servings: 0,
        };
    });
    const mealTypesCountHash = {};
    let totalServings = 0;
    recipes.map((recipe) => {
        const mealTypeId = recipe.mealTypeId ? recipe.mealTypeId : recipe.mealTypes[0];
        const mealType = mealTypeCalories[mealTypeId];
        mealTypesCountHash[mealType.mealType.key] = 1;
        mealType.recipes.push(recipe);
        const caloriesForRecipe = Math.round(recipe.nutrition?.calories.value) * recipe.customServings;
        mealType.totalCalories += caloriesForRecipe;
        mealType.servings += recipe.customServings;
        totalServings += recipe.customServings;
    });

    let totalCalories = 0;
    const finalCalories = Object.values(mealTypeCalories).map((mealType) => {
        if (!mealType.servings) return mealType;
        if (!mealType.calorieServings) {
            mealType.calorieServings = mealType.servings;
        }
        const calories = Math.round(mealType.totalCalories / mealType.servings) * mealType.calorieServings;
        totalCalories += calories;
        // const factor = 'lunch & dinner' === mealType.mealType.key ? 2 : 1;
        return {
            ...mealType,
            calories,
            caloriesPerServing: Math.round(mealType.totalCalories / mealType.servings),
        };
    });

    const mealTypesCount = Object.values(mealTypesCountHash).length + ('lunch & dinner' in mealTypesCountHash ? 1 : 0);

    return [finalCalories, totalCalories, totalServings, mealTypesCount];
};

export const roundNutrition = (nutrition) => {
    const toRoundNutrition = {
        caloriesMax: roundUp(nutrition.data.caloriesMax) || 0,
        caloriesMin: roundDown(nutrition.data.caloriesMin) || 0,
        carbohydratesMax: roundUp(nutrition.data.carbohydratesMax) || 0,
        carbohydratesMin: roundDown(nutrition.data.carbohydratesMin) || 0,
        cookingTimeMax: roundUp(nutrition.data.cookingTimeMax) || 0,
        cookingTimeMin: roundDown(nutrition.data.cookingTimeMin) || 0,
        fatMax: roundUp(nutrition.data.fatMax) || 0,
        fatMin: roundDown(nutrition.data.fatMin) || 0,
        proteinMax: roundUp(nutrition.data.proteinMax) || 0,
        proteinMin: roundDown(nutrition.data.proteinMin) || 0,
    };
    return toRoundNutrition;
};

export const increaseServings = (defaultServings, min, increaseServings) => {
    if (defaultServings) {
        if (min) {
            if (min > defaultServings) return min;
            else if (min < defaultServings) {
                let num = Math.ceil((defaultServings - min) / increaseServings);
                return min + increaseServings * num;
            } else return defaultServings;
        } else return defaultServings;
    }
};
export const decreaseServings = (defaultServings, min, decreaseServings) => {
    if (defaultServings) {
        if (min) {
            if (min < defaultServings) return defaultServings - decreaseServings;
            else return 0;
        } else return defaultServings - 1;
    }
};

const roundUp = (number) => {
    if (number % 10 == 0) return number;
    return 10 - (number % 10) + number;
};

const roundDown = (number) => {
    return number - (number % 10);
};
export const prepareCategorySearch = (allCategories, categories) => {
    let cat = [];
    categories.map((i) => {
        const category = allCategories.find((c) => c._id === i._id);
        if (!isEmpty(category)) {
            cat.push(category);
        }
    });
    return cat;
};
