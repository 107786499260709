import get from 'lodash/get';

import * as constants from '../constants';

const Cart = localStorage.getItem('Cart') ? JSON.parse(localStorage.getItem('Cart')) : {};
const RecipesFilter = localStorage.getItem('RecipesFilter') ? JSON.parse(localStorage.getItem('RecipesFilter')) : {};

const initialState = {
    recipes: [],
    fetching: false,
    isLoadingCommonIng: false,
    recipesToPrint: [],
    loadingPrintRecipes: false,
    isloadedRecipe: false,
    menuRecipes: [],
    fetchingMenu: false,
    filterInfo: get(RecipesFilter, 'data', {}),
    isChangeCookingTime: false,
    commonIngredients: [],
    isFetchingCommonRecipes: false,
    isFetchedCommonRecipes: false,
    unusedGroceriesWorth: 0,
    isloadedFilterAvailable: false,
    recipesFilter: get(RecipesFilter, 'data', {}),
    isFetchedFilter: false,
    isLoadAllRecipes: false,
    totalRecipe: 750,
    isLoadingRecipes: false,
    isLoadedAllRecipes: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case constants.LOAD_MORE_RECIPES:
        case constants.GET_RECIPES_REQUEST:
            return {
                ...state,
                fetching: true,
                isLoadingRecipes: true,
                isloadedFilterAvailable: false,
            };

        case constants.GET_RECIPES_SUCCESS:
            return {
                ...state,
                recipes: action.data.recipes,
                fetching: false,
                isLoadingRecipes: false,
                isloadedRecipe: true,
                isloadedFilterAvailable: true,
                isLoadAllRecipes: action.data.page !== 1,
                totalRecipe: action.data.totalCount,
            };
        case constants.GET_ALL_RECIPES_SUCCESS:
            return {
                ...state,
                isLoadedAllRecipes: true,
            };
        case constants.GET_RECIPES_FAILURE:
            return {
                ...state,
                fetching: false,
                isLoadingRecipes: false,
                error: action.error,
            };
        case constants.GET_RECIPES_FILTER_SUCCESS:
            localStorage.setItem(
                'RecipesFilter',
                JSON.stringify({
                    ...JSON.parse(localStorage.getItem('RecipesFilter')),
                    data: action.payload,
                    isFetched: true,
                }),
            );
            return {
                ...state,
                recipesFilter: action.payload,
                filterInfo: action.payload,
                isFetchedFilter: true,
            };
        case constants.SET_RECIPES_TO_PRINT:
            return {
                ...state,
                loadingPrintRecipes: true,
            };
        case constants.SET_RECIPES_TO_PRINT_SUCCESS:
            return {
                ...state,
                recipesToPrint: action.menuRecipes,
                loadingPrintRecipes: false,
            };

        case constants.GET_RECIPES_TO_PRINT_SUCCESS: {
            return {
                ...state,
                recipesToPrint: action.payload,
            };
        }
        case constants.GET_MENU_REQUEST:
            return {
                ...state,
                fetchingMenu: true,
            };
        case constants.GET_MENU_SUCCESS:
            return {
                ...state,
                menuRecipes: action.data.recipes,
                fetchingMenu: false,
            };
        case constants.GET_MENU_FAILURE:
            return {
                ...state,
                error: action.error,
                fetchingMenu: false,
            };
        case constants.GET_UNUSED_INGREDIENTS_RECIPE_REQUEST:
            return {
                ...state,
                fetchingMenu: true,
            };
        case constants.GET_UNUSED_INGREDIENTS_RECIPE_SUCCESS:
            return {
                ...state,
                fetchingMenu: false,
                unusedGroceriesWorth: action.payload.unusedGroceriesWorth,
                commonIngredients: action.payload.recommendedRecipes,
            };
        case constants.GET_UNUSED_INGREDIENTS_RECIPE_FAILURE:
            return {
                ...state,
                fetchingMenu: false,
                error: action.error,
            };
        case constants.SET_FILTER_INFO:
            return {
                ...state,
                filterInfo: action.filterInfo,
                isChangeCookingTime: true,
            };
        case constants.CLEAR_FLASH_IS_CHANGE_COOKING_TIME:
            return {
                ...state,
                isChangeCookingTime: false,
            };
        case constants.GET_COMMON_INGREDIENTS_REQUEST:
            return {
                ...state,
                isLoadingCommonIng: true,
            };
        case constants.GET_COMMON_INGREDIENTS_SUCCESS:
            return {
                ...state,
                commonIngredients: action.commonIngredients,
                fetching: false,
                isFetchedCommonRecipes: true,
                isLoadingCommonIng: false,
            };

        case constants.GET_COMMON_INGREDIENTS_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoadingCommonIng: false,
                fetching: false,
            };

        case constants.SET_FETCHING_COMMON_INGREDIENT:
            return {
                ...state,
                isFetchedCommonRecipes: false,
                isFetchingCommonRecipes: action.payload,
            };
        case constants.SET_IS_LOADED_RECIPES:
            return {
                ...state,
                isloadedRecipe: action.payload,
            };
        case constants.FILTER_RECIPES:
            return {
                ...state,
                recipes: [],
                isloadedRecipe: false,
            };
        default:
            return state;
    }
}
