import ApiBaseRequest from '../networkRequests/ApiBaseRequest';
import * as actions from './curatedRecipes.action';
import store from '../store';

class GetCuratedRecipes extends ApiBaseRequest {
    constructor() {
        super('GET');
    }

    generateUrl = () => '/curated-recipes';

    beforeCall = () => {
        store.dispatch(actions.fetctingCuratedRecipes());
    };

    shouldCall = ({ forceCall }) => {
        const state = store.getState();
        const {
            featureFlagsReducer: { featureFlags },
            curatedRecipesReducer: { pagination, fetchedCuratedRecipes },
        } = state;
        if (forceCall && (pagination.nextPage == pagination.totalPage || pagination.totalCount == 0)) {
            return false;
        }
        const isCuratedBoxesEnabled = featureFlags.find(({ name }) => name === 'isCuratedBoxesEnabled').enabled;
        if ((forceCall || !fetchedCuratedRecipes) && isCuratedBoxesEnabled) {
            return true;
        }
        return false;
    };

    prepareQuery = () => {
        const state = store.getState();
        const {
            configReducer: {
                config: { countryCode },
            },
            influencerReducer: { data: influencer },
            curatedRecipesReducer: { pagination },
        } = state;
        const query = {
            countryCode,
            influencerId: influencer._id,
            sortBy: 'order',
            order: 'asc',
            page: pagination.nextPage,
            perPage: pagination.limit,
        };
        return query;
    };

    afterCall = ({ response }) => {
        const state = store.getState();
        const {
            curatedRecipesReducer: { curatedRecipes },
        } = state;
        const {
            data: { result, pagination },
        } = response;
        store.dispatch(actions.getCuratedRecipesSuccess({ result: [...curatedRecipes, ...result], pagination }));
        store.dispatch(actions.fetchedCuratedRecipes());
    };
}

export default new GetCuratedRecipes();
