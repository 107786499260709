import * as constants from '../constants';

export const startLoading = () => ({ type: constants.LOADING_CATEGORY_PAGE_DATA });
export const stopLoading = () => ({ type: constants.LOADED_CATEGORY_PAGE_DATA });

export const fetchRecipeCategoriesSuccess = (data) => ({
    type: constants.GET_CATEGORY_RECIPES_SUCCESS,
    data,
});
export const getRecipesFailure = (error) => ({ type: constants.GET_RECIPES_FAILURE, error });

export const loadMoreRecipes = () => ({ type: constants.LOAD_MORE_RECIPES });
