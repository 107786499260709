import * as constants from '../constants';
import get from 'lodash/get';
import { constant } from 'lodash';

const buildMenu = localStorage.getItem('buildMenu') ? JSON.parse(localStorage.getItem('buildMenu')) : {};

const initialState = {
    buildMenuResult: [],
    selectedNutritionProgram: get(buildMenu, 'selectedNutritionProgram', {}),
    selectedMealTypes: get(buildMenu, 'selectedMealTypes', []),
    selectedDiet: get(buildMenu, 'selectedDiet', 'everything'),
    caloriesGoal: get(buildMenu, 'caloriesGoal', null),
    allergies: get(buildMenu, 'allergies', []),
    noOfPeople: get(buildMenu, 'noOfPeople', null),
    noOfDays: get(buildMenu, 'noOfDays', null),
    dislikedIngredients: [],
    cookingTime: get(buildMenu, 'cookingTime', {}),
    difficultyLevel: get(buildMenu, 'difficultyLevel', ''),
    expandedMealTypes: [],
    currentMealTypeStep: 0,
    expandedRecipesFirstTime: false,
    reviewedMealTypes: [],
    mealTypesInCart: [],
    step: get(buildMenu, 'step', 1),
    expandRecipes: get(buildMenu, 'expandRecipes', []),
};

export default function(state = initialState, action) {
    switch (action.type) {
        case constants.SET_SELECTED_NUTRITION_PROGRAM: {
            localStorage.setItem(
                'buildMenu',
                JSON.stringify({
                    ...JSON.parse(localStorage.getItem('buildMenu')),
                    selectedNutritionProgram: action.nutritionProgram,
                }),
            );
            return {
                ...state,
                selectedNutritionProgram: action.nutritionProgram,
            };
        }
        case constants.SET_SELECTED_DIET: {
            localStorage.setItem(
                'buildMenu',
                JSON.stringify({
                    ...JSON.parse(localStorage.getItem('buildMenu')),
                    selectedDiet: action.diet,
                }),
            );
            return {
                ...state,
                selectedDiet: action.diet,
            };
        }
        case constants.SET_SELECTED_MEAL_TYPE: {
            const selectedMealTypes = {
                ...state.selectedMealTypes,
            };
            if (state.selectedMealTypes[action.mealType._id]) {
                delete selectedMealTypes[action.mealType._id];
            } else {
                selectedMealTypes[action.mealType._id] = action.mealType;
            }
            localStorage.setItem(
                'buildMenu',
                JSON.stringify({
                    ...JSON.parse(localStorage.getItem('buildMenu')),
                    selectedMealTypes,
                }),
            );
            return {
                ...state,
                selectedMealTypes,
            };
        }
        case constants.SET_EXPANDED_RECIPES: {
            const expandRecipes = {
                ...state.expandRecipes,
            };
            expandRecipes[action.data._id] = action.expand;
            localStorage.setItem(
                'buildMenu',
                JSON.stringify({
                    ...JSON.parse(localStorage.getItem('buildMenu')),
                    expandRecipes,
                }),
            );
            return {
                ...state,
                expandRecipes,
            };
        }
        case constants.SET_CALORIES_GOAL: {
            localStorage.setItem(
                'buildMenu',
                JSON.stringify({
                    ...JSON.parse(localStorage.getItem('buildMenu')),
                    caloriesGoal: action.cal,
                }),
            );
            return {
                ...state,
                caloriesGoal: action.cal,
            };
        }
        case constants.SET_SELECTED_ALLERGIES: {
            localStorage.setItem(
                'buildMenu',
                JSON.stringify({
                    ...JSON.parse(localStorage.getItem('buildMenu')),
                    allergies: action.allergies,
                }),
            );
            return {
                ...state,
                allergies: action.allergies,
            };
        }
        case constants.SET_SELECTED_DISLIKES:
            return {
                ...state,
                dislikedIngredients: action.ingredients,
            };
        case constants.SET_COOKING_TIME_FILTER: {
            localStorage.setItem(
                'buildMenu',
                JSON.stringify({
                    ...JSON.parse(localStorage.getItem('buildMenu')),
                    cookingTime: action.time,
                }),
            );
            return {
                ...state,
                cookingTime: action.time,
            };
        }
        case constants.SET_DIFFICULTY_LEVEL: {
            localStorage.setItem(
                'buildMenu',
                JSON.stringify({
                    ...JSON.parse(localStorage.getItem('buildMenu')),
                    difficultyLevel: action.difficulty,
                }),
            );
            return {
                ...state,
                difficultyLevel: action.difficulty,
            };
        }

        case constants.SET_BUILD_MENU_INITIAL_STATE: {
            localStorage.removeItem('buildMenu');
            return initialState;
        }
        case constants.GET_BUILD_MENU_RESULT_SUCCESS:
            return {
                ...state,
                buildMenuResult: action.result,
            };
        case constants.SET_EXPANDED_MEAL_TYPES:
            return {
                ...state,
                expandedMealTypes: action.mealTypes,
            };
        case constants.SET_CURRENT_MEAL_TYPE_STEP:
            return {
                ...state,
                currentMealTypeStep: action.step,
            };
        case constants.SET_EXPANDED_FIRST_TIME:
            return {
                ...state,
                expandedRecipesFirstTime: action.flag,
            };
        case constants.SET_REVIEWED_MEAL_TYPES: {
            return {
                ...state,
                reviewedMealTypes: action.mealTypes,
            };
        }
        case constants.SET_MEAL_TYPES_IN_CART:
            return {
                ...state,
                mealTypesInCart: action.mealTypes,
            };
        case constants.SET_NUMBER_OF_PEOPLE:
            localStorage.setItem(
                'buildMenu',
                JSON.stringify({
                    ...JSON.parse(localStorage.getItem('buildMenu')),
                    noOfPeople: action.noOfPeople,
                }),
            );
            return {
                ...state,
                noOfPeople: action.noOfPeople,
            };
        case constants.SET_NUMBER_OF_DAYS:
            localStorage.setItem(
                'buildMenu',
                JSON.stringify({
                    ...JSON.parse(localStorage.getItem('buildMenu')),
                    noOfDays: action.noOfDays,
                }),
            );
            return {
                ...state,
                noOfDays: action.noOfDays,
            };
        case constants.SET_QUESTION_STEP:
            localStorage.setItem(
                'buildMenu',
                JSON.stringify({
                    ...JSON.parse(localStorage.getItem('buildMenu')),
                    step: action.step,
                }),
            );
            return {
                ...state,
                step: action.step,
            };
        default:
            return state;
    }
}
