import { call, put, takeEvery, select } from 'redux-saga/effects';
import * as constants from '../constants';
import * as actions from '../actions/recipeCategory.action';
import * as actionsRecipe from '../actions/recipe.action';
import * as actionOrderMenu from '../actions/orderMenu.actions';
import serverAPI from 'services/recipeCategory.service';
import { errorNotification } from 'helpers/errorNotification';

export const selectSelectedCategories = () => select(({ recipeCategoryReducer }) => recipeCategoryReducer.selected);
export const selectAllCategories = () => select(({ recipeCategoryReducer }) => recipeCategoryReducer.all);
export const selectIsloadedCategories = () => select(({ recipeCategoryReducer }) => recipeCategoryReducer.isloadedCategories);
export function* getRecipesCategories({ forceCall }) {
    try {
        const isloadedCategories = yield selectIsloadedCategories();
        if (!isloadedCategories || forceCall) {
            const response = yield call(serverAPI.getRecipesFilters);
            if (response) {
                localStorage.setItem('categories', JSON.stringify(response.data));
                yield put(actions.getRecipesCategoriesSuccess(response.data));
            }
        }
    } catch (error) {
        yield put(actions.getRecipesCategoriesFailure('getRecipesCategories'));
        const data = {};
        const text = 'Error in getRecipesCategories saga';
        errorNotification(error, data, text, false);
    }
}

export function* toggleCategory({ categoryName }) {
    try {
        const selectedCategories = yield selectSelectedCategories();
        if (selectedCategories.indexOf(categoryName) === -1) {
            yield put(actions.addCategory(categoryName));
        } else {
            yield put(actions.deleteCategory(categoryName));
        }
    } catch (error) {
        const data = { categoryName };
        const text = 'Error in toggleCategory saga';
        errorNotification(error, data, text);
    }
}
export default function* () {
    yield takeEvery(constants.GET_RECIPES_CATEGORIES_REQUEST, getRecipesCategories);
    yield takeEvery(constants.TOGGLE_CATEGORY, toggleCategory);
}
