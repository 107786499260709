import React from 'react';
import { useDispatch } from 'react-redux';
import useScreenType from 'hooks/useScreenType';

import { deleteExtraProduct } from 'store/actions/extraProduct.actions';
import Icons from 'components/common/Icons/Icons';
import { ExtraProductContainer, Image, Name, Quantity, Price, ExtraValue, ButtonRemove, Product } from './styles';

const ExtraProduct = ({ extraProduct }) => {
    const { isDesktopOrLaptop } = useScreenType();
    const dispatch = useDispatch();

    return (
        <ExtraProductContainer isDesktopOrLaptop={isDesktopOrLaptop}>
            <Product isDesktopOrLaptop={isDesktopOrLaptop}>
                <Image image={extraProduct.image}></Image>
                <Name>{extraProduct.name}</Name>
            </Product>
            <ExtraValue isDesktopOrLaptop={isDesktopOrLaptop}>
                <Quantity>{`${extraProduct.value} pack (${extraProduct.quantity} ${extraProduct.units})`}</Quantity>
                <Price>{`$${extraProduct.price}`}</Price>
                <ButtonRemove onClick={() => dispatch(deleteExtraProduct(extraProduct._id))}>
                    <Icons name="trash" size="24"></Icons>
                </ButtonRemove>
            </ExtraValue>
        </ExtraProductContainer>
    );
};

export default ExtraProduct;
