import React, { useEffect, useState } from 'react';
import { SearchContainer, SearchClean } from './styles';
import Icon from '../Icons/Icons';

const SearchField = ({
    width = '100%',
    height = '44px',
    fontSize = '14px',
    placeHolder = '',
    className = '',
    value = '',
    autoFocus = false,
    onChange = () => {},
    onClear = () => {},
    onEnterInput = () => {},
    onFocus = () => {},
    onBlur = () => {},
}) => {
    const [isFocus, setIsFocus] = useState(focus);
    const [isFiled, setIsFiled] = useState(false);

    useEffect(() => {
        if (value && value.trim() !== '') {
            setIsFiled(true);
        } else setIsFiled(false);
    }, [value]);

    return (
        <SearchContainer isFocus={isFocus} width={width} height={height} fontSize={fontSize}>
            <Icon name="search" size="20" color={isFiled ? '#82BD73' : '#909DA6'} />
            <input
                placeholder={placeHolder}
                className={className}
                value={value}
                onChange={onChange}
                onFocus={() => {
                    setIsFocus(true);
                    onFocus();
                }}
                autoFocus={autoFocus}
                onBlur={() => {
                    setIsFocus(false);
                    onBlur();
                }}
                onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                        onEnterInput();
                    }
                }}
            />
            <SearchClean isFiled={isFiled} isFocus={isFocus} onClick={onClear}>
                <Icon name="closeSearch" size="20" />
            </SearchClean>
        </SearchContainer>
    );
};

export default SearchField;
