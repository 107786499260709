import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import history from '../../../history';
import useScreenType from 'hooks/useScreenType';
import useFeatureFlags from 'hooks/useFeatureFlags';
import get from 'lodash/get';

import { Secondary, CartWithItems } from '../Buttons';
import Favourite from '../Buttons/Favourite';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { setUserLocation } from 'store/actions/config.actions';

import { countries as countryOptions } from 'constants/country';
import { pageWithoutCartIcon } from 'constants/pageWithoutCartIcon';

import { HeaderRightContainer } from './styles';

import { updateStateSummaryPopup, showMobileSummaryPopup } from 'store/actions/ui-settings.actions';

const HeaderRight = ({ showDashboard, headerRight, setShowDashboard }) => {
    const { isTabletOrMobile } = useScreenType();
    const { isContentForUSEnabled } = useFeatureFlags();
    const loggedInUser = useSelector((state) => state.authenReducer.loggedInUser);
    const pathname = history.location.pathname;
    const config = useSelector((state) => state.configReducer.config);
    const recipesLenght = useSelector((state) => state.recipeReducer.Cart.selectedRecipesLength);
    const cost = useSelector((state) => state.recipeReducer.Cart.pricePayload);
    const isEditing = useSelector((state) => state.recipeReducer.isEditing);
    const dispatch = useDispatch();
    const [openMenu, setOpenMenu] = useState(null);
    const handleClick = (event) => {
        setOpenMenu(event.currentTarget);
    };

    const onCartClick = () => {
        if (!isTabletOrMobile) {
            dispatch(updateStateSummaryPopup(true));
            return;
        }
        dispatch(showMobileSummaryPopup(true));
    };

    return (
        <HeaderRightContainer isTabletOrMobile={isTabletOrMobile}>
            {headerRight ? (
                headerRight
            ) : (
                <>
                    {isContentForUSEnabled && (
                        <div>
                            <img
                                src={countryOptions.find(({ value }) => value === get(config, 'countryCode', 'CA')).image}
                                onClick={handleClick}
                            />
                            <Menu
                                id="simple-menu"
                                anchorEl={openMenu}
                                keepMounted
                                open={Boolean(openMenu)}
                                onClose={() => setOpenMenu(null)}
                            >
                                {countryOptions.map((i) => (
                                    <MenuItem
                                        onClick={() => {
                                            setOpenMenu(null);
                                            dispatch(
                                                setUserLocation({
                                                    payload: { countryCode: i.value, currency: i.currency },
                                                    isCountryChange: true,
                                                    forceCall: true,
                                                }),
                                            );
                                        }}
                                    >
                                        <img style={{ width: '42px', height: '40px' }} src={i.image} />
                                    </MenuItem>
                                ))}
                            </Menu>
                        </div>
                    )}
                    {isTabletOrMobile ? (
                        pageWithoutCartIcon(pathname) && (
                            <CartWithItems
                                itemsCount={recipesLenght}
                                cost={cost}
                                disabled={isEditing}
                                onClick={() => onCartClick()}
                            />
                        )
                    ) : (
                        <>
                            {loggedInUser && (
                                <Favourite
                                    onClick={() =>
                                        history.push({
                                            pathname: '/recipes/favorites',
                                            state: { from: true },
                                        })
                                    }
                                    active={pathname === '/recipes/favorites'}
                                />
                            )}
                            <Secondary
                                size="small"
                                title={loggedInUser ? 'My account' : 'Sign Up'}
                                onClick={() => {
                                    if (!loggedInUser) {
                                        if (pathname.includes('/menu')) {
                                            return history.push('/signup');
                                        }
                                        return history.push('/onboarding/account');
                                    }
                                    history.push('/orders');
                                }}
                            />
                            {pageWithoutCartIcon(pathname) && (
                                <CartWithItems
                                    itemsCount={recipesLenght}
                                    cost={cost}
                                    disabled={isEditing}
                                    onClick={() => onCartClick()}
                                />
                            )}
                        </>
                    )}
                </>
            )}
        </HeaderRightContainer>
    );
};
export default HeaderRight;
