import React, { useCallback } from 'react';
import get from 'lodash/get';
import { Badge } from '@material-ui/core';
import CustomIcon from './Icon';
import { CartWithItemsWrapper } from './styles';
import history from '../../../../src/history';
import { cartWithoutTotalCost } from 'constants/pageWithoutCartIcon';

const CartWithItems = ({ itemsCount, size, onClick, disabled, cost }) => {
    const {
        location: { pathname, search },
    } = history;

    const hideTotalPrice = useCallback(() => {
        return cartWithoutTotalCost(pathname, search);
    }, [pathname, search]);

    return (
        <CartWithItemsWrapper onClick={onClick} disabled={disabled}>
            {hideTotalPrice() && <span>${get(cost, pathname === '/checkout' ? 'totalPrice' : 'subTotal', 0).toFixed(2)}</span>}
            <div>
                <Badge badgeContent={itemsCount}>
                    <CustomIcon name={'cart'} width={'24px'} height={'24px'} />
                </Badge>
            </div>
        </CartWithItemsWrapper>
    );
};

export default CartWithItems;
