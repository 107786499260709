import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    arrow: {
        '&:before': {
            boxShadow: '0px 4px 18px rgba(13, 33, 32, 0.1)',
        },
        color: theme.palette.common.white,
    },
    tooltip: {
        background: '#FFFFFF',
        boxShadow: '0px 4px 18px rgba(13, 33, 32, 0.1)',
        borderRadius: '8px',
        color: '#909DA6',
        fontFamily: 'unset',
        fontSize: '12px',
        lineHeight: '120%',
        padding: '26px',
        textAlign: 'justify',
    },
}));

const Tooltips = ({ children, label, placement }) => {
    let classes = useStyles();

    return (
        <Tooltip
            enterDelay={0}
            enterTouchDelay={0}
            leaveTouchDelay={6000}
            title={label}
            arrow
            classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
            placement={placement}
            interactive
        >
            {children}
        </Tooltip>
    );
};

Tooltips.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    placement: PropTypes.oneOf([
        'top-start',
        'top',
        'top-end',
        'left-start',
        'left',
        'left-end',
        'right-start',
        'right',
        'right-end',
        'bottom-start',
        'bottom',
        'bottom-end',
    ]),
};

Tooltips.defaultProps = {
    label: '',
    placement: 'top',
};

export default Tooltips;
