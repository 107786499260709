import styled, { css } from 'styled-components';

export const Pantry = styled.div`
    padding: 24px 0;
    border: unset;
    border-bottom: 1px solid #eff5f9;

    ${(props) =>
        !props.isDesktopOrLaptop &&
        css`
            padding: 12px;
            border: 1px solid #e5eaed;
            border-radius: 8px;
        `};
`;

export const PantryItemContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const PantryItemInfo = styled.div`
    width: 40%;
    display: flex;
    align-items: center;

    ${(props) =>
        !props.isDesktopOrLaptop &&
        css`
            padding: 3px 0;
            width: 50%;
        `};
`;

export const Progress = styled.div`
    display: flex;
    align-items: center;
    width: 25%;

    ${(props) =>
        !props.isDesktopOrLaptop &&
        css`
            flex-direction: column-reverse;
            align-items: unset;
            width: 40%;
        `};
`;

export const DaysLeft = styled.div`
    margin-left: auto;
    padding-right: 16px;
    color: #233747;
    font-size: 14px;
    line-height: 146%;
    letter-spacing: -0.4px;

    ${(props) =>
        !props.isDesktopOrLaptop &&
        css`
            margin-left: 0;
            padding-top: 8px;
        `};
`;

export const TitleGroup = styled.div`
    padding: 0 24px;

    ${(props) =>
        !props.isDesktopOrLaptop &&
        css`
            padding: 0 4px 0 8px;
        `};
`;

export const Name = styled.div`
    color: #233747;
    font-weight: 500;
    font-size: 16px;
    line-height: 124%;

    ${(props) =>
        !props.isDesktopOrLaptop &&
        css`
            font-size: 14px;
            line-height: 146%;
        `};
`;

export const DatePurchase = styled.div`
    color: #909da6;
    letter-spacing: -0.4px;
    font-size: 14px;
    line-height: 146%;

    & > span {
        color: #233747;
    }

    ${(props) =>
        !props.isDesktopOrLaptop &&
        css`
            font-size: 12px;
            line-height: 150%;
        `};
`;

export const Image = styled.div`
    width: 88px;
    height: 56px;
    border-radius: 8px;
    background: url(${({ image }) => image}) #d8d8d8 no-repeat center center/cover;

    ${(props) =>
        !props.isDesktopOrLaptop &&
        css`
            border-radius: 4px;
            width: 32px;
            height: 32px;
        `};
`;

export const EditItem = styled.div`
    display: flex;
    align-items: center;
    width: 40%;

    ${(props) =>
        !props.isDesktopOrLaptop &&
        css`
            width: 10%;
        `};
`;

export const ProgressBar = styled.div`
    background: #e5eaed;
    border-radius: 4px;
    height: 2px;
    width: 94px;

    & > div {
        background: #ff6b6b;
        border-radius: 4px;
        width: 84px;
        height: 2px;
    }
`;

export const Quantity = styled.div`
    display: flex;
    align-items: center;
    margin: 0 auto;

    ${(props) =>
        !props.isDesktopOrLaptop &&
        css`
            width: 100%;
        `};
`;

export const ButtonMinus = styled.div`
    margin: 0 16px 0 0;
    color: #82bd73;
    cursor: pointer;

    ${(props) =>
        props.disable &&
        css`
            color: #e5eaed;
            cursor: not-allowed;
        `};
`;

export const ButtonPlus = styled.div`
    margin: 0 0 0 16px;
    color: #82bd73;
    cursor: pointer;
`;

export const QuantityValue = styled.div`
    min-width: 88px;
    padding: 8px 16px;
    border: 1px solid #e5eaed;
    box-sizing: border-box;
    border-radius: 8px;
    color: #233747;
    font-size: 14px;
    line-height: 146%;
    letter-spacing: -0.4px;
    text-align: center;

    & > input {
        color: #233747;
        border: none;
        padding: 0;
        width: 39px;
        text-align: center;
    }

    ${(props) =>
        !props.isDesktopOrLaptop &&
        css`
            width: 100%;
        `};
`;

export const ButtonEdit = styled.div`
    color: #aab5bc;
    cursor: pointer;

    ${(props) =>
        !props.isDesktopOrLaptop &&
        css`
            margin-left: auto;
        `};
`;

export const EditItemMobile = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    width: 100%;
    margin-top: 14px;
    padding-top: 14px;
    border-top: 1px solid #e5eaed;
`;
