/* eslint-disable css-modules/no-unused-class */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon } from 'react-share';
import { withRouter } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';

import { compose } from 'redux';
import { connect } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Close from '@material-ui/icons/Close';
import Copy from '@material-ui/icons/FileCopyOutlined';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Image from '../RecipePreviewModal/Image/Image';
import { setRecipeToPreview as setRecipeToPreviewAction } from 'store/actions/recipe.action';

import config from 'config';
import styles from '../../common/Share/ShareMenu.module.scss';

const ShareMenuModal = ({ open, match, history, setRecipeToPreview, currentRecipe, loading }) => {
    useEffect(() => {
        setRecipeToPreview(match.params.id, null);
    }, []);

    const back = (e) => {
        e.stopPropagation();
        history.goBack();
    };

    return (
        <Dialog className={styles.modal} open={open} scroll="body" keepMounted onClose={back} aria-labelledby="share-menu-dialog">
            {loading && (
                <div className={styles.loadingContainer}>
                    <CircularProgress size={64} color="inherit" />
                </div>
            )}
            {!loading && currentRecipe && (
                <>
                    <div className={styles.modalHeader}>
                        <IconButton className={styles.close} onClick={back} size="small" aria-label="Close" variant="outline">
                            <Close />
                        </IconButton>
                        <DialogTitle id="share-menu-dialog-title" classes={{ root: styles.title }}>
                            Share Recipe
                        </DialogTitle>
                    </div>
                    {/* <Divider classes={{ root: styles.hr }} /> */}

                    <DialogContent classes={{ root: styles.contentContainer }}>
                        <div className={styles.imageContainer}>
                            <Image style={styles.image} image={currentRecipe.images ? currentRecipe.images[0] : ''} />
                            <div className={styles.imageOverlay}>{`${currentRecipe.name} - ${currentRecipe.description}`}</div>
                        </div>

                        <div className={styles.btnContainer}>
                            <TwitterShareButton
                                url={`${config[process.env.REACT_APP_ENV].FRONTEND_DOMAIN}/recipes/${currentRecipe._id}`}
                                title="Check out my recipe:"
                            >
                                <Button variant="contained" aria-label="Twitter Link" className={styles.btn}>
                                    <TwitterIcon size={32} logoFillColor="#00ACED" iconBgStyle={{ fill: 'transparent' }} />
                                    <span className={styles.btnText}>Share on Twitter</span>
                                </Button>
                            </TwitterShareButton>
                            <FacebookShareButton
                                url={`${config[process.env.REACT_APP_ENV].FRONTEND_DOMAIN}/recipes/${currentRecipe._id}`}
                                quote="Check out my recipe:"
                            >
                                <Button variant="contained" aria-label="Facebook Link" className={styles.btn}>
                                    <FacebookIcon size={20} borderRadius={10} />
                                    <span className={styles.btnText}>Share on Facebook</span>
                                </Button>
                            </FacebookShareButton>
                            <CopyToClipboard
                                text={`${config[process.env.REACT_APP_ENV].FRONTEND_DOMAIN}/recipes/${currentRecipe._id}`}
                            >
                                <Button
                                    variant="contained"
                                    aria-label="Copy Link"
                                    className={styles.btn}
                                    // onClick={copyToClipboard(shareUrl)}
                                    data-tip
                                    data-for="tooltip"
                                    data-event="tap focus"
                                >
                                    <Copy variant="outlined" className={styles.copyIcon} />
                                    <span className={styles.btnText}>Copy Link</span>
                                    <ReactTooltip
                                        id="tooltip"
                                        className={styles.tooltip}
                                        globalEventOff="click"
                                        delayHide={700}
                                        effect="solid"
                                        place="bottom"
                                    >
                                        <span>Copied</span>
                                    </ReactTooltip>
                                </Button>
                            </CopyToClipboard>
                        </div>
                    </DialogContent>
                </>
            )}
        </Dialog>
    );
};

ShareMenuModal.propTypes = {
    open: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    currentRecipe: PropTypes.object,
    setRecipeToPreview: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};
ShareMenuModal.defaultProps = {
    currentRecipe: {},
};

const mapStateToProps = (state) => {
    const {
        recipeReducer: { loading, currentRecipe },
    } = state;
    return {
        loading,
        currentRecipe,
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, {
        setRecipeToPreview: setRecipeToPreviewAction,
    }),
)(ShareMenuModal);
