import Cookies from 'js-cookie';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import serverAPI from 'services/usePantry.service';
import cartAPI from 'services/cart.service';
import * as actions from '../actions/userPantry.actions';
import * as cartActions from '../actions/cart.actions';
import * as actionsUiSetting from '../actions/ui-settings.actions';
import * as actionsRecipe from '../actions/recipe.action';
import * as constants from '../constants';
import { errorNotification } from 'helpers/errorNotification';
import { prepareDataUserPantry } from 'utility/userPantryUtil';

export const selectLoggedInUser = () => select(({ authenReducer }) => authenReducer.loggedInUser);
export function* getUserPantryRequest({ forceCall }) {
    const loggedInUser = yield selectLoggedInUser();
    try {
        const isFetched = yield select((state) => state.userPantryReducer.isFetched);
        if (loggedInUser) {
            const token = Cookies.get('token');
            const headers = {
                Authorization: token,
            };
            const query = {
                page: 0,
                perPage: 1000,
            };
            if (forceCall || !isFetched) {
                const response = yield call(serverAPI.getUserPantry, query, headers);
                yield put(actions.getUserPantrySuccess(response.data));
            }
        }
    } catch (error) {
        const data = { loggedInUser };
        const text = 'Error in getUserPantryRequest saga';
        errorNotification(error, data, text);
        yield put(actions.getUserPantryFailure(error));
    }
}

export function* addUserPantry(action) {
    const loggedInUser = yield selectLoggedInUser();
    const countryCode = yield select((state) => state.configReducer.config.countryCode);
    try {
        if (loggedInUser) {
            const token = Cookies.get('token');
            const headers = {
                Authorization: token,
            };
            yield call(serverAPI.addUserPantry, headers, { data: [action.data], countryCode });
            yield put(actions.addUserPantrySuccess());
            yield put(actions.getUserPantryRequest({ forceCall: true }));
            yield put(actionsUiSetting.updateStateAddIngredientPopup(false));
            yield put(cartActions.resaveCart());
        }
    } catch (error) {
        const data = { data: action.data, loggedInUser };
        const text = 'Error in addUserPantry saga';
        errorNotification(error, data, text);
        yield put(actions.addUserPantryFailure(error));
    }
}

export function* removeUserPantry(action) {
    const loggedInUser = yield selectLoggedInUser();
    try {
        if (loggedInUser) {
            const token = Cookies.get('token');
            const headers = {
                Authorization: token,
            };
            const query = {
                items: JSON.stringify(action.ids),
            };
            yield call(serverAPI.removeUserPantry, headers, query);
            yield put(actions.removeUserPantrySuccess(action.ids));
            yield put(cartActions.resaveCart());
        }
    } catch (error) {
        const data = { loggedInUser, ids: action.ids };
        const text = 'Error in removeUserPantry saga';
        errorNotification(error, data, text);
        yield put(actions.removeUserPantryFailure(error));
    }
}

export function* updateUserPantry(action) {
    const loggedInUser = yield selectLoggedInUser();
    try {
        if (loggedInUser) {
            const token = Cookies.get('token');
            const headers = {
                Authorization: token,
            };
            const data = {
                items: prepareDataUserPantry(action.ingredients) || [],
            };
            yield call(serverAPI.updateUserPantry, headers, data);
            yield put(actions.updateUserPantrySuccess());
            yield put(cartActions.resaveCart());
        }
    } catch (error) {
        const data = { ingredients: action.ingredients };
        const text = 'Error in updateUserPantry saga';
        errorNotification(error, data, text);
        yield put(actions.updateUserPantryFailure(error));
    }
}

export function* getGroceries() {
    try {
        const { data } = yield call(cartAPI.get, true);
        if (data) {
            yield put(actionsRecipe.getGroceriesSuccess(data));
        }
    } catch (error) {
        const data = {};
        const text = 'Error in getGroceries saga';
        errorNotification(error, data, text);
    }
}

export default function*() {
    yield takeLatest(constants.GET_USER_PANTRY_REQUEST, getUserPantryRequest);
    yield takeLatest(constants.ADD_USER_PANTRY_REQUEST, addUserPantry);
    yield takeLatest(constants.REMOVE_USER_PANTRY_REQUEST, removeUserPantry);
    yield takeLatest(constants.UPDATE_USER_PANTRY_REQUEST, updateUserPantry);
    yield takeLatest([constants.GET_GROCERIES_REQUEST], getGroceries);
}
