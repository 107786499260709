const isSlackNotificationsOn = process.env.REACT_APP_SLACK_NOTIFICATIONS_ENABLED === 'true';

import slack from 'slack';
import take from 'lodash/take';
import pick from 'lodash/pick';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import truncate from 'lodash/truncate';
import isString from 'lodash/isString';
import { sendMessage } from 'services/slack.service';
import { getUserActivityLogs, resetUserActivityLogs } from './trackUserActivity';
import store from 'store/store';
import get from 'lodash/get';
import { useState } from 'react';

const generateBlocks = (text, data) => {
    if (!data) return [];

    if ((isArray(data) || isString(data)) && data.length) {
        return [createHeader(text), createSection(data)];
    } else if (isObject(data) && Object.keys(data).length) {
        return [createHeader(text), createSectionWithFields(data)];
    }
    return [];
};

const generateText = (text, length = 400) =>
    truncate(isString(text) ? text : JSON.stringify(text), {
        length,
    });

const createSectionWithFields = (data) => ({
    type: 'section',
    fields: take(Object.keys(data), 10).map((field) => ({
        type: 'mrkdwn',
        text: `*${field}* \n ${generateText(data[field])}`,
    })),
});

const createHeader = (text) => ({
    type: 'header',
    text: {
        type: 'plain_text',
        text: generateText(text, 150),
    },
});

const createSection = (text) => ({
    type: 'section',
    text: {
        type: 'plain_text',
        text: generateText(text),
    },
});

const insertEnvBlock = () =>
    createSectionWithFields({
        appEnvironment: process.env.REACT_APP_ENV,
    });

const insertPageBlock = () =>
    createSectionWithFields({
        page: window.location.href,
    });

const insertSourceBlock = () =>
    createSectionWithFields({
        appSource: 'USER_APP',
    });

/**
 *
 * @param text The title of the message
 * @param channel If the targe slack channel has to be modified
 * @param data An object with relevant information to be logged
 */
const pushMessage = async ({ text, channel, data }) => {
    if (!isSlackNotificationsOn) return;

    let blocks = [createHeader(text), ...generateBlocks('Info', data), insertEnvBlock()];

    slack.chat.postMessage({
        token: slackAuthToken,
        channel,
        text,
        icon_url: 'https://easierchef.com/android-icon-36x36.png',
        blocks,
    });
};

const formatUserLogs = (logs) => {
    if (!logs) return [];
    const textLogs = [];
    logs.forEach((log) => log.type === 'action' && textLogs.push(log.value));
    return textLogs;
};

/**
 *
 * @param text The title of the message
 * @param error The error object caught in try/catch
 * @param channel If the targe slack channel has to be modified
 * @param data An object with relevant information to be logged
 */
export const pushError = async ({ text, error, payload, data }) => {
    console.log(text, error, data);

    const state = store.getState();
    const {
        userReducer: { userDetails },
    } = state;

    const config = JSON.parse(localStorage.getItem('config'));
    const getUserFromCheckout = JSON.parse(localStorage.getItem('user'));
    const userId = localStorage.getItem('guestUserId');

    let userData = {
        FirstName: get(userDetails, 'firstName', 'N/A'),
        LastName: get(userDetails, 'lastName', 'N/A'),
        EmailId: get(userDetails, 'emailAddress', 'N/A'),
        id: get(userDetails, '_id', 'N/A'),
        IP: get(config, 'ip', ''),
        City: get(config, 'city', ''),
        Country: get(config, 'country', ''),
        NameFromCheckout: get(getUserFromCheckout, 'customerName', 'N/A'),
        EmailFromCheckout: get(getUserFromCheckout, 'emailAddress', 'N/A'),
        GuestUserId: get(userId, 'guestUserId', 'N/A'),
    };

    if (!isSlackNotificationsOn) return;
    if (window.navigator.userAgent.includes('GoogleBot') || window.navigator.userAgent.includes('Googlebot')) return;
    data = { ...data, Browser: window.navigator.userAgent };
    const userLogs = formatUserLogs(getUserActivityLogs());
    resetUserActivityLogs();
    let textFormatted = error && error.message ? `${text}: ${error.message}` : text;

    let info = {
        appEnvironment: process.env.REACT_APP_ENV,
        appSource: 'USER_APP',
    };
    if (data && isObject(data)) {
        info = {
            ...info,
            ...data,
        };
    }
    let blocks = [createHeader(text), createSection(textFormatted), insertPageBlock(), ...generateBlocks('Info', info)];
    if (error) {
        blocks = [
            ...blocks,
            ...generateBlocks('Error Info', pick(error, ['response.data.error', 'response.statusText', 'response.status'])),
            ...generateBlocks('Stack trace', error && error.stack),
            ...generateBlocks('User logs', userLogs),
            ...generateBlocks('User Info', userData),
        ];
    }
    if (payload) {
        blocks = [...blocks, ...generateBlocks('Payload', payload)];
    }
    return sendMessage({
        text: textFormatted,
        icon_url: 'https://easierchef.com/android-icon-36x36.png',
        blocks,
    });
};
