function getGroceryByBrandType(grocery, brandType) {
    let assignedProduct = grocery.assignedProducts.find((product) => product.brandType === brandType);
    if (!assignedProduct) {
        assignedProduct = grocery.assignedProducts.find((product) => product.brandType === 'LOW_COST');
    }
    return {
        id: grocery._id,
        assignedProductId: assignedProduct._id,
        image: assignedProduct.image.url,
        name: grocery.name,
        price: assignedProduct.price,
        productName: assignedProduct.name,
        productsAmount: grocery.productsAmount ? grocery.productsAmount : 1,
        quantity: grocery.quantity,
        baseMeasurableUnits: grocery.baseMeasurableUnits,
        recipes: grocery.recipes,
        required: grocery.required,
        singleIngredientAmount: grocery.singleIngredientAmount,
        units: assignedProduct.units,
        requiredToUser: grocery.requiredToUser,
        fromUserPantry: grocery.fromUserPantry,
        ingredientAmountDelivered: grocery.ingredientAmountDelivered,
        totalIngredientAmountRequired: grocery.totalIngredientAmountRequired,
        usagePercentage: grocery.usagePercentage,
        leftOverQuantity: grocery.leftOverQuantity,
        availableQuantity: grocery.availableQuantity,
        youHave: grocery.youHave,
        youNeed: grocery.youNeed,
        youNeedQuantity: grocery.youNeedQuantity,
    };
}

function roundValue(value) {
    if (value < 10) {
        return value.toFixed(2);
    } else {
        return value.toFixed(0);
    }
}

// get a single ingredient substitute
const getIngredientSubstitutes = (ingredientId, substitutes) => {
    const ingredient = substitutes.find(({ ingredient }) => ingredient === ingredientId);
    if (ingredient && ingredient.substitutes.length > 0) {
        return ingredient.substitutes;
    }
};

// prepare ingredient substitute option for dropdown
const prepareIngredientSubstitutes = (substitutes = [], ingredientSubstitute, brandType, originalIngredient) => {
    const options = [];
    if (originalIngredient) {
        options.push({
            label: originalIngredient.name,
            value: originalIngredient.id,
            subtitle: 'Original',
        });
    }
    substitutes.forEach(({ availability, ingredient }) => {
        const ing = getGroceryByBrandType(availability[0], brandType);
        if (ingredient != (ingredientSubstitute && ingredientSubstitute.id)) {
            options.push({
                label: ing.name,
                value: ingredient,
            });
        }
    });

    return options;
};

//prepare list for all the available substitute from ingredient to cost list
const prepareIngredientSubstituteList = (ingredientsToCost = []) => {
    const substituteList = [];
    ingredientsToCost.map((i) => {
        if (i.isSubstitute) {
            substituteList.push({
                ingredient: i.originalIngredient,
                isSubstituteEnabled: true,
                substitute: {
                    ingredient: i.ingredient,
                },
            });
        }
    });
    return substituteList || [];
};

module.exports = {
    getGroceryByBrandType,
    roundValue,
    getIngredientSubstitutes,
    prepareIngredientSubstitutes,
    prepareIngredientSubstituteList,
};
