import keyBy from 'lodash/keyBy';
import * as constants from '../constants';
const initialState = {
    isFetched: false,
    isFetching: false,
    mealTypes: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case constants.GET_MEAL_TYPES_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case constants.GET_MEAL_TYPES_SUCCESS:
            return {
                ...state,
                isFetching: false,
                isFetched: true,
                mealTypes: keyBy(action.mealTypes, '_id'),
            };
        default:
            return state;
    }
}
