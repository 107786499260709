import React, { useEffect, useState } from 'react';
import FacebookLogin from 'react-facebook-login';
import { useDispatch, useSelector } from 'react-redux';
import history from '../../../history';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import 'yup-phone';
import get from 'lodash/get';
import { pushToGTM } from 'helpers/dataLayer';
import { Events } from 'constants/events';
import { identifyUser } from 'helpers/trackEvents';
import useScreenType from 'hooks/useScreenType';

import Input from 'components/common/Fields/Input';
import { Button } from '@material-ui/core';
import Icon from 'components/common/Icons/Icons';
import Cookies from 'js-cookie';

import { pushLearnqEvent } from 'helpers/trackEvents';

import { loginGoogle, loginFacebook, register } from 'store/actions/authentication.actions';
import { getLoginInfoByEmail } from 'store/actions/loginModal.action';
import useFeatureFlags from 'hooks/useFeatureFlags';

import styles from './signup.module.scss';

const appId = process.env.REACT_APP_FACEBOOK_APP_ID;

const Signup = ({ selectedLoginMode, emailInPopup, isFetched, accountInfo, chnageMode = () => {} }) => {
    const dispatch = useDispatch();
    const { isMobile } = useScreenType();

    const { loading, loggedInUser, registerSuccess } = useSelector((state) => state.authenReducer);
    const influencer = useSelector((state) => state.influencerReducer.handle);
    const isDefaultInfluencer = useSelector((state) => state.influencerReducer.isDefaultInfluencer);
    const deliveryAddress = useSelector((state) => state.recipeReducer.Cart.deliveryInfo);
    const influencerHandle = useSelector((state) => state.influencerReducer.handle);

    const onSuccess = () => {
        dispatch(loginGoogle());
    };
    const { isFacebookLoginEnabled } = useFeatureFlags();

    const responseFacebook = (response) => {
        if (response.status !== 'unknown') {
            dispatch(loginFacebook(response));
        }
    };

    const handleClickContinue = (email) => {
        dispatch(getLoginInfoByEmail(email));
        pushLearnqEvent(Events.EVENT_INITIALIZE_USER, { email }, influencerHandle);
    };
    const phoneRegex = RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);
    const pickupFormSchema = Yup.object().shape({
        firstName: Yup.string().required('Required'),
        lastName: Yup.string().required('Required'),
        email: Yup.string()
            .email('Invalid email')
            .required('Required'),
        password: Yup.string()
            .required('required')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W|_/g])(?=.{8,})/,
                'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
            ),
        retypePass: Yup.string()
            .required('required')
            .oneOf([Yup.ref('password')], `Pass Don't match!`),
    });
    const pickupForm = useFormik({
        initialValues: {
            firstName: get(deliveryAddress, 'firstName', ''),
            lastName: get(deliveryAddress, 'lastName', ''),
            email: emailInPopup ? emailInPopup : get(deliveryAddress, 'emailAddress', ''),
            phoneNumber: get(deliveryAddress, 'phoneNumber', ''),
            password: '',
            retypePass: '',
        },
        onSubmit: (values) => {
            const pickupFormSubmit = {
                firstName: values.firstName,
                lastName: values.lastName,
                emailAddress: values.email.toLowerCase(),
                username: values.email.toLowerCase(), // use email for now
                phoneNumber: values.phoneNumber,
                password: values.password,
            };
            dispatch(register(pickupFormSubmit));
            localStorage.setItem(
                'user',
                JSON.stringify({
                    email: values.email.toLowerCase(),
                }),
            );
        },
        validationSchema: pickupFormSchema,
        isInitialValid: false,
        validateOnChange: true,
    });

    useEffect(() => {
        if (registerSuccess) {
            pushToGTM({
                event: Events.EVENT_SIGNUP,
                email: pickupForm.values.email,
            });
            identifyUser({
                username: pickupForm.values.email,
                firstName: pickupForm.values.firstName,
                lastName: pickupForm.values.lastName,
            });
            if (loggedInUser) {
                const token = Cookies.get('token');
                if (token) {
                    const user = jwt_decode(token);
                    pushToGTM({
                        event: Events.EVENT_LOGIN,
                        email: user.username,
                    });
                    identifyUser(null, influencer);
                }
            }
            history.push('/groceries');
        }
    }, [loggedInUser, registerSuccess]);

    const registerContent = (
        <div>
            <form onSubmit={pickupForm.handleSubmit}>
                <div className={styles.fields}>
                    <div className={styles.title}>Get started now</div>
                    <div className={styles.fieldGroup}>
                        <Input
                            placeHolder="First Name"
                            id="firstName"
                            name="firstName"
                            value={pickupForm.values.firstName || ''}
                            error={pickupForm.touched.firstName && pickupForm.errors.firstName}
                            onChange={pickupForm.handleChange}
                            onBlur={() => pickupForm.setFieldTouched('firstName', true)}
                        />
                        <Input
                            placeHolder="Last Name"
                            id="lastName"
                            name="lastName"
                            value={pickupForm.values.lastName || ''}
                            error={pickupForm.touched.lastName && pickupForm.errors.lastName}
                            onChange={pickupForm.handleChange}
                            onBlur={() => pickupForm.setFieldTouched('lastName', true)}
                        />
                    </div>
                    <Input
                        placeHolder="Email"
                        id="email"
                        name="email"
                        value={pickupForm.values.email || ''}
                        error={pickupForm.touched.email && pickupForm.errors.email}
                        onChange={pickupForm.handleChange}
                        onBlur={() => pickupForm.setFieldTouched('email', true)}
                    />
                    <div className={styles.fieldGroup}>
                        <Input
                            placeHolder="Password"
                            id="password"
                            name="password"
                            type="password"
                            value={pickupForm.values.password || ''}
                            error={pickupForm.touched.password && pickupForm.errors.password}
                            onChange={pickupForm.handleChange}
                            onBlur={() => pickupForm.setFieldTouched('password', true)}
                        />
                        <Input
                            placeHolder="Retype Password"
                            id="retypePass"
                            name="retypePass"
                            type="password"
                            value={pickupForm.values.retypePass || ''}
                            error={pickupForm.touched.retypePass && pickupForm.errors.retypePass}
                            onChange={pickupForm.handleChange}
                            onBlur={() => pickupForm.setFieldTouched('retypePass', true)}
                        />
                    </div>
                </div>
                <div className={styles.subTitle}>
                    Already have an account?{' '}
                    <span
                        onClick={() => {
                            chnageMode('signin');
                        }}
                    >
                        Log In
                    </span>
                </div>
                <div className={styles.createButton}>
                    <Button type="submit" disabled={!pickupForm.isValid || loading} size="medium">
                        {loading ? 'Creating...' : 'Create Account'}
                    </Button>
                </div>
            </form>
            <div className={styles.divider}>
                <span className={styles.line} />
                <span className={styles.text}>or</span>
                <span className={styles.line} />
            </div>
            <div>
                <div className={styles.google}>
                    <button onClick={onSuccess}>
                        <Icon name="googlePlus" size="18" />
                        <span>Continue with Google</span>
                    </button>
                </div>
                {isFacebookLoginEnabled ? (
                    <div className={styles.facebook}>
                        <FacebookLogin
                            appId={appId}
                            textButton="Continue with Facebook"
                            fields="email,picture,first_name,last_name"
                            callback={responseFacebook}
                            icon="fa-facebook"
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
    const emailContent = (
        <div>
            <div className={styles.title}>Enter email & get started</div>
            <div className={styles.fields}>
                <Input
                    placeHolder="Email"
                    id="email"
                    name="email"
                    value={pickupForm.values.email || ''}
                    error={pickupForm.touched.email && pickupForm.errors.email}
                    onChange={pickupForm.handleChange}
                    onBlur={() => pickupForm.setFieldTouched('email', true)}
                />

                <div className={styles.agreement}>
                    By continuing, you agree to our <a href="/tos">Terms of Service</a> & <a href="/privacy">Privacy Policy.</a>
                </div>
                <div className={styles.createButton}>
                    <Button
                        onClick={() => handleClickContinue(pickupForm.values.email.toLowerCase())}
                        disabled={!pickupForm.values.email || (pickupForm.errors.email ? true : false) || loading}
                        size="medium"
                    >
                        Continue
                    </Button>
                </div>
            </div>
            <div className={styles.subTitle}>
                Already have an account?{' '}
                <span
                    onClick={() => {
                        chnageMode('signin');
                    }}
                >
                    Log In
                </span>
            </div>
            <div className={styles.divider}>
                <span className={styles.line} />
                <span className={styles.text}>or</span>
                <span className={styles.line} />
            </div>
            <div>
                <div className={styles.google}>
                    <button onClick={onSuccess}>
                        <Icon name="googlePlus" size="18" />
                        <span>Continue with Google</span>
                    </button>
                </div>
                {isFacebookLoginEnabled ? (
                    <div className={styles.facebook}>
                        <FacebookLogin
                            appId={appId}
                            textButton="Continue with Facebook"
                            fields="email,picture,first_name,last_name"
                            callback={responseFacebook}
                            icon="fa-facebook"
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );

    return <div>{selectedLoginMode === 'email' ? emailContent : registerContent}</div>;
};

export default Signup;
