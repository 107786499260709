import * as constants from '../constants';

const initialState = {
    isLoading: false,
    isLoaded: false,
    searchRecipes: [],
    search: '',
    searchHistory: localStorage.getItem('search') ? JSON.parse(localStorage.getItem('search')) : [],
    isUpdateSearch: false,
    categoriesSearch: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case constants.SET_RECIPES_SEARCH:
            let history = state.searchHistory;
            if (action.payload) {
                history = [action.payload, ...state.searchHistory];
            }
            localStorage.setItem('search', JSON.stringify(history) || []);

            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                search: action.payload,
                searchHistory: history,
                isUpdateSearch: true,
            };

        case constants.SEARCH_COMMON_INGREDIENTS:
            let historyCommon = state.searchHistory;
            if (action.value) {
                historyCommon = [action.value, ...state.searchHistory];
            }
            localStorage.setItem('search', JSON.stringify(historyCommon) || []);

            return {
                ...state,
                search: action.value,
                searchHistory: historyCommon,
            };

        case constants.GET_SEARCH_RESULT_SUCCESS:
            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                searchRecipes: action.data.recipes,
                isUpdateSearch: action.data.isUpdateSearch,
                categoriesSearch: action.data.categories,
            };
        case constants.CLEAR_IS_UPDATE_SEARCH_RECIPES: {
            return {
                ...state,
                isUpdateSearch: false,
            };
        }
        case constants.REMOVE_RECIPES_SEARCH:
            return {
                ...state,
                search: '',
            };
        default:
            return state;
    }
}
