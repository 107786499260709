import get from 'lodash/get';
import Cookies from 'js-cookie';

import * as constants from '../constants';

const token = Cookies.get('token');

const initialState = {
    loading: false,
    error: '',
    loggedInUser: Boolean(token),
    register_success: false,
    registerSuccess: false,
    reseting: false,
    reset_success: false,
    logout_success: false,
    sending_email_verification: false,
    send_email_verification_success: false,
    send_email_verification_failure: false,
    verifying_email: false,
    verify_email_success: false,
    verify_email_failure: false,
    resetSuccess: false,
    logoutSuccess: false,
    sendingLink: false,
    sendLinkSuccess: '',
    setPassword: {},
    setPasswordError: {},
    isLogining: false,
    loggedInToCustomerAccount: Boolean(localStorage.getItem('loggedInToCustomerAccount')),
};

export default function (state = initialState, action) {
    switch (action.type) {
        case constants.LOGIN_REQUEST:

        case constants.ADMIN_ACCESS_USER_ACCOUNT_REQUEST:

        case constants.REGISTER_REQUEST:
            return {
                ...state,
                loading: true,
                error: '',
                registerSuccess: false,
            };
        case constants.LOGIN_SUCCESS:
            const jwtToken = get(action, 'data.jwtToken');
            localStorage.removeItem('guestUserId');
            localStorage.setItem('isContentForLoggedInUser', true);
            if (jwtToken) {
                Cookies.set('token', jwtToken, { expires: 2 }); // 48 hours
                return {
                    ...state,
                    loading: false,
                    loggedInUser: true,
                    error: '',
                };
            }
            return state;

        case constants.ADMIN_ACCESS_USER_ACCOUNT_SUCCESS:
            const adminAccessJwtToken = get(action, 'data.jwtToken');
            localStorage.setItem('loggedInToCustomerAccount', true);
            if (adminAccessJwtToken) {
                Cookies.set('token', adminAccessJwtToken, { expires: 2 }); // 48 hours
                return {
                    ...state,
                    loading: false,
                    loggedInUser: true,
                    loggedInToCustomerAccount: action.data.loggedInToCustomerAccount,
                    error: '',
                };
            }
            return state;

        case constants.LOGIN_FAILURE:

        case constants.ADMIN_ACCESS_USER_ACCOUNT_FAILURE:

        case constants.REGISTER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case constants.REGISTER_SUCCESS:
            localStorage.removeItem('guestUserId');
            return {
                ...state,
                loading: false,
                registerSuccess: true,
                error: '',
            };
        case constants.RESETPASS_REQUEST:
            return {
                ...state,
                error: '',
                reseting: true,
            };
        case constants.RESETPASS_SUCCESS:
            return {
                ...state,
                reseting: false,
                error: '',
                resetSuccess: true,
            };
        case constants.RESETPASS_FAILURE:
            return {
                ...state,
                reseting: false,
                error: action.error,
            };
        case constants.LOGOUT_REQUEST:
            localStorage.removeItem('user');
            return {
                ...state,
                loading: true,
                error: '',
            };
        case constants.LOGOUT_SUCCESS:
            Cookies.remove('token');
            localStorage.removeItem('deliveryAddress');
            localStorage.removeItem('selectedAllergies');
            localStorage.removeItem('Cart');
            localStorage.removeItem('selectedExtraProducts');
            localStorage.removeItem('dietPlan');
            localStorage.removeItem('loggedInToCustomerAccount');
            return {
                ...state,
                loading: false,
                error: '',
                logoutSuccess: true,
                loggedInUser: false,
                loggedInToCustomerAccount: false,
            };
        case constants.LOGOUT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case constants.FORGOTPASS_REQUEST:
            return {
                ...state,
                sendingLink: true,
                error: '',
                sendLinkSuccess: '',
            };
        case constants.FORGOTPASS_SUCCESS:
            return {
                ...state,
                sendingLink: false,
                error: '',
                sendLinkSuccess: action.data,
            };
        case constants.FORGOTPASS_FAILURE:
            return {
                ...state,
                sendingLink: false,
                error: action.error,
            };
        case constants.SEND_EMAIL_VERIFICATION_REQUEST:
            return {
                ...state,
                sending_email_verification: true,
            };
        case constants.SEND_EMAIL_VERIFICATION_SUCCESS:
            return {
                ...state,
                sending_email_verification: false,
                send_email_verification_success: true,
            };
        case constants.SEND_EMAIL_VERIFICATION_FAILURE:
            return {
                ...state,
                sending_email_verification: false,
                send_email_verification_failure: true,
                error: action.error,
            };
        case constants.VERIFY_EMAIL_REQUEST:
            return {
                ...state,
                verifying_email: true,
            };
        case constants.VERIFY_EMAIL_SUCCESS:
            return {
                ...state,
                verifying_email: false,
                verify_email_success: true,
            };
        case constants.VERIFY_EMAIL_FAILURE:
            return {
                ...state,
                verifying_email: false,
                verify_email_failure: true,
                error: action.error,
            };
        case constants.SET_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case constants.SET_PASSWORD_SUCCESS:
            const passwordToken = get(action, 'data.jwtToken');
            if (passwordToken) {
                Cookies.set('token', passwordToken, { expires: 2 }); // 48 hours
                return {
                    ...state,
                    loading: false,
                    loggedInUser: true,
                    setPasswordError: {},
                    setPassword: action.data,
                };
            }
            return state;
        case constants.SET_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                setPasswordError: action.error,
            };
        case constants.UNAUTHORISED_USER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case constants.UNAUTHORISED_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                loggedInUser: false,
            };
        case constants.LOGIN_GOOGLE_REQUEST:
            return {
                ...state,
                loading: true,
                error: '',
            };
        case constants.LOGIN_GOOGLE_SUCCESS:
            const jwtTokenGoogle = get(action, 'data.jwtToken');
            localStorage.removeItem('guestUserId');
            localStorage.setItem('isContentForLoggedInUser', true);
            if (jwtTokenGoogle) {
                Cookies.set('token', jwtTokenGoogle, { expires: 2 }); // 48 hours
                return {
                    ...state,
                    loading: false,
                    loggedInUser: true,
                    error: '',
                };
            }
            return state;
        case constants.LOGIN_GOOGLE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case constants.LOGIN_FACEBOOK_REQUEST:
            return {
                ...state,
                loading: true,
                error: '',
            };
        case constants.LOGIN_FACEBOOK_SUCCESS:
            const jwtTokenFacebook = get(action, 'data.jwtToken');
            localStorage.removeItem('guestUserId');
            localStorage.setItem('isContentForLoggedInUser', true);
            if (jwtTokenFacebook) {
                Cookies.set('token', jwtTokenFacebook, { expires: 2 }); // 48 hours
                return {
                    ...state,
                    loading: false,
                    loggedInUser: true,
                    error: '',
                };
            }
            return state;
        case constants.LOGIN_FACEBOOK_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case constants.CLOSE_IS_LOGINING:
            return {
                ...state,
                isLogining: false,
            };
        case constants.CLEAR_STATE_REGISTER_SUCCESS:
            return {
                ...state,
                registerSuccess: false,
            };
        default:
            return state;
    }
}
