import styled, { css } from 'styled-components';

export const ContainerFluid = styled.div`
    ${(props) =>
        props.isHeader &&
        css`
            position: sticky;
            top: 0;
            z-index: 999;
            overflow-x: unset;
        `};

    ${(props) =>
        props.backgroundColor &&
        css`
            background: ${props.backgroundColor};
        `};
`;

export const ContainerInner = styled.div`
    margin: 0 auto;
    ${(props) =>
        !props.isHeader &&
        !props.isLandingPage &&
        props.isPadding &&
        css`
            // min-height: calc(100vh - 81px);
            // padding-bottom: 50px;
        `};

    ${({ minHeight }) =>
        minHeight &&
        css`
            min-height: ${minHeight};
        `};

    ${(props) =>
        props.isOnboarding &&
        css`
            padding: 0;
        `};

    ${(props) =>
        props.backgroundInner &&
        css`
            background: ${props.backgroundInner};
        `};

    ${(props) =>
        props.maxWidth &&
        css`
            max-width: ${props.maxWidth}px;
        `};

    ${(props) =>
        props.isRecipePage &&
        css`
            max-width: 1048px;
        `};
`;
