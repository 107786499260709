import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import useTheme from 'hooks/useTheme';
import useScreenType from 'hooks/useScreenType';
import get from 'lodash/get';

import Icons from '../Icons/Icons';
import { Input } from '../Fields';
import TypographyD from '../TypographyD/TypographyD';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';

import { checkPromoCode, setPromoCode as setPromoCodeAction } from 'store/actions/order.actions';
import { clearPromoCodeDiscount } from 'store/actions/order.actions';

import { PromoCodeWrapper } from './styles';

const PromoCode = ({
    isPromoCodeApplied,
    applyPromoCode,
    discount,
    promoValue,
    error,
    setPromoValue,
    clearDiscount,
    user,
    loggedInUser,
    isDiscountApply,
}) => {
    const influencerData = localStorage.getItem('influencer') && JSON.parse(localStorage.getItem('influencer'));

    const [promoCode, setPromoCode] = useState(get(influencerData, 'couponCode', ''));
    const [showPromoCode, setShowPromoCode] = useState(true);
    const [showDiscount, setShowDiscount] = useState(false);
    const color = useTheme();
    const { isMobile, isTabletOrMobile } = useScreenType();

    useEffect(() => {
        if ((!promoCode && user && user.isFirstOrder) || !loggedInUser) {
            setPromoCode('10OFF');
            // setPromoValue('10OFF');
        }
    }, []);

    useEffect(() => {
        if (discount > 0) {
            setShowDiscount(true);
        } else {
            setShowPromoCode(true);
            setShowDiscount(false);
        }
    }, [discount]);

    const promoApply = () => {
        if (promoCode) {
            setPromoValue(promoCode);
            applyPromoCode(promoCode);
        }
    };

    const renderAfterElement = () => {
        return (
            <button onClick={promoApply}>
                <TypographyD type="buttonMedium" color={error ? '#FF6B6B' : color.brandGreen}>
                    Apply
                </TypographyD>
            </button>
        );
    };

    return (
        <PromoCodeWrapper isMobile={isTabletOrMobile}>
            {showPromoCode && !showDiscount && (
                <TypographyD
                    type="bodyMedium"
                    fontWeight="400"
                    color={color.brandGreen}
                    onClick={() => {
                        setShowPromoCode(false);
                    }}
                    className="promo-text"
                >
                    Have any coupon code?
                </TypographyD>
            )}

            {!showPromoCode && !showDiscount && (
                <div className="promo-code-input">
                    <Input
                        value={promoCode}
                        placeHolder={'Enter coupon code'}
                        height={20}
                        error={error && 'Coupon code incorrect'}
                        onChange={(e) => {
                            setPromoCode(e.target.value);
                        }}
                        onKeyUp={(e) => {
                            if (e.key === 'Enter' && isTabletOrMobile) {
                                promoApply();
                            }
                        }}
                        afterEl={renderAfterElement()}
                    />
                </div>
            )}
            {showDiscount && (
                <div className="active-promo-code">
                    <div className="discount-info">
                        <TypographyD type="bodyMedium" fontWeight="400" color={color.gray1}>
                            Discount
                        </TypographyD>
                        <LocalOfferIcon />
                        <TypographyD type="bodyMedium" fontWeight="400" color={color.brandGreen}>
                            {(promoValue || promoCode || '').toUpperCase()}
                        </TypographyD>
                    </div>
                    <div className="close-icon">
                        <TypographyD type="helpMedium" fontWeight="400" color={color.brandGreen}>
                            {isDiscountApply ? '-' : 'You saved'} ${Number(discount).toFixed(2) || 0.0}
                        </TypographyD>
                        <Icons
                            name="close"
                            size="20"
                            color={color.grey2}
                            onClick={() => {
                                setPromoCode('');
                                setPromoValue('');
                                clearDiscount(true);
                                setShowDiscount(false);
                                setShowPromoCode(true);
                            }}
                        />
                    </div>
                </div>
            )}
        </PromoCodeWrapper>
    );
};

PromoCode.propTypes = {
    isPromoCodeApplied: PropTypes.bool.isRequired,
    applyPromoCode: PropTypes.func.isRequired,
    discount: PropTypes.number.isRequired,
    setPromoValue: PropTypes.func.isRequired,
    clearDiscount: PropTypes.func.isRequired,
    loggedInUser: PropTypes.bool.isRequired,
    user: PropTypes.object,
    isMobile: PropTypes.bool.isRequired,
    isDiscountApply: PropTypes.bool,
};

PromoCode.defaultProps = {
    discount: 0.0,
    isMobile: false,
};

const mapStateToProps = ({
    orderReducer: {
        error: { error },
        isPromoCodeApplied,
        promoCode,
    },
    recipeReducer: {
        Cart: {
            pricePayload: { promoDiscount },
        },
    },
    userReducer: { userDetails: user },
    authenReducer: { loggedInUser },
}) => ({
    isPromoCodeApplied,
    discount: promoDiscount,
    promoValue: promoCode,
    loggedInUser,
    user,
    error,
});

export default connect(mapStateToProps, {
    applyPromoCode: checkPromoCode,
    setPromoValue: setPromoCodeAction,
    clearDiscount: clearPromoCodeDiscount,
})(PromoCode);
