import styled, { css } from 'styled-components';

export const IngredientManage = styled.div`
    ${(props) =>
        props.isRemoved &&
        css`
            opacity: 0.4;
        `};

    ${(props) =>
        props.isMobile &&
        css`
            padding: 12px;
            box-sizing: border-box;
            border-radius: 8px;
            border: 1px solid #e5eaed;
        `};
`;

export const IngredientContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const IngredientInfo = styled.div`
    display: flex;
    align-items: center;
    width: 35%;

    ${(props) =>
        props.isMobile &&
        css`
            width: 50%;
        `};
`;

export const Image = styled.div`
    width: 88px;
    height: 56px;
    background: url(${({ image }) => image}) #d8d8d8 no-repeat center center/cover;
    border-radius: 8px;

    ${(props) =>
        props.isMobile &&
        css`
            width: 32px;
            height: 32px;
            border-radius: 4px;
        `};
`;

export const IngredientName = styled.div`
    padding: 0 24px;

    ${(props) =>
        props.isMobile &&
        css`
            padding: 0 8px;
        `};
`;

export const Name = styled.div`
    color: #233747;
    font-weight: 500;
    font-size: 16px;
    line-height: 124%;
    padding-bottom: 2px;

    ${(props) =>
        props.isMobile &&
        css`
            font-size: 14px;
            line-height: 146%;
        `};
`;

export const DatePurchase = styled.div`
    color: #909da6;
    letter-spacing: -0.4px;
    font-size: 14px;
    line-height: 146%;

    & > span {
        color: #233747;
    }
`;

export const CustomIngredient = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 65%;
    position: relative;
    padding-right: 100px;

    ${(props) =>
        props.isMobile &&
        css`
            width: 50%;
            padding-right: 50px;
        `};
`;

export const GrocerySize = styled.div`
    display: flex;
    align-items: center;
    width: 50%;
    justify-content: center;

    ${(props) =>
        props.isEdit &&
        css`
            padding: 14px 0;
            margin-top: 14px;
            border-top: 1px solid #e5eaed;
        `};
`;

export const ButtonMinus = styled.div`
    padding-right: 16px;
    cursor: pointer;
    color: #82bd73;

    ${(props) =>
        props.disabled &&
        css`
            cursor: unset;
            color: #bfc8cd;
        `};
`;

export const Quantity = styled.div`
    border: 1px solid #e5eaed;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    color: #233747;
    letter-spacing: -0.4px;
    font-size: 14px;
    line-height: 146%;
    width: 148px;
    text-align: center;
    align-items: center;

    & > input {
        border: none;
        background: none;
        text-align: center;
        min-width: 56px;
        width: 100%;
        padding: 8px 16px;
    }

    & > div {
        background: #eff5f9;
        padding: 8px;
        border-radius: 0 8px 8px 0;
        min-width: 60px;
    }

    ${(props) =>
        props.isEdit &&
        css`
            width: 100%;
        `};
`;

export const ButtonPlus = styled.div`
    padding-left: 16px;
    cursor: pointer;
    color: #82bd73;

    ${(props) =>
        props.disabled &&
        css`
            cursor: unset;
            color: #bfc8cd;
        `};
`;

export const Progress = styled.div`
    display: flex;
    align-items: center;
    min-width: 278px;
    padding: 0 10px;
    width: 50%;
    justify-content: center;

    & > div:nth-child(1) > div {
        display: flex;
        align-items: center;
    }

    ${(props) =>
        props.isMobile &&
        css`
            justify-content: space-between;
            width: 100%;
            min-width: unset;

            & > div:nth-child(1) > div {
                display: block;
            }
        `};
`;

export const ProgressBar = styled.div`
    width: 94px;
    height: 2px;
    background: #e5eaed;
    border-radius: 4px;
    margin-right: 16px;

    & > div {
        width: 84px;
        height: 2px;
        background: #ff6b6b;
        border-radius: 4px;
    }

    ${(props) =>
        props.isMobile &&
        css`
            margin-bottom: 8px;
            font-size: 12px;
            line-height: 150%;
        `};
`;

export const DayLeft = styled.div`
    padding-right: 10px;
    color: #233747;
    letter-spacing: -0.4px;
    font-size: 14px;
    line-height: 146%;
    display: flex;
    align-items: center;

    ${(props) =>
        props.isMobile &&
        css`
            padding: 0;
            font-size: 12px;
            line-height: 150%;
        `};
`;

export const ButtonEdit = styled.div`
    color: #82bd73;
    cursor: pointer;

    ${(props) =>
        !props.isMobile &&
        props.isEdit &&
        css`
            align-self: flex-start;
            padding-left: 10px;
        `};

    ${(props) =>
        props.isMobile &&
        css`
            color: #aab5bc;
        `};

    ${(props) =>
        props.disabled &&
        css`
            cursor: unset;
            color: #bfc8cd;
        `};
`;

export const ButtonRemove = styled.div`
    color: #aab5bc;
    cursor: pointer;
`;

export const CustomDate = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div:nth-child(1) {
        color: #233747;
        letter-spacing: -0.4px;
        font-size: 14px;
        line-height: 146%;
    }
`;

export const Calendar = styled.div`
    padding-left: 10px;

    & > div {
        width: 16px;
        height: 16px;
        position: relative;

        & > div:nth-child(1) {
            height: 100%;

            input {
                height: 100%;
                color: #233747;
                font-size: 16px;
                border: none;
                opacity: 0;
                padding: 0;
                cursor: pointer;
            }

            & > div > div {
                width: 365px;
                left: unset;
                right: 0;
                bottom: 20px;
                border: 1px solid #ebebeb;
                & > div > div > div:nth-child(2) {
                    padding: 20px 43px 0px 43px;
                }

                ${(props) =>
                    props.isMobile &&
                    css`
                        width: 300px;
                        bottom: 15px;

                        & > div > div > div:nth-child(2) {
                            padding: 10px 20px;
                        }
                    `};
            }
        }

        & > div:nth-child(2) {
            top: 13px;
            right: 12px;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 0;
        }
    }
`;

export const ButtonGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    right: 0;

    ${(props) =>
        props.isMobile &&
        css`
            min-width: unset;
        `};
`;

export const InputContainer = styled.div`
    position: relative;
    width: ${(props) => (props.isMobile ? '100%' : '175px')};
    & > div:nth-child(1) {
        width: 100%;
        height: 56px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        cursor: pointer;
        input {
            width: 100%;
            height: 56px;
            padding: 16px 22px;
            opacity: ${(props) => (props.disabled ? '0.3' : '1')};
            pointer-events: ${(props) => props.disabled && 'none'};
            background: #ffff;
            border-radius: 8px;
            color: #233747;
            display: flex;
            padding: 16px 24px 16px 20px;
        }
    }

    & > div:nth-child(2) {
        position: absolute;
        top: 22px;
        right: 20px;
    }
    .DayPickerInput-OverlayWrapper {
        width: 100%;
    }
    .DayPickerInput-Overlay {
        width: ${(props) => (props.isMobile ? '100%' : '365px')};
        bottom: calc(100% + 56px);
        background: #ffffff;
        box-sizing: border-box;
        box-shadow: 0px -8px 18px rgb(13 33 32 / 3%);
        border-radius: 12px;
    }
    .DayPicker {
        width: 100%;
    }
    .DayPicker-wrapper {
        width: ${(props) => (props.isMobile ? '100%' : '365px')};
        ${(props) =>
            props.isMobile &&
            css`
                padding-bottom: 0;
            `};
    }
    .DayPicker-Body {
        font-family: Work Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 146%;
        letter-spacing: -0.4px;
        color: #666666;
    }
    .DayPicker-Months {
        padding: 24px 43px 33px 43px;
    }
    .DayPicker-Month {
        width: 100%;
        margin: unset;
    }
    .DayPicker-Day {
        height: 30px;
        width: 30px;
        padding: 10px 6px;
        @media (max-width: 1024px) {
            padding: 10px 11px;
        }
        @media (max-width: 425px) {
            padding: 10px;
        }
        @media (max-width: 375px) {
            padding: 10px 12px;
        }
    }
    .DayPicker-Day--today {
        font-weight: 500;
        color: #0d2120;
    }
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
        position: relative;
        color: #f0f8ff;
        background: #82bd73;
    }
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
        background: #82bd73;
    }
`;
