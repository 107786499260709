import * as constants from '../constants';
import get from 'lodash/get';

const onboarding = localStorage.getItem('onboarding') ? JSON.parse(localStorage.getItem('onboarding')) : {};

const initialState = {
    selectedAllergies: localStorage.getItem('selectedAllergies') ? JSON.parse(localStorage.getItem('selectedAllergies')) : [],
    isUpdatedAllergies: false,
    allergyList: [],
    isLoadedAllergyList: false,
    loading: false,
    error: '',
    isAllergiesFetched: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case constants.FETCHING_ALLERGY_REQUEST:
            return {
                ...state,
                loading: true,
                error: '',
            };
        case constants.GET_ALLERGY_SUCCESS:
            return {
                ...state,
                loading: false,
                allergyList: action.data.allergies,
                isLoadedAllergyList: true,
                isAllergiesFetched: true,
            };
        case constants.GET_ALLERGY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case constants.GET_USER_SUCCESS:
            const isUpdating = get(onboarding, 'isUpdating', false);
            const steps = get(onboarding, 'steps', []);
            let allergies = [];
            if (isUpdating) {
                allergies = get(steps[2], 'info.allergies', []);
            } else {
                allergies = get(action.data, 'shopperInfo.allergies', []);
            }

            localStorage.setItem('selectedAllergies', JSON.stringify(allergies));
            return {
                ...state,
                selectedAllergies: allergies,
            };

        case constants.EDIT_USER_SUCCESS:
            return {
                ...state,
                selectedAllergies: localStorage.getItem('selectedAllergies')
                    ? JSON.parse(localStorage.getItem('selectedAllergies'))
                    : [],
            };

        case constants.ADD_ALLERGY: {
            localStorage.setItem('selectedAllergies', JSON.stringify([...state.selectedAllergies, action.allergyId]));
            return {
                ...state,
                selectedAllergies: [...state.selectedAllergies, action.allergyId],
                isUpdatedAllergies: true,
            };
        }

        case constants.UPDATED_PAYLOAD_ONBOARDING_SUCCESS: {
            const steps = get(onboarding, 'steps', []);
            return {
                ...state,
                selectedAllergies: get(steps[2], 'info.allergies', []),
            };
        }

        case constants.DELETE_ALLERGY: {
            localStorage.setItem(
                'selectedAllergies',
                JSON.stringify(state.selectedAllergies.filter((allergyId) => allergyId !== action.allergyId)),
            );
            return {
                ...state,
                selectedAllergies: state.selectedAllergies.filter((allergyId) => allergyId !== action.allergyId),
                isUpdatedAllergies: true,
            };
        }
        case constants.SELECT_ALLERGY:
            return {
                ...state,
                selectedAllergies: action.allergies,
                isUpdatedAllergies: true,
            };
        case constants.FILTER_RECIPES:
            return {
                ...state,
            };
        case constants.CLEAR_FLASH_IS_UPDATED_ALLERGIES:
            return {
                ...state,
                isUpdatedAllergies: false,
            };
        default:
            return state;
    }
}
