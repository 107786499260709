import React, { useEffect, useState } from 'react';
import useTheme from 'hooks/useTheme';
import PropTypes from 'prop-types';

import { ServingsContainer, Minus, Plus, Value } from './styles';
import Icons from '../../Icons/Icons';
import { useDebouncedCallback } from 'use-debounce';

import { increaseServings, decreaseServings } from 'utility/recipeUtil';

const Servings = ({
    value,
    recipe,
    changeServings,
    handleDeleteRecipe,
    defaultServings,
    getCommonIngredientsRequest,
    setServingsRecipe,
    mealTypeId,
}) => {
    const color = useTheme();
    const [totalServings, setTotalServings] = useState(recipe.customServings);
    useEffect(() => {
        setTotalServings(value);
    }, [value]);
    useEffect(() => {
        if (recipe.customServings) {
            setTotalServings(recipe.customServings);
        }
    }, [recipe.customServings]);
    // const [debouncedCallback] = useDebouncedCallback((value) => {
    //     if (value === 0) return handleDeleteRecipe(recipe._id, mealTypeId);
    //     setServingsRecipe(recipe._id, value, recipe.curatedBoxId ? recipe.curatedBoxId : undefined, mealTypeId);
    // }, 500);
    const handleChangeServings = (value) => {
        if (value === 0) {
            return handleDeleteRecipe(recipe._id, mealTypeId);
        }
        setTotalServings(value);
        setServingsRecipe(recipe._id, value, recipe.curatedBoxId ? recipe.curatedBoxId : undefined, mealTypeId);
    };
    return (
        <ServingsContainer>
            <Minus
                onClick={() => handleChangeServings(decreaseServings(totalServings, recipe.minServings, recipe.incrServings))}
                color={color}
            >
                {decreaseServings(totalServings, recipe.minServings, recipe.incrServings) ? (
                    <Icons name="minusCircle" size="24" />
                ) : (
                    <Icons name="trash" size="22" />
                )}
                {/* <Icons name="minusCircle" size="24" /> */}
            </Minus>
            <Value color={color}>x{totalServings}</Value>
            <Plus
                onClick={() => handleChangeServings(increaseServings(totalServings + 1, recipe.minServings, recipe.incrServings))}
            >
                <Icons name="plusCircle" size="24" />
            </Plus>
        </ServingsContainer>
    );
};

Servings.propTypes = {
    value: PropTypes.number.isRequired,
};
export default Servings;
