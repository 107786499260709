import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import GoogleLogin from 'react-google-login';
import { useFormik } from 'formik';
import FacebookLogin from 'react-facebook-login';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import * as Yup from 'yup';
import Input from 'components/common/Fields/Input';
import Icon from 'components/common/Icons/Icons';
import history from '../../../history';
import { Button } from '@material-ui/core';

import { login, loginGoogle, loginFacebook, clearStateRegisterSuccess, forgotPass } from 'store/actions/authentication.actions';
import { pushToGTM } from 'helpers/dataLayer';
import { Events } from 'constants/events';

import styles from './signup.module.scss';
import { identifyUser } from 'helpers/trackEvents';
import get from 'lodash/get';
import useFeatureFlags from 'hooks/useFeatureFlags';

const appId = process.env.REACT_APP_FACEBOOK_APP_ID;

const Signin = ({
    login,
    loading,
    error,
    loggedInUser,
    loginGoogle,
    loginFacebook,
    clearStateRegisterSuccess,
    forgotPass,
    chnageMode = () => {},
}) => {
    const [errorGG, setErrorGG] = useState();
    const influencer = useSelector((state) => state.influencerReducer.handle);
    const isDefaultInfluencer = useSelector((state) => state.influencerReducer.isDefaultInfluencer);
    const { emailInPopup, accountInfo } = useSelector((state) => state.loginModalReducer);
    const { isFacebookLoginEnabled } = useFeatureFlags();

    useEffect(() => {
        if (loggedInUser) {
            const token = Cookies.get('token');
            if (token) {
                const user = jwt_decode(token);
                pushToGTM({
                    event: Events.EVENT_LOGIN,
                    email: user.username,
                });
                identifyUser(null, influencer);
            }
            history.push('/groceries');
        }
    }, [loggedInUser]);

    const pickupFormSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Required'),
        password: Yup.string().required('Required'),
    });
    const pickupForm = useFormik({
        initialValues: {
            email: emailInPopup ? emailInPopup : get(JSON.parse(localStorage.getItem('user')), 'email', ''),
            password: '',
        },
        onSubmit: (values) => {
            const pickupFormSubmit = {
                email: values.email.toLowerCase(),
                password: values.password,
            };
            login(pickupFormSubmit);
        },
        validationSchema: pickupFormSchema,
        isInitialValid: false,
        validateOnChange: true,
    });

    const resetPassword = (e) => {
        e.preventDefault();
        if(pickupForm.values.email){
            forgotPass({ email: pickupForm.values.email.toLowerCase() });
        }
    }

    const onSuccess = (e) => {
        e.preventDefault();
        loginGoogle();
    };

    const onFailure = (res) => {
        if (res.error !== 'popup_closed_by_user') {
            setErrorGG(res.error);
        }
    };

    const responseFacebook = (response) => {
        if (response.status !== 'unknown') {
            loginFacebook(response);
        }
    };
   
    return (
        <div>
            <form onSubmit={pickupForm.handleSubmit}>
                {emailInPopup && accountInfo.hasAccount ? (
                    accountInfo.hasNormalLogin ||
                    (!accountInfo?.hasNormalLogin && !accountInfo?.hasFacebookLogin && !accountInfo?.hasGoogleLogin) ? (
                        <div className={styles.title}>Welcome back!</div>
                    ) : accountInfo.hasGoogleLogin ? (
                        <div className={styles.title}>Use your Google account to continue:</div>
                    ) : (
                        accountInfo.hasFacebookLogin && <div className={styles.title}>Use your Facebook account to continue:</div>
                    )
                ) : (
                    <div className={styles.title}>Sign in</div>
                )}
                {accountInfo?.hasNormalLogin === false &&
                    accountInfo?.hasGoogleLogin === false &&
                    accountInfo?.hasFacebookLogin === false && (
                        <>
                            <div className={styles.resetPasswordText}>Set your password to access your account.</div>
                            <div className={styles.createButton}>
                                <Button onClick={resetPassword}>Set Password</Button>
                            </div>
                            <div className={styles.divider}>
                                <span className={styles.line} />
                                <span className={styles.text}>or</span>
                                <span className={styles.line} />
                            </div>
                        </>
                    )}
                {(accountInfo?.hasNormalLogin || !accountInfo.hasAccount) && (
                    <>
                        <div className={styles.fields}>
                            <Input
                                placeHolder="Email"
                                id="name"
                                name="email"
                                value={pickupForm.values.email || ''}
                                error={pickupForm.touched.email && pickupForm.errors.email}
                                onChange={pickupForm.handleChange}
                                onBlur={() => pickupForm.setFieldTouched('email', true)}
                            />
                            <Input
                                placeHolder="Password"
                                id="password"
                                name="password"
                                type="password"
                                value={pickupForm.values.password || ''}
                                error={pickupForm.touched.password && pickupForm.errors.password}
                                onChange={pickupForm.handleChange}
                                onBlur={() => pickupForm.setFieldTouched('password', true)}
                            />
                        </div>
                        <div className={styles.createButton}>
                            <Button variant="contained" disabled={!pickupForm.isValid || loading} type="submit">
                                {loading ? 'Submiting...' : 'Submit'}
                            </Button>
                        </div>
                        <div className={styles.divider}>
                            <span className={styles.line} />
                            <span className={styles.text}>or</span>
                            <span className={styles.line} />
                        </div>
                    </>
                )}
                {!accountInfo?.hasFacebookLogin || accountInfo?.hasGoogleLogin ? (
                    <div>
                        <div className={styles.google}>
                            <button onClick={onSuccess}>
                                <Icon name="googlePlus" size="18" />
                                <span>Continue with Google</span>
                            </button>
                        </div>
                        {isFacebookLoginEnabled && (
                            <div className={styles.facebook}>
                                <FacebookLogin
                                    appId={appId}
                                    textButton="Continue with Facebook"
                                    fields="email,picture,first_name,last_name"
                                    callback={responseFacebook}
                                    icon="fa-facebook"
                                />
                            </div>
                        )}
                    </div>
                ) : (
                    <div>
                        {isFacebookLoginEnabled && (
                            <div className={styles.facebook}>
                                <FacebookLogin
                                    appId={appId}
                                    textButton="Continue with Facebook"
                                    fields="email,picture,first_name,last_name"
                                    callback={responseFacebook}
                                    icon="fa-facebook"
                                />
                            </div>
                        )}
                        <div className={styles.google}>
                            <button onClick={onSuccess}>
                                <Icon name="googlePlus" size="18" />
                                <span>Continue with Google</span>
                            </button>
                        </div>
                    </div>
                )}
                <div className={styles.forgot}>
                    <p className={styles.forgotText} onClick={() => history.push('/forgot-password')}>
                        Forgot your Password?
                    </p>
                </div>
                <div className={styles.newMember}>
                    New to EasierChef?{' '}
                    <strong
                        onClick={() => {
                            chnageMode('signup');
                        }}
                    >
                        Sign Up
                    </strong>
                </div>
            </form>
        </div>
    );
};
Signin.propTypes = {
    login: PropTypes.func.isRequired,
};
const mapStateToProps = ({ authenReducer: { loading, loggedInUser } }) => ({
    loading,
    loggedInUser,
});

export default connect(mapStateToProps, {
    login,
    loginGoogle,
    loginFacebook,
    clearStateRegisterSuccess,
    forgotPass,
})(Signin);
