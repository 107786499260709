import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
export const getPrimaryDeliveryAddress = (userDetails, countries) => {
    if (!userDetails) return;
    if (!isEmpty(countries) && !isEmpty(userDetails.addresses)) {
        return userDetails.addresses.find((address) => address.primary && address.country === countries[0]._id);
    }
    if (!isEmpty(userDetails.addresses)) {
        return userDetails.addresses.find((address) => address.primary);
    }
};

export const prepareUserDetailsState = (userDetails, countries) => {
    const primaryAddress = getPrimaryDeliveryAddress(userDetails, countries);
    return {
        firstName: get(userDetails, 'firstName', ''),
        lastName: get(userDetails, 'lastName', ''),
        emailAddress: get(userDetails, 'emailAddress', ''),
        city: get(primaryAddress, 'city', ''),
        province: get(primaryAddress, 'province', ''),
        postalCode: get(primaryAddress, 'postalCode', ''),
        lineOne: get(primaryAddress, 'lineOne', ''),
        lineTwo: get(primaryAddress, 'lineTwo', ''),
        optional: get(primaryAddress, 'optional', ''),
        deliveryInstructions: get(primaryAddress, 'deliveryInstructions', ''),
        phoneNumber: get(userDetails, 'phoneNumber', ''),
        isPasswordSet: get(userDetails, 'isPasswordSet', ''),
        country: get(countries, 'countryCode', ''),
        error: '',
    };
};

export const getAddressContent = (address, cities, states, countries) => {
    const { lineOne, lineTwo, province, postalCode, city } = address;
    if (city) {
        const selectedCity = cities.find(({ _id }) => _id === city);
        const selectedState = states.find(({ _id }) => _id === province);
        const cityName = (
            selectedCity || {
                name: '',
            }
        ).name;
        const stateName = (selectedState || { name: '' }).name;
        const countryName = !isEmpty(countries) ? countries[0].name : '';
        return `${lineOne} ${lineTwo || ''} ${cityName} ${stateName} ${countryName} ${postalCode}`;
    }
};
