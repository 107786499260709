import React, { useState } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import PropTypes from 'prop-types';
import 'react-day-picker/lib/style.css';
import Icons from '../Icons/Icons';
import { InputContainer } from './styles';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { ErrorMessage } from '../Fields/styles';
import useScreenType from 'hooks/useScreenType';
const CalendarInput = ({ onChange, disabled, value, name, error, placeholder, isActive }) => {
    const { isMobile } = useScreenType();
    return (
        <InputContainer disabled={disabled} isMobile={isMobile} isActive={isActive}>
            <DayPickerInput
                onDayChange={onChange}
                value={value}
                name={name}
                formatDate={formatDate}
                parseDate={parseDate}
                format="DD MMMM"
                placeholder={placeholder}
                dayPickerProps={{
                    modifiers: {
                        disabled: [{ before: new Date() }],
                    },
                }}
            />
            <Icons name={isActive ? 'calenderActive' : 'calender'} size="16" />
            {error && <ErrorMessage errorSize="12px">{error}</ErrorMessage>}
        </InputContainer>
    );
};

CalendarInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    disable: PropTypes.bool,
    name: PropTypes.string,
    error: PropTypes.string,
    placeholder: PropTypes.string,
};

CalendarInput.defaultProps = {
    disable: false,
    name: '',
    placeholder: 'Custom date',
    error: '',
};

export default CalendarInput;
