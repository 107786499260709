import * as constants from '../constants';

export const getIngredientsRequest = (query) => ({
    type: constants.GET_INGREDIENTS_REQUEST,
    payload: query,
});
export const getIngredientsSuccess = (data) => ({ type: constants.GET_INGREDIENTS_SUCCESS, data });
export const getIngredientsFailure = (error) => ({ type: constants.GET_INGREDIENTS_FAILURE, error });

export const selectIngredient = (ingredients) => ({ type: constants.SELECT_INGREDIENT, ingredients });

export const setManualIngredientSubstitute = (ingredientSubstitutes, isSubstitutionOn = false) => ({
    type: constants.SET_MANUAL_INGREDIENT_SUBSTITUTION,
    ingredientSubstitutes,
    isSubstitutionOn,
});
export const setManualIngredientSubstituteSuccess = (data) => ({
    type: constants.SET_MANUAL_INGREDIENT_SUBSTITUTION_SUCCESS,
    data,
});

export const setSystemDefineSubstitutes = (payload) => ({
    type: constants.SET_SYSTEM_DEFINE_SUSBTITUTES,
    payload,
});
