import * as constants from '../constants';

export const getRecipesRequest = (page = 0, forceCall = false) => ({ type: constants.GET_RECIPES_REQUEST, page, forceCall });
export const getRecipesSuccess = (data) => ({
    type: constants.GET_RECIPES_SUCCESS,
    data,
});
export const getRecipesFailure = (error) => ({ type: constants.GET_RECIPES_FAILURE, error });

export const searchRecipes = (value) => ({ type: constants.SET_RECIPES_SEARCH, payload: value });
export const getSearchRecipes = (data) => ({ type: constants.GET_SEARCH_RESULT_SUCCESS, data });

export const getRecipeCategories = (data) => ({ type: constants.GET_RECIPES_SUCCESS, data });

export const loadMoreRecipes = (forceCall = true) => ({ type: constants.LOAD_MORE_RECIPES, forceCall });
export const getCategoryWiseRecipes = (data) => ({ type: constants.GET_CATEGORY_WISE_RECIPES, data });
