import isEmpty from 'lodash/isEmpty';
import * as constants from '../store/constants';

const queryFilterRecipes = ({
    selectedCategories,
    selectedAllergies,
    diet,
    searchValue,
    isSelectedIngredients,
    excludeCategoryFilteres,
    pagination,
    influencer,
    isDefaultInfluencer,
    recipesFilter,
    filterInfo,
    recipeLimit,
    mealTypes,
    nutritionProgram,
    cookingTime,
    difficultyLevel,
}) => {
    let query = {
        status: constants.recipesStatus.PUBLISHED,
    };
    if (selectedCategories && !excludeCategoryFilteres) query.categories = JSON.stringify(selectedCategories);
    if (selectedAllergies) query.allergies = JSON.stringify(selectedAllergies);
    if (mealTypes) query.mealTypes = JSON.stringify(mealTypes);
    if (diet) {
        if (diet.toLowerCase() === 'everything' || diet.toLowerCase() === 'meat & veggies') {
            query.diet = '';
        } else {
            query.diet = diet;
        }
    }
    if (searchValue) query.search = searchValue;
    if (isSelectedIngredients) query.ingredients = isSelectedIngredients.join(',');
    if (pagination && pagination.nextPage > 0) {
        query.page = pagination.nextPage;
        query.limit = pagination.limit;
    }
    query.recipeLimit = recipeLimit;
    if (influencer) {
        query.influencerId = influencer._id;
    }
    query.isDefaultInfluencer = isDefaultInfluencer;
    if (cookingTime) {
        (query.cookingTimeMin = cookingTime.min), (query.cookingTimeMax = cookingTime.max);
    }
    if (difficultyLevel) {
        query.difficultyLevel = difficultyLevel;
    }
    if (nutritionProgram && nutritionProgram?.goalTitle) {
        query = checkNutritionPercentage(query, nutritionProgram.nutritionPercentage);
    }
    if (filterInfo && recipesFilter) query = checkNutrition(query, filterInfo, recipesFilter);
    return query;
};

const queryFilterCommonIngredients = (
    selectedCategories,
    selectedAllergies,
    searchValue,
    diet,
    influencer,
    recipesFilter,
    filterInfo,
    isSubstitutionOn,
) => {
    let query = {
        categories: JSON.stringify(selectedCategories),
        allergies: JSON.stringify(selectedAllergies),
        search: location.pathname.includes('use-pantry') ? searchValue : '',
        diet: diet === 'everything' ? '' : diet,
        influencerId: influencer && influencer._id,
        isSubstitutionOn,
    };
    return (query = checkNutrition(query, filterInfo, recipesFilter));
};

const checkNutrition = (query, filterInfo, recipesFilter) => {
    if (
        filterInfo.cookingTimeMax &&
        (filterInfo.cookingTimeMin !== recipesFilter.cookingTimeMin || filterInfo.cookingTimeMax !== recipesFilter.cookingTimeMax)
    ) {
        query.cookingTimeMin = filterInfo.cookingTimeMin;
        query.cookingTimeMax = filterInfo.cookingTimeMax;
    }
    if (
        filterInfo.proteinMax &&
        (filterInfo.proteinMin !== recipesFilter.proteinMin || filterInfo.proteinMax !== recipesFilter.proteinMax)
    ) {
        query.proteinMin = filterInfo.proteinMin;
        query.proteinMax = filterInfo.proteinMax;
    }

    if (
        filterInfo.carbohydratesMax &&
        (filterInfo.carbohydratesMin !== recipesFilter.carbohydratesMin ||
            filterInfo.carbohydratesMax !== recipesFilter.carbohydratesMax)
    ) {
        query.carbohydratesMin = filterInfo.carbohydratesMin;
        query.carbohydratesMax = filterInfo.carbohydratesMax;
    }

    if (
        filterInfo.caloriesMax &&
        (filterInfo.caloriesMin !== recipesFilter.caloriesMin || filterInfo.caloriesMax !== recipesFilter.caloriesMax)
    ) {
        query.caloriesMin = filterInfo.caloriesMin;
        query.caloriesMax = filterInfo.caloriesMax;
    }

    if (filterInfo.fatMax && (filterInfo.fatMin !== recipesFilter.fatMin || filterInfo.fatMax !== recipesFilter.fatMax)) {
        query.fatMin = filterInfo.fatMin;
        query.fatMax = filterInfo.fatMax;
    }

    return query;
};

const checkNutritionPercentage = (query, nutritionPercentage) => {
    if (nutritionPercentage.carbohydrates) {
        query.carbsMinDv = nutritionPercentage.carbohydrates.carbsMin;
        query.carbsMaxDv = nutritionPercentage.carbohydrates.carbsMax;
    }
    if (nutritionPercentage.protein) {
        query.proteinMinDv = nutritionPercentage.protein.proteinMin;
        query.proteinMaxDv = nutritionPercentage.protein.proteinMax;
    }
    if (nutritionPercentage.fat) {
        query.fatMinDv = nutritionPercentage.fat.fatMin;
        query.fatMaxDv = nutritionPercentage.fat.fatMax;
    }
    return query;
};

export { queryFilterRecipes, queryFilterCommonIngredients };
