import React from 'react';
import toast from 'react-hot-toast';
import styles from './Notification.module.scss';
import { IconButton } from '@material-ui/core';
import { CheckCircle, Close, Error, Info } from '@material-ui/icons';
import cn from 'classnames';

export const customNotification = (type, content, className = '', onClose) => {
    const handleClose = (id) => {
        toast.dismiss(id);
        if (onClose) {
            onClose();
        }
    };

    const defaultStructure = (t) => (
        <div className={styles.notificationContainer}>
            <div className={styles.notificationWrap}>
                <div className={styles.iconWrap}>{getIcon(type)}</div>
                <div className={styles.content}>
                    <div className={styles.title}>{type}</div>
                    <div className={styles.message}>{content}</div>
                </div>
            </div>
            <IconButton onClick={() => handleClose(t.id)}>
                <Close />
            </IconButton>
        </div>
    );

    return toast((t) => (type === 'custom' ? content : defaultStructure(t)), {
        className: cn(styles.notification, type === 'custom' ? className : styles[type]),
        duration: 60 * 60 * 2,
    });
};

function getIcon(type) {
    switch (type) {
        case 'success':
            return <CheckCircle className={styles.successIcon} />;
        case 'information':
            return <Info className={styles.infoIcon} />;
        case 'warning':
            return <Error className={styles.warningIcon} />;
        case 'error':
            return <Error className={styles.errorIcon} />;
        default:
            return <CheckCircle className={styles.successIcon} />;
    }
}
