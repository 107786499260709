import styled, { css } from 'styled-components';

export const DropdownServingsContainer = styled.div`
    position: relative;
    z-index: 999;
    width: ${(props) => props.width};
`;

export const DropdownContentContainer = styled.div`
    display: none;
    position: absolute;
    margin-top: 6px;
    box-shadow: 0px 4px 18px rgba(13, 33, 32, 0.1);
    border-radius: 14px;
    background: #ffffff;
    z-index: 100;
    ${(props) =>
        props.open &&
        css`
            display: block;
        `};
    ${(props) =>
        props.isFilterOrders &&
        css`
            min-width: ${(props) => props.width};
        `};

    .dropdown-item {
        display: flex;
        align-items: center;
        cursor: pointer;
        justify-content: ${(props) => props.align};
    }
`;

export const DropdownItem = styled.div`
    display: flex;
    justify-content: flex-start;
    padding: 16px 24px;
    font-size: ${(props) => props.fontSize};

    ${({ isSubtitle }) =>
        isSubtitle &&
        css`
            padding: 16px 0 16px 24px;
        `}

    ${({ changeOnHover }) =>
        changeOnHover &&
        css`
            &:hover {
                color: #82bd73;
            }
        `}

    ${(props) =>
        props.active
            ? css`
                  color: #82bd73;
              `
            : css`
                  color: ${props.color};
              `};
`;

export const DropdownTitle = styled.div`
    padding: 16px 24px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #82bd73;
    border-radius: 8px 8px 0 0;
    background-color: #ecf5f0;
`;

export const Subtitle = styled.div`
    font-size: 14px;
    color: #82bd73;
    margin: 0 24px 0 10px;
`;

export const Lines = styled.div`
    height: 0.5px;
    margin: 0 24px;

    ${(props) =>
        css`
            background: ${props.color.grey5};
        `};
`;

export const DropdownInput = styled.div`
    padding: 16px 24px;
    ${(props) =>
        css`
            & > div {
                display: flex;
                background: #eff5f9;
                padding: 12px 16px;
                border-radius: 8px;

                & > input {
                    max-width: 120px;
                    border: none;
                    background: #eff5f9;
                    font-size: 12px;
                    color: ${props.color.grey2};
                }
            }
        `}
    ${(props) =>
        props.disabled
            ? css`
                  & > div {
                      & > span {
                          pointer-events: none;
                          color: ${props.color.grey2};
                          font-size: 14px;
                          font-weight: 500;
                      }
                  }
              `
            : css`
                  & > div {
                      & > span {
                          color: #82bd73cf;
                          font-size: 14px;
                          font-weight: 500;
                          cursor: pointer;

                          &:hover {
                              color: #82bd73;
                          }
                      }
                  }
              `};
`;
