import * as constants from '../constants';

const initialState = {
    currentProgressStep: 0,
    nestedProgressStep: 0,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case constants.SET_CURRENT_PROGRESS_STEP:
            return {
                ...state,
                currentProgressStep: action.stepIndex,
                nestedProgressStep: action.nestedStepIndex,
            };

        default:
            return state;
    }
}
