import * as constants from '../constants';
const initialState = {
    loading: false,
    fetching: false,
    curatedRecipes: [],
    curatedRecipe: {},
    fetchedCuratedRecipes: false,
    pagination: {
        currentPage: 0,
        nextPage: 0,
        totalPage: 1,
        limit: 10,
    },
};
export default function (state = initialState, action) {
    switch (action.type) {
        case constants.GET_CURATED_RECIPES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case constants.GET_CURATED_RECIPES_SUCCESS:
            return {
                ...state,
                loading: false,
                curatedRecipes: action.data.result,
                pagination: action.data.pagination,
                fetchedCuratedRecipes: true,
            };
        case constants.GET_CURATED_RECIPES_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case constants.GET_CURATED_RECIPE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case constants.GET_CURATED_RECIPE_SUCCESS:
            return {
                ...state,
                loading: false,
                curatedRecipe: action.data,
            };
        case constants.GET_CURATED_RECIPE_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case constants.FETCHING_CURATED_RECIPES:
            return {
                ...state,
                fetching: true,
            };
        case constants.FETCHED_CURATED_RECIPES:
            return {
                ...state,
                fetching: false,
            };

        default:
            return state;
    }
}
