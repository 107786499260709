import styled, { css } from 'styled-components';
export const Header = styled.div`
    border-bottom: 0px;
    position: sticky;
    top: 0;
    z-index: 999;
    background: #ffff;
    & > div {
        display: flex;
        align-items: center;
        height: 80px;
        padding: 0 32px;
        ${({ backgroundColor }) =>
            backgroundColor &&
            css`
                background: ${backgroundColor};
            `}
        & > div {
            width: unset;
            margin: 0;
            & > div {
                display: flex;
                align-items: center;
                // padding: 0 !important;
                &:last-child {
                    margin-left: 15px;
                }
                @media (max-width: 1024px) {
                    padding: 0 !important;
                    &:last-child {
                        margin: 0;
                    }
                }
            }
            & > div:nth-child(2) {
                // padding: 0 !important;
                justify-content: ${({ isTabletOrMobile, headerCenter, open }) =>
                    open ? 'flex-end' : (isTabletOrMobile || headerCenter) && 'center'};
            }
            & > div:nth-child(3) {
                // padding: 0 !important;
                display: flex;
                justify-content: flex-end;
            }
        }
    }
`;
