import * as constants from '../constants';
import uniqBy from 'lodash/uniqBy';

const initialState = {
    loading: false,
    error: '',
    all: localStorage.getItem('categories') ? JSON.parse(localStorage.getItem('categories')) : [],
    selected: [],
    isUpdateCategories: false,
    isloadedCategories: false,
    excludeCategoryFilters: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case constants.GET_RECIPES_CATEGORIES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case constants.GET_RECIPES_CATEGORIES_SUCCESS: {
            return {
                ...state,
                all: uniqBy(action.recipesCategories, '_id'),
                loading: false,
                isloadedCategories: true,
            };
        }
        case constants.GET_RECIPES_CATEGORIES_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case constants.ADD_CATEGORY:
            return {
                ...state,
                selected: [...state.selected, action.categoryName],
                isUpdateCategories: true,
            };
        case constants.DELETE_CATEGORY:
            return {
                ...state,
                selected: state.selected.filter((category) => category !== action.categoryName),
                isUpdateCategories: true,
            };
        case constants.SELECT_CATEGORY:
            return {
                ...state,
                selected: action.categories,
                isUpdateCategories: true,
            };
        case constants.FILTER_RECIPES:
            return {
                ...state,
            };
        case constants.CLEAR_FLAGS_IS_UPDATE_CATEGORIES:
            return {
                ...state,
                isUpdateCategories: false,
            };
        case constants.SET_EXCLUDE_CATEGORY_FILTERS:
            return {
                ...state,
                excludeCategoryFilters: action.excludeCategoryFilters,
            };
        case constants.GET_FILTER_AVAILABLE: {
            return {
                ...state,
                all: action.filterAvailable,
            };
        }
        default:
            return state;
    }
}
