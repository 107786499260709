import styled, { css } from 'styled-components';

export const PageWrapper = styled.div`
    padding: 25px;
    > * {
        > * {
            padding: 50px;
            &:nth-child(2) {
                margin-bottom: 50px;
            }
        }
    }
`;

export const PrimaryButtonContainer = styled.button`
    display: flex;
    align-items: center;
    cursor: ${({ state }) => (state === 'Active' ? '' : 'pointer')};
    border: 1px solid #82bd73;
    border-radius: 8px;
    padding: 16px 20px;
    background: ${({ state }) => (state === 'Active' ? '#82bd73' : '#ffffff')};
    height: 50px;
    width: ${({ size }) => (size ? (size === 'medium' ? '228px' : '335px') : '100%')};
    justify-content: ${({ state }) => (state === 'Active' ? 'space-between' : 'center')};
    ${({ isMobile }) =>
        !isMobile &&
        css`
            &:hover {
                background: #82bd73;
                & > span {
                    color: #ffffff !important;
                }
            }
        `}
    ${({ color }) =>
        color &&
        `   background: ${color};
             & > span {
                    color: #ffffff !important;
                }
            border: none;
            &:hover {
                background: #F7BA50;
                & > span {
                    color: #ffffff !important;
                }
            }
        `}
    &:disabled {
        cursor: alias;
        opacity: 0.3;
        border: 1px solid #82bd73;
    }
`;

export const ServingsIcon = styled.span`
    display: ${({ state }) => (state === 'Active' ? 'block' : 'none')};
    color: #ffffff;
    cursor: pointer;
`;

export const Title = styled.span`
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: ${({ color }) => color};
`;

export const SecondaryButtonContainer = styled.button`
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    padding: ${({ size }) => size.padding};
    background: #82bd73;
    border-radius: 8px;
    border: none;
    color: #ffffff;
    font-size: ${({ size }) => size.fontSize};
    font-weight: 500;
    width: ${({ size }) => size.width};
    height: ${({ size }) => size.height};

    &:hover {
        background: #6dac5d;
    }
    &:focus {
        background: #6dac5d;
    }
    &:disabled {
        background: #82bd73;
        opacity: 0.3;
        cursor: alias;
    }
`;

export const TertiaryButtonContainer = styled.button`
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    padding: ${({ size }) => size.padding};
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #6dac5d;
    color: ${({ size }) => (size.color ? size.color : '#233747')};
    font-size: ${({ size }) => size.fontSize};
    font-weight: 500;
    width: ${({ size }) => size.width};
    height: ${({ size }) => size.height};

    &:hover {
        background: #82bd73;
        color: #ffffff;
    }
    &:disabled {
        opacity: 0.3;
        border: 1px solid #82bd73;
        cursor: alias;
        color: #233747;
        &:hover {
            background: transparent;
        }
    }

    ${({ isActive }) =>
        isActive &&
        css`
            background: #6dac5d;
            color: #ffffff;
        `};
`;

export const ButtonWithIconContainer = styled.button`
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
    padding: ${({ size }) => size.padding};
    background: #82bd73;
    border-radius: 8px;
    border: none;
    color: #ffffff;
    font-size: ${({ size }) => size.fontSize};
    font-weight: 500;
    width: ${({ size, width }) => (width ? width : size.width)};
    height: ${({ size }) => size.height};

    svg {
        width: 18px;
        height: 18px;
    }

    &:hover {
        background: #6dac5d;
    }
    &:focus {
        background: #6dac5d;
    }
    &:disabled {
        background: #82bd73;
        opacity: 0.3;
        cursor: alias;
    }
`;

export const PopupButton = styled.button`
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    border-radius: 88px;
    padding: 12px 20px;
    width: 100px;
    height: 44px;
    font-weight: normal;
    font-size: 14px;
    background: #ffffff;
    border: ${({ state }) => (state ? '1px solid #82bd73' : '1px solid lightgrey')};
    color: ${({ state }) => (state ? '#233747' : '#909da6')};

    &:hover {
        border: 1px solid #909da6;
    }
    &:focus {
        border: 1px solid #82bd73;
    }
    &:disabled {
        cursor: alias;
        opacity: 0.3;
        border: 1px solid #e5eaed;
    }
    ${({ state }) =>
        state &&
        css`
            background: rgba(0, 190, 130, 0.1);
        `}
`;

export const IconWrapper = styled.div`
    svg {
        width: ${({ size, width }) => width || size.width};
        height: ${({ size, height }) => height || size.height};
        opacity: ${({ disabled }) => disabled && 0.3};
        path {
            &:first-child {
                &:hover {
                    fill: ${({ colorObj }) => colorObj && colorObj.hover};
                }
            }
        }
    }
    .search-icon {
        border: 1px solid #bfc8cd;
        border-radius: 100%;
        height: 44px;
        width: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            height: 20px;
            width: 20px;
        }
        &:hover {
            border: 1px solid #82bd73;
            svg {
                path {
                    &:first-child {
                        &:hover {
                            fill: none;
                        }
                    }
                }
            }
        }
        &:focus {
            border: 1px solid #82bd73;
            background: rgba(130, 189, 115, 0.1);
            svg {
                path {
                    stroke: #0d2120;
                }
            }
        }
    }
`;

export const FavouriteIcon = styled.div`
    svg {
        opacity: ${({ disabled }) => disabled && 0.3};
        &:hover {
            path {
                fill: #233747;
                stroke: #233747;
            }
        }
    }
`;

export const CartWithItemsWrapper = styled.div`
    display: flex;
    background: #233747;
    border-radius: 52px;
    padding: 8px !important;
    align-items: center;
    cursor: pointer;
    span {
        margin-right: 2px;
        color: #fff;
    }

    .MuiIconButton-label > div {
        display: flex;
        align-items: center;
    }
    .MuiBadge-badge {
        border: 1px solid #ffffff;
        color: #ffffff;
        background: #82bd73;
        top: 0;
        right: 3px;
    }

    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.1;
        `}
`;

export const PlayBtn = styled.div`
    background: #f7fafc;
    border-radius: 50%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg,
    div {
        width: 64px;
        height: 64px;
        color: #82bd73;
    }
    ${({ isMobile }) =>
        isMobile &&
        css`
            padding: 10px;
            svg,
            div {
                width: 32px;
                height: 32px;
            }
        `}
`;
