import ApiBaseRequest from '../../networkRequests/ApiBaseRequest';
import store from 'store/store';
class BaseRecipesApi extends ApiBaseRequest {
    constructor() {
        super('GET');
    }

    generateUrl = () => '/recipes/all';
}

export default BaseRecipesApi;
