import { apiRequest } from 'helpers/request';

async function getUserPantry(query, headers) {
    return apiRequest({ url: '/users/pantry', query, method: 'get', headers });
}

async function addUserPantry(headers, data) {
    return apiRequest({ url: '/users/pantry', method: 'post', headers, data });
}

async function removeUserPantry(headers, query) {
    return apiRequest({ url: '/users/pantry/updateGroceriesAvailability', query, method: 'put', headers });
}

async function updateUserPantry(headers, data) {
    return apiRequest({ url: '/users/pantry/updateGroceriesDetails', data, method: 'put', headers });
}

export default {
    getUserPantry,
    addUserPantry,
    removeUserPantry,
    updateUserPantry,
};
