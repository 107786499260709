import React, { useState } from 'react';
import HeaderCenter from '../../common/headers/HeaderCenter';
import HeaderLeft from '../../common/headers/HeaderLeft';
import HeaderRight from '../../common/headers/HeaderRight';
import MenuMobile from '../../common/headers/MenuMobile';
import DashboardMenu from '../../common/DashBoardTabs/DashboardMenu';
import { HeaderMobileContainer } from './styles';

const HeaderMobile = (props) => {
    const {
        showFavorite,
        setOpen,
        open,
        loggedInUser,
        isTabletOrMobile,
        showDashboard,
        setShowDashboard,
        isDefaultInfluencer,
        influencer,
        diet,
    } = props;
    return (
        <HeaderMobileContainer {...props} open={open}>
            <div>
                <HeaderLeft setOpen={setOpen} open={open} isDefaultInfluencer={isDefaultInfluencer} influencer={influencer} />
                <HeaderCenter loggedInUser={loggedInUser} isDefaultInfluencer={isDefaultInfluencer} influencer={influencer} />
                <HeaderRight
                    showDashboard={showDashboard}
                    setShowDashboard={setShowDashboard}
                    isDefaultInfluencer={isDefaultInfluencer}
                    influencer={influencer.handle}
                />
            </div>
            <MenuMobile
                open={open}
                setOpen={setOpen}
                isTabletOrMobile={isTabletOrMobile}
                showFavorite={showFavorite}
                isDefaultInfluencer={isDefaultInfluencer}
                influencer={influencer.handle}
                diet={diet}
            />
            <DashboardMenu
                open={showDashboard}
                setOpen={setShowDashboard}
                isTabletOrMobile={isTabletOrMobile}
                isDefaultInfluencer={isDefaultInfluencer}
                influencer={influencer.handle}
            />
        </HeaderMobileContainer>
    );
};
export default HeaderMobile;
