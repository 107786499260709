import React from 'react';
import useScreenType from 'hooks/useScreenType';
import { IconButton } from '@material-ui/core';
import PauseIcon from '@material-ui/icons/Pause';

import Icons from '../Icons/Icons';
import useTheme from 'hooks/useTheme';

import { PlayBtn } from './styles';

const Play = ({ onClick, pause, orientation }) => {
    const color = useTheme();
    const { isMobile } = useScreenType();
    return (
        <IconButton onClick={onClick}>
            <PlayBtn isMobile={isMobile || orientation == 'portrait'}>
                {pause ? <PauseIcon /> : <Icons name="play" color={color.brandGreen} />}
            </PlayBtn>
        </IconButton>
    );
};

export default Play;
