import styled, { css } from 'styled-components';

export const IconContainer = styled.div`
    ${(props) =>
        css`
            width: ${props.size}px;
            min-width: ${props.size}px;
            height: ${props.size}px;
            color: ${props.color};
            & > svg {
                width: 100%;
                height: 100%;
            }
        `};
`;
