import get from 'lodash/get';
import { customNotification } from '../components/common/Notification';
import { pushError } from '../helpers/slack';
import NetworkError from '../helpers/NetworkError';
import * as Sentry from '@sentry/browser';

export const handleNetworkError = (error, data, text) => {
    const status = get(error, 'error.response.status');
    const errorName =
        get(error, 'error.response.data.message', get(error, 'error.response.data.error')) || get(error, 'error.name');
    const url = get(error, 'requestInfo.url');
    const query = get(error, 'requestInfo.query');
    let body = get(error, 'requestInfo.body');

    if (body) {
        if (body.password) {
            delete body.password;
        }
        body = JSON.stringify(body);
    } else {
        body = '[empty]';
    }

    if (status !== 401) {
        pushError({
            text: `${text} - ${errorName}`,
            data: {
                url,
                query,
                data,
            },
            payload: body,
            error,
        });
        const errorInfo = {
            url,
            query,
            data,
            body,
        };
        Sentry.withScope((scope) => {
            Object.keys(errorInfo).forEach((key) => {
                scope.setExtra(key, errorInfo[key]);
            });
            Sentry.captureException(error.error);
        });
    }
};

export const errorNotification = (error, data, text, show = false) => {
    const errorName =
        get(
            error,
            'error.response.data.message',
            get(error, 'error.response.data.error', get(error, 'error.response.data.error[0]')),
        ) || get(error, 'error.name');

    if (error instanceof NetworkError) {
        handleNetworkError(error, data, text);
    } else {
        pushError({
            text,
            data,
            error,
        });
    }

    show && customNotification('error', errorName);
    console.error(error);
};
