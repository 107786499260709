import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useScreenType from 'hooks/useScreenType';
import moment from 'moment-timezone';
import findIndex from 'lodash/findIndex';
import { useDebouncedCallback } from 'use-debounce/lib';

import Icons from 'components/common/Icons/Icons';
import CalendarIcon from './CalendarIcon';

import { setUserItemPantryRemove, setUserItemPantryUpdate, updateUserPantryItems } from 'store/actions/userPantry.actions';
import { updateStateEditPantryPopup } from 'store/actions/ui-settings.actions';

import {
    IngredientManage,
    IngredientContainer,
    IngredientInfo,
    Image,
    IngredientName,
    Name,
    DatePurchase,
    CustomIngredient,
    GrocerySize,
    Quantity,
    Progress,
    ProgressBar,
    DayLeft,
    ButtonRemove,
    ButtonGroup,
    CustomDate,
    Calendar,
} from './styles';

const IngredientGroceryManage = ({ ingredient }) => {
    const dispatch = useDispatch();
    const { isMobile } = useScreenType();

    const { userPantryItemsRemove, userPantryItems, userPantryItemsUpdate } = useSelector((state) => state.userPantryReducer);
    const groceries = useSelector((state) => state.recipeReducer.Cart.groceries);

    const [isRemoved, setIsRemove] = useState(false);
    const [quantity, setQuantity] = useState(ingredient.quantity);
    const [valueDate, setValueDate] = useState();

    useEffect(() => {
        setQuantity(ingredient.quantity);
        setValueDate(ingredient.expiryDate);
    }, [ingredient, groceries]);

    const [debouncedCallback] = useDebouncedCallback((value) => {
        userPantryItems.forEach((item) => {
            if (item.id === ingredient.id) {
                item.quantity = value;
            }
        });
        const newUserPantryItemsUpdate = userPantryItemsUpdate.filter((userPantry) => userPantry.id !== ingredient.id);
        dispatch(
            setUserItemPantryUpdate([...newUserPantryItemsUpdate, { ...ingredient, quantity: value, expiryDate: valueDate }]),
        );
        dispatch(updateUserPantryItems(userPantryItems));
    }, 500);

    const onChangeQuantity = (e) => {
        if (!isNaN(e.target.value)) {
            setQuantity(e.target.value);
            debouncedCallback(e.target.value);
        }
    };

    useEffect(() => {
        if (findIndex(userPantryItemsRemove, (id) => id === ingredient.id) !== -1) {
            setIsRemove(true);
        } else {
            setIsRemove(false);
        }
    }, [userPantryItemsRemove]);

    const handleRemoveUserPantryItem = () => {
        dispatch(setUserItemPantryRemove(ingredient.id));
    };

    const onChangeDate = (value) => {
        setValueDate(value);
        userPantryItems.forEach((item) => {
            if (item.id === ingredient.id) {
                item.expiryDate = value;
            }
        });
        const newUserPantryItemsUpdate = userPantryItemsUpdate.filter((userPantry) => userPantry.id !== ingredient.id);
        dispatch(
            setUserItemPantryUpdate([...newUserPantryItemsUpdate, { ...ingredient, quantity: quantity, expiryDate: value }]),
        );
        dispatch(updateUserPantryItems(userPantryItems));
    };

    return (
        <IngredientManage isRemoved={isRemoved} isMobile={isMobile}>
            <IngredientContainer>
                <IngredientInfo isMobile={isMobile}>
                    <Image isMobile={isMobile} image={ingredient.image} />
                    <IngredientName isMobile={isMobile}>
                        <Name isMobile={isMobile}>{ingredient.name}</Name>
                        {isMobile ? (
                            <DatePurchase>
                                You have:{' '}
                                <span>
                                    {ingredient.quantity} {ingredient.units}
                                </span>
                            </DatePurchase>
                        ) : (
                            <DatePurchase>
                                Date of purchase: <span>{moment(ingredient.expiryDate).format('DD-MM-YY')}</span>
                            </DatePurchase>
                        )}
                    </IngredientName>
                </IngredientInfo>
                <CustomIngredient isMobile={isMobile}>
                    {!isMobile && (
                        <GrocerySize>
                            <Quantity>
                                <input value={quantity} onChange={onChangeQuantity} />
                                <div>{ingredient.units}</div>
                            </Quantity>
                        </GrocerySize>
                    )}
                    <Progress isMobile={isMobile}>
                        <div>
                            <div>
                                <DayLeft isMobile={isMobile}>
                                    {moment(ingredient.expiryDate).diff(moment(), 'days') < 1 ? (
                                        <CustomDate isMobile={isMobile}>
                                            <div>Set expiry date:</div>
                                            <Calendar isMobile={isMobile}>
                                                <CalendarIcon
                                                    name="date"
                                                    value={moment(valueDate).format('DD-MM-YY') || ''}
                                                    onChange={(value) => onChangeDate(value)}
                                                />
                                            </Calendar>
                                        </CustomDate>
                                    ) : (
                                        <>
                                            <div>{moment(ingredient.expiryDate).toNow(true)} left</div>
                                            <Calendar isMobile={isMobile}>
                                                <CalendarIcon
                                                    name="date"
                                                    value={moment(valueDate).format('DD-MM-YY') || ''}
                                                    onChange={(value) => onChangeDate(value)}
                                                />
                                            </Calendar>
                                        </>
                                    )}
                                </DayLeft>
                            </div>
                        </div>
                    </Progress>
                    <ButtonGroup isMobile={isMobile}>
                        <ButtonRemove onClick={handleRemoveUserPantryItem}>
                            {isRemoved ? <Icons name="plus" size="24" /> : <Icons name="trash" size="24" />}
                        </ButtonRemove>
                    </ButtonGroup>
                </CustomIngredient>
            </IngredientContainer>
        </IngredientManage>
    );
};

export default IngredientGroceryManage;
