import { autoPlay } from 'react-swipeable-views-utils';
import styled, { css } from 'styled-components';

export const SummaryWrapper = styled.div`
    max-width: 392px;
    margin: 15px 24px;

    ${({ isMobile }) =>
        isMobile &&
        css`
            margin: 20px !important;
            padding: unset;
            padding-bottom: 15px;
            max-width: calc(100vw - 40px);
            border-bottom: 1px solid #e5eaed;
        `};
`;

export const MobileTitle = styled.div`
    display: flex;
    padding: 12px 20px;
    border-bottom: 1px solid #e5eaed;
    align-items: center;
    justify-content: space-between;
    .summary-title {
        p {
            margin: 0;
        }
    }
`;
export const MobileText = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 15px;
    font-weight: 20px;
    color: black;
    div {
        &:first-child {
            font-size: 20px;
        }
    }
`;
export const RecipeWrapper = styled.div`
    display: flex;
    width: 100%;
`;

export const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;
    margin-bottom: 16px;

    span {
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.4px;
        color: #0d2120;
    }

    ${({ isMobile }) =>
        isMobile &&
        css`
            border-bottom: 1px solid #e5eaed;

            span {
                font-size: 14px;
            }
        `};
`;

export const ImageContainer = styled.div`
    height: 40px;
    display: flex;
    img {
        width: 40px;
        height: auto;
        object-fit: cover;
        border-radius: 4px;
        margin-right: 8px;
    }
`;

export const NameContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    div {
        color: #bfc8cd;
        font-size: 14px;
        &:first-child {
            text-align: left;
            color: #6b787e;
            max-width: 170px;
            margin-right: 24px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &:hover {
                cursor: pointer;
            }

            ${({ isMobile }) =>
                isMobile &&
                css`
                    width: 130px;
                    margin-right: 14px;
                `};

            ${({ showImage, isMobile }) =>
                showImage &&
                !isMobile &&
                css`
                    max-width: 155px;
                `};

            ${({ isTablet }) =>
                isTablet &&
                css`
                    max-width: 100%;
                    margin-right: 14px;
                `};
        }
    }
`;

export const NoDataContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    color: #bfc8cd;
    font-weight: 500;
    font-size: 16px;
    padding-top: 15px;
`;

export const RecipeContainer = styled.div`
    display: block;
`;
export const RecipeContent = styled.div`
    display: flex;
    margin-bottom: 16px;
    justify-content: space-between;
    &:last-child {
        margin: 0;
    }
`;

export const MobileContainer = styled.div`
    // position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 999;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .content-wrapper {
        overflow: auto;
        flex: 1;
        display: flex;
        flex-direction: column;
        max-height: calc(100% - 110px);
        flex: 1;
        .main-list-wrapper {
            flex: 1;
        }
        .menu-wrapper {
            display: flex;
            align-items: center;
            flex-direction: column;

            p {
                text-align: left;
            }

            .see-more-link {
                width: 100%;
                padding: 10px 20px;
                border-bottom: 1px solid #e5eaed;
                ${({ extraProducts }) =>
                    extraProducts.length > 0 &&
                    css`
                        margin: 0 20px;
                    `}
            }
        }
        .menu-wrapper > div:first-child {
            width: 100%;
            border-bottom: none;
            ${({ recipes }) =>
                recipes.length > 5 &&
                css`
                    margin: 20px 20px 0 !important;
                `}
        }

        .menu-wrapper > div:nth-child(2) {
            width: 100%;
            display: flex;
            align-items: flex-end;
        }

        .menu-wrapper > div:last-child {
            width: 100%;
            max-width: calc(100vw - 40px);
        }

        .MuiAccordion-root.Mui-expanded {
            margin: 0 20px;
        }

        .MuiAccordionSummary-root {
            padding: 9px 18px;
            border-radius: 8px;
            background: #eff5f9;
            border: 1px solid #eff5f9;

            .MuiAccordionSummary-content {
                margin: unset;
            }
            .MuiButtonBase-root {
                margin: unset;
                padding: unset;
            }
        }

        .MuiAccordionDetails-root {
            .MuiAccordionSummary-root.Mui-expanded {
                margin: 0 20px;
            }
        }

        .MuiAccordionSummary-root.Mui-expanded {
            min-height: 48px;
        }
        .ingredientDetail {
            .MuiAccordionSummary-root {
                padding: 9px;
                border-radius: 8px;
                background: none;
                border: 1px solid #e5eaed;
            }
        }
    }
    .mobile-bar-block {
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: 998;
        > div {
            position: unset;
        }
    }
`;

export const CostWrapper = styled.div`
    display: flex;
    max-height: 100%;
    flex-direction: column;
    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.3;
        `}
    ${({ hideTotal }) =>
        hideTotal &&
        css`
            visibility: hidden;
        `}

    p {
        margin: 0;
    }

    .cost-wrapper {
        font-size: 14px;
        padding-top: 5px;
        font-weight: 400;
        
        & > * {
            font-size: 14px;
            font-weight: 400;
            padding-top: 5px;
            margin: 5px 20px;

            ${({ isMobile }) =>
                isMobile &&
                css`
                    padding: 7px 0;
                    margin: 5px 20px;
                `}
        }

        .cost-content {
            padding-top: 5px;
            margin-top: 0px;
            align-items: center;
            display: flex;
            flex: 1;
            .cost-content-block {
                display: flex;
                align-items: center;
            }

            .grocery-leftovers {
                margin-right: 5px;
            }
            
            .promo-block{
                padding-top: 5px;
                margin-top: 0px;
                align-items:center;
                display: flex;
                flex: 1;
            }
            ${({ isMobile }) =>
                isMobile &&
                css`
                    width: calc(100vw - 40px);
                    padding-top: 0px;
                `}
        }

        .delivery-block-box{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 5px;
            margin-top: 5px ;       
            ${({ isMobile }) =>
                isMobile &&
                css`
                    padding-top: 5px;
                    margin-top: 5px;
                `} }

        .add-border-top {
                border-top: 1px solid #e5eaed;
        }

        .remove-fee {
            text-align: left;
            margin-left: 4px;
            font-size: 14px;
            color: #909da6;
            display: flex;
            align-items: center;          
            cursor: pointer;
            text-decoration-line: underline;  
        }

        .unused-container {
            &.add-background {
                background: #233747;
                padding: 16px 20px;
                margin: 13px 0 0;
                border-radius: 8px 0px 0px 8px;
                ${({ isMobile }) =>
                    isMobile &&
                    css`
                        border-radius: 0;
                    `}
                > div > div > span {
                    color: #fff;
                }
            }

            .unused-cost {
                display: flex;
                justify-content: space-between;
                align-items: center;
                &.pr10 {
                    padding-right: 10px;
                }

                .common-ing-recipes-wrapper {
                    display: flex;
                    align-items: center;
                    .discountedRecipeImages {
                        height: 36px;
                        width: 36px;
                        margin: 9px 8px 0 0;
                    }
                    .images {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-template-rows: 1fr 1fr;
                        grid-row-gap: 3px;
                        grid-column-gap: 2px;
                        border-radius: 8px;
                        background: #e5eaed;
                        border: 2px solid #e5eaed;
                        box-sizing: border-box;
                        margin: 9px 8px 0 0;

                        > div:first-child {
                            img {
                                border-radius: 8px 0 0 0;
                            }
                        }
                        > div:nth-child(2) {
                            img {
                                border-radius: 0 8px 0 0;
                            }
                        }
                        > div:nth-child(3) {
                            img {
                                border-radius: 0 0 0 8px;
                            }
                        }
                        > div:last-child {
                            img {
                                border-radius: 0 0 8px 0;
                            }
                        }
                        > * {
                            height: 18px;
                            width: 18px;
                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
                .unused-desc {
                    font-weight: normal;
                    font-size: 12px;
                    color: #bfc8cd;
                    text-align: left;
                }
                .use-it-link {
                    span {
                        white-space: nowrap;
                    }
                }
            }
        }

        .per-serving-cost-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 18px 0 10px;
            padding: 0 20px;

            .per-serving-block {
                display: flex;
                flex-direction: row;
                span {
                    font-weight: 500;
                    font-size: 16px;
                    color: #233747;
                    ${({ isMobile }) =>
                        isMobile &&
                        css`
                            font-size: 14px;
                        `}
                }
                svg {
                    margin-left: 5px;
                }
            }
            .total-servings {
                text-align: left;
                margin-left: 15px;
                font-size: 14px;
                color: #bfc8cd;
                display: flex;
                align-items: center;
            }
            > div:last-child {
                font-weight: 600;
                font-size: 16px;
                color: #233747;
            }
        }
    }

    .delivery-block {
        > div > span {
            font-weight: 400;
        }
        padding-top 5px;
        margin-top: 0px;
        ${({ isMobile }) =>
            !isMobile &&
            css`
                padding: 0 0 9px;
            `}
    }

    .sub-total-block {
        ${({ isMobile }) =>
            !isMobile &&
            css`
                padding-top: 5px;
            `}
    }
    .tax-block {
        font-size: 14px;
        color: #233747;
        font-weight: 400;
        padding-top: 5px;
        margin: 5px 20px;
    }

    .service-fee-block {
        font-size: 14px;
        color: #233747;
        font-weight: 400;
        padding-top: 0;
        margin: 0px 20px;
    }

    .add-border {
        border-bottom: 1px solid #e5eaed;
    }
    
    .total-cost {
        font-size: 16px;
        font-weight: 400;
        ${({ isMobile }) =>
            isMobile &&
            css`
                padding: 0;
                font-size: 16px;
            `}
    }
`;

export const PromoCodeWrapper = styled.div`
    .promo-text {
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        line-height: unset;
        p {
            margin: 0;
        }
        padding-top: 5px;
        ${({ isMobile }) =>
            isMobile &&
            css`
                padding-top: 0px;
            `}
    }
    .active-promo-code {
        padding-top: 10px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        ${({ isMobile }) =>
            isMobile &&
            css`
                padding-top: 0px;
            `}
        p {
            margin: 0;
        }
        div:last-child {
            display: flex;
            align-items: center;
            div:first-child {
                margin-right: 12px;
            }
        }
        .discount-info {
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                margin: 0 2px 0 10px;
                fill: #909da6;
                width: 20px;
                height: 20px;
            }
        }
        .close-icon {
            svg {
                cursor: pointer;
            }
        }
    }
    .promo-code-input {
        div:first-child {
            background: transparent;
            padding: 0;
            border: none;
        }
        div:last-child {
            padding: 2px 0px;
        }
        input {
            font-size: 14px;
            color: #4f5f6c;
            border-bottom: 1px solid #aab5bc;
            width: 50%;
        }
    }
`;

export const ExtraProductWrapper = styled.div`
    margin: 20px;
    border-bottom: 1px solid #e5eaed;

    .title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 8px;
        p {
            margin: unset;
        }
    }

    .product-list {
        width: 100%;
        margin-top: 16px;

        .single-row {
            display: flex;
            flex: 1;
            flex-direction: row;
            align-items: center;
            padding: 8px 0;

            &:last-child {
                margin: 0;
            }

            .product-info {
                display: flex;
                flex: 1;
                .image {
                    width: 40px;
                    height: 40px;
                    margin: 0 8px;
                    img {
                        width: 40px;
                        height: 40px;
                        border-radius: 4px;
                        object-fit: cover;
                    }
                }

                .description {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .name {
                        max-width: 100%;
                        text-align: left;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        margin: 0;
                        @media (max-width: 480px) {
                            width: 130px;
                        }
                    }
                    p {
                        margin: 0;
                        text-align: left;
                    }
                }
            }

            .price {
                min-width: 70px;
                width: 70px;
            }

            .trash-icon {
                flex: 1;
            }
        }
    }
`;
