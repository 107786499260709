import * as constants from '../constants';

const initialState = {
    locations: [],
    isloadedLocations: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case constants.GET_LOCATIONS_REQUEST:
            return {
                loading: true,
                ...state,
            };
        case constants.GET_LOCATIONS_SUCCESS:
            return {
                ...state,
                locations: action.locations,
                loading: false,
                isloadedLocations: true,
            };
        default:
            return state;
    }
}
