import { trackUserActivity } from './trackUserActivity';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import { inspect } from 'util';
const substrings = ['SET_', 'ADD_', 'CHECK_', 'UPDATE_', 'EDIT_', 'TOGGLE_', 'DELETE_', 'SEND_', 'VERIFY_'];
const substringsRegEx = new RegExp(substrings.join('|'));

export const trackReduxActions = (action) => {
    if (!action['@@redux-saga/SAGA_ACTION'] === true && !action.type.includes('_SUCCESS') && substringsRegEx.test(action.type)) {
        let actionData = cloneDeep({ ...action });
        if (get(actionData, 'payload.cardElement')) {
            delete actionData.payload.cardElement;
        }
        if (get(actionData, 'payload.createdCardElement')) {
            delete actionData.payload.createdCardElement;
        }

        trackUserActivity({
            type: 'action',
            value: JSON.stringify(
                inspect(actionData, {
                    showHidden: true,
                }),
            ),
        });
    }
};
