import * as constants from '../constants';
const initialState = {
    loading: false,
    pagination: {
        currentPage: 1,
        nextPage: 1,
        totalPage: 1,
        limit: 2,
    },
    data: {},
    message: null,
};

const categoryReducer = (state = null, action) => {
    switch (action.type) {
        case constants.GET_CATEGORY_RECIPES_SUCCESS:
            return {
                category: action.data.categories[0],
                recipes: action.data.recipes,
            };
    }

    return state;
};

const findCategoryById = (object, key, state, action) => {
    return {
        [object[key]]: categoryReducer(state.data[object[key]], action),
    };
};

export default function (state = initialState, action) {
    switch (action.type) {
        case constants.LOADING_CATEGORY_PAGE_DATA:
            return {
                ...state,
                loading: true,
            };
        case constants.LOADED_CATEGORY_PAGE_DATA:
            return {
                ...state,
                loading: false,
            };
        case constants.GET_CATEGORY_RECIPES_SUCCESS:
            const { categories } = action.data;
            if (!categories.length)
                return {
                    ...initialState,
                    message: 'No recipes found',
                };
            return {
                ...state,
                data: {
                    ...state.data,
                    ...findCategoryById(categories[0], '_id', state, action),
                },
                message: null,
            };
    }

    return state;
}
