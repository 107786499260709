import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { all, fork } from 'redux-saga/effects';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import './localStorage/migrations';

import orderReducer from './reducers/order.reducer';
import authenReducer from './reducers/authentication.reducer';
import locationReducer from './reducers/location.reducer';
import userReducer from './reducers/user.reducer';
import citiesReducer from './reducers/cities.reducer';
import recipeCategoryReducer from './reducers/recipeCategory.reducer';
import extraProductReducer from './reducers/extraProduct.reducer';
import extraProductCategoryReducer from './reducers/extraProductCategory.reducer';
import userProgressReducer from './reducers/userProgress.reducer';
import recipeReducer from './reducers/recipe.reducer';
import allergyReducer from './allergy/allergy.reducer';
import orderMenuReducer from './reducers/orderMenu.reducer';
import themeReducer from './reducers/theme.reducer';
import ingredientReducer from './reducers/ingredient.reducer';
import cartReducer from './reducers/cart.reducer';
import uiSettingsReducer from './reducers/ui-settings.reducer';
import userPantryReducer from './reducers/userPantry.reducer';
import faqReducer from './reducers/faq.reducer';
import influencerReducer from './reducers/indfluencer.reducer';
import recipeSearchReducer from './reducers/recipeSearch.reducer';
import onboardingReducer from './reducers/onboarding.reducer';
import featureFlagsReducer from './reducers/featureFlags.reducer';
import configReducer from './reducers/config.reducer';
import curatedRecipesReducer from './curatedRecipes/curatedRecipes.reducer';
import initializeReducer from './reducers/initialize.reducer';
import recipesReducer from './recipes/recipe.reducer';
import categoryReducer from './categories/category.reducer';
import nutritionProgramsReducer from './reducers/nutritionPrograms.reducer';
import buildMenuReducer from './buildMenu/buildMenu.reducer';
import mealTypesReducer from './reducers/mealTypes.reducer';
import aboutUsReducer from './reducers/aboutUs.reducer';
import loginModalReducer from './reducers/loginModal.reducer';
import linkeinBioReducer from './reducers/linkeinBio.reducer';

import orderSagas from './sagas/order.sagas';
import authenSagas from './sagas/authentication.sagas';
import userSagas from './sagas/user.sagas';
import citiesSagas from './sagas/cities.sagas';
import locationSagas from './sagas/location.sagas';
import recipeCategorySagas from './sagas/recipeCategory.sagas';
import extraProductSagas from './sagas/extraProduct.sagas';
import extraProductCategorySagas from './sagas/extraProductCategory.sagas';
import recipeSagas from './sagas/recipe.sagas';
import allergySagas from 'store/allergy/allergy.sagas';
import cartSagas from './sagas/cart.sagas';
import orderMenuSagas from './sagas/orderMenu.sagas';
import ingredientSagas from './sagas/ingredient.sagas';
import userPantrySagas from './sagas/userPantry.sagas';
import influencerSagas from './sagas/influencer.sagas';
import onboardingSagas from './sagas/onboarding.sagas';
import featureFlagsSagas from './sagas/featureFlags.sagas';
import { trackReduxActions } from 'helpers/trackReduxActions';
import curatedRecipesSagas from './curatedRecipes/curatedRecipes.sagas';
import initializeSagas from './sagas/initialize.sagas';
import recipesSagas from './recipes/recipes.saga';
import categorySagas from './categories/category.sagas';
import initializePageSagas from './sagas/initializePage.sagas';
import buildMenuSagas from './buildMenu/buildMenu.sagas';
import linkeinBioSagas from './sagas/linkeinBio.sagas';

const sagaMiddleware = createSagaMiddleware();

const logger = (store) => (next) => (action) => {
    trackReduxActions(action);
    return next(action);
};

const Store = createStore(
    combineReducers({
        orderReducer,
        authenReducer,
        userReducer,
        citiesReducer,
        locationReducer,
        recipeCategoryReducer,
        extraProductReducer,
        extraProductCategoryReducer,
        userProgressReducer,
        recipeReducer,
        allergyReducer,
        orderMenuReducer,
        themeReducer,
        ingredientReducer,
        cartReducer,
        uiSettingsReducer,
        userPantryReducer,
        faqReducer,
        influencerReducer,
        recipeSearchReducer,
        onboardingReducer,
        featureFlagsReducer,
        configReducer,
        curatedRecipesReducer,
        initializeReducer,
        recipesReducer,
        categoryReducer,
        // recipesReducer,
        nutritionProgramsReducer,
        buildMenuReducer,
        mealTypesReducer,
        aboutUsReducer,
        loginModalReducer,
        linkeinBioReducer,
    }),
    composeWithDevTools(applyMiddleware(logger, sagaMiddleware)),
);

function* sagas() {
    yield all([
        fork(orderSagas),
        fork(authenSagas),
        fork(userSagas),
        fork(citiesSagas),
        fork(locationSagas),
        fork(recipeCategorySagas),
        fork(extraProductSagas),
        fork(extraProductCategorySagas),
        fork(recipeSagas),
        fork(allergySagas),
        fork(cartSagas),
        fork(orderMenuSagas),
        fork(ingredientSagas),
        fork(userPantrySagas),
        fork(influencerSagas),
        fork(onboardingSagas),
        fork(featureFlagsSagas),
        fork(curatedRecipesSagas),
        fork(initializeSagas),
        fork(recipesSagas),
        fork(initializePageSagas),
        fork(categorySagas),
        fork(buildMenuSagas),
        fork(linkeinBioSagas),
    ]);
}

sagaMiddleware.run(sagas);

export default Store;
