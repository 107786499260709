import styled, { css } from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

export const ModalContainer = styled(Dialog)`
    & > div:nth-child(3) {
        & > div:nth-child(1) {
            min-width: 1000px;
            border-radius: 8px;
        }
    }

    ${(props) =>
        props.$isTabletOrMobile &&
        css`
            & > div:nth-child(3) {
                position: relative;

                & > div:nth-child(1) {
                    width: 100%;
                    min-width: unset;
                    max-width: unset;
                    margin: 0;
                    margin-top: auto;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    border-radius: 16px 16px 0 0;
                }
            }
        `};

    ${(props) =>
        props.$isMobile &&
        css`
            & > div:nth-child(3) {
                & > div:nth-child(1) {
                    min-height: 224px;
                }
            }
        `};
`;

export const ModalContent = styled(DialogContent)`
    padding: 0 !important;
    .react-vedio-player div iframe {
        min-height: 360px;
    }
    .react-vedio-player div {
        display: flex;
    }
    .react-vedio-player {
        height: unset !important;
    }
    .header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
`;
