import styled, { css } from 'styled-components';

export const DashboardMenuContainer = styled.div`
    position: absolute;
    z-index: 9999;
    width: 100% !important;
    top: 65px;
    right: 0;
`;

export const DashboardTabsContainer = styled.div`
    background: #ffffff;
    display: flex;
    flex-direction: row;
    position: relative;
    nav {
        display: flex;
        .tab-item {
            a {
                padding: 12px 32px;
                height: 48px;
                background: #ffffff;
                border: 1px solid transparent;
                border-bottom: 1px solid #82bd73;
                box-sizing: border-box;
                border-radius: 8px 8px 0px 0px;
                justify-content: center;
                align-items: center;
                display: flex;
                flex-direction: row;
                text-decoration: none;
                span {
                    font-weight: 500;
                    font-size: 16px;
                    color: #909da6;
                    margin-left: 10px;
                }
                &.active {
                    background: #ffffff;
                    border: 1px solid #82bd73;
                    border-bottom: 1px solid transparent;
                    border-radius: 8px 8px 0 0;
                    span {
                        color: #233747;
                    }
                }
            }
        }
    }

    .border-style {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: calc(100% - 641.5px);
        height: 1px;
        background: #82bd73;
    }
`;
