// --------REQUESTS CONSTANTS HERE--------

export const recipesStatus = {
    DRAFT: 'draft',
    PUBLISHED: 'published',
};

export const GET_RECIPES_REQUEST = 'GET_RECIPES_REQUEST';
export const GET_RECIPES_SUCCESS = 'GET_RECIPES_SUCCESS';
export const GET_RECIPES_FAILURE = 'GET_RECIPES_FAILURE';
export const GET_ALL_RECIPES_SUCCESS = 'GET_ALL_RECIPES_SUCCESS';
export const FILTER_RECIPES = 'FILTER_RECIPES';
export const FILTER_COMMON_INGREDIENTS = 'FILTER_COMMON_INGREDIENTS';
export const SEARCH_COMMON_INGREDIENTS = 'SEARCH_COMMON_INGREDIENTS';

export const GET_RECIPES_CATEGORIES_REQUEST = 'GET_RECIPES_CATEGORIES_REQUEST';
export const GET_RECIPES_CATEGORIES_SUCCESS = 'GET_RECIPES_CATEGORIES_SUCCESS';
export const GET_RECIPES_CATEGORIES_FAILURE = 'GET_RECIPES_CATEGORIES_FAILURE';

export const GET_RECIPE_BY_ID_SUCCESS = 'GET_RECIPE_BY_ID_SUCCESS';

export const GET_RECIPE_REQUEST = 'GET_RECIPE_REQUEST';
export const GET_RECIPE_SUCCESS = 'GET_RECIPE_SUCCESS';
export const GET_RECIPE_FAILURE = 'GET_RECIPE_FAILURE';

export const GET_CATEGORY_RECIPES_REQUEST = 'GET_CATEGORY_RECIPES_REQUEST';
export const GET_CATEGORY_RECIPES_SUCCESS = 'GET_CATEGORY_RECIPES_SUCCESS';
export const GET_CATEGORY_RECIPES_FAILURE = 'GET_CATEGORY_RECIPES_FAILURE';

export const GET_SEARCH_RESULT_SUCCESS = 'GET_SEARCH_RESULT_SUCCESS';
export const GET_RECIPES_FILTER_REQUEST = 'GET_RECIPES_FILTER_REQUEST';
export const GET_RECIPES_FILTER_SUCCESS = 'GET_RECIPES_FILTER_SUCCESS';
export const GET_RECIPES_FILTER_FAILURE = 'GET_RECIPES_FILTER_FAILURE';

export const TOGGLE_CATEGORY = 'TOGGLE_CATEGORY';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const SELECT_CATEGORY = 'SELECT_CATEGORY';

export const SET_RECIPES_SEARCH = 'SET_RECIPES_SEARCH';
export const REMOVE_RECIPES_SEARCH = 'REMOVE_RECIPES_SEARCH';
export const LOAD_MORE_RECIPES = 'LOAD_MORE_RECIPES';
export const GET_CATEGORY_WISE_RECIPES = 'GET_CATEGORY_WISE_RECIPES';

export const SET_CURRENT_RECIPE = 'SET_CURRENT_RECIPE';

export const ADD_RECIPE = 'ADD_RECIPE';
export const RESAVE_CART = 'RESAVE_CART';
export const ADD_RECIPE_SUCCESS = 'ADD_RECIPE_SUCCESS';
export const SELECTED_RECIPES = 'SELECTED_RECIPES';
export const ADD_RECIPE_API = 'ADD_RECIPE_API';
export const ADD_ALL_RECIPE = 'ADD_ALL_RECIPE';

export const DELETE_RECIPE = 'DELETE_RECIPE';
export const DELETE_RECIPE_SUCCESS = 'DELETE_RECIPE_SUCCESS';

export const ADD_MEAL_PLAN = 'ADD_MEAL_PLAN';
export const SWAP_MEAL_PLAN_RECIPE = 'SWAP_MEAL_PLAN_RECIPE';

export const GET_EXTRA_PRODUCTS_REQUEST = 'GET_EXTRA_PRODUCTS_REQUEST';
export const GET_EXTRA_PRODUCTS_SUCCESS = 'GET_EXTRA_PRODUCTS_SUCCESS';
export const GET_EXTRA_PRODUCTS_FAILURE = 'GET_EXTRA_PRODUCTS_FAILURE';

export const GET_EXTRA_PRODUCTS_CATEGORIES_REQUEST = 'GET_EXTRA_PRODUCTS_CATEGORIES_REQUEST';
export const GET_EXTRA_PRODUCTS_CATEGORIES_SUCCESS = 'GET_EXTRA_PRODUCTS_CATEGORIES_SUCCESS';
export const GET_EXTRA_PRODUCTS_CATEGORIES_FAILURE = 'GET_EXTRA_PRODUCTS_CATEGORIES_FAILURE';

export const TOGGLE_EXTRA_CATEGORY = 'TOGGLE_EXTRA_CATEGORY';
export const ADD_EXTRA_CATEGORY = 'ADD_EXTRA_CATEGORY';
export const DELETE_EXTRA_CATEGORY = 'DELETE_EXTRA_CATEGORY';

export const SET_EXTRA_PRODUCTS_SEARCH = 'SET_EXTRA_PRODUCTS_SEARCH';
export const CLEAR_EXTRA_PRODUCTS_SEARCH = 'CLEAR_EXTRA_PRODUCTS_SEARCH';

export const SET_SERVINGS = 'SET_SERVINGS';

export const SET_LEFTOVERS = 'SET_LEFTOVERS';

export const SET_DINNERS_COUNT = 'SET_DINNERS_COUNT';

export const SET_CURRENT_PROGRESS_STEP = 'SET_CURRENT_PROGRESS_STEP';

export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';
export const ADD_INFO_ONBOARDING = 'ADD_INFO_ONBOARDING';
export const UPDATED_PAYLOAD_ONBOARDING = 'UPDATED_PAYLOAD_ONBOARDING';
export const UPDATED_PAYLOAD_ONBOARDING_SUCCESS = 'UPDATED_PAYLOAD_ONBOARDING_SUCCESS';

export const SET_SELECTED_RECIPE_SERVINGS = 'SET_SELECTED_RECIPE_SERVINGS';
export const SET_SERVINGS_RECIPE = 'SET_SERVINGS_RECIPE';
export const SET_SELECTED_RECIPE_SERVINGS_SUCCESS = 'SET_SELECTED_RECIPE_SERVINGS_SUCCESS';
export const SET_RECIPE_SERVINGS = 'SET_RECIPE_SERVINGS';
export const CLEAR_IS_UPDATE_SEARCH_RECIPES = 'CLEAR_IS_UPDATE_SEARCH_RECIPES';

export const GET_GROCERIES_REQUEST = 'GET_GROCERIES_REQUEST';
export const GET_GROCERIES_SUCCESS = 'GET_GROCERIES_SUCCESS';
export const GET_GROCERIES_FAILURE = 'GET_GROCERIES_FAILURE';

export const ADD_EXTRA_PRODUCT = 'ADD_EXTRA_PRODUCT';
export const ADD_EXTRA_PRODUCT_SUCCESS = 'ADD_EXTRA_PRODUCT_SUCCESS';

export const DELETE_EXTRA_PRODUCT = 'DELETE_EXTRA_PRODUCT';
export const DELETE_EXTRA_PRODUCT_SUCCESS = 'DELETE_EXTRA_PRODUCT_SUCCESS';

export const SET_EXTRA_PRODUCT_VALUE = 'SET_EXTRA_PRODUCT_VALUE';

export const ADD_REQUIRED_GROCERY_QUANTITY = 'ADD_REQUIRED_GROCERY_QUANTITY';
export const SUBTRACT_REQUIRED_GROCERY_QUANTITY = 'SUBTRACT_REQUIRED_GROCERY_QUANTITY';
export const ADD_NON_REQUIRED_GROCERY_QUANTITY = 'ADD_NON_REQUIRED_GROCERY_QUANTITY';
export const SUBTRACT_NON_REQUIRED_GROCERY_QUANTITY = 'SUBTRACT_NON_REQUIRED_GROCERY_QUANTITY';

export const CLEAR_STATE_LOCAL_STORAGE = 'CLEAR_STATE_LOCAL_STORAGE';
export const CLEAR_SELECTED_EXTRA_PRODUCTS = 'CLEAR_SELECTED_EXTRA_PRODUCTS';
export const GET_STATE_FROM_LOCAL_STORAGE = 'GET_STATE_FROM_LOCAL_STORAGE';

export const RESET_PRODUCT_AMOUNT_SUCCESS = 'RESET_PRODUCT_AMOUNT_SUCCESS';

export const MAKE_GROCERY_REQUIRED_TO_USER = 'MAKE_GROCERY_REQUIRED_TO_USER';
export const MAKE_GROCERY_REQUIRED_TO_USER_API = 'MAKE_GROCERY_REQUIRED_TO_USER_API';

export const SET_FILTER_INFO = 'SET_FILTER_INFO';

export const SET_ITEM_NONE_REQUIRED = 'SET_ITEM_NONE_REQUIRED';
export const SET_IS_EDITING = 'SET_IS_EDITING';

export const GET_CART = 'GET_CART';
export const SET_IS_LOADED_RECIPES = 'SET_IS_LOADED_RECIPES';
// --------ORDER CONSTANTS HERE--------

export const SET_DELIVERY_ADDRESS = 'SET_DELIVERY_ADDRESS';
export const SET_DELIVERY_ADDRESS_SUCCESS = 'SET_DELIVERY_ADDRESS_SUCCESS';

export const SET_BILLING_ADDRESS = 'SET_BILLING_ADDRESS';
export const SET_BILLING_ADDRESS_SUCCESS = 'SET_BILLING_ADDRESS_SUCCESS';

export const GET_LOCATIONS_REQUEST = 'GET_LOCATIONS_REQUEST';
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS';
export const GET_LOCATIONS_FAILURE = 'GET_LOCATIONS_FAILURE';

export const GET_CITIES_REQUEST = 'GET_CITIES_REQUEST';
export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS';

export const SET_CURRENT_CITY = 'SET_CURRENT_CITY';

export const SET_DELIVERY_TYPE = 'SET_DELIVERY_TYPE';

export const GET_DAY_REQUEST = 'GET_DAY_REQUEST';
export const GET_DAY_SUCCESS = 'GET_DAY_SUCCESS';
export const GET_DAY_FAILURE = 'GET_DAY_FAILURE';

export const SET_DELIVERY_DATE = 'SET_DELIVERY_DATE';

export const GET_CURRENT_TIME_REQUEST = 'GET_CURRENT_TIME_REQUEST';
export const GET_CURRENT_TIME_SUCCESS = 'GET_CURRENT_TIME_SUCCESS';

export const SET_DAY_AVAILABILITY = 'SET_DAY_AVAILABILITY';

export const GET_SUMMARY_REQUEST = 'GET_SUMMARY_REQUEST';
export const GET_SUMMARY_SUCCESS = 'GET_SUMMARY_SUCCESS';

export const CREATE_ORDER_REQUEST = 'CREATE_ORDER_REQUEST';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAILURE = 'CREATE_ORDER_FAILURE';

export const SET_PROMO_CODE = 'SET_PROMO_CODE';
export const SET_DELIVERY_FEE = 'SET_DELIVERY_FEE';

export const CHECK_PROMO_REQUEST = 'CHECK_PROMO_REQUEST';
export const CHECK_PROMO_SUCCESS = 'CHECK_PROMO_SUCCESS';
export const CHECK_PROMO_FAILURE = 'CHECK_PROMO_FAILURE';

export const SET_ORDER_INFORMATION = 'SET_ORDER_INFORMATION';

export const GET_MENU_REQUEST = 'GET_MENU_REQUEST';
export const GET_MENU_SUCCESS = 'GET_MENU_SUCCESS';
export const GET_MENU_FAILURE = 'GET_MENU_FAILURE';

export const SET_RECIPES_TO_PRINT = 'SET_RECIPES_TO_PRINT';
export const SET_RECIPES_TO_PRINT_SUCCESS = 'SET_RECIPES_TO_PRINT_SUCCESS';

export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export const SET_DIET = 'SET_DIET';
export const CLEAR_IS_UPDATE_DIET = 'CLEAR_IS_UPDATE_DIET';

export const SET_BULK_TYPE = 'SET_BULK_TYPE';
export const SET_BULK_TYPE_SUCCESS = 'SET_BULK_TYPE_SUCCESS';

export const SET_CURRENT_RECIPE_SERVINGS = 'SET_CURRENT_RECIPE_SERVINGS';

export const GET_RECIPES_TO_PRINT_REQUEST = 'GET_RECIPES_TO_PRINT_REQUEST';
export const GET_RECIPES_TO_PRINT_SUCCESS = 'GET_RECIPES_TO_PRINT_SUCCESS';
export const GET_RECIPES_TO_PRINT_FAILURE = 'GET_RECIPES_TO_PRINT_FAILURE';

export const GET_DELIVERY_ADDRESS_REQUEST = 'GET_DELIVERY_ADDRESS_REQUEST';
export const GET_DELIVERY_ADDRESS_SUCCESS = 'GET_DELIVERY_ADDRESS_SUCCESS';
export const GET_DELIVERY_ADDRESS_ERROR = 'GET_DELIVERY_ADDRESS_ERROR';
export const EDIT_DELIVERY_ADDRESS = 'EDIT_DELIVERY_ADDRESS';
export const SET_IS_VALID_DELIVERY_FORM = 'SET_IS_VALID_DELIVERY_FORM';

export const GET_UNUSED_INGREDIENTS_RECIPE_REQUEST = 'GET_UNUSED_INGREDIENTS_RECIPE_REQUEST';
export const GET_UNUSED_INGREDIENTS_RECIPE_SUCCESS = 'GET_UNUSED_INGREDIENTS_RECIPE_SUCCESS';
export const GET_UNUSED_INGREDIENTS_RECIPE_FAILURE = 'GET_UNUSED_INGREDIENTS_RECIPE_FAILURE';

export const CLEAR_STATE_ORDER = 'CLEAR_STATE_ORDER';
export const ADD_PAYMENT_METHOD_ON_CHECKOUT = 'ADD_PAYMENT_METHOD_ON_CHECKOUT';

// --------AUTHENTICATON CONSTANTS HERE--------
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const ADMIN_ACCESS_USER_ACCOUNT_REQUEST = 'ADMIN_ACCESS_USER_ACCOUNT_REQUEST';
export const ADMIN_ACCESS_USER_ACCOUNT_SUCCESS = 'ADMIN_ACCESS_USER_ACCOUNT_SUCCESS';
export const ADMIN_ACCESS_USER_ACCOUNT_FAILURE = 'ADMIN_ACCESS_USER_ACCOUNT_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const FORGOTPASS_REQUEST = 'FORGOTPASS_REQUEST';
export const FORGOTPASS_SUCCESS = 'FORGOTPASS_SUCCESS';
export const FORGOTPASS_FAILURE = 'FORGOTPASS_FAILURE';

export const RESETPASS_REQUEST = 'RESETPASS_REQUEST';
export const RESETPASS_SUCCESS = 'RESETPASS_SUCCESS';
export const RESETPASS_FAILURE = 'RESETPASS_FAILURE';

export const SEND_EMAIL_VERIFICATION_REQUEST = 'SEND_EMAIL_VERIFICATION_REQUEST';
export const SEND_EMAIL_VERIFICATION_SUCCESS = 'SEND_EMAIL_VERIFICATION_SUCCESS';
export const SEND_EMAIL_VERIFICATION_FAILURE = 'SEND_EMAIL_VERIFICATION_FAILURE';

export const VERIFY_EMAIL_REQUEST = 'VERIFY_EMAIL_REQUEST';
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_FAILURE = 'VERIFY_EMAIL_FAILURE';

export const SET_PASSWORD_REQUEST = 'SET_PASSWORD_REQUEST';
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS';
export const SET_PASSWORD_FAILURE = 'SET_PASSWORD_FAILURE';

export const UNAUTHORISED_USER_REQUEST = 'UNAUTHORISED_USER_REQUEST';
export const UNAUTHORISED_USER_SUCCESS = 'UNAUTHORISED_USER_SUCCESS';

export const LOGIN_GOOGLE_REQUEST = 'LOGIN_GOOGLE_REQUEST';
export const LOGIN_GOOGLE_SUCCESS = 'LOGIN_GOOGLE_SUCCESS';
export const LOGIN_GOOGLE_FAILURE = 'LOGIN_GOOGLE_FAILURE';

export const LOGIN_FACEBOOK_REQUEST = 'LOGIN_FACEBOOK_REQUEST';
export const LOGIN_FACEBOOK_SUCCESS = 'LOGIN_FACEBOOK_SUCCESS';
export const LOGIN_FACEBOOK_FAILURE = 'LOGIN_FACEBOOK_FAILURE';
export const CLOSE_IS_LOGINING = 'CLOSE_IS_LOGINING';
// ----------- USER CONSTANTS HERE --------------------------

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

export const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAILURE = 'EDIT_USER_FAILURE';
export const CHANGE_SHOPPERINFO = 'CHANGE_SHOPPERINFO';

export const GET_ALLERGY_REQUEST = 'GET_ALLERGY_REQUEST';
export const FETCHING_ALLERGY_REQUEST = 'FETCHING_ALLERGY_REQUEST';
export const GET_ALLERGY_SUCCESS = 'GET_ALLERGY_SUCCESS';
export const GET_ALLERGY_FAILURE = 'GET_ALLERGY_FAILURE';

export const GET_DIET_REQUEST = 'GET_DIET_REQUEST';
export const GET_DIET_SUCCESS = 'GET_DIET_SUCCESS';
export const GET_DIET_FAILURE = 'GET_DIET_FAILURE';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';

export const GET_ORDER_HISTORY_REQUEST = 'GET_ORDER_HISTORY_REQUEST';
export const GET_ORDER_HISTORY_SUCCESS = 'GET_ORDER_HISTORY_SUCCESS';
export const GET_ORDER_HISTORY_ERROR = 'GET_ORDER_HISTORY_ERROR';

export const GET_PAYMENT_METHODS_REQUEST = 'GET_PAYMENT_METHODS_REQUEST';
export const GET_PAYMENT_METHODS_SUCCESS = 'GET_PAYMENT_METHODS_SUCCESS';
export const GET_PAYMENT_METHODS_FAILURE = 'GET_PAYMENT_METHODS_FAILURE';

export const DELETE_PAYMENT_METHOD_REQUEST = 'DELETE_PAYMENT_METHOD_REQUEST';
export const DELETE_PAYMENT_METHOD_SUCCESS = 'DELETE_PAYMENT_METHOD_SUCCESS';
export const DELETE_PAYMENT_METHOD_FAILURE = 'DELETE_PAYMENT_METHOD_FAILURE';

export const ADD_PAYMENT_METHOD_REQUEST = 'ADD_PAYMENT_METHOD_REQUEST';
export const ADD_PAYMENT_METHOD_SUCCESS = 'ADD_PAYMENT_METHOD_SUCCESS';
export const ADD_PAYMENT_METHOD_FAILURE = 'ADD_PAYMENT_METHOD_FAILURE';

export const UPDATE_PAYMENT_METHOD_REQUEST = 'UPDATE_PAYMENT_METHOD_REQUEST';
export const UPDATE_PAYMENT_METHOD_SUCCESS = 'UPDATE_PAYMENT_METHOD_SUCCESS';
export const UPDATE_PAYMENT_METHOD_FAILURE = 'UPDATE_PAYMENT_METHOD_FAILURE';

export const TOGGLE_ALLERGY = 'TOGGLE_ALLERGY';
export const ADD_ALLERGY = 'ADD_ALLERGY';
export const DELETE_ALLERGY = 'DELETE_ALLERGY';
export const SELECT_ALLERGY = 'SELECT_ALLERGY';
export const SET_IS_LOADED_USER = 'SET_IS_LOADED_USER';

// ----------- INGREDIENT CONSTANTS HERE --------------------------
export const GET_INGREDIENTS_REQUEST = 'GET_INGREDIENTS_REQUEST';
export const GET_INGREDIENTS_SUCCESS = 'GET_INGREDIENTS_SUCCESS';
export const GET_INGREDIENTS_FAILURE = 'GET_INGREDIENTS_FAILURE';

export const SELECT_INGREDIENT = 'SELECT_INGREDIENT';

//CLEAR FLAGS
export const CLEAR_FLAGS_IS_CHANGE_EXTRA_CATEGORIES = 'CLEAR_FLAGS_IS_CHANGE_EXTRA_CATEGORIES';
export const CLEAR_FLAGS_IS_UPDATE_CATEGORIES = 'CLEAR_FLAGS_IS_UPDATE_CATEGORIES';
export const CLEAR_STATE_REGISTER_SUCCESS = 'CLEAR_STATE_REGISTER_SUCCESS';
export const CLEAR_FLASH_IS_UPDATED_ALLERGIES = 'CLEAR_FLASH_IS_UPDATED_ALLERGIES';
export const CLEAR_FLASH_IS_CHANGE_COOKING_TIME = 'CLEAR_FLASH_IS_CHANGE_COOKING_TIME';

//FAVORITE
export const UPDATE_RECIPE_FAVORITE_REQUEST = 'UPDATE_RECIPE_FAVORITE_REQUEST';
export const UPDATE_RECIPE_FAVORITE_SUCCESS = 'UPDATE_RECIPE_FAVORITE_SUCCESS';
export const UPDATE_RECIPE_FAVORITE_FAILURE = 'UPDATE_RECIPE_FAVORITE_FAILURE';
export const GET_RECIPE_FAVORITE = 'GET_RECIPE_FAVORITE';
export const CLOSE_RECIPE_FAVORITE = 'CLOSE_RECIPE_FAVORITE';
export const GET_RECIPE_FAVORITE_SUCCESS = 'GET_RECIPE_FAVORITE_SUCCESS';
export const GET_RECIPE_FAVORITE_FAILURE = 'GET_RECIPE_FAVORITE_FAILURE';
export const TOGGLE_SHOW_RECIPE_FAVORITE = 'TOGGLE_SHOW_RECIPE_FAVORITE';

// ----------- CART-----------
export const GET_CART_DETAILS = 'GET_CART_DETAILS';
export const GET_CART_DETAILS_SUCCESS = 'GET_CART_DETAILS_SUCCESS';

export const GENERATE_SHARE_CART_URL = 'GENERATE_SHARE_CART_URL';
export const SHARE_CART_URL_SUCCESS = 'SHARE_CART_URL_SUCCESS';
export const GET_SHARE_CART_URL = 'GET_SHARE_CART_URL';

export const SET_CART_DELIVERY_INFO = 'SET_CART_DELIVERY_INFO';
export const CART_DELIVERY_INFO_SUCCESS = 'CART_DELIVERY_INFO_SUCCESS';

export const SET_INGREDIENTS_TO_COST = 'SET_INGREDIENTS_TO_COST';

export const SET_WAIVEOFF_DELIVERY_FEE = 'SET_WAIVEOFF_DELIVERY_FEE';

export const SET_CALORIE_INFO = 'SET_CALORIE_INFO';
export const SET_CALORIE_INFO_FETCHING = 'SET_CALORIE_INFO_FETCHING';
export const SET_CALORIE_INFO_SUCCESS = 'SET_CALORIE_INFO_SUCCESS';

//COMMON INGREDIENTS
export const GET_COMMON_INGREDIENTS_REQUEST = 'GET_COMMON_INGREDIENTS_REQUEST';
export const GET_COMMON_INGREDIENTS_SUCCESS = 'GET_COMMON_INGREDIENTS_SUCCESS';
export const GET_COMMON_INGREDIENTS_FAILURE = 'GET_COMMON_INGREDIENTS_FAILURE';
export const SET_FETCHING_COMMON_INGREDIENT = 'SET_FETCHING_COMMON_INGREDIENT';

// ----------- GLOBAL UI CONSTANTS HERE --------------------------
export const UPDATE_STATE_SUMMARY_POPUP = 'UPDATE_STATE_SUMMARY_POPUP';
export const UPDATE_STATE_MOBILE_SUMMARY_POPUP = 'UPDATE_STATE_MOBILE_SUMMARY_POPUP';
export const SET_DEFAULT_SERVINGS = 'SET_DEFAULT_SERVINGS';
export const UPDATE_STATE_SERVINGS_POPUP = 'UPDATE_STATE_SERVINGS_POPUP';
export const UPDATE_STATE_FILTERS_POPUP = 'UPDATE_STATE_FILTERS_POPUP';
export const UPDATE_STATE_SEARCH_POPUP = 'UPDATE_STATE_SEARCH_POPUP';
export const SHOW_MOBILE_BAR_EXPANDED = 'SHOW_MOBILE_BAR_EXPANDED';
export const UPDATE_STATE_EXTRA_GROCERIES_POPUP = 'UPDATE_STATE_EXTRA_GROCERIES_POPUP';
export const SET_VALUE_INPUT_EXTRA_GROCERIES_POPUP = 'SET_VALUE_INPUT_EXTRA_GROCERIES_POPUP';
export const UPDATE_STATE_REVIEW_PRODUCTS_POPUP = 'UPDATE_STATE_REVIEW_PRODUCTS_POPUP';
export const ANY_PANTRY_ITEM_ADDED_IN_CART = 'ANY_PANTRY_ITEM_ADDED_IN_CART';
export const UPDATE_STATE_SUBSCRIPTION_SECTION = 'UPDATE_STATE_SUBSCRIPTION_SECTION';
export const UPDATE_STATE_MINIMUM_POPUP = 'UPDATE_STATE_MINIMUM_POPUP';
export const UPDATE_STATE_ADD_INGREDIENT_POPUP = 'UPDATE_STATE_ADD_INGREDIENT_POPUP';
export const UPDATE_STATE_REMOVE_INGREDIENT_POPUP = 'UPDATE_STATE_REMOVE_INGREDIENT_POPUP';
export const UPDATE_STATE_EDIT_PANTRY_POPUP = 'UPDATE_STATE_EDIT_PANTRY_POPUP';
export const UPDATE_STATE_REVIEW_GROCERIES_POPUP = 'UPDATE_STATE_REVIEW_GROCERIES_POPUP';
export const SHOW_REVIEW_GROCERIES_POPUP = 'SHOW_REVIEW_GROCERIES_POPUP';
export const UPDATE_NO_ACTION_POPUP = 'UPDATE_NO_ACTION_POPUP';
export const UPDATE_SHOW_GROCERIES_SECTION = 'UPDATE_SHOW_GROCERIES_SECTION';
export const SHOW_NO_ACTION_POPUP = 'SHOW_NO_ACTION_POPUP';
export const SHOW_DISCOUNT_RECIPES_BANNER = 'SHOW_DISCOUNT_RECIPES_BANNER';
export const SHOW_PRINT_SHOPPING_POPUP = 'SHOW_PRINT_SHOPPING_POPUP';
export const SHOW_HOW_IT_WOKRS_POPPUP = 'SHOW_HOW_IT_WOKRS_POPPUP';

export const SET_EXCLUDE_CATEGORY_FILTERS = 'SET_EXCLUDE_CATEGORY_FILTERS';
export const REMOVED_INGREDIENT_LIST = 'UPDATE_INGREDIENT_LIST';
export const ADDED_NON_REQUIRED_PRODUCT_LIST = 'ADDED_NON_REQUIRED_PRODUCT_LIST';

export const SHOW_PAYMENT_SUCCESS_POPUP = 'SHOW_PAYMENT_SUCCESS_POPUP';
export const SHOW_PAYMENT_ERROR_POPUP = 'SHOW_PAYMENT_ERROR_POPUP';
export const SHOW_PAYMENT_LOADING_POPUP = 'SHOW_PAYMENT_LOADING_POPUP';
export const SET_TEMPORARY_ORDER_INFORMATION = 'SET_TEMPORARY_ORDER_INFORMATION';
export const SET_ACTIVE_BUTOTON = 'SET_ACTIVE_BUTOTON';
export const CLEAR_ACTIVE_BUTOTON = 'CLEAR_ACTIVE_BUTOTON';
export const SET_IS_SHOW_COMMON_INGREDIENTS = 'SET_IS_SHOW_COMMON_INGREDIENTS';
export const SET_IS_CLOSED_COMMON_INGREDIENTS = 'SET_IS_CLOSED_COMMON_INGREDIENTS';
export const SHOW_CREATE_CARD_ELEMENT_ERROR = 'SHOW_CREATE_CARD_ELEMENT_ERROR';
export const SHOW_SUMMARY_DRAW = 'SHOW_SUMMARY_DRAW';
export const SET_SHOW_HEADER_RECIPE_PAGE = 'SET_SHOW_HEADER_RECIPE_PAGE';
export const SHOW_BUILD_MENU_FILTERS_POPUP = 'SHOW_BUILD_MENU_FILTERS_POPUP';
export const SHOW_CALORIE_GOAL_POPUP = 'SHOW_CALORIE_GOAL_POPUP';
export const SHOW_REMOVE_GROCERIES_BUTTON = 'SHOW_REMOVE_GROCERIES_BUTTON';
export const SET_CONFIRMOTION_DIALOGUE_OPEN = 'SET_CONFIRMOTION_DIALOGUE_OPEN';
export const SET_SHARERECIPE_DIALOGUE_OPEN = 'SET_SHARERECIPE_DIALOGUE_OPEN';

// ----------- USER PANTRY CONSTANTS HERE --------------------------
export const GET_USER_PANTRY_REQUEST = 'GET_USER_PANTRY_REQUEST';
export const GET_USER_PANTRY_SUCCESS = 'GET_USER_PANTRY_SUCCESS';
export const GET_USER_PANTRY_FAILURE = 'GET_USER_PANTRY_FAILURE';

export const ADD_USER_PANTRY_REQUEST = 'ADD_USER_PANTRY_REQUEST';
export const ADD_USER_PANTRY_SUCCESS = 'ADD_USER_PANTRY_SUCCESS';
export const ADD_USER_PANTRY_FAILURE = 'ADD_USER_PANTRY_FAILURE';

export const REMOVE_USER_PANTRY_REQUEST = 'REMOVE_USER_PANTRY_REQUEST';
export const REMOVE_USER_PANTRY_SUCCESS = 'REMOVE_USER_PANTRY_SUCCESS';
export const REMOVE_USER_PANTRY_FAILURE = 'REMOVE_USER_PANTRY_FAILURE';
export const SET_USER_ITEM_PANTRY_REMOVE = 'SET_USER_ITEM_PANTRY_REMOVE';
export const CLEAR_USER_ITEM_PANTRY_REMOVE = 'CLEAR_USER_ITEM_PANTRY_REMOVE';

export const SET_USER_ITEM_PANTRY_UPDATE = 'SET_USER_ITEM_PANTRY_UPDATE';
export const UPDATE_USER_PANTRY_ITEMS = 'UPDATE_USER_PANTRY_ITEMS';
export const UPDATE_USER_PANTRY_REQUEST = 'UPDATE_USER_PANTRY_REQUEST';
export const UPDATE_USER_PANTRY_SUCCESS = 'UPDATE_USER_PANTRY_SUCCESS';
export const UPDATE_USER_PANTRY_FAILURE = 'UPDATE_USER_PANTRY_FAILURE';
export const SET_SORT_ORDERS = 'SET_SORT_ORDERS';
export const SET_OPEN_MODAL_SHARE = 'SET_OPEN_MODAL_SHARE';
export const CLEAR_PROMO_CODE_DISCOUNT = 'CLEAR_PROMO_CODE_DISCOUNT';
export const UPDATE_DRAWER_STATE = 'UPDATE_DRAWER_STATE';

export const SET_INGREDIENTS_EDIT = 'SET_INGREDIENTS_EDIT';
export const CLEAR_INGREDIENTS_EDIT = 'CLEAR_INGREDIENTS_EDIT';

export const SET_FETCHING_CART = 'SET_FETCHING_CART';
export const SET_FETCHED_CART = 'SET_FETCHED_CART';

// ----------- INFLUNECER CONSTANTS HERE --------------------------

export const GET_INFLUENCER_REQUEST = 'GET_INFLUENCER_REQUEST';
export const GET_INFLUENCER_SUCCESS = 'GET_INFLUENCER_SUCCESS';
export const GET_INFLUENCER_FAILURE = 'GET_INFLUENCER_FAILURE';

//
export const GET_FILTER_AVAILABLE = 'GET_FILTER_AVAILABLE';

// ----------- FEATURE FLAGS CONSTANTS HERE --------------------------

export const GET_FEATURE_FLAGS_REQUEST = 'GET_FEATURE_FLAGS_REQUEST';
export const GET_FEATURE_FLAGS_SUCCESS = 'GET_FEATURE_FLAGS_SUCCESS';
export const GET_FEATURE_FLAGS_FAILURE = 'GET_FEATURE_FLAGS_FAILURE';

// ------------------- USA EXPANSION -----------------------
export const SET_USER_LOCATION = 'SET_USER_LOCATION';

// ----------- INGREDIENT SUBSTITUTE HERE -------------------------

export const SET_MANUAL_INGREDIENT_SUBSTITUTION = 'SET_MANUAL_INGREDIENT_SUBSTITUTION';
export const SET_MANUAL_INGREDIENT_SUBSTITUTION_SUCCESS = 'SET_MANUAL_INGREDIENT_SUBSTITUTION_SUCCESS';
export const SET_MANUAL_INGREDIENT_SUBSTITUTION_FAILURE = 'SET_MANUAL_INGREDIENT_SUBSTITUTION_FAILURE';

export const SET_SYSTEM_DEFINE_SUSBTITUTES = 'SET_SYSTEM_DEFINE_SUSBTITUTES';
export const SET_SYSTEM_DEFINE_SUSBTITUTES_SUCCESS = 'SET_SYSTEM_DEFINE_SUSBTITUTES_SUCCESS';
export const SET_SYSTEM_DEFINE_SUSBTITUTES_FAILURE = 'SET_SYSTEM_DEFINE_SUSBTITUTES_FAILURE';

//-------------------- CURATED RECIPES ------------------------------
export const GET_CURATED_RECIPES_REQUEST = 'GETCURATED_RECIPES_REQUEST';
export const GET_CURATED_RECIPES_SUCCESS = 'GETCURATED_RECIPES_SUCCESS';
export const GET_CURATED_RECIPES_FAILURE = 'GETCURATED_RECIPES_FAILURE';
export const FETCHING_CURATED_RECIPES = 'FETCHING_CURATED_RECIPES';
export const FETCHED_CURATED_RECIPES = 'FETCHED_CURATED_RECIPES';

export const GET_CURATED_RECIPE_REQUEST = 'GETCURATED_RECIPE_REQUEST';
export const GET_CURATED_RECIPE_SUCCESS = 'GETCURATED_RECIPE_SUCCESS';
export const GET_CURATED_RECIPE_FAILURE = 'GETCURATED_RECIPE_FAILURE';
export const LOAD_MORE_CURATED_RECIPES = 'LOAD_MORE_CURATED_RECIPES';

//-------------------- EMAIL SHOPPING LIST ------------------------------
export const SET_EMAIL_SHOPPING_LIST = 'SET_EMAIL_SHOPPING_LIST';
export const SET_EMAIL_SHOPPING_LIST_SUCCESS = 'SET_EMAIL_SHOPPING_LIST_SUCCESS';

//-------------------- AGGREGATE DATA ------------------------------
export const GET_INITIAL_DATA_REQUEST = 'GET_INITIAL_DATA_REQUEST';
export const GET_INITIAL_DATA_SUCCESS = 'GET_INITIAL_DATA_SUCCESS';

//---------------- INITIALIZE PAGE DATA -----------------------------
export const GET_COLLECTION_PAGE_DATA = 'GET_COLLECTION_PAGE_DATA';
export const GET_RECIPE_PAGE_DATA = 'GET_RECIPE_PAGE_DATA';
export const GET_MINIMUM_ORDER_PAGE_DATA = 'GET_MINIMUM_ORDER_PAGE_DATA';

export const GET_RECIPE_ITEM_PAGE_DATA = 'GET_RECIPE_ITEM_PAGE_DATA';
export const LOADING_RECIPE_ITEM_PAGE = 'LOADING_RECIPE_ITEM_PAGE';
export const LOADED_RECIPE_ITEM_PAGE = 'LOADED_RECIPE_ITEM_PAGE';

export const GET_CATEGORY_PAGE_DATA = 'GET_CATEGORY_PAGE_DATA';
export const LOADING_CATEGORY_PAGE_DATA = 'LOADING_CATEGORY_PAGE_DATA';
export const LOADED_CATEGORY_PAGE_DATA = 'LOADED_CATEGORY_PAGE_DATA';
export const GET_BUILD_MENU_PAGE_DATA = 'GET_BUILD_MENU_PAGE_DATA';
export const GET_USE_PANTRY_PAGE_DATA = 'GET_USE_PANTRY_PAGE_DATA';

export const GET_COLLECTIONS_PAGE = 'GET_COLLECTIONS_PAGE';
export const GET_CHECKOUT_PAGE = 'GET_CHECKOUT_PAGE';
export const LOADING_PAGE = 'LOADING_PAGE';
export const LOADED_PAGE = 'LOADED_PAGE';
export const UPDATE_GROCERIES_PLAN = 'UPDATE_GROCERIES_PLAN';
export const GET_GROCERIES_PLAN_PAGE = 'GET_GROCERIES_PLAN_PAGE';
export const GET_GROCERIES_MANAGEMENT_PAGE = 'GET_GROCERIES_MANAGEMENT_PAGE';
export const GET_RECENT_ORDERS_PAGE = 'GET_RECENT_ORDERS_PAGE';
export const GET_SETTINGS_PAGE = 'GET_SETTINGS_PAGE';

//-------------------- GET NUTRITION PROGRAM ------------------------------
export const GET_NUTRITION_PROGRAMS_REQUEST = 'GET_NUTRITION_PROGRAMS_REQUEST';
export const GET_NUTRITION_PROGRAMS_SUCCESS = 'GET_NUTRITION_PROGRAMS_SUCCESS';

//-------------------- BUILD MENU ------------------------------
export const SET_SELECTED_NUTRITION_PROGRAM = 'SET_SELECTED_NUTRITION_PROGRAM';
export const SET_SELECTED_DIET = 'SET_SELECTED_DIET';
export const SET_SELECTED_MEAL_TYPE = 'SET_SELECTED_MEAL_TYPE';
export const SET_CALORIES_GOAL = 'SET_CALORIES_GOAL';
export const SET_SELECTED_ALLERGIES = 'SET_SELECTED_ALLERGIES';
export const SET_SELECTED_DISLIKES = 'SET_SELECTED_DISLIKES';
export const SET_COOKING_TIME_FILTER = 'SET_COOKING_TIME_FILTER';
export const SET_DIFFICULTY_LEVEL = 'SET_DIFFICULTY_LEVEL';
export const SET_BUILD_MENU_INITIAL_STATE = 'SET_BUILD_MENU_INITIAL_STATE';
export const GET_BUILD_MENU_RESULT_SUCCESS = 'SET_BUILD_MENU_RESULT_SUCCESS';
export const GET_BUILD_MENU_RECIPES = 'GET_BUILD_MENU_RECIPES';
export const SET_EXPANDED_MEAL_TYPES = 'SET_EXPANDED_MEAL_TYPES';
export const SET_CURRENT_MEAL_TYPE_STEP = 'SET_CURRENT_MEAL_TYPE_STEP';
export const SET_EXPANDED_FIRST_TIME = 'SET_EXPANDED_FIRST_TIME';
export const SET_REVIEWED_MEAL_TYPES = 'SET_REVIEWED_MEAL_TYPES';
export const SET_MEAL_TYPES_IN_CART = 'SET_MEAL_TYPES_IN_CART';
export const SET_QUESTION_STEP = 'SET_QUESTION_STEP';
export const SET_EXPANDED_RECIPES = 'SET_EXPANDED_RECIPES';
export const SET_NUMBER_OF_PEOPLE = 'SET_NUMBER_OF_PEOPLE';
export const SET_NUMBER_OF_DAYS = 'SET_NUMBER_OF_DAYS';

//-------------------- MEAL TYPES ------------------------------
export const GET_MEAL_TYPES_REQUEST = 'GET_MEAL_TYPES_REQUEST';
export const GET_MEAL_TYPES_SUCCESS = 'GET_MEAL_TYPES_SUCCESS';

//-------------------- LINKEINBIO ------------------------------
export const GET_LINKEINBIO_REQUEST = 'GET_LINKEINBIO_REQUEST';
export const GET_LINKEINBIO_SUCCESS = 'GET_LINKEINBIO_SUCCESS';

//--------------------LOGIN POPUP ------------------------------

export const SHOW_LOGIN_POPUP = 'SHOW_LOGIN_POPUP';
export const SET_EMAIL_FROM_LOGIN_MODAL = 'SET_EMAIL_FROM_LOGIN_MODAL';
export const GET_LOGIN_INFO_BY_EMAIL = 'GET_LOGIN_INFO_BY_EMAIL';
export const GET_LOGIN_INFO_BY_EMAIL_SUCCESS = 'GET_LOGIN_INFO_BY_EMAIL_SUCCESS';
export const SELECTED_LOGIN_MODE = 'SELECTED_LOGIN_MODE';
