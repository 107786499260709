import { call, takeEvery } from 'redux-saga/effects';
import CategoryRecipesAPI from './CategoriesRecipesAPI';
import * as constants from '../constants';

export function* getCategoryRecipes({ categoryId }) {
    try {
        if (!window.location.pathname.includes('/categories')) return;
        yield call(CategoryRecipesAPI.make, {
            categoryId,
        });
    } catch (error) {
        console.log(error);
    }
}

export default function* () {
    yield takeEvery([constants.ADD_ALLERGY, constants.FILTER_RECIPES], getCategoryRecipes);
}
