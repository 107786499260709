import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import history from '../../history';
import useScreenType from 'hooks/useScreenType';

import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import { Tertiary, Secondary } from 'components/common/Buttons';
import IngredientSingle from 'components/common/IngredientSingle';

import { prepareRecipePayload } from 'helpers/klaviyoEvent';
import { pushLearnqEvent } from '../../helpers/trackEvents';
import { Events } from '../../constants/events.js';

import { updateStateReviewProductsPopup } from 'store/actions/ui-settings.actions';
import { getGroceryByBrandType } from 'utility/groceryUtil';

import {
    ExtraContainer,
    ExtraContent,
    HeaderContainer,
    ContentContainer,
    FooterContainer,
    ReviewTitle,
    ReviewButton,
} from './styles';
import influencerReducer from '../../store/reducers/indfluencer.reducer';

const ReviewProductsPopup = () => {
    const dispatch = useDispatch();
    const { isMobile } = useScreenType();

    const groceries = useSelector((state) => state.recipeReducer.Cart.groceries);
    const selectedRecipes = useSelector((state) => state.recipeReducer.Cart.selectedRecipes);
    const bulkType = useSelector((state) => state.recipeReducer.bulkType);
    const itemNoneRequired = useSelector((state) => state.recipeReducer.itemNoneRequired);
    const influencerHandle = useSelector((state) => state.influencerReducer.handle);

    const back = (e) => {
        e.stopPropagation();
        dispatch(updateStateReviewProductsPopup(false));
    };

    const nextPage = () => {
        pushLearnqEvent(
            Events.EVENT_STARTED_CHECKOUT,
            selectedRecipes.map((item) => prepareRecipePayload(item)),
            influencerHandle,
        );
        history.push({ pathname: '/checkout', state: { from: true } });
        dispatch(updateStateReviewProductsPopup(false));
    };

    return (
        <ExtraContainer
            scroll="body"
            open={true}
            onClose={back}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            $isMobile={isMobile}
        >
            <ExtraContent $isMobile={isMobile}>
                <HeaderContainer isMobile={isMobile}>
                    <IconButton onClick={back} size="small" aria-label="Add" variant="outline">
                        <Close />
                    </IconButton>
                    <ReviewTitle>Are you missing these pantry items for your recipes:</ReviewTitle>
                </HeaderContainer>
                <ContentContainer isMobile={isMobile}>
                    {groceries.nonRequiredProducts &&
                        groceries.nonRequiredProducts.map(
                            (ingredient, index) =>
                                !ingredient.fromUserPantry && (
                                    <IngredientSingle
                                        key={index}
                                        isRequired={ingredient.required}
                                        type="haveItem"
                                        ingredient={getGroceryByBrandType(
                                            {
                                                ...ingredient,
                                                youHave: ingredient.availableQuantity ? ingredient.availableQuantity : 0,
                                                youNeed: ingredient.quantity,
                                            },
                                            bulkType,
                                        )}
                                    />
                                ),
                        )}
                </ContentContainer>
                <FooterContainer isMobile={isMobile}>
                    <ReviewButton>
                        {itemNoneRequired > 0 ? (
                            <Secondary
                                onClick={nextPage}
                                size="medium"
                                title={`Add ${itemNoneRequired} item to cart and continue`}
                            />
                        ) : (
                            <Tertiary onClick={nextPage} size="medium" title="No thanks, continue to checkout" />
                        )}
                    </ReviewButton>
                </FooterContainer>
            </ExtraContent>
        </ExtraContainer>
    );
};

export default ReviewProductsPopup;
