import React from 'react';
import { SecondaryButtonContainer } from './styles';

const Secondary = ({ disabled, title = 'Show Results', size, onClick }) => {
    return (
        <SecondaryButtonContainer disabled={disabled} size={prepareSize(size)} onClick={onClick}>
            {title}
        </SecondaryButtonContainer>
    );
};

function prepareSize(size) {
    switch (size) {
        case 'large':
            return { width: '327px', height: '62px', padding: '16px 40px', fontSize: '20px' };
        case 'medium':
            return { width: '246px', height: '48px', padding: '12px 16px', fontSize: '16px' };
        case 'small':
            return { width: '125px', height: '40px', padding: '8px', fontSize: '16px' };
        case 'xsmall':
            return { width: '80px', height: '30px', padding: '3px', fontSize: '13px' };
        default:
            return { width: '100%', height: '40px', padding: '8px', fontSize: '16px' };
    }
}

export default Secondary;
