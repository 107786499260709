import React from 'react';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { ButtonWithIconContainer } from './styles';

const ButtonWithIcon = ({ disabled, icon = <ArrowForwardIosIcon />, title = 'Finalize Groceries', size, onClick, ...props }) => {
    return (
        <ButtonWithIconContainer disabled={disabled} size={prepareSize(size)} onClick={onClick} {...props}>
            <span>{title}</span>
            {icon}
        </ButtonWithIconContainer>
    );
};

function prepareSize(size) {
    switch (size) {
        case 'large':
            return { width: '344px', height: '64px', padding: '20px 40px', fontSize: '16px' };
        case 'medium':
            return { width: '271px', height: '48px', padding: '16px 24px', fontSize: '16px' };
        default:
            return { width: '100%', height: '48px', padding: '16px 24px', fontSize: '16px' };
    }
}
export default ButtonWithIcon;
