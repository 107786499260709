import styled, { css } from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import useScreenType from 'hooks/useScreenType';

export const AddIngredientContainer = styled(Dialog)`
    & > div:nth-child(3) {
        & > div:nth-child(1) {
            max-width: unset !important;
            width: 600px;
            background: #ffffff;
            border-radius: 16px;
            overflow-y: unset;

            ${(props) =>
                props.$isMobile &&
                css`
                    margin: 0;
                    width: 100%;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    border-radius: 16px 16px 0 0;
                `};
        }
    }
`;

export const AddIngredientContent = styled(DialogContent)`
    padding: 0 !important;
    overflow-y: unset !important;
`;

export const HeaderContainer = styled.div`
    min-height: 72px;
    border-bottom: 1px solid #e5eaed;

    & > button {
        position: absolute;
        top: 24px;
        left: 32px;
        color: #0d2120;
        padding: 0;
    }

    ${(props) =>
        props.isMobile &&
        css`
            & > button {
                display: none;
            }
        `};
`;

export const HeaderTitle = styled.div`
    text-align: center;
    padding: 26px 0;
    color: #0d2120;
    font-weight: 500;
    font-size: 16px;
    line-height: 124%;
`;

export const ContentContainer = styled.div`
    min-height: 125px;
    padding: 32px;
`;

export const EditIngredient = styled.div`
    padding-top: 24px;
    display: flex;
    align-items: center;

    ${(props) =>
        props.isMobile &&
        css`
            display: block;
        `};
`;

export const Calendar = styled.div`
    display: flex;
    align-items: center;
    width: 204px;

    & > div {
        & > div:nth-child(1) {
            height: 44px;

            & > input {
                height: 44px;
                padding: 10px 24px 10px 20px;
            }

            & > div > div {
                width: 396px;
                bottom: 44px;
            }
        }

        & > div:nth-child(2) {
            top: 15px;
        }
    }

    ${(props) =>
        props.isMobile &&
        css`
            width: 100%;
        `};
`;

export const Quantity = styled.div`
    width: 50%;
    display: flex;
    align-items: center;
    margin-left: auto;

    ${(props) =>
        props.isMobile &&
        css`
            width: 100%;
            padding-top: 24px;
        `};
`;

export const ButtonMinus = styled.div`
    margin-right: 16px;
    color: #82bd73;
    cursor: pointer;

    ${(props) =>
        props.disabled &&
        css`
            color: #bfc8cd;
            cursor: unset;
        `};
`;

export const ButtonPlus = styled.div`
    margin-left: 16px;
    color: #82bd73;
    cursor: pointer;

    ${(props) =>
        props.disabled &&
        css`
            color: #bfc8cd;
            cursor: unset;
        `};
`;

export const QuantityValue = styled.div`
    width: 100%;
    text-align: center;
    border: 1px solid #e5eaed;
    box-sizing: border-box;
    border-radius: 8px;
    color: #233747;
    letter-spacing: -0.4px;
    font-size: 14px;
    line-height: 146%;
    display: flex;
    padding: 0;
    height: 44px;

    & > input {
        border: none;
        color: #233747;
        letter-spacing: -0.4px;
        font-size: 14px;
        line-height: 146%;
        text-align: center;
        background: #fff;
        width: 100%;
        padding: 8px 12px;
        border-radius: 8px;
    }

    & > div {
        height: 44px;

        & > div > div {
            padding: 2px !important;
            padding-right: 20px !important;
            border-radius: 0 8px 8px 0;

            & > input {
                text-align: center;
            }

            & > div:nth-child(2) {
                right: 0 !important;

                & > button:nth-child(1) {
                    display: none !important;
                }
            }
        }
    }
`;

export const FooterContainer = styled.div`
    min-height: 88px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px 32px;
    border-top: 1px solid #e5eaed;

    ${(props) =>
        props.isMobile &&
        css`
            & > button {
                width: 100%;
            }
        `};
`;
