import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useScreenType from 'hooks/useScreenType';
import { useDebouncedCallback } from 'use-debounce';

import { updateStateExtraGroceriesPopup } from 'store/actions/ui-settings.actions';
import { getStateLocalStorage } from 'store/actions/recipe.action';
import { getExtraProductsRequest, searchExtraProducts } from 'store/actions/extraProduct.actions';

import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import InputSearch from 'components/common/InputSearch';
import ExtraProductCart from 'components/common/ExtraProductCart';
import notFindImg from 'assets/images/notFind.svg';
import Loading from 'components/common/Loading';

import {
    ExtraContainer,
    ExtraContent,
    HeaderContainer,
    ContentContainer,
    FooterContainer,
    ServingContainer,
    Serving,
    ListExtraProducts,
    ExtraTitle,
    Notification,
} from './styles';

const ExtraGroceriesContainer = () => {
    const dispatch = useDispatch();
    const { isMobile, isTabletOrMobile } = useScreenType();

    const [debouncedCallback] = useDebouncedCallback((value) => {
        dispatch(searchExtraProducts(value));
    }, 1000);

    const totalPriceExtra = useSelector((state) => state.recipeReducer.Cart.pricePayload.totalPriceExtra);
    const extraProductList = useSelector((state) => state.extraProductReducer.all);
    const isFetching = useSelector((state) => state.extraProductReducer.isFetching);
    const valueInputSearch = useSelector((state) => state.uiSettingsReducer.valueInputExtraGroceriesPopup);

    const [valueInput, setValueInput] = useState(valueInputSearch);

    const removeValueInput = () => {
        setValueInput('');
        debouncedCallback('');
    };

    useEffect(() => {
        if (valueInputSearch) {
            debouncedCallback(valueInputSearch);
        } else if (!extraProductList.length) {
            dispatch(getExtraProductsRequest());
        }
        dispatch(getStateLocalStorage());
    }, []);

    const back = (e) => {
        e.stopPropagation();
        dispatch(updateStateExtraGroceriesPopup(false));
    };

    const onChangeInputSearch = (e) => {
        setValueInput(e.target.value);
        debouncedCallback(e.target.value);
    };

    return (
        <ExtraContainer
            scroll="body"
            open={true}
            onClose={back}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            $isMobile={isMobile}
            $isTabletOrMobile={isTabletOrMobile}
        >
            <ExtraContent $isMobile={isMobile}>
                <HeaderContainer isMobile={isMobile}>
                    <IconButton onClick={back} size="small" aria-label="Add" variant="outline">
                        <Close />
                    </IconButton>
                    {isMobile && <ExtraTitle>Search</ExtraTitle>}
                    <InputSearch value={valueInput} onRemove={removeValueInput} onChange={onChangeInputSearch} />
                </HeaderContainer>
                <ContentContainer isFetching={isFetching} isMobile={isMobile}>
                    {isFetching ? (
                        <Loading color="primary" />
                    ) : (
                        <ListExtraProducts
                            isTabletOrMobile={isTabletOrMobile}
                            checkExtra={extraProductList.length}
                            isMobile={isMobile}
                        >
                            {extraProductList.length ? (
                                extraProductList.map((product, index) => (
                                    <div key={index}>
                                        <ExtraProductCart extraProduct={product} />
                                    </div>
                                ))
                            ) : (
                                <Notification isMobile={isMobile}>
                                    <img alt="" src={notFindImg} />
                                    <span>No products were found based on the search.</span>
                                </Notification>
                            )}
                        </ListExtraProducts>
                    )}
                </ContentContainer>
                <FooterContainer>
                    <ServingContainer>
                        <Serving>Total additional items:</Serving>
                        <Serving>{`$${totalPriceExtra || 0}`}</Serving>
                    </ServingContainer>
                </FooterContainer>
            </ExtraContent>
        </ExtraContainer>
    );
};

export default ExtraGroceriesContainer;
