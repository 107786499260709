import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useScreenType from 'hooks/useScreenType';
import useFeatureFlags from 'hooks/useFeatureFlags';
import useTheme from 'hooks/useTheme';
import debounce from 'lodash/debounce';
import useOnClickOutside from 'hooks/useClickOutside';

import { makeGroceryRequiredToUser } from 'store/actions/recipe.action';
import {
    anyPantryItemAddedInCart,
    updateStateAddIngredientPopup,
    updateStateEditPantryPopup,
} from 'store/actions/ui-settings.actions';
import { setManualIngredientSubstitute } from 'store/actions/ingredient.actions';
import { removeUserPantryRequest } from 'store/actions/userPantry.actions';

import Icons from 'components/common/Icons/Icons';
import Links from 'components/common/Links/Links';
import DropdownServings from 'components/common/Controls/DropdownServings/DropdownServings';

import { roundValue, getIngredientSubstitutes, prepareIngredientSubstitutes } from 'utility/groceryUtil';
import { getGroceryByBrandType } from 'utility/groceryUtil';

import {
    IngredientSingleContainer,
    Ingredient,
    IngredientInfo,
    ImageContainer,
    Image,
    NameGroup,
    Name,
    SubName,
    QuantityContainer,
    ProgressContainer,
    Progress,
    QuantityPercent,
    Quantity,
    RemoveButton,
    HaveQuantity,
    SubstituteTag,
    QuantityAvailable,
    QuantityNumber,
    ShowLabel,
    DonotHaveLabel,
    DropdownContainer,
    Dropdowns,
    DetailsDropdown,
} from './styles';

const IngredientSingle = ({ ingredient, isRequired, recipeId, type = '' }) => {
    const dispatch = useDispatch();
    const color = useTheme();
    const ref = useRef();
    const { isMobile, isDesktopOrLaptop } = useScreenType();
    const { isIngredientSubstituteEnabled } = useFeatureFlags();

    const loggedInUser = useSelector((state) => state.authenReducer.loggedInUser);
    const groceries = useSelector((state) => state.recipeReducer.Cart.groceries);
    const userPantryItems = useSelector((state) => state.userPantryReducer.userPantryItems);
    const substitutes = useSelector((state) => state.recipeReducer.Cart.groceries.substitutes);
    const ingredientsToCost = useSelector((state) => state.recipeReducer.Cart.ingredientsToCost);
    const ingredientSubstituteSuccess = useSelector((state) => state.ingredientReducer.ingredientSubstituteSuccess);

    const [youHave, setYouHave] = useState(0);
    const [youNeed, setYouNeed] = useState(0);
    const [required, setRequired] = useState();
    const [unit, setUnit] = useState('');
    const [displayProgress, setDisplayProgress] = useState(true);
    const [unusedFromPantry, setUnusedFromPantry] = useState();
    const [ingredientSubstitute, setIngredientSubstitute] = useState();
    const [ingredientSubstituteId, setIngredientSubstituteId] = useState();
    const [isReplaced, setIsReplaced] = useState(false);
    const [showIngredientSubstitute, setShowIngredientSubstitute] = useState(false);
    const [showIngredientSubstituteOptions, setShowIngredientSubstituteOptions] = useState(false);
    const [showIngredientDetail, setShowIngredientDetail] = useState(false);

    useOnClickOutside(ref, () => {
        if (showIngredientDetail) {
            setShowIngredientDetail(false);
        }
    });
    useEffect(() => {
        if (type != 'newItem' && type != 'haveItem') {
            setDisplayProgress(false);
        }
    }, []);

    useEffect(() => {
        setRequired((ingredient.required || ingredient.requiredToUser) && ingredient.ingredientAmountDelivered > 0);
        setYouHave(ingredient.youHave);
        setYouNeed(ingredient.youNeed);
    }, [ingredient]);

    useEffect(() => {
        if (showIngredientSubstituteOptions) {
            return setRequired(true);
        }
        setRequired((ingredient.required || ingredient.requiredToUser) && ingredient.ingredientAmountDelivered > 0);
    }, [showIngredientSubstituteOptions]);

    useEffect(() => {
        if (ingredientSubstituteSuccess) {
            setIngredientSubstituteId({});
        }
    }, [ingredientSubstituteSuccess]);

    useEffect(() => {
        let have = 0;
        let need = 0;
        groceries &&
            groceries.requiredProducts.map((categories) => {
                categories.ingredients.map((i) => {
                    if (i._id === ingredient.id) {
                        if (i.fromUserPantry) {
                            have += parseFloat(i.quantity);
                        }
                    }
                });
            });
        groceries &&
            groceries.nonRequiredProducts.map((i) => {
                if (i._id === ingredient.id) {
                    if (i.fromUserPantry) {
                        have += parseFloat(i.quantity);
                    }
                }
            });
        need = ingredient.totalIngredientAmountRequired;
        setYouHave(roundValue(have));
        setYouNeed(roundValue(need));
    }, [groceries, ingredient]);

    useEffect(() => {
        if (ingredient.id && ingredientsToCost) {
            const ingredientSubstitute = ingredientsToCost.find(
                ({ originalIngredient: i, isSubstitute }) => i == ingredient.id && isSubstitute,
            );
            if (ingredientSubstitute) {
                let have = 0;
                let need = 0;
                const ingredientSubstituteAvailability = getIngredientSubstitutes(ingredient.id, substitutes).find(
                    ({ ingredient }) => ingredient == ingredientSubstitute.ingredient,
                ).availability;
                const ing =
                    ingredientSubstituteAvailability.find((i) => !i.fromUserPantry) || ingredientSubstituteAvailability[0];
                ingredientSubstituteAvailability.map((i) => {
                    if (i.fromUserPantry) {
                        have += parseFloat(i.quantity);
                    }
                });
                need = ing.totalIngredientAmountRequired;
                setYouHave(roundValue(have));
                setYouNeed(roundValue(need));

                setRequired((ingredient.required || ingredient.requiredToUser) && ing.ingredientAmountDelivered > 0);
                setIngredientSubstitute(getGroceryByBrandType(ing, 'LOW_COST'));
                return setIsReplaced(true);
            }
            setYouHave(roundValue(ingredient.youHave));
            setYouNeed(roundValue(ingredient.youNeed));
            setIsReplaced(false);
            setIngredientSubstitute(null);
        }
    }, [ingredientsToCost, ingredient, ingredientSubstituteSuccess]);

    useEffect(() => {
        if (
            (ingredient.leftOverQuantity === 0 && ingredient.fromUserPantry) ||
            (ingredientSubstitute && ingredientSubstitute.leftOverQuantity === 0 && ingredientSubstitute.fromUserPantry)
        ) {
            let unused = 0;
            userPantryItems &&
                userPantryItems
                    .filter((i) => i.ingredientId === (ingredientSubstitute ? ingredientSubstitute.id : ingredient.id))
                    .map((i) => {
                        unused += parseFloat(i.quantity);
                    });
            if (roundValue(unused - parseFloat(youNeed)) < 0) {
                setUnusedFromPantry((0.0).toFixed(2));
            } else {
                setUnusedFromPantry(roundValue(unused - parseFloat(youNeed)));
            }
        }
    }, [userPantryItems, ingredient, ingredientSubstitute]);

    const onMakeGroceryRequiredToUser = () => {
        setRequired((prev) => !prev);
        dispatch(makeGroceryRequiredToUser(ingredient.id, isRequired));
        dispatch(anyPantryItemAddedInCart(true));
    };

    const handleDontHaveAnyMore = () => {
        let ingredientPantryId;
        if (ingredientSubstitute) {
            ingredientPantryId = userPantryItems?.find((item) => {
                if (item.ingredientId == ingredientSubstitute.id) return item;
            });
        } else
            ingredientPantryId = userPantryItems?.find((item) => {
                if (item.ingredientId == ingredient.id) return item;
            });
        dispatch(removeUserPantryRequest([ingredientPantryId.id]));
        dispatch(makeGroceryRequiredToUser(ingredient.id, isRequired, false));
    };

    const handleCustomIngredient = () => {
        if (youHave == 0) {
            dispatch(updateStateAddIngredientPopup(true, ingredientSubstitute ? ingredientSubstitute.id : ingredient.id));
        } else {
            dispatch(updateStateEditPantryPopup(true, ingredientSubstitute ? ingredientSubstitute.id : ingredient.id));
        }
    };

    const handleSaveIngredientSubstitute = (flag, id) => {
        dispatch(
            setManualIngredientSubstitute({
                ingredient: ingredient.id,
                isSubstituteEnabled: flag,
                substitute: {
                    ingredient: id,
                },
            }),
        );
    };
    const renderIngredientSubstituteOptions = () => {
        if (
            showIngredientSubstitute &&
            isIngredientSubstituteEnabled &&
            getIngredientSubstitutes(ingredient.id, substitutes || [])
        ) {
            return (
                <DropdownServings
                    title="Swap"
                    isOpen={showIngredientSubstituteOptions}
                    width={isMobile ? '100%' : 'max-content'}
                    itemFontSize={isMobile ? '14px' : '16px'}
                    itemColor={color.gray1 || '#233747'}
                    value={ingredientSubstituteId || {}}
                    placeHolder={'Select substitute'}
                    isFilterOrders={true}
                    changeOnHover={false}
                    setIsOpen={() => {
                        setShowIngredientSubstitute(false);
                        setShowIngredientSubstituteOptions(false);
                    }}
                    name={'substitute'}
                    onSelect={(item) => {
                        setShowIngredientSubstitute(false);
                        setShowIngredientSubstituteOptions(false);
                        setIngredientSubstituteId(item);
                        handleSaveIngredientSubstitute(ingredient.id != item.value, item.value);
                    }}
                    options={prepareIngredientSubstitutes(
                        getIngredientSubstitutes(ingredient.id, substitutes || []),
                        ingredientSubstitute,
                        'LOW_COST',
                        ingredientSubstitute && ingredient,
                    )}
                />
            );
        }
        if (showIngredientDetail) {
            const ing = ingredientSubstitute ? ingredientSubstitute : ingredient;
            return (
                <DetailsDropdown ref={ref} onClick={() => setShowIngredientDetail(false)}>
                    <div className="detail-title">
                        <div>Details</div>
                        <Icons name="arrowUp" color="#82bd73" size="10" />
                    </div>
                    <div className="options">
                        <div className="option-title">You have</div>{' '}
                        <div className="option-value">
                            {Number(youHave) === 0 || type == 'newItem' ? '-' : youHave + ' ' + ing.baseMeasurableUnits}
                            {youHave > 0 && (
                                <Icons onClick={youHave > 0 ? handleCustomIngredient : () => {}} name="edit" size="16" />
                            )}
                        </div>
                    </div>
                    <div className="options">
                        <div className="option-title">You need</div>{' '}
                        <div className="option-value">
                            {Number(youNeed) === 0
                                ? '-'
                                : !ing.youNeedQuantity
                                ? youNeed + ' ' + ing.baseMeasurableUnits
                                : ing.youNeedQuantity + ' ' + `(${youNeed + ing.baseMeasurableUnits})`}
                        </div>
                    </div>
                    <div className="options">
                        <div className="option-title">You'll get</div>{' '}
                        <div className="option-value">
                            {ingredientSubstitute
                                ? ingredientSubstitute.ingredientAmountDelivered + ' ' + ing.baseMeasurableUnits
                                : ingredient.ingredientAmountDelivered + ' ' + ing.baseMeasurableUnits}
                        </div>
                    </div>
                </DetailsDropdown>
            );
        }
    };

    const renderInfoContainer = (ing) => (
        <IngredientInfo isMobile={isMobile} unRequired={!required}>
            <ImageContainer isMobile={isMobile}>
                <Image isMobile={isMobile} image={ing.image} />
            </ImageContainer>

            <NameGroup isMobile={isMobile}>
                <Name isMobile={isMobile} required={!required}>
                    {ing.name}
                </Name>
                {/* {!recipeId && ingredientSubstitute && <SubstituteTag isMobile={isMobile}>Replaced</SubstituteTag>} */}
                {/* {!recipeId && (
                    <SubName isMobile={isMobile}>
                        {ing.ingredientAmountDelivered === 0
                            ? 'Not delivered'
                            : `Get ${roundValue(ing.ingredientAmountDelivered)} ${ing.baseMeasurableUnits}`}
                    </SubName>
                )} */}
            </NameGroup>
        </IngredientInfo>
    );

    const renderQuantityContainer = (ing) => (
        <QuantityContainer unRequired={!required} isMobile={isMobile}>
            {(!recipeId || type == '') && (
                <ProgressContainer isMobile={isMobile}>
                    <Progress isMobile={isMobile} progress={Math.ceil(ing.usagePercentage)}>
                        <div></div>
                    </Progress>
                    <QuantityPercent isMobile={isMobile}>
                        {Math.ceil(ing.usagePercentage)}% used{' '}
                        {type == 'haveItem' && Number(youHave) > 0 && `(you have ${youHave + ing.baseMeasurableUnits}) `}
                    </QuantityPercent>
                </ProgressContainer>
            )}
            {!recipeId && ing.ingredientAmountDelivered > 0 && (
                <RemoveButton
                    required={required}
                    isMobile={isMobile}
                    isDesktopOrLaptop={isDesktopOrLaptop}
                    onClick={onMakeGroceryRequiredToUser}
                >
                    {required ? (
                        <Icons name="closePopup" size={isMobile ? '22' : '24'} />
                    ) : (
                        <Icons name="addCheckMark" size={isMobile ? '22' : '24'} />
                    )}
                    {!isMobile &&
                        (required ? (
                            <Icons name="filledClosePopup" size={isMobile ? '22' : '24'} />
                        ) : (
                            <Icons name="filledAddCheckMark" size={isMobile ? '22' : '24'} />
                        ))}
                </RemoveButton>
            )}
            <Dropdowns isOpen={showIngredientSubstitute || showIngredientDetail} isMobile={isMobile}>
                {!showIngredientSubstitute && !showIngredientDetail && (
                    <DropdownContainer
                        onClick={() => {
                            setShowIngredientDetail(true);
                        }}
                        info={true}
                    >
                        <Links label={'Info'} size="default" type="secondary" showIcon={false} />
                        <Icons name="arrowDown" size="16" color="#82BD73" />
                    </DropdownContainer>
                )}
                {!recipeId &&
                    !showIngredientSubstitute &&
                    !showIngredientDetail &&
                    isIngredientSubstituteEnabled &&
                    getIngredientSubstitutes(ingredient.id, substitutes || []) && (
                        <DropdownContainer
                            onClick={() => {
                                setShowIngredientSubstitute(true);
                                setShowIngredientSubstituteOptions(true);
                            }}
                        >
                            <Links
                                label={!recipeId && ingredientSubstitute ? 'Swapped' : 'Swap'}
                                size="default"
                                type="secondary"
                                showIcon={false}
                            />
                            <Icons name="arrowDown" size="16" color="#82BD73" />
                        </DropdownContainer>
                    )}
                {renderIngredientSubstituteOptions()}
            </Dropdowns>
        </QuantityContainer>
    );
    return (
        <IngredientSingleContainer isMobile={isMobile} isReplaced={isReplaced}>
            <Ingredient isMobile={isMobile}>
                {renderInfoContainer(ingredientSubstitute ? ingredientSubstitute : ingredient)}
                {/* {type != 'newItem' && youHave > 0 && (
                    <QuantityAvailable>
                        {type == 'restockItem' && <span style={{ opacity: !required && '0.4' }}>you have</span>}
                        {type == 'haveItem' && youHave && <span style={{ color: '#6DAC5D', opacity: '0.4' }}>In stock</span>}
                        {!type && <span>you need</span>}

                        <QuantityNumber onClick={required ? handleCustomIngredient : () => {}} fadeIn={type == 'haveItem'}>
                            {type == 'haveItem' && youHave && roundValue(Number(youHave))}
                            {type == 'restockItem' &&
                                `${roundValue(Number(youHave))}/${roundValue(ingredient.totalIngredientAmountRequired)} `}
                            {!type && roundValue(ingredient.totalIngredientAmountRequired)}
                            {ingredient.fromUserPantry && ingredient.baseMeasurableUnits}
                        </QuantityNumber>
                    </QuantityAvailable>
                )} */}
                {renderQuantityContainer(ingredientSubstitute ? ingredientSubstitute : ingredient)}
                {type === 'haveItem' && youHave > 0 && (
                    <DonotHaveLabel onClick={handleDontHaveAnyMore}>Don't have anymore</DonotHaveLabel>
                )}
            </Ingredient>
            {/* {isMobile && <div className={'mobile-ingredient-substitute-block'}>{renderIngredientSubstituteOptions()}</div>} */}
        </IngredientSingleContainer>
    );
};

export default IngredientSingle;
