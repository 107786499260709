import React from 'react';
import { useSelector } from 'react-redux';
import history from '../../../../history';
import Icons from 'components/common/Icons/Icons';
import styles from '../../groceriesPage.module.scss';

const BonusRecipes = () => {
    const minimumCommonIngredientRecipes = useSelector((state) => state.uiSettingsReducer.minimumCommonIngredientRecipes);

    return (
        <div className={styles.bonusRecipes}>
            <div className={styles.content}>
                <div className={styles.bonusInfo}>
                    <Icons name="listIcon" size="40" />
                    <div>
                        {minimumCommonIngredientRecipes}+ Bonus Recipes{' '}
                        <div>you can make with the recipes you have unused after cooking</div>
                    </div>
                </div>
                <div
                    className={styles.tryRecipes}
                    onClick={() =>
                        history.push({
                            pathname: '/use-pantry',
                            search: `?source=groceries`,
                            state: { from: true },
                        })
                    }
                >
                    Try Now <Icons name="arrowRight" size="18" />
                </div>
            </div>
        </div>
    );
};

export default BonusRecipes;
