import styled, { css } from 'styled-components';
export const InputContainer = styled.div`
    position: relative;
    width: ${(props) => (props.isMobile ? '100%' : '180px')};
    & > div:nth-child(1) {
        width: 100%;
        height: 56px;
        input {
            width: 100%;
            height: 56px;
            padding: 16px 22px;
            opacity: ${(props) => (props.disabled ? '0.3' : '1')};
            pointer-events: ${(props) => props.disabled && 'none'};
            background: #ffff;
            border-radius: 8px;
            color: #233747;
            display: flex;
            border-radius: 8px;
            padding: 16px 24px 16px 20px;
            border: ${(props) => (props.isActive ? '1px solid #82BD73' : '1px solid #e5eaed')};
            &:hover {
                border: 1px solid #82bd73;
            }
            ${({ isMobile }) =>
                isMobile &&
                css`
                    height: 40px;
                `}
        }
        ${({ isMobile }) =>
            isMobile &&
            css`
                height: 40px;
            `}
    }

    & > div:nth-child(2) {
        position: absolute;
        top: 22px;
        right: 20px;
        ${({ isMobile }) =>
            isMobile &&
            css`
                top: 13px;
            `}
    }
    .DayPickerInput-OverlayWrapper {
        width: 100%;
    }
    .DayPickerInput-Overlay {
        width: ${(props) => (props.isMobile ? '100%' : '365px')};
        bottom: calc(100% + 56px);
        background: #ffffff;
        border: 1px solid #ebebeb;
        box-sizing: border-box;
        box-shadow: 0px -8px 18px rgb(13 33 32 / 3%);
        border-radius: 12px;
    }
    .DayPicker {
        width: 100%;
    }
    .DayPickerInput {
        input {
            cursor: pointer;
        }
        ${({ isMobile }) =>
            isMobile &&
            css`
                height: 40px;
            `}
    }
    .DayPicker-wrapper {
        width: ${(props) => (props.isMobile ? '100%' : '365px')};
    }
    .DayPicker-Body {
        font-family: Work Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 146%;
        letter-spacing: -0.4px;
        color: #666666;
    }
    .DayPicker-Months {
        padding: 24px 43px 33px 43px;
    }
    .DayPicker-Month {
        width: 100%;
        margin: unset;
    }
    .DayPicker-Day {
        height: 30px;
        width: 30px;
        padding: 10px 6px;
        @media (max-width: 1024px) {
            padding: 10px 11px;
        }
        @media (max-width: 425px) {
            padding: 10px;
        }
        @media (max-width: 375px) {
            padding: 10px 12px;
        }
    }
    .DayPicker-Day--today {
        font-weight: 500;
        color: #0d2120;
    }
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
        position: relative;
        color: #f0f8ff;
        background: #82bd73;
    }
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
        background: #82bd73;
    }
`;
