import get from 'lodash/get';

export const prepareCollectionPayload = (mealPlan) => ({
    id: get(mealPlan, '_id', ''),
    key: get(mealPlan, 'key', ''),
    title: get(mealPlan, 'title', ''),
    description: get(mealPlan, 'description', ''),
    imageBanner: get(mealPlan, 'imageBanner', ''),
    cookingTime: get(mealPlan, 'cookingTime', 0),
});

export const prepareRecipePayload = (recipe) => ({
    id: get(recipe, '_id', ''),
    key: get(recipe, 'key', ''),
    name: get(recipe, 'name', ''),
    source: get(recipe, 'source', ''),
    mainImage: get(recipe, 'mainImage', ''),
    servings: get(recipe, 'servings', 0),
    customServings: get(recipe, 'customServings', 0),
    defaultServings: get(recipe, 'defaultServings', 0),
    difficulty: get(recipe, 'difficulty', ''),
    cookingTime: get(recipe, 'cookingTime', ''),
});
