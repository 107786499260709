import * as constants from '../constants';

const initialState = {
    emailInPopup: localStorage.getItem('emailInPopup') || '',
    accountInfo: {},
    isLoginPopupOpen: false,
};

const LoginModalReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.SHOW_LOGIN_POPUP:
            return {
                ...state,
                isLoginPopupOpen: action.payload,
            };
        case constants.SET_EMAIL_FROM_LOGIN_MODAL: {
            localStorage.setItem('emailInPopup', action.payload);
            return {
                ...state,
                emailInPopup: action.payload,
            };
        }
        case constants.GET_LOGIN_INFO_BY_EMAIL_SUCCESS:
            return {
                ...state,
                accountInfo: action.payload,
            };
        default:
            return state;
    }
};

export default LoginModalReducer;
