import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useScreenType from 'hooks/useScreenType';
import { prepareUserPantry } from 'utility/userPantryUtil';

import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import PantryItem from 'components/common/PantryItem';
import { Tertiary } from 'components/common/Buttons';
import IngredientList from 'pages/GroceryManagement/components/IngredientList';
import GroceryHeader from 'pages/GroceryManagement/components/GroceryHeader';

import { updateStateEditPantryPopup, updateStateRemoveIngredientPopup } from 'store/actions/ui-settings.actions';
import { getUserPantryRequest } from 'store/actions/userPantry.actions';

import {
    EditPantryContainer,
    EditPantryContent,
    HeaderContainer,
    EditHeader,
    HeaderTitle,
    HeaderSubTitle,
    ContentContainer,
    FooterContainer,
    ButtonGroup,
    ListPantry,
} from './styles';

const EditPantryPopup = () => {
    const dispatch = useDispatch();
    const { isDesktopOrLaptop, isTabletOrMobile } = useScreenType();

    const [listUserPantry, setListUserPantry] = useState();
    const [userPantryView, setUserPantryView] = useState([]);

    const { userPantryItems, userPantryItemsUpdate, userPantryItemsRemove } = useSelector((state) => state.userPantryReducer);
    const ingredientDefaultEdit = useSelector((state) => state.uiSettingsReducer.ingredientDefaultEdit);

    useEffect(() => {
        !userPantryItems.length && dispatch(getUserPantryRequest({ forceCall: false }));
    }, []);

    useEffect(() => {
        if (ingredientDefaultEdit && userPantryItems) {
            setListUserPantry(userPantryItems.filter((i) => i.ingredientId === ingredientDefaultEdit));
        }
    }, [userPantryItems]);

    const back = (e) => {
        e.stopPropagation();
        dispatch(updateStateEditPantryPopup(false));
    };

    const handleSave = () => {
        if (userPantryItemsUpdate.length || userPantryItemsRemove.length) {
            dispatch(updateStateRemoveIngredientPopup(true));
        } else {
            dispatch(updateStateEditPantryPopup(false));
        }
    };

    const handleCancel = () => {
        dispatch(updateStateEditPantryPopup(false));
    };

    return (
        <div>
            <EditPantryContainer
                scroll="body"
                open={true}
                onClose={back}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                $isTabletOrMobile={isTabletOrMobile}
            >
                <EditPantryContent $isTabletOrMobile={isTabletOrMobile}>
                    <HeaderContainer isDesktopOrLaptop={isDesktopOrLaptop}>
                        <IconButton onClick={back} size="small" aria-label="Add" variant="outline">
                            <Close />
                        </IconButton>
                        <EditHeader isDesktopOrLaptop={isDesktopOrLaptop}>
                            <HeaderTitle isDesktopOrLaptop={isDesktopOrLaptop}>Review your Digital Pantry</HeaderTitle>
                            <HeaderSubTitle isDesktopOrLaptop={isDesktopOrLaptop}>
                                You should have these groceries leftover from your previous orders. Please review to help
                                optimize.
                            </HeaderSubTitle>
                        </EditHeader>
                    </HeaderContainer>
                    <ContentContainer isDesktopOrLaptop={isDesktopOrLaptop}>
                        <ButtonGroup>
                            <GroceryHeader userPantryItems={listUserPantry} setUserPantryView={setUserPantryView} />
                        </ButtonGroup>
                        {userPantryView && userPantryView.length !== 0 && (
                            <ListPantry>
                                <IngredientList index={0} product={prepareUserPantry(userPantryView)[0]} isEdit={true} />
                            </ListPantry>
                        )}
                    </ContentContainer>
                    <FooterContainer isDesktopOrLaptop={isDesktopOrLaptop}>
                        <div className="skip" onClick={handleCancel}>
                            Skip
                        </div>
                        <Tertiary onClick={handleSave} size="medium" title="Save" />
                    </FooterContainer>
                </EditPantryContent>
            </EditPantryContainer>
        </div>
    );
};

export default EditPantryPopup;
