import styled, { css } from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

export const ReviewGroceriesContainer = styled(Dialog)`
    & > div:nth-child(3) {
        & > div:nth-child(1) {
            width: 533px;
            min-height: 274px;
            background: #ffffff;
            border-radius: 16px;
            margin: 0;

            ${(props) =>
                props.$isMobile &&
                css`
                    width: 100%;
                    max-width: unset;
                    min-height: 260px;
                    border-radius: 20px 20px 0 0;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                `};
        }
    }
`;

export const ReviewGroceriesContent = styled(DialogContent)`
    padding: 0 !important;
    overflow-y: hidden !important;
`;

export const HeaderContainer = styled.div`
    & > button {
        position: absolute;
        padding: 0;
        top: 24px;
        left: 24px;
        color: #0d2120;
    }

    ${(props) =>
        props.isMobile &&
        css`
            & > button {
                display: none;
            }
        `};
`;

export const Title = styled.div`
    padding: 20px 20px 20px 50px;
    text-align: center;
    color: #0d2120;
    letter-spacing: -0.69px;
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;

    ${(props) =>
        props.isMobile &&
        css`
            padding: 14px 0 26px;
        `};
`;

export const ContentContainer = styled.div`
    max-width: 356px;
    margin: 0 auto;
    text-align: center;

    ${(props) =>
        props.isMobile &&
        css`
            max-width: unset;
            padding: 0 20px;
        `};
`;

export const SubTitle = styled.div`
    color: #4f5f6c;
    letter-spacing: -0.685714px;
    font-size: 16px;
    line-height: 150%;
    padding-bottom: 22px;

    ${(props) =>
        props.isMobile &&
        css`
            padding-bottom: 33px;
            font-size: 14px;
        `};
`;

export const Button = styled.div`
    & > button {
        height: 36px;
        width: 100%;
        letter-spacing: -0.4px;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
    }
`;

export const ShowCheckbox = styled.div`
    display: flex;
    padding: 12px 0 20px 11px;

    & > label {
        & > span:nth-child(1) {
            padding: 0;
        }
        & > span:nth-child(2) {
            color: #bfc8cd;
            letter-spacing: -0.4px;
            font-size: 12px;
            line-height: 150%;
            padding-left: 6px;
        }
    }
`;

export const UnCheckedContainer = styled.div`
    width: 11px;
    height: 11px;
    border: 1px solid #bfc8cd;
`;

export const CheckedContainer = styled.div`
    width: 11px;
    height: 11px;
    background: #82bd73;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;

    & > div {
        display: flex;
    }
`;
