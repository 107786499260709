import styled, { css } from 'styled-components';

export const SearchContainer = styled.div`
    display: flex;
    margin: 0 auto;
    padding: 12px 16px;
    border-radius: 8px;
    border: 1px solid #eff5f9;
    align-items: center;
    color: #909da6;
    min-width: 418px;
    background: #ffffff;

    & > div:nth-child(2) {
        width: 100%;

        & > input {
            border: none;
            padding-left: 8px;
            font-size: 14px;
            line-height: 146%;
            width: 100%;

            &::placeholder {
                color: #aab5bc;
            }
        }
    }

    ${(props) =>
        props.isMobile &&
        css`
            width: 100%;
            min-width: unset;
            padding: 10px 16px;
        `};

    ${(props) =>
        props.activeInput &&
        css`
            border: 1px solid #82bd73;
            color: #82bd73;
        `};
`;

export const ButtonEnter = styled.div`
    cursor: pointer;
`;
