import * as constants from '../constants';

const initialState = {
    isLoading: false,
    sharedCartUrl: '',
    error: '',
    cart: {},
    isFetching: true,
    isFetched: false,
    isCalorieInfoFetching: false,
};

const CartReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.ADD_RECIPE_SUCCESS:
            return {
                ...state,
                cart: action.data.data,
            };
        case constants.GET_CART_DETAILS:
            return {
                ...state,
                isLoading: true,
                isFetching: true,
            };
        case constants.SHARE_CART_URL_SUCCESS:
            return {
                ...state,
                sharedCartUrl: action.url,
            };

        case constants.GET_CART_DETAILS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                cart: action.data,
                isFetching: false,
                isFetched: true,
            };

        case constants.SET_FETCHING_CART:
            return {
                ...state,
                isFetching: action.data,
            };
        case constants.SET_FETCHED_CART:
            return {
                ...state,
                isFetched: action.data,
            };
        case constants.LOGIN_SUCCESS:
            return {
                ...state,
                isFetched: false,
                isLoading: false,
            };
        case constants.SET_CALORIE_INFO_SUCCESS:
            return {
                ...state,
                cart: {
                    ...state.cart,
                    calorieInfo: action.data.calorieInfo,
                },
            };
        case constants.SET_CALORIE_INFO_FETCHING:
            return {
                ...state,
                isCalorieInfoFetching: action.isFetching,
            };
        default:
            return state;
    }
};

export default CartReducer;
