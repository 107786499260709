import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import Icons from '../Icons/Icons';
import { Primary } from '../Buttons';

import { logout } from 'store/actions/authentication.actions';

import { MenuItem, Menu, MenuPaper } from '../headers/styles';
import { DashboardMenuContainer } from './styles';

const DashboardMenu = (props) => {
    const { open, setOpen, isTabletOrMobile } = props;
    const dispatch = useDispatch();
    const ref = useRef();
    const [isSetOpen, setIsSetOpen] = useState(true);
    useEffect(() => {
        const listener = (event) => {
            if (!ref.current || ref.current.contains(event.target)) {
                setIsSetOpen(false);
            } else {
                setIsSetOpen(true);
            }
        };
        document.addEventListener('mousedown', listener);
        return () => {
            document.removeEventListener('mousedown', listener);
        };
    }, [ref]);

    return (
        <DashboardMenuContainer
            onClick={() => {
                if (isSetOpen) {
                    setOpen(false);
                }
            }}
        >
            {open && isTabletOrMobile && (
                <Menu>
                    <MenuPaper ref={ref} isDashboardMenu={true}>
                        <MenuItem>
                            <NavLink to="/manage-groceries" activeClassName="active">
                                <Icons name="list" size="24" color="#82BD73" />
                                <span>Grocery Management</span>
                            </NavLink>
                        </MenuItem>
                        <MenuItem>
                            <NavLink to="/orders" activeClassName="active">
                                <Icons name="calenderActive" size="24" color="#82BD73" />
                                <span>Recent Orders</span>
                            </NavLink>
                        </MenuItem>
                        <MenuItem>
                            <NavLink to="/settings" activeClassName="active">
                                <Icons name="settingsActive" size="24" color="#82BD73" />
                                <span>Settings</span>
                            </NavLink>
                        </MenuItem>

                        <MenuItem logOutBtn={true}>
                            <Primary size="medium" title="Log Out" onClick={() => dispatch(logout())} />
                        </MenuItem>
                    </MenuPaper>
                </Menu>
            )}
        </DashboardMenuContainer>
    );
};

export default DashboardMenu;
