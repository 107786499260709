export default {
    name: 'v1.2',
    up: () => {
        const cart = localStorage.getItem('Cart');
        const extraProducts = localStorage.getItem('selectedExtraProducts');
        if (cart) {
            localStorage.removeItem('Cart');
        }
        if (extraProducts) {
            localStorage.removeItem('selectedExtraProducts');
        }
    },
};
