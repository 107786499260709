import get from 'lodash/get';
import * as constants from '../constants';

const initialState = {
    featureFlags: [
        {
            name: 'isFacebookLoginEnabled',
            enabled: true,
        },
        {
            name: 'isGoogleLoginEnabled',
            enabled: true,
        },
        {
            name: 'isContentForUSEnabled',
            enabled: false,
        },
        {
            name: 'isIngredientSubstituteEnabled',
            enabled: true,
        },
        {
            name: 'isCuratedBoxesEnabled',
            enabled: true,
        },
    ],
    isFetched: false,
    loading: false,
    isFeatureFlagsFetched: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case constants.GET_FEATURE_FLAGS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case constants.GET_FEATURE_FLAGS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data,
                isFetched: true,
                isFeatureFlagsFetched: true,
            };
        case constants.GET_FEATURE_FLAGS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
}
