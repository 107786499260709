import React, { useEffect, useState } from 'react';
import useScreenType from 'hooks/useScreenType';
import useTheme from 'hooks/useTheme';
import get from 'lodash/get';

import { ButtonWithIcon } from '../Buttons';
import TypographyD from '../TypographyD/TypographyD';

import { ButtonWrapper } from '../MobileBar/styles';
import { useSelector } from 'react-redux';
import { prepareRecipesMealTypeWise } from 'utility/recipeUtil';
import { selectedRecipes } from 'store/actions/recipe.action';

const NextMealTypeButton = ({ nextMealType, mealData, setCurrentMealTypeStep, step, handleNextClick, currentMealType, cost }) => {
    const { isTabletOrMobile } = useScreenType();
    const color = useTheme();

    const handleNextMealtype = () => {
        handleNextClick(nextMealType._id, step + 1);
    };

    if (!mealData) return null;
    if (isTabletOrMobile) {
        return (
            <div className="mobile-wrapper" onClick={handleNextMealtype}>
                <ButtonWrapper>
                    <div className="build-menu-CTA">
                        <TypographyD color={color.white} type="h5">
                            View {nextMealType.name}
                        </TypographyD>
                        <div className="collapse-price-per-serving">
                            <span>${get(cost, 'pricePerServing', '0.00').toFixed(2)} / serv.</span>
                        </div>
                    </div>
                </ButtonWrapper>
            </div>
        );
    } else return <ButtonWithIcon title={`View ${nextMealType.name}`} onClick={handleNextMealtype} />;
};
export default NextMealTypeButton;
