import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import get from 'lodash/get';
import * as constants from '../constants';
import * as actions from '../actions/initialize.actions';
import * as featureFlagActions from '../actions/featureFlags.actions';
import * as mealTypesActions from '../actions/mealTypes.actions';
import * as userActions from '../actions/user.actions';
import * as allergiesActions from '../allergy/allergy.actions';
import * as influencerAction from '../actions/influencer.action';
import * as recipesAction from 'store/recipes/recipe.action';
import * as recipeCategoryActions from 'store/actions/recipeCategory.action';
import serverAPI from 'services/initialize.service';
import { errorNotification } from 'helpers/errorNotification';

export const selectIsDietsFetched = () => select(({ userReducer }) => userReducer.isDietsFetched);
export const selectIsFeatureFlagsFetched = () => select(({ featureFlagsReducer }) => featureFlagsReducer.isFeatureFlagsFetched);
export const selectIsAllergiesFetched = () => select(({ allergyReducer }) => allergyReducer.isAllergiesFetched);
export const selectIsInfluencerFatched = () => select(({ influencerReducer }) => influencerReducer.isInfluencerFatched);
export const selectIsMealTypesFetched = () => select(({ mealTypesReducer }) => mealTypesReducer.isFetched);
export function* getInitialData({ params }) {
    try {
        const isDietsFetched = yield selectIsDietsFetched();
        const isFeatureFlagsFetched = yield selectIsFeatureFlagsFetched();
        const isAllergiesFetched = yield selectIsAllergiesFetched();
        const isInfluencerFatched = yield selectIsInfluencerFatched();
        const isMealTypesFetched = yield selectIsMealTypesFetched();
        const loggedInUser = yield select((state) => state.authenReducer.loggedInUser);
        let defaultConfig = {
            diets: params.default,
            allergies: params.default,
            filters: params.default,
            featureFlags: params.default,
            influencer: params.default,
            mealTypes: params.default,
        };
        let queryParams = {};
        const config = {
            ...defaultConfig,
            ...params.config,
        };
        let isRequestNeeded = false;
        if (config.allergies === true && isAllergiesFetched === false) {
            queryParams.allergies = true;
            isRequestNeeded = true;
        } else {
            queryParams.allergies = false;
        }
        if (config.featureFlags === true && isFeatureFlagsFetched === false) {
            queryParams.featureFlags = true;
            isRequestNeeded = true;
        } else {
            queryParams.featureFlags = false;
        }
        if (config.diets === true && isDietsFetched === false) {
            queryParams.diets = true;
            isRequestNeeded = true;
        } else {
            queryParams.diets = false;
        }
        if (config.mealTypes === true && isMealTypesFetched === false) {
            queryParams.mealTypes = true;
            isRequestNeeded = true;
        } else {
            queryParams.mealTypes = false;
        }
        const influData = localStorage.getItem('influencer') && JSON.parse(localStorage.getItem('influencer'));

        if (config.influencer === true && isInfluencerFatched === false) {
            queryParams.influencer = true;
            isRequestNeeded = true;

            if (influData) {
                queryParams.handle = get(influData, 'handle');
            }

            if (params.handle) {
                queryParams.handle = params.handle;
            } else if (influData) {
                queryParams.handle = get(influData, 'handle');
            } else {
                queryParams.handle = 'easierchef';
            }
        } else {
            queryParams.influencer = false;
        }

        if (!isRequestNeeded) return;
        const response = yield call(serverAPI.getInitialData, queryParams);
        if (response.data.data) {
            const data = response.data.data;
            yield put(actions.getInitialDataSuccess());
            if (loggedInUser) {
                yield put(userActions.getUserRequest(params.loadUser, params.isLoadedUser));
            }
            if (data.featureFlags) yield put(featureFlagActions.getFeatureFlagsSuccess(data.featureFlags));
            if (data.diets) yield put(userActions.getDietSuccess(data.diets));
            if (data.allergies) yield put(allergiesActions.getAllergySuccess(data.allergies));
            if (data.influencer) {
                yield put(influencerAction.getInfluencerSuccess(data.influencer));
                yield put(recipeCategoryActions.getRecipesCategoriesSuccess(get(data, 'influencer.filters', [])));
                if (params.pathname === 'landingPage') {
                    yield put(recipesAction.getRecipesRequest());
                }
            }
            if (data.mealTypes) yield put(mealTypesActions.getMealTypesSuccess(data.mealTypes.mealTypes));
        }
    } catch (error) {
        const data = {};
        const text = 'Error in initialize saga';
        errorNotification(error, data, text);
    }
}

export default function* () {
    yield takeEvery(constants.GET_INITIAL_DATA_REQUEST, getInitialData);
}
