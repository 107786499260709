import { call, put, takeEvery, select, takeLatest } from 'redux-saga/effects';
import Cookies from 'js-cookie';
import * as constants from '../constants';
import * as actions from '../allergy/allergy.actions';
import serverAPI from 'services/allergy.service';
import { errorNotification } from '../../helpers/errorNotification';
import GetAllergiesRequest from './GetAllergiesRequest';

export const selectedAllergies = () => select(({ allergyReducer }) => allergyReducer.selectedAllergies);
export const userDetails = () => select(({ userReducer }) => userReducer.userDetails);

export const selectIsLoadedAllergyList = () => select(({ allergyReducer }) => allergyReducer.isLoadedAllergyList);
export function* getAllergyRequest() {
    try {
        yield call(GetAllergiesRequest.make);
    } catch (error) {
        const data = {};
        const text = 'Error in getAllergyRequest saga';
        errorNotification(error, data, text);
        yield put(actions.getAllergyFailure(error));
    }
}

export function* toggleAllergy({ allergyId }) {
    try {
        const selectedSelectedAllergies = yield selectedAllergies();
        if (selectedSelectedAllergies.indexOf(allergyId) === -1) {
            yield put(actions.addAllergy(allergyId));
        } else {
            yield put(actions.deleteAllergy(allergyId));
        }
    } catch (error) {
        const data = { allergyId };
        const text = 'Error in toggle allergy saga';
        errorNotification(error, data, text);
        yield put(actions.getAllergyFailure(error));
    }
}

export default function* () {
    yield takeLatest(constants.GET_ALLERGY_REQUEST, getAllergyRequest);
    yield takeEvery(constants.TOGGLE_ALLERGY, toggleAllergy);
}
