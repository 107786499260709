import * as constants from '../constants';

export const getExtraProductsRequest = () => ({ type: constants.GET_EXTRA_PRODUCTS_REQUEST });
export const getExtraProductsSuccess = (extraProducts) => ({ type: constants.GET_EXTRA_PRODUCTS_SUCCESS, extraProducts });
export const getExtraProductsFailure = (error) => ({ type: constants.GET_EXTRA_PRODUCTS_FAILURE, error });

export const searchExtraProducts = (value) => ({ type: constants.SET_EXTRA_PRODUCTS_SEARCH, payload: value });
export const clearExtraProductsSearch = () => ({ type: constants.CLEAR_EXTRA_PRODUCTS_SEARCH });
export const clearSelectedExtraProducts = () => ({ type: constants.CLEAR_SELECTED_EXTRA_PRODUCTS });

export const addExtraProduct = (product) => ({
    type: constants.ADD_EXTRA_PRODUCT,
    product,
});

export const addExtraProductSuccess = (data) => ({ type: constants.ADD_EXTRA_PRODUCT_SUCCESS, data });

export const deleteExtraProduct = (productId) => ({
    type: constants.DELETE_EXTRA_PRODUCT,
    productId,
});

export const deleteExtraProductSuccess = (data) => ({ type: constants.DELETE_EXTRA_PRODUCT_SUCCESS, data });
