import * as constants from '../constants';

const initialState = {
    loading: false,
    linkbios: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case constants.GET_LINKEINBIO_REQUEST:
            return {
                loading: true,
                ...state,
            };
        case constants.GET_LINKEINBIO_SUCCESS:
            return {
                ...state,
                linkbios: action.payload,
                loading: false,
            };
        default:
            return state;
    }
}
