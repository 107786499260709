import * as constants from '../constants';
import get from 'lodash/get';

import { CLEAR_CHECKOUT_STATE } from '../actions/order.actions';
const Cart = localStorage.getItem('Cart') ? JSON.parse(localStorage.getItem('Cart')) : {};
const getDeliveryAddressLocal = () => {
    const deliveryAddressLocal = localStorage.getItem('deliveryAddress');
    if (deliveryAddressLocal && deliveryAddressLocal !== 'undefined') {
        return JSON.parse(deliveryAddressLocal);
    }
    return {
        promoDiscount: '',
    };
};

const getDeliveryTimeLocal = () => {
    const deliveryAddressLocal = localStorage.getItem('deliveryAddress');
    if (deliveryAddressLocal && deliveryAddressLocal !== 'undefined') {
        const data = JSON.parse(deliveryAddressLocal);
        return {
            date: data.date,
            timeSlot: data.time,
            timestamp: data.timestamp,
            allowSubstitute: data.allowSubstitute,
        };
    }
    return null;
};

const initialState = {
    loading: false,
    currentPage: '',
    deliveryAddress: getDeliveryAddressLocal(),
    deliveryType: '',
    deliveryDate: getDeliveryTimeLocal(),
    error: '',
    timeSlots: [],
    dayAvailability: false,
    fetchingTimeSlots: false,
    currentTime: new Date().toISOString(),
    summary: {},
    creatingOrder: false,
    promoCode: '',
    orderExtraProducts: null,
    orderRecipes: null,
    recipeIds: null,
    isCheckoutSuccess: false,
    orderDetail: get(Cart, 'orderDetail', {}),
    isPasswordSet: true,
    isPromoCodeApplied: false,
    isValidDeliveryForm: false,
    isAddressInitialized: !!getDeliveryAddressLocal().email || !!getDeliveryAddressLocal().emailAddress,
    deliveryFee: 0,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case constants.SET_DELIVERY_ADDRESS_SUCCESS: {
            const address = action.address;
            return {
                ...state,
                isAddressInitialized: true,
                deliveryAddress: { ...address, customerName: `${address.firstName || ''} ${address.lastName || ''}` },
            };
        }
        case constants.SET_DELIVERY_TYPE:
            return {
                ...state,
                deliveryType: action.deliveryType,
            };

        case constants.SET_DAY_AVAILABILITY:
            return {
                ...state,
                dayAvailability: action.dayAvailability,
            };
        case constants.SET_DELIVERY_DATE: {
            return {
                ...state,
                deliveryDate: action.deliveryDate,
            };
        }
        case constants.GET_DAY_REQUEST:
            return {
                ...state,
                fetchingTimeSlots: true,
            };
        case constants.GET_DAY_SUCCESS:
            return {
                ...state,
                fetchingTimeSlots: false,
                timeSlots: action.timeSlots,
                currentTime: action.currentTime,
            };
        case constants.GET_DAY_FAILURE:
            return {
                ...state,
                fetchingTimeSlots: false,
            };
        case constants.GET_SUMMARY_SUCCESS:
            return {
                ...state,
                summary: action.summary,
            };
        case constants.CHECK_PROMO_SUCCESS:
            return {
                ...state,
                isPromoCodeApplied: true,
                error: '',
            };
        case constants.CHECK_PROMO_REQUEST:
            return {
                ...state,
                isPromoCodeApplied: false,
            };
        case constants.CHECK_PROMO_FAILURE:
            return {
                ...state,
                isPromoCodeApplied: false,
                error: action.error,
            };
        case constants.CREATE_ORDER_REQUEST:
            return {
                ...state,
                error: '',
                creatingOrder: true,
            };
        case constants.CREATE_ORDER_SUCCESS:
            return {
                ...state,
                error: '',
                creatingOrder: false,
                isCheckoutSuccess: true,
                orderDetail: action.payload,
                isPasswordSet: action.payload.user.isPasswordSet,
            };
        case constants.GET_MENU_SUCCESS:
            return {
                ...state,
                orderDetail: { ...state.orderDetail, customer: action.data.customer, priceDetails: action.data.priceDetails },
            };
        case constants.CREATE_ORDER_FAILURE:
            return {
                ...state,
                creatingOrder: false,
                error: action.error,
            };
        case constants.SET_ORDER_INFORMATION: {
            return {
                ...state,
                orderExtraProducts: action.extraProducts,
                orderRecipes: action.body,
            };
        }
        case CLEAR_CHECKOUT_STATE: {
            return {
                ...state,
                isCheckoutSuccess: false,
            };
        }

        case constants.SET_PROMO_CODE: {
            return {
                ...state,
                promoCode: action.promoCode,
            };
        }

        case constants.SET_DELIVERY_FEE: {
            return {
                ...state,
                deliveryFee: action.deliveryFee,
            };
        }

        case constants.SET_CURRENT_PAGE: {
            return {
                ...state,
                currentPage: action.payload,
            };
        }

        case constants.GET_DELIVERY_ADDRESS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case constants.GET_DELIVERY_ADDRESS_SUCCESS:
            const deliveryAddress = {
                ...action.data,
                customerName: `${action.data.firstName || ''} ${action.data.lastName || ''}`,
                emailAddress: action.data.email,
            };
            // if (!JSON.parse(localStorage.getItem('deliveryAddress'))) {
            //     localStorage.setItem('deliveryAddress', JSON.stringify(deliveryAddress));
            // }
            return {
                ...state,
                isAddressInitialized: true,
                loading: false,
                deliveryAddress,
                deliveryDate: null,
            };
        case constants.GET_DELIVERY_ADDRESS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case constants.EDIT_DELIVERY_ADDRESS:
            return {
                ...state,
                deliveryAddress: action.deliveryAddress,
            };
        case constants.SET_IS_VALID_DELIVERY_FORM:
            return {
                ...state,
                isValidDeliveryForm: action.payload,
            };
        case constants.CLEAR_STATE_ORDER: {
            // const deliveryAddress = {
            //     ...state.deliveryAddress,
            //     date: '',
            //     time: '',
            //     active: { today: false, tomorrow: false, custom: false },
            // };
            // localStorage.setItem('deliveryAddress', JSON.stringify(deliveryAddress));
            return {
                ...state,
                deliveryDate: null,
            };
        }
        case constants.CLEAR_PROMO_CODE_DISCOUNT:
            return {
                ...state,
                summary: {
                    ...state.summary,
                    promoDiscount: 0,
                },
            };
        case constants.LOGIN_SUCCESS: {
            if (!state.isAddressInitialized) {
                return {
                    ...state,
                    deliveryDate: null,
                };
            }
            return state;
        }
        case constants.LOGOUT_SUCCESS:
            return {
                ...state,
                deliveryAddress: {
                    promoDiscount: '',
                },
                deliveryDate: null,
            };
        default:
            return state;
    }
}
