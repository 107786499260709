import React from 'react';

import { useSelector } from 'react-redux';

import SummaryPopupContainer from '../SummaryPopupContainer';
import ExtraGroceriesContainer from '../ExtraGroceriesContainer';
import ReviewProductsPopup from '../ReviewProductsPopup';
import MinimumPopup from '../MinimumPopup';
import AddIngredientPopup from '../AddIngredientPopup';
import RemoveIngredientPopup from '../RemoveIngredientPopup';
import EditPantry from '../EditPantryPopup';
import ReviewGroceriesPopup from '../ReviewGroceriesPopup';
import NoActionPopup from '../NoRemoveGroceriesActionPopup';
import MobileSummaryPopUp from 'components/common/Summary/modal';
import PrintShoppingPopup from '../PrintShoppingPopup';
import HowItWorksVideoPopup from '../HowItWorksVideoPopup';
import LoginPopup from '../LoginPopup';
import ShareRecipePopup from '../common/ShareRecipePopup';

const GlobalUIComponents = () => {
    const {
        isSummaryPopupOpen,
        isExtraGroceriesPopupOpen,
        isReviewProductsPopupOpen,
        isMinimumPopupOpen,
        isAddIngredientPopupOpen,
        isRemoveIngredientPopupOpen,
        isEditPantryPopupOpen,
        isReviewGroceriesPopupOpen,
        isNoStatusPopupOpen,
        isMobileSummaryPopupOpen,
        isPrintShoppingPopupOpen,
        showHowItWorksPopup,
        isShareRecipeDialogueOpen,
    } = useSelector((state) => state.uiSettingsReducer);
    const { isLoginPopupOpen } = useSelector((state) => state.loginModalReducer);

    return (
        <>
            {isSummaryPopupOpen && <SummaryPopupContainer />}
            {isExtraGroceriesPopupOpen && <ExtraGroceriesContainer />}
            {isReviewProductsPopupOpen && <ReviewProductsPopup />}
            {isMinimumPopupOpen && <MinimumPopup />}
            {isAddIngredientPopupOpen && <AddIngredientPopup />}
            {isRemoveIngredientPopupOpen && <RemoveIngredientPopup />}
            {isEditPantryPopupOpen && <EditPantry />}
            {isReviewGroceriesPopupOpen && <ReviewGroceriesPopup />}
            {isMobileSummaryPopupOpen && <MobileSummaryPopUp />}
            {/* {isNoStatusPopupOpen && <NoActionPopup />} */}
            {isPrintShoppingPopupOpen && <PrintShoppingPopup />}
            {showHowItWorksPopup && <HowItWorksVideoPopup />}
            {isLoginPopupOpen && <LoginPopup />}
            {isShareRecipeDialogueOpen && <ShareRecipePopup />}
        </>
    );
};

export default GlobalUIComponents;
