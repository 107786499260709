const { apiRequest } = require('helpers/request');

class HttpRequest {
    getRequest = ({ url, query }) => apiRequest({ url, method: 'get', query });
    postRequest = ({ url, query, headers, payload }) => apiRequest({ url, method: 'post', query, headers, data: payload });
    putRequest = ({ url, query, headers, payload }) => apiRequest({ url, method: 'put', query, headers, data: payload });
    deleteRequest = ({ url, query, headers, payload }) => apiRequest({ url, method: 'delete', query, headers, data: payload });

    performForHttpMethod = (method, requestInfo) => {
        switch (method) {
            case 'GET':
                return this.getRequest(requestInfo);
            case 'POST':
                return this.postRequest(requestInfo);
            case 'PUT':
                return this.putRequest(requestInfo);
            case 'DELETE':
                return this.deleteRequest(requestInfo);
        }
    };

    perform = (method, httpRequestInfo) =>
        new Promise(async (resolve, reject) => {
            const response = await this.performForHttpMethod(method, httpRequestInfo);
            resolve({
                httpRequestInfo,
                response,
            });
        });
}

export default new HttpRequest();
