import get from 'lodash/get';

export const formatRecipeImages = (images) => {
    let image;
    if (get(images, 'small[0]')) {
        image = (images.small.find(({ isMain }) => isMain) || images.small[0]).url;
    } else if (get(images, 'large[0]')) {
        image = (images.large.find(({ isMain }) => isMain) || images.large[0]).url;
    } else {
        image = '';
    }
    return image;
};
export const formatRecipeImagesLarge = (images) => {
    let image;
    if (get(images, 'large[0]')) {
        image = (images.large.find(({ isMain }) => isMain) || images.large[0]).url;
    } else if (get(images, 'small[0]')) {
        image = (images.small.find(({ isMain }) => isMain) || images.small[0]).url;
    } else {
        image = '';
    }
    return image;
};
