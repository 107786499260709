import { apiRequest } from 'helpers/request';

function getDay(dayTimestamp, cityId, type, combineSlots = true) {
    return apiRequest({ url: `/days/${dayTimestamp}/${type}/${cityId}?combineSlots=${combineSlots}` });
}

function getCurrentTime() {
    return apiRequest({ url: '/days/time' });
}

function createOrder(data) {
    return apiRequest({ url: '/orders', method: 'post', data });
}

function getSummary(data) {
    return apiRequest({ url: 'orders/totalPrice', method: 'post', data });
}
function checkPromo(data) {
    return apiRequest({ url: 'cart/check-promo', method: 'post', data });
}

export default {
    getSummary,
    getDay,
    getCurrentTime,
    createOrder,
    checkPromo,
};
