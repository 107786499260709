import { call, put, select, takeEvery } from 'redux-saga/effects';
import * as constants from '../constants';
import * as actions from '../actions/cities.actions';
import serverAPI from 'services/cities.service';
import { errorNotification } from 'helpers/errorNotification';
export const selectIsLoadedCities = () => select(({ citiesReducer }) => citiesReducer.isLoadedCities);
const selectConfig = () => select(({ configReducer }) => configReducer.config);

export function* getCities({ forceCall }) {
    try {
        const isLoadedCities = yield selectIsLoadedCities();
        const { countryCode } = yield selectConfig();
        if (!isLoadedCities || forceCall) {
            const response = yield call(serverAPI.getCities, { countryCode });
            yield put(actions.getCitiesSuccess(response.data));
        }
    } catch (error) {
        const data = {};
        const text = 'Error in getCities saga';
        errorNotification(error, data, text);
    }
}
export default function* () {
    yield takeEvery(constants.GET_CITIES_REQUEST, getCities);
}
