import React from 'react';
import { NavLink } from 'react-router-dom';
import { HeaderCenterContainer } from './styles';
import useScreenType from '../../../hooks/useScreenType';
import history from '../../../history';
import Icons from '../Icons/Icons';
const HeaderCenter = ({ loggedInUser, headerCenter, isDefaultInfluencer, influencer, diet }) => {
    const { isTabletOrMobile } = useScreenType();
    const showLogo = influencer.data && !influencer.data.disableLogo;

    return (
        <HeaderCenterContainer isTabletOrMobile={isTabletOrMobile}>
            {headerCenter ? (
                headerCenter
            ) : (
                <>
                    {isTabletOrMobile ? (
                        showLogo ? (
                            <Icons
                                name="logo2"
                                size="40"
                                onClick={() => {
                                    if (!isDefaultInfluencer) {
                                        history.push(`/${influencer.handle}`);
                                    } else {
                                        history.push(`/`);
                                    }
                                }}
                            />
                        ) : null
                    ) : (
                        <nav>
                            <ul>
                                {/* <li>
                                    <NavLink
                                        exact
                                        to={isDefaultInfluencer ? '/' : `/${influencer}`}
                                        exact
                                        activeClassName="active"
                                    >
                                        Home Page
                                    </NavLink>
                                </li> */}
                                <li>
                                    <NavLink
                                        exact
                                        to={isDefaultInfluencer ? '/build-menu' : `/${influencer.handle}/build-menu`}
                                        activeClassName="active"
                                    >
                                        Weekly Plan
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to={
                                            isDefaultInfluencer
                                                ? diet
                                                    ? `/recipes?diet=${diet}`
                                                    : '/recipes'
                                                : diet
                                                ? `/${influencer.handle}/recipes?diet=${diet}`
                                                : `/${influencer.handle}/recipes`
                                        }
                                        exact
                                        activeClassName="active"
                                    >
                                        Explore Recipes
                                    </NavLink>
                                </li>
                                {loggedInUser && (
                                    <li>
                                        <NavLink to="/manage-groceries" exact activeClassName="active">
                                            Digital Pantry
                                        </NavLink>
                                    </li>
                                )}
                                <li>
                                    <NavLink to="/faq" exact activeClassName="active">
                                        FAQ
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/about-us" exact activeClassName="active">
                                        Team
                                    </NavLink>
                                </li>
                            </ul>
                        </nav>
                    )}
                </>
            )}
        </HeaderCenterContainer>
    );
};
export default HeaderCenter;
