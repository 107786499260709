import * as constants from '../constants';

export const updateStateSummaryPopup = payload => ({ type: constants.UPDATE_STATE_SUMMARY_POPUP, payload });
export const showMobileSummaryPopup = payload => ({ type: constants.UPDATE_STATE_MOBILE_SUMMARY_POPUP, payload });
export const showMobileServingsPopup = payload => ({ type: constants.UPDATE_STATE_SERVINGS_POPUP, payload });
export const showFiltersPopup = payload => ({ type: constants.UPDATE_STATE_FILTERS_POPUP, payload });
export const showSearchPopup = payload => ({ type: constants.UPDATE_STATE_SEARCH_POPUP, payload });
export const showMobileBarExpanded = payload => ({ type: constants.SHOW_MOBILE_BAR_EXPANDED, payload });
export const showNoActionPopUp = payload => ({ type: constants.SHOW_NO_ACTION_POPUP, payload });
export const showSummaryDrawer = payload => ({ type: constants.SHOW_SUMMARY_DRAW, payload });
export const showDiscountRecipesBanner = payload => ({ type: constants.SHOW_DISCOUNT_RECIPES_BANNER, payload });
export const showHowItWorksPopUp = payload => ({ type: constants.SHOW_HOW_IT_WOKRS_POPPUP, payload });

export const setDefaultServings = servings => ({ type: constants.SET_DEFAULT_SERVINGS, servings });
export const updateStateExtraGroceriesPopup = payload => ({ type: constants.UPDATE_STATE_EXTRA_GROCERIES_POPUP, payload });
export const setValueInputExtraGroceriesPopup = payload => ({ type: constants.SET_VALUE_INPUT_EXTRA_GROCERIES_POPUP, payload });
export const updateStateReviewProductsPopup = payload => ({ type: constants.UPDATE_STATE_REVIEW_PRODUCTS_POPUP, payload });
export const anyPantryItemAddedInCart = payload => ({ type: constants.ANY_PANTRY_ITEM_ADDED_IN_CART, payload });
export const updateStateSubscriptionSection = payload => ({ type: constants.UPDATE_STATE_SUBSCRIPTION_SECTION, payload });
export const updateStateMinimumPopup = payload => ({ type: constants.UPDATE_STATE_MINIMUM_POPUP, payload });
export const updateNoActionStatusPopup = payload => ({ type: constants.UPDATE_NO_ACTION_POPUP, payload });
export const updateShowGroceriesSectionState = payload => ({ type: constants.UPDATE_SHOW_GROCERIES_SECTION, payload });

// payment-status popup
export const showPaymentSuccess = payload => ({ type: constants.SHOW_PAYMENT_SUCCESS_POPUP, payload });
export const showPaymentLoading = payload => ({ type: constants.SHOW_PAYMENT_LOADING_POPUP, payload });
export const showPaymentError = payload => ({ type: constants.SHOW_PAYMENT_ERROR_POPUP, payload });
export const setTemporaryOrderInfo = payload => ({ type: constants.SET_TEMPORARY_ORDER_INFORMATION, payload });
export const showCrateCardElementError = isComplete => ({ type: constants.SHOW_CREATE_CARD_ELEMENT_ERROR, isComplete });
export const updateStateAddIngredientPopup = (isOpen, ingredient, isOnboarding) => ({
    type: constants.UPDATE_STATE_ADD_INGREDIENT_POPUP,
    isOpen,
    ingredient,
    isOnboarding,
});
export const updateStateRemoveIngredientPopup = payload => ({ type: constants.UPDATE_STATE_REMOVE_INGREDIENT_POPUP, payload });

export const setOpenModalShare = (isOpenModalShare, orderId) => ({
    type: constants.SET_OPEN_MODAL_SHARE,
    isOpenModalShare,
    orderId,
});
export const updateStateEditPantryPopup = (isOpen, ingredient) => ({
    type: constants.UPDATE_STATE_EDIT_PANTRY_POPUP,
    isOpen,
    ingredient,
});
export const updateDrawerState = payload => ({ type: constants.UPDATE_DRAWER_STATE, payload });

export const updateStateReviewGroceriesPopup = payload => ({ type: constants.UPDATE_STATE_REVIEW_GROCERIES_POPUP, payload });

export const showReviewGroceriesPopup = payload => ({ type: constants.SHOW_REVIEW_GROCERIES_POPUP, payload });
export const setActiveButton = payload => ({ type: constants.SET_ACTIVE_BUTOTON, payload });
export const clearActiveButton = () => ({ type: constants.CLEAR_ACTIVE_BUTOTON });

export const setIsShowCommon = payload => ({ type: constants.SET_IS_SHOW_COMMON_INGREDIENTS, payload });
export const setIsClosedCommon = payload => ({ type: constants.SET_IS_CLOSED_COMMON_INGREDIENTS, payload });
export const setShowHeader = payload => ({ type: constants.SET_SHOW_HEADER_RECIPE_PAGE, payload });

export const showPrintShoppingPopup = payload => ({ type: constants.SHOW_PRINT_SHOPPING_POPUP, payload });
export const showBuildMenuFiltersPopup = payload => ({ type: constants.SHOW_BUILD_MENU_FILTERS_POPUP, payload });
export const loadingPage = () => ({ type: constants.LOADING_PAGE });
export const loadedPage = () => ({ type: constants.LOADED_PAGE });
export const updateGroceriesPlan = payload => ({ type: constants.UPDATE_GROCERIES_PLAN, payload });
export const showCalorieGoalPopup = payload => ({ type: constants.SHOW_CALORIE_GOAL_POPUP, payload });
export const showRemoveGroceriesButton = payload => ({ type: constants.SHOW_REMOVE_GROCERIES_BUTTON, payload });
export const setConformationDialogue = payload => ({ type: constants.SET_CONFIRMOTION_DIALOGUE_OPEN, payload });
export const setShareRecipePopup = payload => ({ type: constants.SET_SHARERECIPE_DIALOGUE_OPEN, payload });
