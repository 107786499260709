import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import history from '../../history';
import useTheme from 'hooks/useTheme';

import { Header } from './styles';
import useScreenType from '../../hooks/useScreenType';
import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from './HeaderMobile';
import Container from 'components/common/Container';

import { updateDrawerState } from 'store/actions/ui-settings.actions';
import queryString from 'query-string';
import get from 'lodash/get';

const HeaderContainer = (props) => {
    const pathname = history.location.pathname;
    const { isTabletOrMobile } = useScreenType();
    const [open, setOpen] = useState(false);
    const [showDashboardMenu, setShowDashboard] = useState(false);
    const loggedInUser = useSelector((state) => state.authenReducer.loggedInUser);
    const dispatch = useDispatch();
    const isDefaultInfluencer = useSelector((state) => state.influencerReducer.isDefaultInfluencer);
    const influencer = useSelector((state) => state.influencerReducer);
    const isShowHeader = useSelector((state) => state.uiSettingsReducer.isShowHeader);
    const params = queryString.parse(window.location.search);
    const loggedInToCustomerAccount = useSelector((state) => state.authenReducer.loggedInToCustomerAccount);
    const diet = get(params, 'diet', '');
    const color = useTheme();

    return (
        <>
            {isShowHeader && (
                <Container
                    maxWidth={pathname === '/recipes' ? 'EXTRA_LARGE_DESKTOP' : 'LARGE_DESKTOP'}
                    backgroundColor="#ffffff"
                    isHeader={true}
                >
                    <Header backgroundColor={loggedInToCustomerAccount && color.orange}>
                        {isTabletOrMobile ? (
                            <HeaderMobile
                                {...props}
                                isTabletOrMobile={isTabletOrMobile}
                                loggedInUser={loggedInUser}
                                open={open}
                                setOpen={(value) => {
                                    setShowDashboard(false);
                                    dispatch(updateDrawerState(value));
                                    setOpen(value);
                                }}
                                showDashboard={showDashboardMenu}
                                setShowDashboard={(value) => {
                                    setOpen(false);
                                    dispatch(updateDrawerState(value));
                                    setShowDashboard(value);
                                }}
                                isDefaultInfluencer={isDefaultInfluencer}
                                influencer={influencer}
                                diet={diet}
                            />
                        ) : (
                            <HeaderDesktop
                                {...props}
                                loggedInUser={loggedInUser}
                                isDefaultInfluencer={isDefaultInfluencer}
                                influencer={influencer}
                                diet={diet}
                            />
                        )}
                    </Header>
                </Container>
            )}
        </>
    );
};
export default HeaderContainer;
