import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useScreenType from 'hooks/useScreenType';

import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import { Tertiary } from 'components/common/Buttons';

import { updateStateRemoveIngredientPopup, updateStateEditPantryPopup } from 'store/actions/ui-settings.actions';
import {
    removeUserPantryRequest,
    clearUserItemPantryRemove,
    updateUserPantryRequest,
    setUserItemPantryUpdate,
} from 'store/actions/userPantry.actions';

import { RemoveIngredientContainer, RemoveIngredientContent, HeaderContainer, Title, Subtitle, ButtonGroup } from './styles';

const RemoveIngredientPopup = () => {
    const dispatch = useDispatch();
    const { isMobile } = useScreenType();

    const { userPantryItemsRemove, userPantryItemsUpdate } = useSelector((state) => state.userPantryReducer);

    const [count, setCount] = useState(0);

    useEffect(() => {
        let ingredientModify = userPantryItemsUpdate;
        userPantryItemsRemove.forEach((r) => {
            ingredientModify = ingredientModify.filter((userPantry) => userPantry.id !== r);
        });
        setCount(ingredientModify.length + userPantryItemsRemove.length);
    }, [userPantryItemsRemove, userPantryItemsUpdate]);

    const back = (e) => {
        e.stopPropagation();
        dispatch(updateStateRemoveIngredientPopup(false));
    };

    const HandleRemove = () => {
        if (userPantryItemsRemove.length) {
            dispatch(removeUserPantryRequest(userPantryItemsRemove));
            dispatch(clearUserItemPantryRemove());
        }
        if (userPantryItemsUpdate.length) {
            dispatch(updateUserPantryRequest(userPantryItemsUpdate));
            dispatch(setUserItemPantryUpdate([]));
        }
        dispatch(updateStateRemoveIngredientPopup(false));
        dispatch(updateStateEditPantryPopup(false));
    };

    return (
        <RemoveIngredientContainer
            scroll="body"
            open={true}
            onClose={back}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            $isMobile={isMobile}
        >
            <RemoveIngredientContent>
                <HeaderContainer isMobile={isMobile}>
                    <IconButton onClick={back} size="small" aria-label="Add" variant="outline">
                        <Close />
                    </IconButton>
                    <Title isMobile={isMobile}>
                        Are you sure you want to modify <br /> <span>{count} ingredients</span> from Digital Pantry?
                    </Title>
                </HeaderContainer>
                <Subtitle isMobile={isMobile}>After modifying it, you won’t be able to undo this action.</Subtitle>
                <ButtonGroup isMobile={isMobile}>
                    <Tertiary onClick={() => dispatch(updateStateRemoveIngredientPopup(false))} size="medium" title="No" />
                    <Tertiary onClick={HandleRemove} size="medium" title="Yes" />
                </ButtonGroup>
            </RemoveIngredientContent>
        </RemoveIngredientContainer>
    );
};

export default RemoveIngredientPopup;
