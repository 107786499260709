import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Tooltips from 'components/common/Controls/Tooltips/Tooltips';

import { RealNameContainer, NameDisplay } from './styles';

const LongText = ({ text, classes, maxLine }) => {
    const realName = useRef(null);
    const nameDisplay = useRef(null);
    const [checkIsTooBig, setIsCheckTooBig] = useState(false);
    const [isRealName, setIsRealName] = useState(true);

    useEffect(() => {
        if (realName.current && nameDisplay.current) {
            if (realName.current.offsetHeight > nameDisplay.current.offsetHeight) {
                setIsCheckTooBig(true);
            }
            setIsRealName(false);
        }
    }, []);

    return (
        <>
            {isRealName && (
                <RealNameContainer classes={classes} ref={realName}>
                    {text}
                </RealNameContainer>
            )}

            {checkIsTooBig ? (
                <Tooltips label={text} placement="top">
                    <NameDisplay classes={classes} ref={nameDisplay} maxLine={maxLine}>
                        {text}
                    </NameDisplay>
                </Tooltips>
            ) : (
                <NameDisplay classes={classes} ref={nameDisplay} maxLine={maxLine}>
                    {text}
                </NameDisplay>
            )}
        </>
    );
};

LongText.propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    classes: PropTypes.object,
    maxLine: PropTypes.number,
};

LongText.defaultProps = {
    text: '',
    classes: {
        lineHeight: '18px',
        color: '#0d2120',
        fontSize: '12px',
        minHeight: '36px',
        fontWeight: 400,
    },
    maxLine: 2,
};

export default LongText;
