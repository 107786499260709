import { apiRequest } from '../helpers/request';

async function addItemToCart(data, query) {
    return apiRequest({ url: `/cart/`, method: 'post', data, query });
}

async function get(getGroceries, adjustDigitalPantry) {
    return apiRequest({ url: `/cart/`, query: { getGroceries, adjustDigitalPantry } });
}

async function getCartGroceries(data) {
    return apiRequest({ url: '/cart/email-shopping-list', method: 'post', data });
}

async function addDeliveryAddress(data) {
    return apiRequest({ url: '/cart/delivery-info', method: 'post', data });
}

async function setCalorieInfo(data) {
    return apiRequest({ url: '/cart/set-calorie-info', method: 'post', data });
}

export default {
    addItemToCart,
    getCartGroceries,
    get,
    addDeliveryAddress,
    setCalorieInfo,
};
