/* eslint-disable func-names */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './App.module.scss';
import Routes from './Routes';
import { identifyUser } from './helpers/trackEvents';
import { Toaster } from 'react-hot-toast';
import { getFeatureFlagsRequest } from 'store/actions/featureFlags.actions';
import { trackUserActivity } from './helpers/trackUserActivity';
import IPGeolocationAPI from 'ip-geolocation-api-javascript-sdk/IPGeolocationAPI';
import { setUserLocation } from 'store/actions/config.actions';
import queryString from 'query-string';
import get from 'lodash/get';
import useLoginPopup from 'hooks/useLoginPopup';
import useNetworkConnection from 'hooks/useNetworkConnection';

const App = ({ location, history }) => {
    const [additionalStylesheet, setAdditionalStylesheet] = useState(null);
    const loginUser = useSelector((state) => state.authenReducer.loggedInUser);
    const influencer = useSelector((state) => state.influencerReducer.handle);
    const isFetchedFeatureFlags = useSelector((state) => state.featureFlagsReducer.isFetched);
    const featureFlags = useSelector((state) => state.featureFlagsReducer.featureFlags);
    const { pathname, search } = location;
    const dispatch = useDispatch();

    useEffect(() => {
        if (loginUser) {
            identifyUser(null, influencer);
        }
        dispatch(getFeatureFlagsRequest());
        if (!localStorage.getItem('config')) {
            const ipgeolocationApi = new IPGeolocationAPI(process.env.REACT_APP_GEO_LOCATION_API);
            ipgeolocationApi.getGeolocation((data) => {
                let locationConfig = {
                    countryCode: 'CA',
                    currency: 'CAD',
                    ip: data.ip,
                    city: data.city,
                    country: data.country_name,
                };
                if (
                    data.country_code2 &&
                    ['CA', 'US'].includes(data.country_code2) &&
                    (featureFlags.find(({ name }) => name === 'isContentForUSEnabled') || { enabled: false }).enabled
                ) {
                    locationConfig = { countryCode: data.country_code2, currency: data.currency.code };
                }
                dispatch(setUserLocation({ payload: locationConfig }));
            });
        }
    }, [featureFlags]);
    useLoginPopup();

    useNetworkConnection();

    useEffect(() => {
        const stopListening = history.listen((location) => {
            trackUserActivity({ type: 'page', value: location.pathname });
        });
        return () => stopListening();
    }, []);

    const getPageTitle = (pathname) => {
        switch (pathname) {
            case '/recipes':
                return 'Select Your Meals - EasierChef';

            case '/groceries':
                return 'Customize Your Groceries';

            case '/summary':
                return 'EasierChef Order Summary - Meals & Groceries, Solved';

            case '/checkout':
                return 'Enter Your Delivery Details';

            case '/payment':
                return 'Enter Payment to Complete Order';

            case '/faq':
                return 'EasierChef Common Questions';

            default:
                return 'EasierChef - Meals & Groceries, Solved';
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    // This effect is used to update iOS safari viewport when address bar is showed.
    useEffect(() => {
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        const vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);

        // get Coupon code from query string
        const campaignPromoCode = get(queryString.parse(search), 'campaignPromoCode', '');
        if (campaignPromoCode) localStorage.setItem('campaignPromoCode', campaignPromoCode);
    }, []);

    const isTawkBlockedOnPage =
        pathname === '/' ||
        pathname === '/recipes' ||
        pathname === '/groceries' ||
        pathname === '/groceries/extra' ||
        pathname.includes('/view') ||
        pathname === '/viewCategory' ||
        pathname.includes('/menu/');
    pathname.includes('/viewHeaderContainer') || pathname.includes('/delivery_address');

    useEffect(() => {
        setTimeout(() => {
            const allIframes = document.getElementsByTagName('iframe');

            if (isTawkBlockedOnPage && additionalStylesheet) {
                if (additionalStylesheet.rules[0]) additionalStylesheet.deleteRule(0);
                additionalStylesheet.insertRule('body > div[class=""] { clip-path: circle(0) !important; }', 0);
                [...allIframes].map((iframe) => {
                    iframe.style.pointerEvents = 'none';
                });
            } else if (additionalStylesheet) {
                if (additionalStylesheet.rules[0]) additionalStylesheet.deleteRule(0);
                [...allIframes].map((iframe) => {
                    iframe.style.pointerEvents = 'all';
                });
            }
        }, 200);

        document.title = getPageTitle(pathname);
    });

    useEffect(() => {
        const styleElement = document.createElement('style');
        document.head.appendChild(styleElement);
        const styleSheet = styleElement.sheet;
        styleSheet.insertRule('body > div[class=""] { clip-path: circle(0) !important; }', 0);
        setAdditionalStylesheet(styleSheet);
        if (!pathname.includes('/meal-plan')) {
            const Tawk_API = Tawk_API || {};

            (function () {
                const s1 = document.createElement('script');
                const s0 = document.getElementsByTagName('script')[0];
                s1.async = false;
                s1.src = 'https://embed.tawk.to/5e9369b769e9320caac2e0e9/default';
                s1.charset = 'UTF-8';
                s1.setAttribute('crossorigin', '*');
                s0.parentNode.insertBefore(s1, s0);
            })();
        }
    }, []);
    useEffect(() => {
        window.addEventListener('klaviyoForms', function (e) {
            if (e.detail.type === 'submit') {
                identifyUser(
                    {
                        username: e.detail.metaData['$email'],
                    },
                    influencer,
                );
            }
        });
    }, [influencer]);

    return (
        <>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
            <div className={styles.App}>{isFetchedFeatureFlags && <Routes location={location} history={history} />}</div>
            <Toaster position={'top-center'} />
        </>
    );
};

App.propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

export default App;
