import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import history from '../../../history';
import useTheme from 'hooks/useTheme';
import isEmpty from 'lodash/isEmpty';
import { useSelector, useDispatch } from 'react-redux';

import Links from '../Links/Links.jsx';
import TypographyD from '../TypographyD/TypographyD';
import Tooltips from '../Controls/Tooltips/Tooltips';
import Icons from '../Icons/Icons';
import DiscountedRecipesImages from 'assets/images/discountedRecipesImage.png';

import PromoCode from './promoCode';

import { getProgressStep } from 'helpers/progressStep';
import { formatRecipeImages } from 'helpers/formatRecipeImages';

import { CostWrapper } from './styles';
import { PerServing, Cost } from '../MobileBar/styles';
import { CompareArrowsOutlined } from '@material-ui/icons';
import PromoCodeDiscount from './promoCodeDiscount';
import get from 'lodash/get';
import { NavLink } from 'react-router-dom';
import { setShareRecipePopup } from 'store/actions/ui-settings.actions';
import { shareCartUrlRequest } from 'store/actions/cart.actions';
import BonusRecipes from '../../../pages/GroceriesPage/components/BonusRecipes';

const CostContainer = ({
    isMobile,
    isDesktopOrLaptop,
    cost: {
        totalPrice = 0,
        totalServings = 0,
        priceOfRequiredProducts = 0,
        priceOfUnusedProductsWithRoundingCorrection = 0,
        totalPriceExtra = 0,
        pricePerServing = 0,
        extraProductTaxes = 0,
        actualCostWithoutDiscount = 0,
        usedGroceriesInPercentage = 0,
        promoDiscount = 0,
        subTotal = 0,
        deliveryFee = 0,
        serviceFee = 0,
    },
    extraProducts,
    commonIngredientRecipes,
    minimumCommonIngredientRecipes,
    isEditing,
}) => {
    const {
        location: { pathname, search },
    } = history;
    const color = useTheme();
    const dispatch = useDispatch();
    const currentStep = getProgressStep(pathname);

    const recipes = useSelector((state) => state.orderMenuReducer.recipes);
    const groceries = useSelector((state) => state.recipeReducer.Cart.groceries);
    const ingredientsToCost = useSelector((state) => state.recipeReducer.Cart.ingredientsToCost);
    const [totalGroceries, setTotalGroceries] = useState(0);

    const toolTipText = (
        <div>
            This is your avg cost per serving of the {Math.round(usedGroceriesInPercentage)}% groceries you’ve used. See how to
            use more of your groceries{' '}
            <a
                href="/use-pantry"
                style={{
                    color: '#909da6',
                }}
            >
                {'here'}
            </a>
            .
        </div>
    );

    useEffect(() => {
        setTotalGroceries(ingredientsToCost?.length);
    }, [groceries, ingredientsToCost]);

    const handleShareMenu = () => {
        const url = new URL(window.location);
        url.searchParams.set('sharemenu', 'true');
        window.history.pushState({}, '', url);
        dispatch(setShareRecipePopup(true));
        dispatch(shareCartUrlRequest());
    };
    return (
        <CostWrapper isMobile={isMobile} disabled={isEditing}>
            <div className="cost-wrapper">
                <div className="per-serving-cost-wrapper">
                    <div className="per-serving-block">
                        <span>{totalServings} Servings</span>
                        <Tooltips label={toolTipText}>
                            <Icons name="alert" size="16" color={color.grey1} />
                        </Tooltips>
                    </div>
                    <div>${pricePerServing.toFixed(2) || '0.00'} /serv.</div>
                </div>

                {(currentStep > 0 || search.includes('=groceries')) && (
                    <>
                        <div className={'unused-container sub-total-block add-border-top'}>
                            <div className="cost-content">
                                <PerServing>
                                    <span className="font-normal">Recipe plan total:</span>
                                </PerServing>
                                <Cost>${priceOfRequiredProducts.toFixed(2) || '0.00'}</Cost>
                            </div>
                        </div>
                        <div className={'unused-container'}>
                            <div className="cost-content">
                                <PerServing>
                                    <span className="font-normal grocery-leftovers">Grocery leftovers </span>
                                    <Tooltips
                                        label={
                                            "Reduce food waste by using leftovers next week. These will be automatically added to your digital pantry after the delivery of your order and then we'll suggest you recipes to utilise them."
                                        }
                                    >
                                        <Icons name="alert" size="16" color={color.grey1} />
                                    </Tooltips>
                                    &nbsp;&nbsp;
                                    <a
                                        href="/use-pantry"
                                        style={{
                                            color: '#909da6',
                                            display: 'flex',
                                        }}
                                    >
                                        Use more
                                    </a>
                                </PerServing>
                                <Cost>${priceOfUnusedProductsWithRoundingCorrection.toFixed(2) || '0.00'}</Cost>
                            </div>
                        </div>
                    </>
                )}

                {!isEmpty(extraProducts) && currentStep > 0 && (
                    <div className="unused-container">
                        <div className="cost-content">
                            <PerServing currentStep={currentStep}>
                                <span className="font-normal">Extra Products</span>
                            </PerServing>
                            <Cost currentStep={currentStep} isBold={currentStep < 2}>
                                ${totalPriceExtra.toFixed(2) || '0.00'}
                            </Cost>
                        </div>
                    </div>
                )}

                {(currentStep > 0 || search.includes('=groceries')) && (
                    <div className={'unused-container sub-total-block add-border-top'}>
                        <div className="cost-content">
                            <PerServing>
                                <span className="font-normal">Subtotal</span>
                            </PerServing>
                            <Cost>${subTotal.toFixed(2) || '0.00'}</Cost>
                        </div>
                    </div>
                )}

                {!isEmpty(extraProducts) && currentStep > 1 && (
                    <div className="unused-container tax-block">
                        <div className="cost-content">
                            <PerServing currentStep={currentStep}>
                                <span className="font-normal">Taxes</span>
                            </PerServing>
                            <Cost currentStep={currentStep} isBold={false}>
                                ${extraProductTaxes.toFixed(2) || '0.00'}
                            </Cost>
                        </div>
                    </div>
                )}

                {/* {currentStep > 1 && !isMobile && promoDiscount > 0 && <PromoCodeDiscount />} */}

                {currentStep > 1 && pathname === '/checkout' && (
                    <div className={'unused-container service-fee-block'}>
                        <div className={`cost-content`}>
                            <PerServing currentStep={currentStep}>
                                <span>Service Fee</span>
                            </PerServing>
                            <TypographyD type="bodyMedium">+${serviceFee.toFixed(2)}</TypographyD>
                        </div>
                    </div>
                )}
                {currentStep > 1 && pathname === '/checkout' && (
                    <div className={'delivery-block'}>
                        <div className={`promo-block`}>
                            <PromoCode isDiscountApply={true} />
                        </div>
                        <div className={`cost-content delivery-block-box`}>
                            <PerServing>
                                <span>Delivery</span>
                                {deliveryFee.toFixed(2) > 0 && (
                                    <div className="remove-fee" onClick={handleShareMenu}>
                                        Remove fee?
                                    </div>
                                )}
                            </PerServing>
                            <TypographyD type="bodyMedium" fontWeight="600" color={color.brandGreen}>
                                +${deliveryFee.toFixed(2)}
                            </TypographyD>
                        </div>
                    </div>
                )}

                {currentStep != 2 && pathname !== '/groceries' && (
                    <div className="unused-container add-background">
                        <div className="cost-content">
                            <PerServing>
                                <span>{currentStep == 0 ? 'Discounted recipes' : 'Used groceries'}</span>
                                <div className="ml-5">
                                    <Tooltips label="Amount of groceries you’re using towards the recipes in your cart. ">
                                        <Icons name="help" size="16" color={color.white} />
                                    </Tooltips>
                                </div>
                            </PerServing>
                        </div>
                        <div className="unused-cost pr10">
                            <div className="common-ing-recipes-wrapper">
                                <div>
                                    <img className="discountedRecipeImages" src={DiscountedRecipesImages} />
                                </div>

                                <div className="unused-desc">
                                    {minimumCommonIngredientRecipes}+ recipes you can make with your unused groceries
                                </div>
                            </div>

                            <div
                                className="use-it-link"
                                onClick={() =>
                                    history.push({
                                        pathname: '/use-pantry',
                                        search: `?source=${currentStep == 0 ? 'recipes' : 'groceries'}`,
                                        state: { from: true },
                                    })
                                }
                            >
                                <Links label="See more" size="medium" type="secondary" />
                            </div>
                        </div>
                    </div>
                )}

                {currentStep > 1 && (
                    <div className="cost-content add-border-top">
                        <PerServing>
                            <span className="total-cost">Total</span>
                        </PerServing>
                        <Cost>
                            <span className="total-cost">${totalPrice.toFixed(2) || '0.00'}</span>
                        </Cost>
                    </div>
                )}

                {/* <div className="per-serving-cost-wrapper">
                    <div className="per-serving-block">
                        <span>{totalServings} Servings</span>
                        <Tooltips label={toolTipText}>
                            <Icons name="alert" size="16" color={color.grey1} />
                        </Tooltips>
                    </div>
                    <div>${pricePerServing.toFixed(2) || '0.00'} /serv.</div>
                </div> */}
            </div>
        </CostWrapper>
    );
};

CostContainer.propTypes = {
    cost: PropTypes.object.isRequired,
    isMobile: PropTypes.bool.isRequired,
    isDesktopOrLaptop: PropTypes.bool.isRequired,
    extraProducts: PropTypes.array.isRequired,
    summary: PropTypes.object,
};

CostContainer.defaultProps = {
    isMobile: false,
    isDesktopOrLaptop: false,
    extraProducts: [],
};

export default CostContainer;
