import * as constants from '../constants';

export const getCuratedRecipesRequest = (forceCall) => ({
    type: constants.GET_CURATED_RECIPES_REQUEST,
    forceCall,
});
export const getCuratedRecipesSuccess = (data) => ({ type: constants.GET_CURATED_RECIPES_SUCCESS, data });
export const getCuratedRecipesFailure = (error) => ({ type: constants.GET_CURATED_RECIPES_FAILURE, error });

export const getCuratedRecipeRequest = (id) => ({
    type: constants.GET_CURATED_RECIPE_REQUEST,
    id,
});
export const getCuratedRecipeSuccess = (data) => ({ type: constants.GET_CURATED_RECIPE_SUCCESS, data });
export const getCuratedRecipeFailure = (error) => ({ type: constants.GET_CURATED_RECIPE_FAILURE, error });

export const addMealPlan = (mealPlan) => ({ type: constants.ADD_MEAL_PLAN, mealPlan });
export const swapMealBoxRecipe = (originalRecipe, newRecipe, curatedBoxId) => ({
    type: constants.SWAP_MEAL_PLAN_RECIPE,
    originalRecipe,
    newRecipe,
    curatedBoxId,
});

export const loadMoreCuratedRecipes = (forceCall) => ({ type: constants.LOAD_MORE_CURATED_RECIPES, forceCall });

export const fetctingCuratedRecipes = () => ({ type: constants.FETCHING_CURATED_RECIPES });
export const fetchedCuratedRecipes = () => ({ type: constants.FETCHED_CURATED_RECIPES });
