import * as constants from '../constants';

export const getUserRequest = (loadUser = false, isAddressAvailable = true) => ({
    type: constants.GET_USER_REQUEST,
    loadUser,
    isAddressAvailable,
});

export const getUserSuccess = (data) => ({ type: constants.GET_USER_SUCCESS, data });
export const getUserFailure = (error) => ({ type: constants.GET_USER_FAILURE, error });
export const setIsLoadedUser = () => ({ type: constants.SET_IS_LOADED_USER });

export const editUserInfoRequest = (payload, isDisableNotification = false) => ({
    type: constants.EDIT_USER_REQUEST,
    payload,
    isDisableNotification,
});

export const editUserInfoSuccess = (data) => ({ type: constants.EDIT_USER_SUCCESS, data });
export const changeShopperInfo = (data) => ({ type: constants.CHANGE_SHOPPERINFO, data });
export const editUserInfoFailure = (error) => ({ type: constants.EDIT_USER_FAILURE, error });

export const getDietRequest = () => ({
    type: constants.GET_DIET_REQUEST,
});

export const getDietSuccess = (data) => ({ type: constants.GET_DIET_SUCCESS, data });
export const getDietFailure = (error) => ({ type: constants.GET_DIET_FAILURE, error });

export const changePasswordRequest = (payload, onSuccess = () => {}) => ({
    type: constants.CHANGE_PASSWORD_REQUEST,
    payload,
    onSuccess,
});

export const changePasswordSuccess = (data) => ({ type: constants.CHANGE_PASSWORD_SUCCESS, data });
export const changePasswordFailure = (error) => ({ type: constants.CHANGE_PASSWORD_ERROR, error });

export const getOrderHistoryRequest = () => ({
    type: constants.GET_ORDER_HISTORY_REQUEST,
});

export const getOrderHistorySuccess = (data) => ({ type: constants.GET_ORDER_HISTORY_SUCCESS, data });
export const getOrderHistoryError = (error) => ({ type: constants.GET_ORDER_HISTORY_ERROR, error });

//----------------User Payments Information-----------------------
export const getPaymentMethods = (cb) => ({ type: constants.GET_PAYMENT_METHODS_REQUEST, cb });
export const getPaymentMethodsSuccess = ({ data }) => ({ type: constants.GET_PAYMENT_METHODS_SUCCESS, data });
export const getPaymentMethodsFailure = (error) => ({ type: constants.GET_PAYMENT_METHODS_FAILURE, error });

export const deletePaymentMethod = ({ id }) => ({ type: constants.DELETE_PAYMENT_METHOD_REQUEST, id });
export const deletePaymentMethodSuccess = (data) => ({ type: constants.DELETE_PAYMENT_METHOD_SUCCESS, data });
export const deletePaymentMethodFailure = (error) => ({ type: constants.DELETE_PAYMENT_METHOD_FAILURE, error });

export const addPaymentMethod = ({ billing_details, cardElement, stripe, showSuccessNotification = true, isStepOpen }) => ({
    type: constants.ADD_PAYMENT_METHOD_REQUEST,
    billing_details,
    cardElement,
    stripe,
    showSuccessNotification,
    isStepOpen,
});
export const addPaymentMethodSuccess = (data) => ({ type: constants.ADD_PAYMENT_METHOD_SUCCESS, data });
export const addPaymentMethodFailure = (error) => ({ type: constants.ADD_PAYMENT_METHOD_FAILURE, error });

export const updatePaymentMethod = ({ id, cardData }) => ({ type: constants.UPDATE_PAYMENT_METHOD_REQUEST, id, cardData });
export const updatePaymentMethodSuccess = (data) => ({ type: constants.UPDATE_PAYMENT_METHOD_SUCCESS, data });
export const updatePaymentMethodFailure = (error) => ({ type: constants.UPDATE_PAYMENT_METHOD_FAILURE, error });

export const updateRecipeFavorite = (recipe) => ({
    type: constants.UPDATE_RECIPE_FAVORITE_REQUEST,
    recipe,
});
export const updateRecipeFavoriteSuccess = (recipeFavorites) => ({
    type: constants.UPDATE_RECIPE_FAVORITE_SUCCESS,
    recipeFavorites,
});
export const updateRecipeFavoriteFailure = (error) => ({ type: constants.UPDATE_RECIPE_FAVORITE_FAILURE, error });
export const getRecipeFavorite = () => ({ type: constants.GET_RECIPE_FAVORITE });
export const getRecipeFavoriteSuccess = (recipeFavorites) => ({ type: constants.GET_RECIPE_FAVORITE_SUCCESS, recipeFavorites });
export const getRecipeFavoriteFailure = (error) => ({ type: constants.GET_RECIPE_FAVORITE_FAILURE, error });
export const toggleShowRecipeFavorite = (isShowRecipeFavorites) => ({
    type: constants.TOGGLE_SHOW_RECIPE_FAVORITE,
    isShowRecipeFavorites,
});
export const setSortOrder = (sortOrder) => ({
    type: constants.SET_SORT_ORDERS,
    sortOrder,
});
