import * as constants from '../constants';

const selectedExtraProductsLocal = localStorage.getItem('selectedExtraProducts');
const initialState = {
    loading: false,
    error: '',
    all: [],
    selected: selectedExtraProductsLocal ? JSON.parse(selectedExtraProductsLocal) : [],
    search: '',
    isloadedExtraProduct: false,
    isChangeParams: false,
    isFetching: true,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case constants.GET_EXTRA_PRODUCTS_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case constants.GET_EXTRA_PRODUCTS_SUCCESS:
            return {
                ...state,
                all: action.extraProducts,
                isFetching: false,
                isloadedExtraProduct: true,
                isChangeParams: false,
            };
        case constants.GET_EXTRA_PRODUCTS_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.error,
            };
        case constants.SET_EXTRA_PRODUCTS_SEARCH: {
            return {
                ...state,
                search: action.payload,
                isChangeParams: true,
                isFetching: true,
            };
        }
        case constants.CLEAR_EXTRA_PRODUCTS_SEARCH: {
            return {
                ...state,
                search: '',
                isChangeParams: true,
            };
        }
        case constants.ADD_EXTRA_PRODUCT: {
            return {
                ...state,
                loading: true,
            };
        }

        case constants.ADD_EXTRA_PRODUCT_SUCCESS: {
            return {
                ...state,
                loading: false,
                selected: action.data.productList,
            };
        }

        case constants.DELETE_EXTRA_PRODUCT: {
            return {
                ...state,
                loading: true,
                error: '',
            };
        }
        case constants.DELETE_EXTRA_PRODUCT_SUCCESS: {
            return {
                ...state,
                loading: false,
                selected: action.data.productList,
            };
        }
        case constants.CLEAR_SELECTED_EXTRA_PRODUCTS: {
            return {
                ...state,
                selected: [],
            };
        }
        case constants.GET_CART_DETAILS_SUCCESS: {
            const { extraProducts } = action.data;
            return {
                ...state,
                selected: extraProducts,
            };
        }
        default:
            return state;
    }
}
