import { useEffect, useState } from 'react';
import { customNotification } from 'components/common/Notification';
import history from '../history';
import { toast } from 'react-hot-toast';

const useNetworkConnection = () => {
    let [online, isOnline] = useState(false);
    const path = history.location;

    const handleInternetChange = () => {
        const condition = navigator.onLine ? 'online' : 'offline';

        if (condition === 'offline') {
            toast.dismiss();
            customNotification('error', 'Lost Internet Connection!');
            isOnline(false);
        } else {
            toast.dismiss();
            customNotification('success', 'We are online!');
            isOnline(true);
        }
    };

    // useEffect(() => {
    //     window.addEventListener('offline', handleInternetChange);
    //     window.addEventListener('online', handleInternetChange);
    //     return () => {
    //         window.removeEventListener('offline', handleInternetChange);
    //         window.removeEventListener('online', handleInternetChange);
    //     };
    // }, [path]);
};

export default useNetworkConnection;
