import styled, { css } from 'styled-components';

export const RealNameContainer = styled.div`
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
`;

export const NameDisplay = styled.div`
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${({ maxLine }) => maxLine};
    line-height: ${({ classes }) => classes.lineHeight};
    color: ${({ classes }) => classes.color};
    font-size: ${({ classes }) => classes.fontSize};
    font-weight: ${({ classes }) => classes.fontWeight};
    min-height: ${({ classes }) => classes.minHeight};
`;
