import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import useScreenType from 'hooks/useScreenType';

import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import { Secondary } from 'components/common/Buttons';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckboxMUI from '@material-ui/core/Checkbox';
import Icons from 'components/common/Icons/Icons';

import { updateStateReviewGroceriesPopup, showReviewGroceriesPopup, showSummaryDrawer } from 'store/actions/ui-settings.actions';

import {
    ReviewGroceriesContainer,
    ReviewGroceriesContent,
    HeaderContainer,
    Title,
    ContentContainer,
    SubTitle,
    Button,
    ShowCheckbox,
    UnCheckedContainer,
    CheckedContainer,
} from './styles';

const ReviewGroceriesPopup = () => {
    const dispatch = useDispatch();
    const { isMobile } = useScreenType();

    const [checked, setChecked] = useState(false);

    const onChange = (e) => {
        setChecked(e.target.checked);
        if (e.target.checked) {
            dispatch(showReviewGroceriesPopup(false));
        } else {
            dispatch(showReviewGroceriesPopup(true));
        }
    };

    const back = (e) => {
        e.stopPropagation();
        dispatch(updateStateReviewGroceriesPopup(false));
    };

    const Checked = (
        <CheckedContainer>
            <Icons name="check" size="11" />
        </CheckedContainer>
    );

    return (
        <ReviewGroceriesContainer
            scroll="body"
            open={true}
            onClose={back}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            $isMobile={isMobile}
        >
            <ReviewGroceriesContent>
                <HeaderContainer isMobile={isMobile}>
                    <IconButton onClick={back} size="small" aria-label="Add" variant="outline">
                        <Close />
                    </IconButton>
                    <Title isMobile={isMobile}>Reminder: Save time with groceries</Title>
                </HeaderContainer>
                <ContentContainer isMobile={isMobile}>
                    <SubTitle isMobile={isMobile}>
                        You’ll probably have groceries unused after you cook, so we’ll give you new recipes to choose from now or
                        next time that can help reduce your food waste and costs.
                    </SubTitle>
                    <Button onClick={back}>
                        <Secondary size="medium" title="Ok, got it" />
                    </Button>
                    <ShowCheckbox>
                        <FormControlLabel
                            control={
                                <CheckboxMUI
                                    onChange={onChange}
                                    checked={checked}
                                    icon={<UnCheckedContainer />}
                                    checkedIcon={Checked}
                                />
                            }
                            label="Don't show me this again"
                        />
                    </ShowCheckbox>
                </ContentContainer>
            </ReviewGroceriesContent>
        </ReviewGroceriesContainer>
    );
};

export default ReviewGroceriesPopup;
