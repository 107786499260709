import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useScreenType from 'hooks/useScreenType';
import moment from 'moment-timezone';
import { useDebouncedCallback } from 'use-debounce/lib';
import findIndex from 'lodash/findIndex';

import Icons from 'components/common/Icons/Icons';
import CalendarInput from 'components/common/CalendarInput';

import { setUserItemPantryRemove, updateUserPantryItems, setUserItemPantryUpdate } from 'store/actions/userPantry.actions';

import {
    IngredientManage,
    IngredientContainer,
    IngredientInfo,
    Image,
    IngredientName,
    Name,
    DatePurchase,
    CustomIngredient,
    GrocerySize,
    ButtonMinus,
    Quantity,
    ButtonPlus,
    Progress,
    ProgressBar,
    DayLeft,
    ButtonEdit,
    ButtonRemove,
    CustomDate,
    Calendar,
} from './styles';

const IngredientPantryEdit = ({ ingredient }) => {
    const dispatch = useDispatch();
    const { isMobile } = useScreenType();

    const { userPantryItems, isEditing, userPantryItemsUpdate, userPantryItemsRemove } = useSelector(
        (state) => state.userPantryReducer,
    );

    const [isEdit, setIsEdit] = useState(false);
    const [valueDate, setValueDate] = useState();
    const [valueQuantity, setValueQuantity] = useState('');
    const [quantity, setQuantity] = useState(ingredient.quantity);
    const [isRemoved, setIsRemove] = useState(false);

    useEffect(() => {
        if (findIndex(userPantryItemsRemove, (id) => id === ingredient.id) !== -1) {
            setIsRemove(true);
        } else {
            setIsRemove(false);
        }
    }, [userPantryItemsRemove]);

    useEffect(() => {
        setQuantity(ingredient.quantity);
        setValueDate(ingredient.expiryDate);
    }, [ingredient]);

    useEffect(() => {
        if (!isEditing) {
            setIsEdit(false);
            setQuantity(ingredient.quantity);
            setValueQuantity(`${quantity} ${ingredient.units}`);
            setValueDate(ingredient.expiryDate);
        }
    }, [isEditing]);

    useEffect(() => {
        setValueQuantity(`${quantity} ${ingredient.units}`);
    }, [quantity]);

    const handleEdit = () => {
        setValueQuantity(quantity);
        setIsEdit(true);
    };

    const onChangeDate = (value) => {
        setValueDate(value);
        userPantryItems.forEach((item) => {
            if (item.id === ingredient.id) {
                item.expiryDate = value;
            }
        });
        const newUserPantryItemsUpdate = userPantryItemsUpdate.filter((userPantry) => userPantry.id !== ingredient.id);
        dispatch(
            setUserItemPantryUpdate([
                ...newUserPantryItemsUpdate,
                { ...ingredient, quantity: !isNaN(valueQuantity) ? valueQuantity : quantity, expiryDate: value },
            ]),
        );
        dispatch(updateUserPantryItems(userPantryItems));
    };

    const handleRemoveUserPantryItem = () => {
        dispatch(setUserItemPantryRemove(ingredient.id));
    };

    const [debouncedCallback] = useDebouncedCallback((value) => {
        userPantryItems.forEach((item) => {
            if (item.id === ingredient.id) {
                item.quantity = value;
            }
        });
        const newUserPantryItemsUpdate = userPantryItemsUpdate.filter((userPantry) => userPantry.id !== ingredient.id);
        dispatch(
            setUserItemPantryUpdate([...newUserPantryItemsUpdate, { ...ingredient, quantity: value, expiryDate: valueDate }]),
        );
        dispatch(updateUserPantryItems(userPantryItems));
    }, 500);

    const onChangeQuantity = (e) => {
        if (!isNaN(e.target.value)) {
            isMobile ? setQuantity(e.target.value) : setValueQuantity(e.target.value);
            debouncedCallback(e.target.value);
        }
    };

    const onHandleMinus = () => {
        if (quantity > 0) {
            setQuantity((prev) => parseFloat(prev) - 1);
            debouncedCallback(quantity - 1);
        } else {
            debouncedCallback(quantity);
        }
    };

    const onHandlePlus = () => {
        setQuantity((prev) => parseFloat(prev) + 1);
        debouncedCallback(quantity + 1);
    };

    return (
        <IngredientManage isRemoved={isRemoved} isMobile={isMobile}>
            <IngredientContainer>
                <IngredientInfo isMobile={isMobile}>
                    <Image isMobile={isMobile} image={ingredient.image} />
                    <IngredientName isMobile={isMobile}>
                        <Name isMobile={isMobile}>{ingredient.name}</Name>
                        {isMobile ? (
                            <DatePurchase>
                                You have:{' '}
                                <span>
                                    {quantity} {ingredient.units}
                                </span>
                            </DatePurchase>
                        ) : (
                            <DatePurchase>
                                Date of purchase: <span>{moment(ingredient.expiryDate).format('DD-MM-YY')}</span>
                            </DatePurchase>
                        )}
                    </IngredientName>
                </IngredientInfo>
                <CustomIngredient isMobile={isMobile}>
                    {!isMobile && (
                        <GrocerySize>
                            <ButtonMinus disabled={quantity < 1 || isRemoved} onClick={!isRemoved ? onHandleMinus : () => {}}>
                                <Icons name="minusCircle" size="24" />
                            </ButtonMinus>
                            <Quantity valueQuantity={valueQuantity}>
                                <input value={valueQuantity} onChange={onChangeQuantity} disabled={!isEdit} />
                            </Quantity>
                            <ButtonPlus disabled={isRemoved} onClick={!isRemoved ? onHandlePlus : () => {}}>
                                <Icons name="plusCircle" size="24" />
                            </ButtonPlus>
                        </GrocerySize>
                    )}
                    <Progress isMobile={isMobile}>
                        <div>
                            <div>
                                {moment(ingredient.expiryDate).diff(moment(), 'days') > 0 && (
                                    <ProgressBar isMobile={isMobile}>
                                        <div />
                                    </ProgressBar>
                                )}
                                <DayLeft isMobile={isMobile}>
                                    {moment(ingredient.expiryDate).diff(moment(), 'days') < 1
                                        ? 'No expiry date set'
                                        : `${moment(ingredient.expiryDate).diff(moment(), 'days')} days left`}
                                </DayLeft>
                            </div>
                            {isEdit && !isMobile && (
                                <CustomDate isMobile={isMobile}>
                                    <div>Expiry Date:</div>
                                    <Calendar isMobile={isMobile}>
                                        <CalendarInput
                                            name="date"
                                            value={moment(valueDate).format('DD-MM-YY') || ''}
                                            onChange={(value) => onChangeDate(value)}
                                        />
                                    </Calendar>
                                </CustomDate>
                            )}
                        </div>
                        {isEdit && isMobile ? (
                            <ButtonRemove onClick={handleRemoveUserPantryItem}>
                                {isRemoved ? <Icons name="plus" size="24" /> : <Icons name="trash" size="24" />}
                            </ButtonRemove>
                        ) : (
                            <ButtonEdit disabled={isRemoved} isEdit={isEdit} isMobile={isMobile} onClick={handleEdit}>
                                <Icons name="edit" size={isMobile ? '24' : '20'} />
                            </ButtonEdit>
                        )}
                    </Progress>
                    {!isMobile && (
                        <ButtonRemove onClick={handleRemoveUserPantryItem}>
                            {isRemoved ? <Icons name="plus" size="24" /> : <Icons name="trash" size="24" />}
                        </ButtonRemove>
                    )}
                </CustomIngredient>
            </IngredientContainer>
            {isEdit && isMobile && (
                <div>
                    <GrocerySize isEdit={isEdit}>
                        <ButtonMinus disabled={quantity < 1} onClick={onHandleMinus}>
                            <Icons name="minusCircle" size="24" />
                        </ButtonMinus>
                        <Quantity isEdit={isEdit}>
                            <input value={quantity} onChange={onChangeQuantity} disabled={!isEdit} />
                        </Quantity>
                        <ButtonPlus onClick={onHandlePlus}>
                            <Icons name="plusCircle" size="24" />
                        </ButtonPlus>
                    </GrocerySize>
                    <CustomDate isMobile={isMobile}>
                        <div>Expiry Date:</div>
                        <Calendar isMobile={isMobile}>
                            <CalendarInput
                                name="date"
                                value={moment(valueDate).format('DD-MM-YY') || ''}
                                onChange={(value) => onChangeDate(value)}
                            />
                        </Calendar>
                    </CustomDate>
                </div>
            )}
        </IngredientManage>
    );
};

export default IngredientPantryEdit;
