import * as constants from '../constants';

export const getUserPantryRequest = ({ forceCall = false }) => ({
    type: constants.GET_USER_PANTRY_REQUEST,
    forceCall,
});
export const getUserPantrySuccess = (data) => ({
    type: constants.GET_USER_PANTRY_SUCCESS,
    data,
});
export const getUserPantryFailure = (error) => ({
    type: constants.GET_USER_PANTRY_FAILURE,
    error,
});

export const addUserPantryRequest = (data) => ({
    type: constants.ADD_USER_PANTRY_REQUEST,
    data,
});
export const addUserPantrySuccess = () => ({
    type: constants.ADD_USER_PANTRY_SUCCESS,
});
export const addUserPantryFailure = (error) => ({
    type: constants.ADD_USER_PANTRY_FAILURE,
    error,
});

export const setUserItemPantryRemove = (id) => ({
    type: constants.SET_USER_ITEM_PANTRY_REMOVE,
    id,
});
export const clearUserItemPantryRemove = () => ({
    type: constants.CLEAR_USER_ITEM_PANTRY_REMOVE,
});
export const removeUserPantryRequest = (ids) => ({
    type: constants.REMOVE_USER_PANTRY_REQUEST,
    ids,
});
export const removeUserPantrySuccess = (ids) => ({
    type: constants.REMOVE_USER_PANTRY_SUCCESS,
    ids,
});
export const removeUserPantryFailure = (error) => ({
    type: constants.REMOVE_USER_PANTRY_FAILURE,
    error,
});

export const setUserItemPantryUpdate = (ingredients) => ({
    type: constants.SET_USER_ITEM_PANTRY_UPDATE,
    ingredients,
});
export const updateUserPantryItems = (ingredients) => ({
    type: constants.UPDATE_USER_PANTRY_ITEMS,
    ingredients,
});
export const updateUserPantryRequest = (ingredients) => ({
    type: constants.UPDATE_USER_PANTRY_REQUEST,
    ingredients,
});
export const updateUserPantrySuccess = () => ({
    type: constants.UPDATE_USER_PANTRY_SUCCESS,
});
export const updateUserPantryFailure = (error) => ({
    type: constants.UPDATE_USER_PANTRY_FAILURE,
    error,
});

export const setIngredientsEdit = (ingredient) => ({
    type: constants.SET_INGREDIENTS_EDIT,
    ingredient,
});
export const clearIngredientsEdit = () => ({
    type: constants.CLEAR_INGREDIENTS_EDIT,
});
