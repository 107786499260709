import styled, { css } from 'styled-components';
import Dialog from '@material-ui/core/Dialog';

export const ShoppingMailContainer = styled(Dialog)`
    padding: 0px;
    & > div:nth-child(3) {
        & > div:nth-child(1) {
            width: 480px;
            height: 375px;
            background: #ffffff;
            text-align: -webkit-center;
            padding: 0px;
            border: 1px solid #eff5f9;
            ${(props) =>
                props.$isMobile &&
                css`
                    height: 100%;
                    width: 100%;
                    padding: 0px;
                `};
        }
        ${(props) =>
            props.$isMobile &&
            css`
                position: static;
                padding: 0px;
                margin: 0;
            `};
    }
`;

export const Title = styled.div`
    position: static;
    width: 100%;
    height: auto;
    text-align: center;
    font-family: Work Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
    color: #0d2120;

    ${(props) =>
        props.$isMobile &&
        css`
            position: static;
            width: 100%;
            height: auto;
            text-align: center;
            size: 20px;
            margin-top: 60px;
            margin-bottom: 26px;
            padding: 10px;
        `};
`;

export const GoBack = styled.div`
    display: flex;
    margin: 30px 20px;
    line-height: 116%;
    color: #82bd73;
    cursor: pointer;
`;

export const BackIcon = styled.div`
    width: 100%;
    text-align: -webkit-right;
    matgin: 26px;
    & > div {
        cursor: pointer;
        margin: 26px;
    }
`;

export const ContentContainer = styled.div`
    width: 100%;
    ${(props) =>
        props.$isMobile &&
        css`
            max-width: unset;
        `};
`;

export const SubTitle = styled.div`
    width: 396px;
    height: auto;
    margin: 14px;
    font-family: Work Sans;
    font-size: 14px;
    align-content: center;
    line-height: 146%;
    letter-spacing: -0.4px;
    color: #909dae;
    & > span {
        color: #ff6b6b;
        font-weight: 500;
    }
    & > div {
        color: #82bd73;
        cursor: pointer;
    }
    ${(props) =>
        props.$isMobile &&
        css`
            position: static;
            width: 330px;
            height: auto;
            font-size: 14px;
        `};
`;
export const EmailInput = styled.div`
    width: 396px;
    align: center;
    position: static;
    margin: 12px 0px;
    ${(props) =>
        props.$isMobile &&
        css`
            width: 335px;
        `};
`;

export const Button = styled.div`
    margin: 10px 0px;
    padding: 0px;

    & > button {
        padding: 17px 40px;
        bottom: 48px;
        position: static;
        width: 335px;
        background: #82bd73;
        border: 1px solid #82bd73;
        box-sizing: border-box;
        border-radius: 8px;
    }
`;
