import * as constants from '../constants';

export const getNutritionProgramsRequest = data => ({
    type: constants.GET_NUTRITION_PROGRAMS_REQUEST,
    data,
});
export const getNutritionProgramSuccess = nutritionPrograms => ({
    type: constants.GET_NUTRITION_PROGRAMS_SUCCESS,
    nutritionPrograms,
});
