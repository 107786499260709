import styled, { css } from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

export const RemoveIngredientContainer = styled(Dialog)`
    & > div:nth-child(3) {
        & > div:nth-child(1) {
            max-width: unset !important;
            width: 600px;
            background: #ffffff;
            border-radius: 16px;
            overflow-y: unset;

            ${(props) =>
                props.$isMobile &&
                css`
                    margin: 0;
                    width: 100%;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    border-radius: 16px 16px 0 0;
                `};
        }
    }
`;

export const RemoveIngredientContent = styled(DialogContent)`
    padding: 0 !important;
    overflow-y: unset !important;
`;

export const HeaderContainer = styled.div`
    & > button {
        position: absolute;
        top: 24px;
        left: 32px;
        color: #0d2120;
    }

    ${(props) =>
        props.isMobile &&
        css`
            & > button {
                display: none;
            }
        `};
`;

export const Title = styled.div`
    max-width: 460px;
    margin: 0 auto;
    padding: 80px 0 16px 0;
    color: #0d2120;
    letter-spacing: -0.69px;
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
    text-align: center;

    & > span {
        color: #82bd73;
    }

    ${(props) =>
        props.isMobile &&
        css`
            padding: 40px 20px 16px;
        `};
`;

export const Subtitle = styled.div`
    text-align: center;
    color: #4f5f6c;
    letter-spacing: -0.4px;
    font-size: 14px;
    line-height: 146%;

    ${(props) =>
        props.isMobile &&
        css`
            padding: 0 20px;
        `};
`;

export const ButtonGroup = styled.div`
    display: flex;
    padding: 40px 107px 72px 107px;
    justify-content: space-between;

    & > button {
        width: 181px;
    }

    & > button:nth-child(1) {
        border: 1px solid #e5eaed;
    }

    ${(props) =>
        props.isMobile &&
        css`
            padding: 40px 20px;
        `};
`;
