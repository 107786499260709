import React, { useState } from 'react';
import { StyledComponent } from './styles';
const elements = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
    bodyExtra: 'p',
    bodyBig: 'p',
    bodyMedium: 'p',
    bodySmall: 'p',
    buttonBig: 'span',
    buttonMedium: 'span',
    buttonSmall: 'span',
    linkBig: 'a',
    linkMedium: 'a',
    helpMedium: 'span',
    helpBig: 'span',
};
const TypographyD = (props) => {
    const { type, children } = props;
    const ele = React.createElement(elements[type] || elements.h1, props, children);
    return <StyledComponent {...props}>{ele}</StyledComponent>;
};
TypographyD.defaultProps = {
    type: 'h1',
    color: '#0d2120',
};

export default TypographyD;
