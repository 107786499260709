import styled from 'styled-components';

export const Container = styled.div`
    font-size: 15px;
    padding: 5px 0;
    font-weight: normal;
    margin: 15px 0 12px 0;
    display: flex;
    align-items: center;
    & > span {
        margin-left: 6px;
    }
`;
