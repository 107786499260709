import React, { useEffect, useState } from 'react';
import useScreenType from 'hooks/useScreenType';
import useTheme from 'hooks/useTheme';
import get from 'lodash/get';

import { ButtonWithIcon } from '../Buttons';
import TypographyD from '../TypographyD/TypographyD';

import { ButtonWrapper } from '../MobileBar/styles';

const NotEnoughServingsButton = ({
    nextMealType,
    mealData,
    suggestedServings,
    setCurrentMealTypeStep,
    step,
    handleNextClick,
    currentMealType,
    cost,
    nextTitle = 'Next >',
}) => {
    const { isTabletOrMobile } = useScreenType();
    const color = useTheme();

    if (!mealData) return null;
    if (isTabletOrMobile) {
        return (
            <div className="mobile-wrapper" onClick={handleNextClick}>
                <ButtonWrapper>
                    <div className="build-menu-CTA">
                        <TypographyD color={color.grey1} type="h5">
                            {mealData.selectedRecipesServings} / {suggestedServings} servings
                        </TypographyD>
                        <div className="collapse-price-per-serving">
                            <span style={{ color: color.grey1 }}>${get(cost, 'pricePerServing', '0.00').toFixed(2)} / serv.</span>
                        </div>
                        <div className="collapse-price-per-serving">
                            <span style={{ color: color.grey1 }}>{nextTitle}</span>
                        </div>
                    </div>
                </ButtonWrapper>
            </div>
        );
    } else return <ButtonWithIcon title={`Let's pick ${nextMealType.name}`} onClick={handleNextMealtype} />;
};
export default NotEnoughServingsButton;
