import * as constants from '../constants';

export const getRecipesCategoriesRequest = (forceCall) => ({ type: constants.GET_RECIPES_CATEGORIES_REQUEST, forceCall });
export const getRecipesCategoriesSuccess = (recipesCategories) => ({
    type: constants.GET_RECIPES_CATEGORIES_SUCCESS,
    recipesCategories,
});

export const getRecipesCategoriesFailure = (error) => ({ type: constants.GET_RECIPES_CATEGORIES_FAILURE, error });

export const toggleCategory = (categoryName) => ({ type: constants.TOGGLE_CATEGORY, categoryName });
export const addCategory = (categoryName) => ({ type: constants.ADD_CATEGORY, categoryName });
export const deleteCategory = (categoryName) => ({ type: constants.DELETE_CATEGORY, categoryName });
export const clearFlagIsUpdateCategories = () => ({
    type: constants.CLEAR_FLAGS_IS_UPDATE_CATEGORIES,
});

export const selectCategory = (categories) => ({ type: constants.SELECT_CATEGORY, categories });
export const setExcludeCategoryFilters = (excludeCategoryFilters) => ({
    type: constants.SET_EXCLUDE_CATEGORY_FILTERS,
    excludeCategoryFilters,
});
export const getFilterAvailable = (filterAvailable) => ({
    type: constants.GET_FILTER_AVAILABLE,
    filterAvailable,
});
