import { call, put, takeEvery, select, debounce, delay, takeLatest } from 'redux-saga/effects';
import cloneDeep from 'lodash/cloneDeep';
import uniqBy from 'lodash/uniqBy';
import * as constants from '../constants';
import * as actions from '../actions/recipe.action';
import * as cartActions from '../actions/cart.actions';
import serverAPI from 'services/recipe.service';
import cartAPI from 'services/cart.service';

import { Events } from 'constants/events';

import { errorNotification } from 'helpers/errorNotification';
import { preparePayloadFromLocalStorage } from 'helpers/cart';
import { pushLearnqEvent } from 'helpers/trackEvents';
import { prepareRecipePayload } from 'helpers/klaviyoEvent';

import { addIngredientToList } from 'utility/cartUtil';
import { roundNutrition } from 'utility/recipeUtil';

export const selectCurrentRecipe = () => select(({ recipeReducer }) => recipeReducer.currentRecipe);
export const selectLoggedInUser = () => select(({ authenReducer }) => authenReducer.loggedInUser);
export const selectedInfluencer = () => select(({ influencerReducer }) => influencerReducer.data);

export function* getRecipeById(action) {
    const currentRecipe = yield selectCurrentRecipe();
    const loggedInUser = yield selectLoggedInUser();
    const influencerHandle = yield select((state) => state.influencerReducer.handle);
    try {
        if (action.payload._id) {
            let query = {};
            if (action.payload.orderId) {
                query.orderId = action.payload.orderId;
            }
            if (loggedInUser) {
                query.lastOrderDetail = true;
            }

            const response = yield call(serverAPI.getRecipe, action.payload._id, query);
            if (response) {
                if (action.payload.category !== null) {
                    yield put(actions.getRecipeSuccess(response.data, action.payload.category));
                } else {
                    yield put(actions.getRecipeByIdSuccess(response.data));
                }

                pushLearnqEvent(Events.EVENT_VIEWED_PRODUCT, prepareRecipePayload(response.data), influencerHandle);
            }
        } else {
            yield put(actions.getRecipeByIdSuccess(currentRecipe));
        }
    } catch (error) {
        yield put(actions.getRecipeFailure('getRecipeById'));
        const data = { currentRecipe, loggedInUser, influencerHandle };
        const text = 'Error in getRecipeById saga';
        errorNotification(error, data, text, true);
    }
}

export function* makeGroceryRequiredToUser({ payload: { id, required, selected } }) {
    if (typeof selected === 'boolean') {
        if (required && selected) return;
    }
    const groceries = yield select((state) => state.recipeReducer.Cart.groceries);
    const itemNoneRequired = yield select((state) => state.recipeReducer.itemNoneRequired);
    let removedIngredientList = yield select((state) => state.recipeReducer.Cart.removedIngredientList);
    let updatedNonRequiredProductList = yield select((state) => state.recipeReducer.Cart.noneRequiredProductList);

    let { requiredProducts, nonRequiredProducts, substitutes } = groceries;

    const productsShallowCopy = cloneDeep(required ? requiredProducts : nonRequiredProducts);

    let product = {};
    if (required) {
        productsShallowCopy.forEach((groceries) => {
            uniqBy(groceries.ingredients, '_id').forEach((ingredient) => {
                if (ingredient._id === id) {
                    product = ingredient;
                }
            });
        });
    } else {
        uniqBy(productsShallowCopy, '_id').forEach((ingredient) => {
            if (ingredient._id === id) {
                product = ingredient;
            }
        });
    }

    try {
        if (required) {
            if (selected === false) {
                product.required = true;
                let list;
                if (removedIngredientList.find((i) => i.ingredient === product._id)) {
                    list = removedIngredientList.filter((i) => i.ingredient !== product._id);
                    return yield put(actions.removedIngredientList(list));
                }
                return;
            }
            product.required = !product.required;
            removedIngredientList = addIngredientToList(removedIngredientList, product);
            yield put(actions.removedIngredientList(removedIngredientList));
            requiredProducts = productsShallowCopy;
        } else {
            if (typeof product.requiredToUser === 'boolean') {
                if (selected === false) {
                    product.requiredToUser = true;
                    return yield put(actions.setItemNoneRequiredToCart(itemNoneRequired + 1));
                } else if (product.requiredToUser) {
                    yield put(actions.setItemNoneRequiredToCart(itemNoneRequired - 1));
                } else {
                    yield put(actions.setItemNoneRequiredToCart(itemNoneRequired + 1));
                }
                product.requiredToUser = !product.requiredToUser;
            } else {
                product.requiredToUser = true;
                yield put(actions.setItemNoneRequiredToCart(itemNoneRequired + 1));
            }
            updatedNonRequiredProductList = addIngredientToList(updatedNonRequiredProductList, product);
            yield put(actions.updateNonRequiredProductList(updatedNonRequiredProductList));
            nonRequiredProducts = productsShallowCopy;
        }
        yield put(actions.getGroceriesSuccess({ groceries: { requiredProducts, nonRequiredProducts, substitutes } }));
        yield put(actions.makeGroceryRequiredToUserApi());
    } catch (error) {
        const data = { requiredProducts, nonRequiredProducts, id, required };
        const text = 'Error in makeGroceryRequiredToUser saga';
        errorNotification(error, data, text);
    }
}

export function* addOrDeleteIngredientApi() {
    try {
        const cart = yield select((state) => state.recipeReducer.Cart);
        const loggedInUser = yield select((state) => state.authenReducer.loggedInUser);
        const { data } = yield call(cartAPI.addItemToCart, preparePayloadFromLocalStorage(cart));
        yield put(cartActions.setIngredientsToCost(data.ingredientsToCost));

        if (!loggedInUser) {
            localStorage.setItem('guestUserId', data.guestUserId);
        }
        yield put(actions.resetProductAmountSuccess({ data }));
    } catch (error) {
        const data = {};
        const text = 'Error in addOrDeleteIngredientApi saga';
        errorNotification(error, data, text);
    }
}

export const selectIsFetchedFilter = () => select(({ orderMenuReducer }) => orderMenuReducer.isFetchedFilter);
export const selectInfluencer = () => select(({ influencerReducer }) => influencerReducer);
export function* getRecipesFilter() {
    try {
        const influencer = yield selectInfluencer();
        if (influencer?.data?._id) {
            const isFetchedFilter = yield selectIsFetchedFilter();
            if (!isFetchedFilter) {
                const query = {
                    influencerId: influencer._id,
                };
                const response = yield call(serverAPI.getRecipeFilter, query);
                yield put(actions.getRecipesFilterSuccess(roundNutrition(response.data)));
            }
        }
    } catch (error) {
        const data = {};
        const text = 'Error in getRecipesFilter saga';
        errorNotification(error, data, text);
    }
}

export default function* () {
    yield takeEvery(constants.GET_RECIPE_REQUEST, getRecipeById);
    yield takeEvery(constants.GET_RECIPES_FILTER_REQUEST, getRecipesFilter);
    yield takeLatest(constants.MAKE_GROCERY_REQUIRED_TO_USER, makeGroceryRequiredToUser);
    yield debounce(1000, constants.MAKE_GROCERY_REQUIRED_TO_USER_API, addOrDeleteIngredientApi, { leading: true });
}
