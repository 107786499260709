import styled, { css } from 'styled-components';
export const ListCommonIngredientsContainer = styled.div`
    position: relative;
    .background {
        height: 288px;
        background: #f7fafc;
        position: absolute;
        width: 100%;
        top: 0;
        @media all and (max-width: 1145px) {
            height: 312px;
        }
        ${(props) =>
            props.isMobile &&
            css`
                height: 230px;
            `};
        @media all and (max-width: 768px) {
            height: 288px;
        }
        @media all and (max-width: 480px) {
            height: 230px;
        }
    }
`;
export const BackGround = styled.div`
    position: relative;
    z-index: 20;
    .title {
        display: block;
        padding: ${({ isMobile }) => (isMobile ? '24px 20px 17px 20px' : '32px 24px 17px 40px')};
        position: relative;
        & > div {
            width: 100%
            text-align: left;
            h4 {
                margin: 0;
                line-height: 150%;
                display: flex;
                font-size: ${({ isMobile }) => (isMobile ? '20px' : '24px')}
            }
        }
        .sub {
            display: flex;
            justify-content: space-between;
            & > div:nth-child(1) {
                display: flex;
                text-align: initial;
                h5 {
                    margin: 0;
                    font-weight: normal;
                    line-height: 150%;
                    font-size: ${({ isMobile }) => (isMobile ? '12px' : '16px')}
                }
            }
        }
        .links {
            width: 140px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            & > div {
                padding-bottom: 10px;
                & > div {
                    width: 120px;
                }
            }
        }
        .icons {
            position: absolute;
            right: 24px;
            top: 16px;
            cursor: pointer;
        }
    }
`;
export const CommonIngredients = styled.div`
    padding-bottom: 40px;
    @media screen and (max-width: 780px) {
        padding-bottom: 0px;
    }
`;
