import * as migrator from 'localstorage-migrator';
import migrations from './versions';
import Cookies from 'js-cookie';

const runMigrations = () => {
    try {
        migrator.migrate(migrations);
    } catch (e) {
        //if there is any issue with the migrations, clear the local storage by default
        localStorage.clear();
    }
};

const clearLocalStorageIfNeeded = () => {
    const guestUserId = localStorage.getItem('guestUserId');
    const onboarding = localStorage.getItem('onboarding');
    const token = Cookies.get('token');
    const url = new URL(window.location);
    if (!onboarding && !guestUserId && !token && !url.pathname.includes('/')) {
        localStorage.clear();
    }
};

const runStartupTasks = () => {
    runMigrations();
    clearLocalStorageIfNeeded();
};

runStartupTasks();
