import styled, { css } from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

export const EditPantryContainer = styled(Dialog)`
    & > div:nth-child(3) {
        & > div:nth-child(1) {
            max-width: unset;
            min-width: 1024px;
            min-height: 612px;
            border-radius: 16px;

            @media screen and (max-width: 1024px) {
                min-width: unset;
            }

            ${(props) =>
                props.$isTabletOrMobile &&
                css`
                    margin: 0;
                    height: 80%;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    border-radius: 16px 16px 0 0;
                    width: 100%;
                `};
        }
    }
`;

export const EditPantryContent = styled(DialogContent)`
    padding: 0 !important;

    ${(props) =>
        props.$isTabletOrMobile &&
        css`
            height: 100%;
            overflow-y: hidden !important;
        `};
`;

export const HeaderContainer = styled.div`
    border-bottom: 1px solid #e5eaed;

    & > button {
        position: absolute;
        top: 24px;
        left: 32px;
        color: #0d2120;
    }

    ${(props) =>
        !props.isDesktopOrLaptop &&
        css`
            & > button {
                display: none;
            }
        `};
`;

export const EditHeader = styled.div`
    text-align: center;
    padding: 16px 32px;

    ${(props) =>
        !props.isDesktopOrLaptop &&
        css`
            padding: 24px 32px 32px;
        `};
`;

export const HeaderTitle = styled.div`
    color: #0d2120;
    font-weight: 500;
    font-size: 16px;
    line-height: 124%;
    padding-bottom: 4px;

    ${(props) =>
        !props.isDesktopOrLaptop &&
        css`
            font-size: 20px;
            line-height: 150%;
            letter-spacing: -0.69px;
            padding-bottom: 8px;
        `};
`;

export const HeaderSubTitle = styled.div`
    color: #4f5f6c;
    letter-spacing: -0.4px;
    font-size: 12px;
    line-height: 150%;

    ${(props) =>
        !props.isDesktopOrLaptop &&
        css`
            max-width: 312px;
            margin: 0 auto;
        `};
`;

export const ContentContainer = styled.div`
    padding: 8px 32px 14px;
    height: 442px;
    overflow-y: auto;

    ${(props) =>
        !props.isDesktopOrLaptop &&
        css`
            padding: 0 20px 4px;
            height: calc(100% - 222px);

            & > div {
                margin-bottom: 16px;
            }
        `};
`;

export const FooterContainer = styled.div`
    min-height: 97px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid #eff5f9;
    padding: 0 40px;
    background: #ffffff;
    .skip {
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.63px;
        text-decoration-line: underline;
        color: #909da6;
        margin-right: 36px;
        cursor: pointer;
    }
    ${(props) =>
        !props.isDesktopOrLaptop &&
        css`
            min-height: 92px;
            justify-content: space-between;
            padding: 0 20px;
            position: sticky;
            bottom: 0;
        `};

    ${(props) =>
        props.selectedEdit &&
        !props.isDesktopOrLaptop &&
        css`
            & > button:nth-child(1) {
                border: 1px solid #aab5bc;
            }

            & > button {
                width: 160px;
            }
        `};

    ${(props) =>
        !props.selectedEdit &&
        !props.isDesktopOrLaptop &&
        css`
            & > button:nth-child(1) {
                width: 100%;
            }
        `};
`;

export const ButtonGroup = styled.div`
    padding: 20px 0;
    border-bottom: 1px solid #eff5f9;

    & > div {
        padding: 0;
        min-height: unset;
        border: none;

        & > div:nth-child(1) {
            letter-spacing: -0.69px;
            font-size: 24px;
            line-height: 150%;
        }

        & > div:nth-child(2) {
            & > div {
                margin-right: 0 !important;
            }

            & > button {
                display: none;
            }
        }
    }
`;

export const ListPantry = styled.div`
    & > div > div {
        padding: 0 !important;

        & > div:nth-child(1) {
            padding-top: 12px;
        }

        & > div:nth-child(2) > div > div > div > div {
            & > div:nth-child(1) > div > div {
                & > div:nth-child(2) > div:nth-child(2) {
                    & > div:nth-child(1) {
                        & > div:nth-child(2) > div {
                            & > div:nth-child(1) > div > div > div {
                                bottom: -40px;
                            }
                        }
                    }
                }
            }
        }
    }
`;
