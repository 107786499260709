import React from 'react';
import PropTypes from 'prop-types';

import CircularProgress from '@material-ui/core/CircularProgress';

const Loading = ({ color, muiCheckboxProps }) => {
    return <CircularProgress color={color} {...muiCheckboxProps} />;
};

Loading.propTypes = {
    color: PropTypes.oneOf(['inherit', 'primary', 'secondary']),
};

Loading.defaultProps = {
    color: 'primary',
};

export default Loading;
