import BaseRecipesApi from './BaseRecipesAPI';
import * as actions from '../recipe.action';
import { prepareCategorySearch } from 'utility/recipeUtil';
import { queryFilterRecipes } from 'helpers/queryFilterRecipes';

import store from 'store/store';

class SearchRecipesApi extends BaseRecipesApi {
    constructor() {
        super('GET');
    }
    shouldCall = () => {
        return true;
    };
    prepareQuery = () => {
        const state = store.getState();
        const {
            allergyReducer: { selectedAllergies: allergies },
            recipeReducer: { diet },
            recipeSearchReducer: { search },
            ingredientReducer: { selectedIngredients },
            orderMenuReducer: { recipesFilter, filterInfo },
            influencerReducer: { data: influencer, isDefaultInfluencer },
        } = state;
        const query = queryFilterRecipes({
            selectedAllergies: allergies,
            diet,
            searchValue: search,
            isSelectedIngredients: selectedIngredients,
            influencer,
            isDefaultInfluencer,
            recipesFilter,
            filterInfo,
            recipeLimit: 10,
        });
        return query;
    };
    afterCall = ({ response }) => {
        const {
            data: { recipes, categories },
        } = response.data;
        const state = store.getState();
        const {
            recipeCategoryReducer: { all: allRecipeCategories },
            recipeSearchReducer: { isUpdateSearch },
        } = state;
        store.dispatch(
            actions.getSearchRecipes({
                recipes: recipes,
                isUpdateSearch,
                categories: prepareCategorySearch(allRecipeCategories, categories),
            }),
        );
    };
}

export default new SearchRecipesApi();
