import styled, { css } from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

export const ExtraContainer = styled(Dialog)`
    & > div:nth-child(3) {
        & > div:nth-child(1) {
            width: 796px;
            max-width: 876px;
            max-height: 740px;
            overflow: hidden;
            margin: 0;
            border-radius: 16px;

            ${(props) =>
                props.$isTabletOrMobile &&
                css`
                    width: 100%;
                `};

            ${(props) =>
                props.$isMobile &&
                css`
                    max-width: unset;
                    border-radius: unset;
                    max-height: unset;
                    height: 100%;
                `};
        }
    }
`;

export const ExtraContent = styled(DialogContent)`
    padding: 0 !important;
    overflow-y: unset;

    ${(props) =>
        props.$isMobile &&
        css`
            height: 100%;
        `};
`;

export const HeaderContainer = styled.div`
    position: sticky;
    top: 0;
    display: flex;
    padding: 12px 32px;
    border-bottom: 1px solid #e5eaed;
    z-index: 10;
    background: #ffffff;
    min-height: 72px;

    & > button {
        position: absolute;
        top: 22px;
        left: 25px;
        color: #0d2120;
    }

    ${(props) =>
        props.isMobile &&
        css`
            flex-direction: column;
            padding: 24px 20px 23px 20px;

            & > button {
                top: 19px;
                left: 15px;
            }
        `};
`;

export const ContentContainer = styled.div`
    height: 578px;
    overflow-y: scroll;

    ${(props) =>
        props.isFetching &&
        css`
            display: flex;
            align-items: center;
            justify-content: center;
        `};

    ${(props) =>
        props.isMobile &&
        css`
            height: calc(100% - 232px);
        `};
`;

export const ListExtraProducts = styled.div`
    display: flex;
    flex-flow: wrap;
    padding: 0 24px 48px;

    & > div {
        padding: 32px 8px 16px;
        width: 25%;
    }

    ${(props) =>
        !props.checkExtra &&
        css`
            height: 100%;
            padding: 24px;
            display: flex;
            align-items: center;
        `};

    ${(props) =>
        props.isTabletOrMobile &&
        css`
            justify-content: space-evenly;

            & > div {
                width: unset;
            }
        `};

    ${(props) =>
        props.isMobile &&
        css`
            padding: 0 8px 89px;

            & > div {
                padding: 24px 4px 8px;
            }
        `};
`;

export const FooterContainer = styled.div`
    padding: 20px 32px;
    border-top: 1px solid #e5eaed;
    position: sticky;
    bottom: 0;
    z-index: 10;
    background: #ffffff;
    min-height: 88px;
    display: flex;
    align-items: center;
`;

export const ServingContainer = styled.div`
    display: flex;
    width: 100%;

    & > span:nth-child(2) {
        margin-left: auto;
    }
`;

export const Serving = styled.span`
    color: #233747;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
`;

export const ExtraTitle = styled.div`
    text-align: center;
    padding-bottom: 32px;
    color: #233747;
    font-weight: 500;
    font-size: 14px;
    line-height: 146%;
`;

export const Notification = styled.div`
    display: flex;
    flex-direction: column;
    width: 100% !important;
    height: max-content;
    padding: 0 !important;

    & > img {
        width: 56px;
        height: 56px;
        margin: 0 auto;
    }

    & > span {
        text-align: center;
        font-weight: 500;
        font-size: 24px;
        line-height: 150%;
        color: #909da6;
        padding-top: 32px;
    }

    ${(props) =>
        props.isMobile &&
        css`
            & > span {
                padding-top: 24px;
                color: #909da6;
                font-size: 14px;
                line-height: 146%;
            }
        `};
`;
