import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import useScreenType from 'hooks/useScreenType';
import findIndex from 'lodash/findIndex';

import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import Input from 'components/common/Fields/Input';
import { Secondary } from 'components/common/Buttons';
import CalendarInput from 'components/common/CalendarInput';
import Icons from 'components/common/Icons/Icons';
import InputDropdown from 'components/common/Fields/InputDropdown';

import { updateStateAddIngredientPopup } from 'store/actions/ui-settings.actions';
import { getDay } from 'store/actions/order.actions';
import { addUserPantryRequest } from 'store/actions/userPantry.actions';
import { getIngredientsRequest } from 'store/actions/ingredient.actions';
import { addInfoOnboarding } from 'store/actions/onboarding.actions';

import {
    AddIngredientContainer,
    AddIngredientContent,
    HeaderContainer,
    HeaderTitle,
    ContentContainer,
    EditIngredient,
    Calendar,
    Quantity,
    ButtonMinus,
    ButtonPlus,
    QuantityValue,
    FooterContainer,
} from './styles';

const AddIngredientPopup = () => {
    const dispatch = useDispatch();
    const { isMobile } = useScreenType();

    const ingredients = useSelector((state) => state.ingredientReducer.ingredientsList);
    const { ingredientDefault, isOnboarding } = useSelector((state) => state.uiSettingsReducer);
    const groceries = useSelector((state) => state.onboardingReducer.steps[4].info.groceries);

    const [ingredient, setIngredient] = useState(null);
    const [valueDate, setValueDate] = useState();
    const [expiryDate, setExpiryDate] = useState(0);
    const [quantity, setQuantity] = useState('');
    const [units, setUnits] = useState('');

    useEffect(() => {
        if (ingredient) {
            setUnits(ingredient.allUnits[0]);
        }
    }, [ingredient]);

    useEffect(() => {
        if (!ingredients) {
            dispatch(getIngredientsRequest({ all: true }));
        }
        if (isOnboarding) {
            groceries.map((g) => {
                if (ingredientDefault === g.id) {
                    setQuantity(g.quantity);
                    setUnits(g.units);
                    setExpiryDate(g.expiryDate);
                    setValueDate(moment(g.expiryDate).format('DD-MM-YY'));
                }
            });
        }
    }, []);

    useEffect(() => {
        if (ingredientDefault && ingredients) {
            setIngredient(ingredients.filter((i) => i._id === ingredientDefault)[0]);
        }
    }, [ingredientDefault, ingredients]);

    const back = (e) => {
        e.stopPropagation();
        dispatch(updateStateAddIngredientPopup(false, '', false));
    };

    const onChangeDate = (value) => {
        setValueDate(getDay(moment(value).format('DD-MM-YY')).timestamp);
        setExpiryDate(value.getTime());
    };

    const handleAddIngredient = () => {
        let data = {
            id: ingredient._id,
            quantity: quantity,
            units: units,
            expiryDate: expiryDate,
        };
        if (isOnboarding) {
            data.name = ingredient.name;
            if (findIndex(groceries, (g) => g.id === data.id) === -1) {
                dispatch(addInfoOnboarding([...groceries, data]));
            } else {
                const newGroceries = groceries.filter((g) => g.id !== data.id);
                dispatch(addInfoOnboarding([...newGroceries, data]));
            }
            dispatch(updateStateAddIngredientPopup(false, '', false));
        } else {
            dispatch(addUserPantryRequest(data));
        }
    };

    const onChangeIngredient = (event, value) => {
        setIngredient(value);
    };

    const onChangeQuantity = (e) => {
        if (!isNaN(e.target.value)) {
            setQuantity(e.target.value);
        }
    };

    const onChangeUnit = (event, value) => {
        setUnits(value);
    };

    const handlePlus = () => {
        ingredient && setQuantity((prev) => parseFloat(prev || 0) + 1);
    };

    const handleMinus = () => {
        if (ingredient && quantity >= 1) {
            setQuantity((prev) => parseFloat(prev) - 1);
        }
    };

    return (
        <AddIngredientContainer
            scroll="body"
            open={true}
            onClose={back}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            $isMobile={isMobile}
        >
            <AddIngredientContent>
                <HeaderContainer isMobile={isMobile}>
                    <IconButton onClick={back} size="small" aria-label="Add" variant="outline">
                        <Close />
                    </IconButton>
                    <HeaderTitle>Add ingredients to your Digital Pantry</HeaderTitle>
                </HeaderContainer>
                <ContentContainer>
                    <InputDropdown
                        options={ingredients || []}
                        placeholder="Enter ingredient name"
                        value={ingredient}
                        onChange={onChangeIngredient}
                        disabled={ingredientDefault ? true : false}
                    />
                    <EditIngredient isMobile={isMobile}>
                        <Calendar isMobile={isMobile}>
                            <CalendarInput
                                placeholder="Expiry date"
                                name="date"
                                value={valueDate || ''}
                                onChange={(value) => onChangeDate(value)}
                            />
                        </Calendar>
                        <Quantity isMobile={isMobile}>
                            <ButtonMinus disabled={!ingredient || quantity < 1} onClick={handleMinus}>
                                <Icons name="minusCircle" size="24" />
                            </ButtonMinus>
                            <QuantityValue>
                                <input disabled={!ingredient} value={quantity} onChange={onChangeQuantity} />
                                {ingredient && (
                                    <InputDropdown options={ingredient.allUnits} value={units} onChange={onChangeUnit} />
                                )}
                            </QuantityValue>
                            <ButtonPlus disabled={!ingredient} onClick={handlePlus}>
                                <Icons name="plusCircle" size="24" />
                            </ButtonPlus>
                        </Quantity>
                    </EditIngredient>
                </ContentContainer>
                <FooterContainer isMobile={isMobile}>
                    <Secondary
                        onClick={handleAddIngredient}
                        disabled={!ingredient || !quantity}
                        size="medium"
                        title="Add ingredient"
                    />
                </FooterContainer>
            </AddIngredientContent>
        </AddIngredientContainer>
    );
};

export default AddIngredientPopup;
