import { all, call, put, takeEvery, takeLatest, select } from 'redux-saga/effects';
import * as constants from '../constants';
import * as actions from '../actions/initializePage.actions';
import * as recipeActions from '../actions/recipe.action';
import * as categoryActions from '../categories/category.actions';
import * as curatedRecipesActions from '../curatedRecipes/curatedRecipes.action';
import * as orderActions from '../actions/order.actions';
import * as uiSettingActions from '../actions/ui-settings.actions';

import { errorNotification } from 'helpers/errorNotification';

import { getInitialData } from './initialize.sagas';
import { getCuratedRecipes, getCuratedRecipe } from '../curatedRecipes/curatedRecipes.sagas';
import { getCartDetails } from './cart.sagas';
import { getRecipes } from '../recipes/recipes.saga';
import { getCategoryRecipes } from '../categories/category.sagas';
import { getUserRecipeFavoriteRequest, getPaymentMethods, getOrderHistoryRequest } from './user.sagas';
import { getCommonIngredients } from './orderMenu.sagas';
import { getRecipesFilter, getRecipeById } from './recipe.sagas';
import { getBuildMenuPageResult, getMealTypes } from '../buildMenu/buildMenu.sagas';
import { getCities } from '../sagas/cities.sagas';
import { getUserPantryRequest } from '../sagas/userPantry.sagas';

export function* fetchCollectionPageData({ data }) {
    const { handle, collectionId, pathname } = data;
    try {
        yield call(getInitialData, {
            params: {
                default: true,
                handle,
                pathname,
            },
        });
        yield all([
            call(getCuratedRecipe, { id: collectionId }),
            call(getCartDetails, { forceCall: false, adjustDigitalPantry: false }),
            call(getMealTypes),
        ]);
    } catch (error) {
        const data = {};
        const text = 'Error in fetchCollectionPageData';
        errorNotification(error, data, text);
    }
}

export function* fetchMinimumOrderPageData({ data }) {
    const { handle, pathname } = data;
    try {
        yield call(getInitialData, {
            params: {
                default: true,
                handle,
                pathname,
            },
        });
        yield call(getCartDetails, { forceCall: false, adjustDigitalPantry: false });
        yield all([call(getCommonIngredients, { forceCall: true }), call(getUserRecipeFavoriteRequest)]);
    } catch (error) {
        const data = {};
        const text = 'Error in fetchMinimumOrderPageData';
        errorNotification(error, data, text);
    }
}

export function* fetchRecipesPageData({ data }) {
    const { handleId, search } = data;
    try {
        yield call(getInitialData, {
            params: {
                default: true,
                handle: handleId,
            },
        });
        yield call(getRecipesFilter);
        const fetched = yield select((state) => state.curatedRecipesReducer.fetchedCuratedRecipes);

        if (!fetched) {
            yield put(curatedRecipesActions.getCuratedRecipesRequest());
        }
        if (search) yield put(recipeActions.removeSearchRecipes());
        else {
            yield call(getRecipes, { forceCall: false });
        }
        yield all([call(getCartDetails, { forceCall: false }), call(getUserRecipeFavoriteRequest)]);
    } catch (error) {
        const data = {};
        const text = 'Error in fetchRecipesPageData';
        errorNotification(error, data, text);
    }
}

export function* fetchCategoryPageData({ data }) {
    const { categoryId, handleId } = data;
    try {
        yield put(categoryActions.startLoading());
        const categories = yield select((state) => state.categoryReducer.data);
        yield call(getInitialData, {
            params: {
                default: true,
                handle: handleId,
            },
        });
        yield call(getRecipesFilter);

        if (!categories[categoryId])
            yield call(getCategoryRecipes, {
                categoryId,
            });
        yield put(categoryActions.stopLoading());
        yield all([call(getCartDetails, { forceCall: false }), call(getUserRecipeFavoriteRequest)]);
    } catch (error) {
        const data = { categoryId };
        const text = 'Error in fetchCategoryPageData';
        errorNotification(error, data, text);
    }
}

export function* fetchRecipeItemPageData({ data }) {
    const { recipeId, handle, orderId } = data;
    try {
        yield put(recipeActions.startLoadingRecipeItemPage());
        yield call(getInitialData, {
            params: {
                default: true,
                handle,
            },
        });
        yield call(getRecipeById, { payload: { _id: recipeId, orderId } });
        yield put(recipeActions.stopLoadingRecipeItemPage());
        yield all([call(getCartDetails, { forceCall: false }), call(getUserRecipeFavoriteRequest)]);
    } catch (error) {
        const data = { recipeId };
        const text = 'Error in fetchRecipeItemPageData';
        errorNotification(error, data, text);
    }
}

export function* fetchBuildMenuPageData({ data }) {
    const { handle } = data;
    const result = yield select((state) => state.buildMenuReducer.buildMenuResult);
    try {
        yield call(getInitialData, {
            params: {
                default: true,
                handle,
            },
        });
        if (result.length <= 0) yield call(getBuildMenuPageResult, { forceCall: true });
        yield all([call(getCartDetails, { forceCall: false }), call(getUserRecipeFavoriteRequest)]);
    } catch (error) {
        const data = {};
        const text = 'Error in fetchBuildMenuPageData';
        errorNotification(error, data, text);
    }
}

export function* fetchUsePantryPageData({ data }) {
    const { handle } = data;
    try {
        yield call(getInitialData, {
            params: {
                default: true,
                handle,
            },
        });
        yield call(getCartDetails, { forceCall: false });
    } catch (error) {
        const data = {};
        const text = 'Error in fetchBuildMenuPageData';
        errorNotification(error, data, text);
    }
}
export function* fetchCollectionsPageData({ data }) {
    try {
        yield call(getInitialData, {
            params: {
                default: true,
                handle: data.handleId,
            },
        });
        const fetched = yield select((state) => state.curatedRecipesReducer.fetchedCuratedRecipes);

        if (!fetched) {
            yield put(curatedRecipesActions.getCuratedRecipesRequest());
        }
        yield call(getCartDetails, { forceCall: false });
    } catch (error) {
        const data = {};
        const text = 'Error in fetchCollectionsPageData';
        errorNotification(error, data, text);
    }
}

export function* fetchCheckoutPageData({ data }) {
    try {
        yield put(uiSettingActions.loadingPage());
        yield call(getInitialData, {
            params: {
                default: true,
                handle: data.handleId,
            },
        });
        const loggedInUser = yield select((state) => state.authenReducer.loggedInUser);

        yield put(uiSettingActions.loadedPage());
        yield all([
            call(getCartDetails, { forceCall: false, adjustDigitalPantry: true }),
            call(getCities, { forceCall: false }),
            loggedInUser && call(getPaymentMethods, { cb: () => {} }),
        ]);
        const user = yield select((state) => state.userReducer.userDetails);
        const countries = yield select((state) => state.citiesReducer.countries);
        const storeAddress = yield select((state) => state.orderReducer.deliveryAddress);
        const isAddressInitialized = yield select((state) => state.orderReducer.isAddressInitialized);
        if (isAddressInitialized) return;
        if (storeAddress.email) {
            return yield put(orderActions.setDeliveryAddressSuccess(storeAddress));
        }
        if (loggedInUser) {
            yield put(orderActions.getDeliveryAddressSuccess(user, countries));
        }
    } catch (error) {
        const data = {};
        yield put(uiSettingActions.loadedPage());
        const text = 'Error in fetchCheckoutPageData';
        errorNotification(error, data, text);
    }
}

export function* fetchGroceriesPlanPageData({ data }) {
    try {
        yield put(uiSettingActions.loadingPage());
        yield call(getInitialData, {
            params: {
                default: true,
            },
        });
        yield call(getCartDetails, { forceCall: true });
        yield put(uiSettingActions.loadedPage());
        yield call(getUserPantryRequest, { forceCall: false });
    } catch (error) {
        const data = {};
        const text = 'Error in fetchGroceriesPlanPageData';
        yield put(uiSettingActions.loadedPage());
        errorNotification(error, data, text);
    }
}

export function* fetchGroceriesManagementPageData({ data }) {
    try {
        yield put(uiSettingActions.loadingPage());
        yield call(getInitialData, {
            params: {
                default: true,
                handle: data.handleId,
            },
        });
        yield call(getUserPantryRequest, { forceCall: false });
        yield put(uiSettingActions.loadedPage());
        yield call(getCartDetails, { forceCall: false });
    } catch (error) {
        const data = {};
        const text = 'Error in fetchGroceriesManagementPageData';
        yield put(uiSettingActions.loadedPage());
        errorNotification(error, data, text);
    }
}

export function* fetchRecentOrdersPageData({ data }) {
    try {
        yield put(uiSettingActions.loadingPage());
        yield call(getInitialData, {
            params: {
                default: true,
                handle: data.handleId,
            },
        });
        yield call(getOrderHistoryRequest);
        yield put(uiSettingActions.loadedPage());
        yield all([call(getCartDetails, { forceCall: false }), call(getUserRecipeFavoriteRequest)]);
    } catch (error) {
        const data = {};
        const text = 'Error in fetchRecentOrdersPageData';
        yield put(uiSettingActions.loadedPage());
        errorNotification(error, data, text);
    }
}

export function* fetchSettingsPageData({ data }) {
    try {
        yield put(uiSettingActions.loadingPage());
        yield call(getInitialData, {
            params: {
                default: true,
                handle: data.handleId,
                loadUser: false,
                isLoadedUser: true,
            },
        });
        yield all([call(getCartDetails, { forceCall: false }), call(getPaymentMethods, { cb: () => {} })]);
        yield put(uiSettingActions.loadedPage());
    } catch (error) {
        const data = {};
        const text = 'Error in fetchSettingsPageData';
        yield put(uiSettingActions.loadedPage());
        errorNotification(error, data, text);
    }
}

export default function*() {
    yield takeLatest(constants.GET_COLLECTION_PAGE_DATA, fetchCollectionPageData);
    yield takeLatest(constants.GET_RECIPE_PAGE_DATA, fetchRecipesPageData);
    yield takeLatest(constants.GET_CATEGORY_PAGE_DATA, fetchCategoryPageData);
    yield takeLatest(constants.GET_MINIMUM_ORDER_PAGE_DATA, fetchMinimumOrderPageData);
    yield takeLatest(constants.GET_RECIPE_ITEM_PAGE_DATA, fetchRecipeItemPageData);
    yield takeLatest(constants.GET_BUILD_MENU_PAGE_DATA, fetchBuildMenuPageData);
    yield takeLatest(constants.GET_USE_PANTRY_PAGE_DATA, fetchUsePantryPageData);
    yield takeLatest(constants.GET_COLLECTIONS_PAGE, fetchCollectionsPageData);
    yield takeLatest(constants.GET_CHECKOUT_PAGE, fetchCheckoutPageData);
    yield takeLatest(constants.GET_GROCERIES_PLAN_PAGE, fetchGroceriesPlanPageData);
    yield takeLatest(constants.GET_GROCERIES_MANAGEMENT_PAGE, fetchGroceriesManagementPageData);
    yield takeLatest(constants.GET_RECENT_ORDERS_PAGE, fetchRecentOrdersPageData);
    yield takeLatest(constants.GET_SETTINGS_PAGE, fetchSettingsPageData);
}
