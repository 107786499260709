import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import * as constants from '../constants';
import * as actions from './curatedRecipes.action';
import { errorNotification } from '../../helpers/errorNotification';

import GetCuratedRecipes from './GetCuratedRecipes';
import GetCuratedRecipe from './GetCuratedRecipe';

export function* getCuratedRecipes({ forceCall }) {
    try {
        yield call(GetCuratedRecipes.make, { forceCall });
    } catch (error) {
        const data = {};
        const text = 'Error in getCuratedRecipes saga';
        errorNotification(error, data, text);
        yield put(actions.getCuratedRecipesFailure(error));
    }
}
export function* getCuratedRecipe({ id }) {
    try {
        yield call(GetCuratedRecipe.make, { id });
    } catch (error) {
        const data = { id };
        const text = 'Error in getCuratedRecipe saga';
        errorNotification(error, data, text);
        yield put(actions.getCuratedRecipeFailure(error));
    }
}
export default function* () {
    yield takeLatest([constants.GET_CURATED_RECIPES_REQUEST, constants.LOAD_MORE_CURATED_RECIPES], getCuratedRecipes);
    yield takeEvery(constants.GET_CURATED_RECIPE_REQUEST, getCuratedRecipe);
}
