import { call, put, takeEvery, select } from 'redux-saga/effects';
import * as constants from '../constants';
import * as actions from '../actions/extraProductCategory.actions';
import serverAPI from 'services/extraProductCategory.service';
import { errorNotification } from 'helpers/errorNotification';

export const selectSelectedExtraCategories = () =>
    select(({ extraProductCategoryReducer }) => extraProductCategoryReducer.selected);
export const selectIsLoadedExtraCategories = () =>
    select(({ extraProductCategoryReducer }) => extraProductCategoryReducer.isloadedExtraCategories);
export function* getExtraProductsCategories() {
    try {
        const isLoadedExtraCategories = yield selectIsLoadedExtraCategories();
        if (!isLoadedExtraCategories) {
            const response = yield call(serverAPI.getExtraProductsFilters);
            if (response) {
                yield put(actions.getExtraProductsCategoriesSuccess(response.data));
            }
        }
    } catch (error) {
        yield put(actions.getExtraProductsCategoriesFailure('getExtraProductsCategories'));
        const data = {};
        const text = 'Error in getExtraProductsCategories saga';
        errorNotification(error, data, text, false);
    }
}

export function* toggleExtraCategory({ categoryName }) {
    try {
        const selectedCategories = yield selectSelectedExtraCategories();
        if (selectedCategories.indexOf(categoryName) === -1) {
            yield put(actions.addExtraCategory(categoryName));
        } else {
            yield put(actions.deleteExtraCategory(categoryName));
        }
    } catch (error) {
        const data = { categoryName };
        const text = 'Error in toggleExtraCategory saga';
        errorNotification(error, data, text);
    }
}

export default function* () {
    yield takeEvery(constants.TOGGLE_EXTRA_CATEGORY, toggleExtraCategory);
    yield takeEvery(constants.GET_EXTRA_PRODUCTS_CATEGORIES_REQUEST, getExtraProductsCategories);
}
