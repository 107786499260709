const initialState = {
    data: [
        {
            name: 'Mohamed',
            avatar: 'https://easier-chef.s3.ca-central-1.amazonaws.com/1646647945625.Mohamed.png',
            position: 'CO-FOUNDER, CEO',
            linkedIn: 'https://www.linkedin.com/in/mkdabbour',
            gmail: 'mohamed@easierchef.com',
            description:
                'Tech entrepreneur focused on innovation, Mohamed brings start-up experience beginning in 2012 by building and growing a YouTube MCN garnishing 300MM views per month with an exit in 2012. This was followed by 3-4 years of experience pioneering the telemedicine space in Canada, starting and growing a team of 20+ people before his exit. Mohamed is an Honours BBA graduate from Wilfrid Laurier University who likes to solve problems with tech. ',
        },
        {
            name: 'Abhinav',
            avatar: 'https://easier-chef.s3.ca-central-1.amazonaws.com/1646739008542.Abhinav.png',
            position: 'CO-FOUNDER, CTO',
            linkedIn: 'https://www.linkedin.com/in/abhinav-jain-943867125/',
            gmail: 'abhinav@easierchef.com',
            description:
                'Abhinav brings in his years of experience in Technology in a startup environment. He worked at KNOLSKAPE solutions where he started as a Software Developer Intern and transformed himself into a Technical Team Lead within 4 years. He has built a variety of projects from scratch which includes but not limited to virtual simulations, quizzing platform, research platform, data pipeline, and microservices. He has done his Masters from University of Waterloo where he learnt the art of building scalable systems.',
        },
        {
            name: 'Suzan Galuzzo',
            avatar: 'https://easier-chef.s3.ca-central-1.amazonaws.com/1646648303710.Suzan.png',
            position: 'Director of Fitness',
            linkedIn: 'https://www.linkedin.com/in/suzan-galluzzo-06157914a',
            gmail: 'suzan@easierchef.com',
            description:
                'Womans fitness coach and founder of the ‘Better for You’ program. With an expertise in nutrition and hormones, Sue has transformed 10,000+ clients for better, more confident lives.',
        },
        {
            name: 'Anis Barakat',
            avatar: 'https://easier-chef.s3.ca-central-1.amazonaws.com/1646725642623.Anis.jpg',
            position: 'Director of Marketing',
            linkedIn: 'https://www.linkedin.com/in/anis-barakat-576299b3',
            gmail: 'anis@easierchef.com',
            description:
                'Experienced in the ecommerce and food industry. Having founded Nutri-go; a prepared food meal kit based out of Calgary, and having Founded Revive SuperFoods, a DTC frozen smoothie kit available across North America, Anis brings years of industry expertise to the team.',
        },
        {
            name: 'Nastya Sedova',
            avatar: 'https://easier-chef.s3.ca-central-1.amazonaws.com/1646734154212.Nastya.png',
            position: 'UX/UI Designer',
            linkedIn: 'https://www.linkedin.com/in/anastasiasedova303',
            gmail: 'nastya@easierchef.com',
            description:
                'Designer with 2+ years of experience. Working on complex e-commerce projects, mobile & desktop apps, landing pages. She has created design from scratch and improving existing designs. Uses human-centred approach and design thinking.',
        },
        {
            name: 'Salony Tuli',
            avatar: 'https://easier-chef.s3.ca-central-1.amazonaws.com/1647507987244.IMG_20220316_153652.jpg',
            position: 'Senior Software Developer',
            linkedIn: 'https://www.linkedin.com/in/salony-tuli-2294a61b0',
            gmail: 'salony@easierchef.com',
            description:
                'She is an excellent developer. She has more than 6  years of experience in this field. Her professional experience includes collaborating with front end and back-end developers to create a stellar finished product. She has the ability to adapt quickly to changes. She holds a "M-tech" Degree.',
        },
        {
            name: 'Divyansh Rathore',
            avatar: 'https://easier-chef.s3.ca-central-1.amazonaws.com/1647507987256.IMG_20220316_125637.jpg',
            position: 'Senior Software Developer',
            linkedIn: '',
            gmail: 'divyansh@easierchef.com',
            description:
                'Fullstack developer with 4+ years of experience.  He has done many projects ranging from simple to complex ones.He has master degree in Engineering. He has a sharp knowledge in full stack development and also awesome at communication',
        },
    ],
};
export default function (state = initialState, action) {
    switch (action.type) {
        default:
            return state;
    }
}
