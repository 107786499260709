import styled, { css } from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export const AutocompleteInputDropdown = styled(Autocomplete)`
    width: 100%;

    & > div > div {
        background: #eff5f9;
        border-radius: 8px;

        & > fieldset {
            border: none;
        }
    }
`;

export const TextFieldInputDropdown = styled(TextField)`
    width: 100%;
`;

export const SearchContainer = styled.div`
    display: flex;
    width: fit-content;
    background: #fff;
    padding: 12px;
    border: 1px solid #e5eaed;
    box-sizing: border-box;
    border-radius: 50px;
    ${(props) =>
        css`
            height: ${props.height};
            width: ${props.width};
            font-size: ${props.fontSize};
        `};
    ${(props) =>
        props.isFocus &&
        css`
            border: 1px solid #82bd73 !important;
        `};

    input {
        border: none;
        color: #233747;
        margin-left: 10px;
        width: 100%;
        ::placeholder {
            color: #909da6;
            color: ${(props) => (props.isFocus ? 'transparent' : '#909DA6')};
        }
    }
`;

export const SearchClean = styled.div`
    margin: auto 0;
    cursor: pointer;
    margin-left: 7px;
`;

export const TextFieldContainer = styled.div`
    width: fit-content;
    background: #fff;
    padding: 12px;
    box-sizing: border-box;
    font-size: 14px;
    border-radius: 8px;
    width: ${(props) => props.width};
    min-height: ${(props) => props.height};
    input {
        width: 100%;
        border: none;
        color: #233747;
        &:focus {
            ::placeholder {
                color: transparent;
            }
        }
        ::placeholder {
            color: ${(props) => (props.hidePlaceHolder ? 'transparent' : '#909DA6')};
        }
    }
`;

export const ChipList = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const ChipItem = styled.div`
    display: flex;
    width: fit-content;
    padding: 4px 8px;
    background: #eff5f9;
    border-radius: 4px;
    margin: 2px;
    color: #233747;
`;

export const ChipIcon = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 5px;
`;

export const InputContainer = styled.div`
    width: ${(props) => props.width};
    ${(props) =>
        props.size === 'medium' &&
        css`
            max-width: 180px;
        `}
`;

export const InputElement = styled.div`
    ${(props) =>
        props.size === 'medium' &&
        css`
            max-height: 42px;
        `}
    height: ${(props) => props.height};
    opacity: ${(props) => (props.disabled ? '0.3' : '1')};
    background: #eff5f9;
    border-radius: 8px;
    color: #233747;
    display: flex;
    border-radius: 8px;
    padding: ${(props) => (props.size === 'medium' ? '0 16px' : props.size === 'large' ? '0 20px' : '0: 10px')};
    border: 1px solid transparent;
    border-color: ${(props) => (props.isError ? '#FF6B6B' : 'transparent')};
    font-size: ${(props) => props.fontSize};

    input {
        width: 100%;
        border: none;
        padding: 0;
        color: #233747;
        background: transparent;
        ::placeholder {
            color: #909da6;
        }
        &:focus {
            ::placeholder {
                color: transparent;
            }
        }
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px #eff5f9 inset !important;
    }
    button {
        border: none;
        background: transparent;
        color: ${(props) => (props.isError ? '#FF6B6B' : '#82BD73')};
        cursor: ${(props) => (props.disabled ? 'none' : 'pointer')};
        ${(props) => props.disabled && 'pointer-events: none;'}
        font-weight: 500;
    }
`;

export const BeforeInput = styled.div`
    margin-right: 10px;
    display: flex;
    align-items: center;
`;

export const AfterInput = styled.div`
    margin-left: 10px;
    display: flex;
    align-items: center;
`;

export const ErrorMessage = styled.div`
    color: #ff6b6b;
    margin-top: 5px;
    display: flex;
    padding-left: 20px;
    font-size: ${(props) => props.errorSize};
`;
