const pagesWithoutCart = ['/signin', '/signup', '/onboarding/', '/faq', '/about-us'];
const pagesWithoutTotalCost = ['/recipes', '/meal-plan', '/build-menu'];

export const pageWithoutCartIcon = (path) => {
    let flag = true;
    if (path == '/') {
        return false;
    }
    pagesWithoutCart.map((item) => {
        if (path.includes(item)) {
            flag = false;
        }
    });

    return flag;
};

export const cartWithoutTotalCost = (path, search) => {
    let flag = true;
    pagesWithoutTotalCost.map((item) => {
        if (path.includes(item)) {
            flag = false;
        }
    });
    if (search.includes('recipes')) return false;
    return flag;
};
