import * as constants from '../constants';

export const setCurrentStep = (stepIndex) => ({
    type: constants.SET_CURRENT_STEP,
    stepIndex,
});

export const addInfoOnboarding = (payload) => ({
    type: constants.ADD_INFO_ONBOARDING,
    payload,
});

export const updatedPayloadOnboarding = () => ({
    type: constants.UPDATED_PAYLOAD_ONBOARDING,
});

export const updatedPayloadOnboardingSuccess = () => ({
    type: constants.UPDATED_PAYLOAD_ONBOARDING_SUCCESS,
});
