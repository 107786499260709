import axios from 'axios';
import get from 'lodash/get';
import Cookies from 'js-cookie';
import config from 'config';
import history from '../history';
import reduxStore from 'store/store';
import { unauthenticatedUser } from 'store/actions/authentication.actions';
import NetworkError from './NetworkError';

const getDefaultHeaders = () => {
    const token = Cookies.get('token');
    const body = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };
    const userId = localStorage.getItem('guestUserId');
    if (userId) {
        return { ...body, userId };
    }
    if (token) {
        return { ...body, Authorization: `Bearer ${token}` };
    }
    return body;
};

const handleSessionExpire = () => {
    const token = Cookies.get('token');
    const isContentForLoggedInUser = localStorage.getItem('isContentForLoggedInUser');
    if (!token && Boolean(isContentForLoggedInUser)) {
        const config = localStorage.getItem('config');
        const influencer = localStorage.getItem('influencer');
        localStorage.clear();
        if (config) {
            localStorage.setItem('config', config);
        }
        if (influencer) {
            localStorage.setItem('influencer', influencer);
        }
        if (!location.pathname.includes('/signin')) {
            history.push('/');
            window.location.reload();
        }
    }
};

const apiURL = config[process.env.REACT_APP_ENV].API_DOMAIN;

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        if (get(error, 'response.status') === 401) {
            const { dispatch, getState } = reduxStore;
            const isPopupOpen = getState().loginModalReducer.isLoginPopupOpen;
            await dispatch(unauthenticatedUser());
            if (!isPopupOpen) history.push('/');
        }
        return Promise.reject(error);
    },
);

const requestsFactory =
    (baseURL) =>
    ({ url, method = 'get', headers = {}, data, query }) => {
        handleSessionExpire();
        return axios({
            baseURL,
            url,
            method,
            headers: { ...getDefaultHeaders(), ...headers },
            data,
            params: query,
        })
            .then((response) => response)
            .catch((error) => {
                throw new NetworkError(error, {
                    url: `${method} ${url}`,
                    query,
                    body: data,
                });
            });
    };

export const apiRequest = requestsFactory(apiURL);

export default requestsFactory;
