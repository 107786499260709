/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import history from '../../history';
import useScreenType from 'hooks/useScreenType';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import { showBuildMenuFiltersPopup } from 'store/actions/ui-settings.actions';

import BuildMenuBenefits from './LoginBenefits';
import BuildMenuSignup from './LoginPopups/signUp';
import BuildMenuSignin from './LoginPopups/signIn';

import Loading from 'components/common/Loading';

import styles from './login.module.scss';

const LoginPopup = () => {
    const { isMobile, isTabletOrMobile } = useScreenType();
    const open = useSelector(state => state.loginModalReducer.isLoginPopupOpen);
    const { emailInPopup, accountInfo, isFetched } = useSelector(state => state.loginModalReducer);
    const cost = useSelector(state => state.recipeReducer.Cart.pricePayload);
    const url = new URL(window.location);
    const loginMode = url.searchParams.get('loginmode');

    const handleChnageMode = mode => {
        history.push(`${url.pathname}?loginmode=${mode}`);
    };

    return (
        <div>
            <Dialog
                scroll="body"
                open={open}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                classes={{ paper: styles.modalFilter }}
            >
                <DialogContent className={!isMobile && isTabletOrMobile ? styles.contentTablet : styles.content}>
                    <div className={styles.allContent}>
                        <BuildMenuBenefits cost={cost} />

                        {/* <Loading/> */}
                        <div className={styles.dividedContent}>
                            {(loginMode === 'email' || loginMode === 'signup') && (
                                <BuildMenuSignup
                                    selectedLoginMode={loginMode}
                                    emailInPopup={emailInPopup}
                                    isFetched={isFetched}
                                    accountInfo={accountInfo}
                                    chnageMode={mode => handleChnageMode(mode)}
                                />
                            )}
                            {loginMode === 'signin' && <BuildMenuSignin chnageMode={mode => handleChnageMode(mode)} />}
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default LoginPopup;
