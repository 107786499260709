import Cookies from 'js-cookie';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import cloneDeep from 'lodash/cloneDeep';

import { Messages } from 'constants/notificationMessages';

import { customNotification } from 'components/common/Notification';

import * as constants from '../constants';
import * as actions from '../actions/user.actions';
import * as uiSettingsAction from '../actions/ui-settings.actions';
import * as actionsAuthen from '../actions/authentication.actions';
import { getUserDetails } from '../selectors/user.selectors';

import serverAPI from 'services/user.service';
import { errorNotification } from 'helpers/errorNotification';

export function* getUserRequest({ loadUser, isAddressAvailable }) {
    const isLoadedUser = yield select((state) => state.userReducer.isLoadedUser);
    const userDetails = yield select((state) => state.userReducer.userDetails);
    const deliveryAddress = yield select((state) => state.recipeReducer.Cart.deliveryInfo);

    try {
        if (loadUser || !isLoadedUser) {
            const token = Cookies.get('token');
            const headers = {
                Authorization: token,
            };
            let userResponse;
            if (token) {
                userResponse = yield call(serverAPI.getUserInfo, headers);
                yield put(actions.getUserSuccess(userResponse.data));
                yield put(uiSettingsAction.clearActiveButton());
                if (userResponse.data) {
                    yield put(
                        uiSettingsAction.setDefaultServings(
                            get(userResponse.data, 'shopperInfo.preferences.noOfServingsPerRecipe', 2),
                        ),
                    );
                }
                yield put(actionsAuthen.closeIsLogining());
            }
        } else {
            yield put(actions.getUserSuccess(userDetails));
        }
    } catch (error) {
        const data = { userDetails, deliveryAddress, loadUser, isAddressAvailable };
        const text = 'Error in getUserRequest saga';
        errorNotification(error, data, text);
        yield put(actions.getUserFailure(error));
    }
}
export function* getUserRecipeFavoriteRequest() {
    try {
        const token = Cookies.get('token');
        const headers = {
            Authorization: token,
        };
        const isLoadedFavorite = yield select((state) => state.userReducer.isLoadedFavorite);
        if (token && !isLoadedFavorite) {
            const recipeFavorites = yield call(serverAPI.getFavorite, headers);
            yield put(actions.getRecipeFavoriteSuccess(get(recipeFavorites, 'data', [])));
        }
    } catch (error) {
        const data = {};
        const text = 'Error in getUserRecipeFavoriteRequest saga';
        errorNotification(error, data, text);
        yield put(actions.getRecipeFavoriteFailure(error));
    }
}
export function* editUserInfoRequest(action) {
    try {
        const token = Cookies.get('token');
        const headers = {
            Authorization: token,
        };
        const { _id = '' } = yield select(getUserDetails);
        const userResponse = yield call(serverAPI.editUserInfo, action.payload, _id, headers);
        yield put(actions.editUserInfoSuccess(userResponse.data));
        yield put(actions.getUserRequest());
        if (!action.isDisableNotification) {
            customNotification('success', Messages.EDIT_USER);
        }
    } catch (error) {
        const data = { payload: action.payload };
        const text = 'Error in editUserInfoRequest saga';
        errorNotification(error, data, text);
        yield put(actions.editUserInfoFailure(error));
    }
}

export const selectIsLoadedDiets = () => select(({ userReducer }) => userReducer.isLoadedDiet);
export function* getDietRequest() {
    try {
        const isLoadedDiet = yield selectIsLoadedDiets();
        if (!isLoadedDiet) {
            const token = Cookies.get('token');
            const headers = {
                Authorization: token,
            };
            const response = yield call(serverAPI.getDietList, headers);
            yield put(actions.getDietSuccess(response.data));
        }
    } catch (error) {
        const data = {};
        const text = 'Error in getDietRequest saga';
        errorNotification(error, data, text);
        yield put(actions.getDietFailure(error));
    }
}

export function* changePasswordRequest(action) {
    try {
        const token = Cookies.get('token');
        const headers = {
            Authorization: token,
        };
        const response = yield call(serverAPI.changePassword, action.payload, headers);
        yield put(actions.changePasswordSuccess(response.data));
        action.onSuccess();
        customNotification('success', Messages.CHANGE_PASSWORD);
    } catch (error) {
        const data = { emailAddress: action.payload.emailAddress };
        const text = 'Error in changePasswordRequest saga';
        errorNotification(error, data, text, true);
        yield put(actions.changePasswordFailure(error));
    }
}

export function* getOrderHistoryRequest() {
    try {
        const token = Cookies.get('token');
        const headers = {
            Authorization: token,
        };
        const response = yield call(serverAPI.getOrderHistory, headers);
        yield put(actions.getOrderHistorySuccess(response.data));
    } catch (error) {
        const data = {};
        const text = 'Error in getOrderHistoryRequest saga';
        errorNotification(error, data, text);
        yield put(actions.getOrderHistoryError(error));
    }
}

export function* getPaymentMethods({ cb }) {
    try {
        const token = Cookies.get('token');
        const headers = {
            Authorization: token,
        };
        if (token) {
            const response = yield call(serverAPI.getPaymentMethods, headers);
            yield put(actions.getPaymentMethodsSuccess(response.data));
        }
    } catch (error) {
        const data = {};
        const text = 'Error in getPaymentMethods saga';
        errorNotification(error, data, text, true);
        yield put(actions.getPaymentMethodsFailure(error));
    }
    cb(true);
}

export function* addPaymentMethod({ billing_details, cardElement, stripe, showSuccessNotification, isStepOpen }) {
    try {
        const temporaryOrderInfo = yield select((state) => state.uiSettingsReducer.temporaryOrderInfo);
        const { paymentMethod, error } = yield call(stripe.createPaymentMethod, {
            type: 'card',
            card: cardElement,
            billing_details,
        });
        if (error) {
            return customNotification('error', get(error, 'message', 'Error while adding your card'));
        }
        const response = yield call(serverAPI.addPaymentMethod, paymentMethod.id);
        yield put(actions.addPaymentMethodSuccess(response.data));
        if (showSuccessNotification) {
            customNotification('success', Messages.ADD_PAYMENT_METHOD);
        } else {
            yield put(
                uiSettingsAction.setTemporaryOrderInfo({
                    ...temporaryOrderInfo,
                    cardElementOrId: paymentMethod.id,
                    createdCardElement: [paymentMethod, ...(temporaryOrderInfo.createdCardElement || [])],
                    showCardElements: false,
                    isPaymentComplete: true,
                    isStepOpen: { ...isStepOpen, 4: false },
                }),
            );
        }

        // Card saved from checkout will be available in store no need to fetch again
        if (showSuccessNotification) {
            yield put(actions.getPaymentMethods(() => {}));
        }
    } catch (error) {
        const temporaryOrderInfo = yield select((state) => state.uiSettingsReducer.temporaryOrderInfo);
        const data = { billing_details };
        const text = 'Error while adding payment method';
        errorNotification(error, data, text, true);
        yield put(actions.addPaymentMethodFailure(error));
    }
}

export function* updatePaymentMethod({ id, cardData }) {
    try {
        const response = yield call(serverAPI.updatePaymentMethod, id, { payload: cardData });
        yield put(actions.updatePaymentMethodSuccess(response.data));
        customNotification('success', Messages.UPDATE_PAYMENT_METHOD);
    } catch (error) {
        const data = { id, cardData };
        const text = 'Error in updatePaymentMethod saga';
        errorNotification(error, data, text, true);
        yield put(actions.updatePaymentMethodFailure(error));
    }
}

export function* deletePaymentMethod({ id }) {
    try {
        const response = yield call(serverAPI.deletePaymentMethod, id);
        yield put(actions.deletePaymentMethodSuccess(response.data));
        customNotification('success', Messages.DELETE_PAYMENT_METHOD);
        yield put(actions.getPaymentMethods(() => {}));
    } catch (error) {
        const data = { id };
        const text = 'Error in deletePaymentMethod saga';
        errorNotification(error, data, text, true);
        yield put(actions.deletePaymentMethodFailure(error));
    }
}
export function* updateRecipeFavorite({ recipe }) {
    try {
        const token = Cookies.get('token');
        const headers = {
            Authorization: token,
        };
        const recipeFavorites = yield select((state) => state.userReducer.recipeFavorites);
        let newRecipeFavorites = cloneDeep(recipeFavorites);
        const index = findIndex(recipeFavorites, (item) => item.recipeId._id === recipe._id);
        if (index > -1) {
            newRecipeFavorites.splice(index, 1);
            yield call(serverAPI.deleteRecipeFavorite, headers, recipe._id);
        } else {
            newRecipeFavorites.push({ recipeId: recipe });
            yield call(serverAPI.createRecipeFavorite, headers, recipe._id);
        }
        yield put(actions.updateRecipeFavoriteSuccess(newRecipeFavorites));
    } catch (error) {
        const data = { recipe };
        const text = 'Error in updateRecipeFavorite saga';
        errorNotification(error, data, text);
        yield put(actions.updateRecipeFavoriteFailure(error));
    }
}

export default function* () {
    yield takeLatest(constants.GET_USER_REQUEST, getUserRequest);
    yield takeLatest(constants.EDIT_USER_REQUEST, editUserInfoRequest);
    yield takeLatest(constants.GET_DIET_REQUEST, getDietRequest);
    yield takeLatest(constants.CHANGE_PASSWORD_REQUEST, changePasswordRequest);
    yield takeLatest(constants.GET_ORDER_HISTORY_REQUEST, getOrderHistoryRequest);
    yield takeLatest(constants.GET_PAYMENT_METHODS_REQUEST, getPaymentMethods);
    yield takeLatest(constants.ADD_PAYMENT_METHOD_REQUEST, addPaymentMethod);
    yield takeLatest(constants.DELETE_PAYMENT_METHOD_REQUEST, deletePaymentMethod);
    yield takeLatest(constants.UPDATE_PAYMENT_METHOD_REQUEST, updatePaymentMethod);
    yield takeLatest(constants.UPDATE_RECIPE_FAVORITE_REQUEST, updateRecipeFavorite);
    yield takeLatest(constants.GET_RECIPE_FAVORITE, getUserRecipeFavoriteRequest);
}
