import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import useTheme from 'hooks/useTheme';
import history from '../../../history';
import get from 'lodash/get';

import Dialog from '@material-ui/core/Dialog';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import Icons from '../Icons/Icons';
import TypographyD from '../TypographyD/TypographyD';
import Links from 'components/common/Links/Links';

import Summary from './index';
import ExtraProduct from './extraProduct';
import NutritionDetails from './../../NutritionDetails';
import IngredientDetail from 'components/IngredientDetail';

import MobileBar from 'components/common/MobileBar';
import CostContainer from './cost';

import { getProgressStep } from 'helpers/progressStep';

import { showMobileSummaryPopup as showSummaryModal } from 'store/actions/ui-settings.actions';
import { setSelectedRecipeServings, handleDeleteRecipe, setServingsRecipe } from 'store/actions/recipe.action';
import { getCommonIngredientsRequest } from 'store/actions/orderMenu.actions';
import { deleteExtraProduct } from 'store/actions/extraProduct.actions';
import { createOrder } from 'store/actions/order.actions';

import { MobileContainer, MobileTitle, MobileText } from './styles';
import ShareCartButton from './ShareCartButton';

import { updateStateSummaryPopup } from 'store/actions/ui-settings.actions';

const MobileSummaryPopUp = ({ ...props }) => {
    const color = useTheme();
    const {
        location: { pathname },
    } = history;
    const currentStep = getProgressStep(pathname);
    const open = useSelector((state) => state.uiSettingsReducer.isMobileSummaryPopupOpen);
    const nutrition = useSelector((state) => state.recipeReducer.Cart.avgNutrition);
    const extraProducts = useSelector((state) => state.extraProductReducer.selected);
    const cartLink = useSelector((state) => state.cartReducer.sharedCartUrl);
    const cost = useSelector((state) => state.recipeReducer.Cart.pricePayload);
    const summary = useSelector((state) => state.orderReducer.summary);
    const deliveryFee = useSelector((state) => state.recipesReducer.Cart.pricePayload.deliveryFee);
    const isPromoCodeApplied = useSelector((state) => state.orderReducer.isPromoCodeApplied);
    const commonIngredientRecipes = useSelector((state) => state.orderMenuReducer.commonIngredients);
    const minimumCommonIngredientRecipes = useSelector((state) => state.uiSettingsReducer.minimumCommonIngredientRecipes);
    const defaultServings = useSelector((state) => state.uiSettingsReducer.defaultServings);
    const recipes = useSelector((state) => state.recipeReducer.Cart.selectedRecipes);
    const temporaryOrderInfo = useSelector((state) => state.uiSettingsReducer.temporaryOrderInfo);
    const isEditing = useSelector((state) => state.recipeReducer.isEditing);
    const caloriesGoal = useSelector((state) => state.buildMenuReducer.caloriesGoal);
    const mealTypes = useSelector((state) => state.mealTypesReducer.mealTypes);

    const [expandMenu, setExpandMenu] = useState(currentStep != 2);
    const [showMore, setShowMore] = useState(false);

    const dispatch = useDispatch();

    const disabled =
        (currentStep == 2 &&
            ((temporaryOrderInfo.isStepOpen && Object.values(temporaryOrderInfo.isStepOpen).includes(true)) ||
                !temporaryOrderInfo.isPaymentComplete)) ||
        (pathname === '/min-order' && cost.totalPrice < 50) ||
        isEditing;

    const handleClose = () => {
        dispatch(showSummaryModal(false));
    };

    const viewIcon = () => {
        return <div className="viewMore">{expandMenu ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div>;
    };

    return (
        <Dialog fullScreen open={open} onClose={handleClose}>
            <MobileContainer
                open={open}
                currentStep={currentStep}
                recipes={recipes}
                extraProducts={extraProducts}
                showMore={showMore}
                showDiscountRecipesBanner={props.isShowDiscountRecipesBanner}
            >
                <MobileTitle>
                    <Icons name="close" size="20" color={color.gray0} onClick={() => handleClose()} />
                    <TypographyD type="bodyMedium" fontWeight="500" className="summary-title">
                        Your menu
                    </TypographyD>
                    <ShareCartButton cartLink={cartLink} />
                </MobileTitle>

                {(recipes || []).length > 0 && (
                    <div className="content-wrapper">
                        {Object.keys(nutrition).length > 0 && (
                            <NutritionDetails nutrition={nutrition} caloriesGoal={caloriesGoal} />
                        )}
                        <div className="main-list-wrapper">
                            <div className="menu-wrapper">
                                <Summary
                                    recipes={recipes}
                                    mealTypes={mealTypes}
                                    defaultServings={defaultServings}
                                    changeServings={(recipeId, servings) =>
                                        dispatch(setSelectedRecipeServings(recipeId, servings))
                                    }
                                    handleDeleteRecipe={(val) => dispatch(handleDeleteRecipe(val))}
                                    // getCommonIngredientsRequest={(val) => dispatch(getCommonIngredientsRequest(val))}
                                    setServingsRecipe={(recipeId, servings, curatedBoxId) =>
                                        dispatch(setServingsRecipe(recipeId, servings, curatedBoxId))
                                    }
                                    handleCloseModal={handleClose}
                                    showMore={showMore}
                                />
                                {recipes.length > 5 && (
                                    <Links
                                        label={showMore ? 'Hide' : 'See more'}
                                        type="tertiary"
                                        size="medium"
                                        className="see-more-link"
                                        onClick={() => setShowMore(!showMore)}
                                    />
                                )}
                            </div>
                            {currentStep > 0 && (
                                <div className="ingredientDetail">
                                    <IngredientDetail />
                                </div>
                            )}
                            {extraProducts.length > 0 && (
                                <ExtraProduct
                                    extraProducts={extraProducts}
                                    handleDelete={(id) => dispatch(deleteExtraProduct(id))}
                                />
                            )}
                            <CostContainer
                                isMobile={true}
                                cost={cost}
                                isEditing={isEditing}
                                extraProducts={extraProducts}
                                summary={summary}
                                isPromoCodeApplied={isPromoCodeApplied}
                                commonIngredientRecipes={commonIngredientRecipes}
                                minimumCommonIngredientRecipes={minimumCommonIngredientRecipes}
                                nutrition={nutrition}
                            />
                        </div>
                        <div className="mobile-bar-block">
                            <MobileBar
                                disabled={disabled}
                                handleNext={() => dispatch(createOrder())}
                                pricePayload={cost}
                                showDiscountRecipesBanner={props.showDiscountRecipesBanner}
                                isShowDiscountRecipesBanner={props.isShowDiscountRecipesBanner}
                                isMobileSummaryPopupOpen={props.isMobileSummaryPopupOpen}
                            />
                        </div>
                    </div>
                )}

                {(recipes || []).length === 0 && (
                    <div>
                        <MobileText>You haven't selected any recipes yet</MobileText>
                        <MobileText>
                            <div>
                                <Links
                                    label="Discover recipes"
                                    size="medium"
                                    type="secondary"
                                    name="plus"
                                    onClick={() => {
                                        history.push('/recipes');
                                        dispatch(updateStateSummaryPopup(false));
                                    }}
                                />
                            </div>
                        </MobileText>
                    </div>
                )}
            </MobileContainer>
        </Dialog>
    );
};

MobileSummaryPopUp.protoTypes = {
    open: PropTypes.bool.isRequired,
    expandBar: PropTypes.bool.isRequired,
    showSummaryModal: PropTypes.func.isRequired,
    extraProducts: PropTypes.array.isRequired,
};

export default MobileSummaryPopUp;
