import styled, { css } from 'styled-components';

export const HeaderLeftContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    & > div {
        cursor: pointer;
        margin-right: 0;
        width: ${({ isTabletOrMobile }) => !isTabletOrMobile && '146px'};
        @media (max-width: 768px) {
            width: ${({ isTabletOrMobile }) => !isTabletOrMobile && '130px'};
            margin-right: auto;
        }
    }
`;
export const HeaderCenterContainer = styled('div').attrs({ className: 'active' })`
    height: 100%;
    padding-left: 65px;
    & > div {
        width: ${({ isTabletOrMobile }) => isTabletOrMobile && '146px'};
        cursor: pointer;
    }
    & > nav {
        height: 100%;
        & > ul {
            list-style-type: none;
            display: flex;
            height: 100%;
            margin: 0;
            padding: 0;
            & > li {
                height: 100%;
                margin: 0px 20px;
                display: flex;
                align-items: center;
                @media (max-width: 768px) {
                    margin: 0px 10px;
                }
                & > a {
                    padding: 28px 0;
                    text-decoration: none;
                    font-family: Work Sans;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 150%;
                    color: #909da6;
                    @media (max-width: 1024px) {
                        font-size: 15px;
                        padding: 29.5px 0;
                        &.active {
                            padding-bottom: 27.5px !important;
                        }
                        &:hover {
                            padding-bottom: 27.5px !important;
                        }
                    }
                    @media (max-width: 768px) {
                        font-size: 14px;
                    }
                    &.active {
                        border-bottom: 2px solid #fd891b;
                        padding-bottom: 26px;
                        color: #fd891b;
                    }
                    &:hover {
                        border-bottom: 2px solid #fd891b;
                        padding-bottom: 26px;
                        color: #fd891b;
                    }
                }
            }
        }
    }
`;
export const HeaderRightContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto !important;
    & > button {
        padding: 12px 8px;
        height: 48px;
        cursor: pointer;
        & > span {
            & > div {
                height: 48px;
            }
        }
    }
    & > button:nth-child(1) {
        padding-right: 16px;
        cursor: pointer;
    }
    img {
        width: 42px !important;
        height: 40px !important;
        display: block;
        margin: 0 10px;
        cursor: pointer;
    }
`;

export const MenuMobileContainer = styled.div`
    position: fixed;
    z-index: 9999;
    width: 100% !important;
    top: 64px;
    border-top: 1px solid #e5eaed;
    left: 0;
    ${({ open }) =>
        open &&
        css`
            height: calc(100% - 64px);
        `}
`;
export const MenuItem = styled.div`
    background-color: #fff;
    padding: 0 12px;
    & > a {
        display: flex;
        align-items: center;
        padding: 10px 0;
        border-bottom: 1px solid #e5eaed;
        width: 100%;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        text-decoration: none;
        color: #0d2120;
        & > span {
            padding: 0px 18px;
            font-size: 16px;
            font-weight: 500;
        }
    }
    button {
        margin: 40px 0px;
        ${({ logOutBtn }) =>
            logOutBtn &&
            css`
                @media (max-width: 320px) {
                    width: 171px !important;
                }
                border: 1px solid #ff6b6b !important;
                span {
                    color: #ff6b6b !important;
                }
            `}
        ${({ logInBtn }) =>
            logInBtn &&
            css`
                @media (max-width: 320px) {
                    width: 171px !important;
                }
                width: 225px !important;
            `}
    }
`;
export const Menu = styled.div`
    background-color: rgba(35, 55, 71, 0.8);
    width: 100%;
`;
export const MenuPaper = styled.div`
    position: sticky;
    left: 0;
    width: calc(100vw - 30%);
    height: 100%;
    background: #fff;
    opacity: 1;
    display: flex;
    flex-direction: column;

    & > div:nth-child(${({ loggedInUser }) => (loggedInUser ? 10 : 7)}) {
        display: flex;
        margin-top: auto;
        margin-bottom: 30px;
        & > div {
            border: none;
            @media all and (max-width: 425px) {
                width: 230px;
            }
            @media all and (max-width: 375px) {
                width: 200px;
            }
        }
    }
    ${({ isDashboardMenu }) =>
        isDashboardMenu &&
        css`
            left: 100%;
            width: auto;
            & > div:nth-child(5) {
                display: flex;
                margin-top: auto;
                margin-bottom: 40px;
                & > div {
                    border: none;
                }
            }
        `}
`;
export const ButtonFavorite = styled.button`
    width: 100%;
    padding: 12px 0;
    border-radius: 8px;
    border: 1px solid #82bd73;
    background: #82bd73;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
`;
