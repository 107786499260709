import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';
import { Button, ButtonGroup } from '@material-ui/core';

const RadioNumberOptions = ({ options, title, value, icon, style = {}, size = 'medium', edit = false, onChange = () => {} }) => {
    return (
        <Container style={style}>
            {icon}
            <span>{title}</span>
            <ButtonGroup style={{ marginLeft: 40 }} color="primary" size={size} aria-label="outlined primary button group">
                {options.map(option => (
                    <Button variant={value === option ? 'contained' : 'outlined'} onClick={() => onChange(option)}>
                        {option}
                    </Button>
                ))}
            </ButtonGroup>
        </Container>
    );
};

RadioNumberOptions.propTypes = {
    title: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    edit: PropTypes.bool,
};

RadioNumberOptions.defaultProps = {
    options: [],
    edit: false,
};

export default RadioNumberOptions;
