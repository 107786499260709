import React from 'react';
import useScreenType from 'hooks/useScreenType';
import PropTypes from 'prop-types';

import { ContainerFluid, ContainerInner } from './styles';

const Container = ({
    maxWidth,
    minHeight,
    backgroundColor,
    backgroundInner,
    isHeader,
    isLandingPage,
    children,
    className,
    isRecipePage,
    isPadding = true,
    isOnboarding,
}) => {
    const { screensConfig } = useScreenType();

    return (
        <ContainerFluid isHeader={isHeader} backgroundColor={backgroundColor} className={className}>
            <ContainerInner
                isHeader={isHeader}
                isLandingPage={isLandingPage}
                backgroundInner={backgroundInner}
                maxWidth={screensConfig[maxWidth].minBreakpoint}
                minHeight={minHeight}
                isRecipePage={isRecipePage}
                isPadding={isPadding}
                isOnboarding={isOnboarding}
            >
                {children}
            </ContainerInner>
        </ContainerFluid>
    );
};

Container.propTypes = {
    maxWidth: PropTypes.oneOf(['LARGE_DESKTOP', 'EXTRA_LARGE_DESKTOP']),
    backgroundColor: PropTypes.string,
    backgroundInner: PropTypes.string,
    isHeader: PropTypes.bool,
    isRecipePage: PropTypes.bool,
    isOnboarding: PropTypes.bool,
};

Container.defaultProps = {
    maxWidth: 'LARGE_DESKTOP',
    backgroundColor: '#e5e5e5',
    backgroundInner: '#ffffff',
    isHeader: false,
    isRecipePage: false,
    isOnboarding: false,
};

export default Container;
