import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import Cookies from 'js-cookie';
import get from 'lodash/get';
import * as Sentry from '@sentry/browser';
import moment from 'moment-timezone';
import { toDayFormat } from 'helpers/calendar';
import * as constants from '../constants';
import * as actions from '../actions/order.actions';
import * as uiSettingAction from '../actions/ui-settings.actions';
import * as userActions from '../actions/user.actions';
import { setCurrentCity } from '../actions/cities.actions';
import serverAPI from 'services/order.service';
import userAPI from 'services/user.service';
import { pushToGTM } from 'helpers/dataLayer';
import { Events } from 'constants/events';
import { customNotification } from 'components/common/Notification';
import { Messages } from 'constants/notificationMessages';
import { clearStateLocalStorage } from '../actions/recipe.action';
import { clearSelectedExtraProducts } from '../actions/extraProduct.actions';

import { getBrandPreferencePriceCoeficient } from 'constants/brand-preferences';
import { errorNotification } from 'helpers/errorNotification';

const selectCities = () => select(({ citiesReducer }) => citiesReducer.cities);
const selectLocations = () => select(({ locationReducer }) => locationReducer.locations);
const getCurrentCity = (cities, cityId) => cities.find((city) => cityId === city.value);
const getCurrentLocation = (locations, locationId) => locations.find((location) => locationId === location._id);

const selectOrderInformation = () =>
    select(
        ({
            orderReducer: { deliveryType, deliveryAddress, deliveryDate, summary },
            recipeReducer: {
                Cart: { pricePayload, groceries, selectedRecipes },
                servings,
            },
            uiSettingsReducer: { temporaryOrderInfo },
            extraProductReducer: { selected: selectedExtraProducts },
            citiesReducer: { countries },
            recipeReducer: {
                Cart: { ingredientsToCost },
            },
        }) => ({
            deliveryType,
            deliveryAddress,
            deliveryDate,
            totalPrice: pricePayload.totalPrice,
            selectedRecipes,
            pricePayload,
            temporaryOrderInfo,
            groceries,
            defaultServings: servings,
            selectedExtraProducts,
            countries,
            summary,
            ingredientsToCost,
        }),
    );

export function* setDeliveryAddress({ payload }) {
    try {
        if (payload.deliveryType === 'Address delivery') {
            const cities = yield selectCities();
            const currentCity = getCurrentCity(cities, payload.city);
            yield put(setCurrentCity(currentCity));
        } else {
            const location = yield selectLocations();
            const currentLocation = getCurrentLocation(location, payload.locationId);
            yield put(setCurrentCity(currentLocation));
        }
        yield put(actions.setDeliveryType(payload.deliveryType));
        yield put(actions.setDeliveryAddressSuccess(payload));
    } catch (error) {
        const data = { payload };
        const text = 'Error in setDeliveryAddress saga';
        errorNotification(error, data, text);
    }
}

const parseTimeSlots = (timeSlots) =>
    Object.keys(timeSlots).map((timeSlot) => ({
        label: timeSlot,
        available: !timeSlots[timeSlot].disabled && !timeSlots[timeSlot].turnedOff,
        value: timeSlot,
    }));

export function* getDay({ timestamp, deliveryType, city }) {
    try {
        // const { city, locationId } = yield selectDeliveryAddress();

        // const id = deliveryType === 'city' ? city : locationId;
        const response = yield call(serverAPI.getDay, timestamp, city, deliveryType);
        const timeSlotsArray = parseTimeSlots(response.data.timeSlots);
        yield put(actions.getDaySuccess(timeSlotsArray, response.data.currentTime));
        yield put(actions.setDayAvailability(response.data.available));
    } catch (error) {
        const data = { timestamp, deliveryType, city };
        const text = 'Error in getDay saga';
        errorNotification(error, data, text);
    }
}

// eslint-disable-next-line consistent-return
export function* createOrder() {
    const [bulkType, diet] = yield select((state) => [state.recipeReducer.bulkType, state.recipeReducer.diet]);
    const influencer = yield select((state) => state.influencerReducer.handle);
    const influencerId = yield select((state) => state.influencerReducer.id);
    const config = yield select((state) => state.configReducer.config);
    const ingredientSubstitutes = yield select((state) => state.recipeReducer.Cart.ingredientSubstitutes);
    const temporaryOrderInfo = yield select((state) => state.uiSettingsReducer.temporaryOrderInfo);
    try {
        const {
            deliveryType,
            deliveryAddress,
            deliveryDate,
            selectedRecipes,
            groceries,
            defaultServings,
            selectedExtraProducts,
            countries,
            summary,
            ingredientsToCost,
        } = yield selectOrderInformation();
        const body = [];

        const getIngredients = () => {
            let ingredientsPayload = [];
            ingredientsToCost.map((ing) => {
                ingredientsPayload.push({
                    id: ing.ingredient,
                    quantity: ing.ingredientAmountDelivered,
                    fromUserPantry: ing.fromUserPantry,
                });
            });

            return ingredientsPayload;
        };

        selectedRecipes.forEach((recipe) => {
            body.push({
                recipeId: recipe._id,
                servings: recipe.customServings || defaultServings,
                ingredients: getIngredients(),
                mealTypeId: recipe.mealTypeId,
                curatedBoxId: recipe.curatedBoxId,
                originalRecipeId: recipe.originalRecipeId,
            });
        });

        const extraProducts = {};
        selectedExtraProducts.forEach((product) => {
            extraProducts[product._id] = product.value;
        });

        const orderInformation = {
            isSaveCard: temporaryOrderInfo.isSaveCard,
            paymentMethodId: temporaryOrderInfo.cardElementOrId,
            deliveryAddress: {
                ...deliveryAddress,
                country: countries.find(({ countryCode }) => countryCode === (config.countryCode || 'CA'))._id,
                promoDiscount: (deliveryAddress.promoDiscount || '').toUpperCase(),
            },
            selectedRecipes: body,
            selectedExtraProducts: extraProducts,
            deliveryType,
            deliveryDate: { ...deliveryDate, date: toDayFormat(moment(deliveryDate.date)) },
            brandPreferences: bulkType,
            brandPreferencesPriceCoefficient: getBrandPreferencePriceCoeficient(bulkType),
            diet,
            influencer: influencer,
            influencerId: influencerId,
        };

        yield put(uiSettingAction.showPaymentLoading(true));
        const { data } = yield call(serverAPI.createOrder, orderInformation);
        yield put(actions.createOrderSuccess(data));
        yield put(clearStateLocalStorage());
        yield put(clearSelectedExtraProducts());
        yield put(uiSettingAction.clearActiveButton());
        yield put(uiSettingAction.showPaymentLoading(false));
        yield put(uiSettingAction.showPaymentSuccess(true));
        handleConfirmPayment(summary, deliveryAddress.email);
        localStorage.removeItem('guestUserId');
        yield put(actions.createOrderSuccess(data));
    } catch (error) {
        const order = yield selectOrderInformation();
        const data = { cardHolderName: temporaryOrderInfo.cardHolderName, ...order, isSaveCard: temporaryOrderInfo.isSaveCard };
        const text = 'Error while placing order';
        errorNotification(error, data, text, false);
        yield put(uiSettingAction.showPaymentLoading(false));
        yield put(uiSettingAction.showPaymentError(true));
        yield put(actions.createOrderFailure(error));
    }
}

export function* addPaymentMethodOnCheckout({ stripe, billingAddress, cardElementOrId, isSaveCard, cardHolderName, isStepOpen }) {
    try {
        const { deliveryAddress, temporaryOrderInfo } = yield selectOrderInformation();
        const billing_details = {
            address: billingAddress,
            name: cardHolderName,
            phone: deliveryAddress.phoneNumber,
            email: deliveryAddress.emailAddress,
        };
        if (typeof cardElementOrId === 'string') {
            yield put(
                uiSettingAction.setTemporaryOrderInfo({
                    ...temporaryOrderInfo,
                    isPaymentComplete: true,
                    isStepOpen: { ...isStepOpen, 4: false },
                }),
            );
        } else {
            if (isSaveCard) {
                return yield put(
                    userActions.addPaymentMethod({
                        billing_details,
                        cardElement: cardElementOrId,
                        stripe,
                        showSuccessNotification: false,
                        isStepOpen: { ...isStepOpen, 4: false },
                    }),
                );
            }
            const { paymentMethod, error } = yield call(stripe.createPaymentMethod, {
                type: 'card',
                card: cardElementOrId,
                billing_details,
            });
            if (error) {
                return customNotification('error', get(error, 'message', 'Error while adding your card'));
            }
            yield put(
                uiSettingAction.setTemporaryOrderInfo({
                    ...temporaryOrderInfo,
                    cardElementOrId: paymentMethod.id,
                    createdCardElement: [paymentMethod, ...(temporaryOrderInfo.createdCardElement || [])],
                    showCardElements: false,
                    isPaymentComplete: true,
                    isStepOpen: { ...isStepOpen, 4: false },
                }),
            );
        }
    } catch (error) {
        const data = { billing_details: billingAddress, cardHolderName, isSaveCard };
        const text = 'Error while adding payment method';
        errorNotification(error, data, text, false);
        Sentry.captureException(error);
        return customNotification('error', Messages.STRIPE_INTEGRATION);
    }
}

export function* getDeliveryAddressRequest() {
    try {
        const token = Cookies.get('token');
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        const userDetails = yield select((state) => state.userReducer.userDetails);
        let userResponse = null;
        const countries = yield select((state) => state.citiesReducer.countries);
        if (token) {
            if (userDetails) {
                yield put(actions.getDeliveryAddressSuccess(userDetails, countries));
            } else {
                const response = yield call(userAPI.getUser, headers);
                userResponse = yield call(userAPI.getUserInfo, response.data._id, headers);
                yield put(actions.getDeliveryAddressSuccess(userResponse.data, countries));
            }
        }
    } catch (error) {
        const data = {};
        const text = 'Error in getDeliveryAddressRequest saga';
        errorNotification(error, data, text);
        yield put(actions.getDeliveryAddressError(error));
    }
}

export function* checkPromoCode({ promoCode, isRemove = false }) {
    try {
        const deliveryAddress = yield select((state) => state.orderReducer.deliveryAddress);
        deliveryAddress.promoDiscount = (promoCode || '').toUpperCase();
        const response = yield call(serverAPI.checkPromo, {
            isRemove,
            deliveryAddress,
            promoCode: (promoCode || '').toUpperCase(),
        });
        yield put(actions.checkPromoSuccess(response.data));
        if (!isRemove) {
            customNotification('success', Messages.APPLY_PROMO_CODE);
        }
    } catch (error) {
        const data = { promoCode, isRemove };
        const text = 'Error in checkPromoCode saga';
        errorNotification(error, data, text, true);
        yield put(actions.checkPromoFailure(error));
    }
}

const handleConfirmPayment = (summary, email) => {
    pushToGTM({
        event: Events.EVENT_PURCHASE,
        cartvalue: summary.total,
        email,
    });
};

export default function* () {
    yield takeEvery(constants.SET_DELIVERY_ADDRESS, setDeliveryAddress);
    yield takeEvery(constants.GET_DAY_REQUEST, getDay);
    yield takeEvery(constants.CREATE_ORDER_REQUEST, createOrder);
    yield takeLatest(constants.GET_DELIVERY_ADDRESS_REQUEST, getDeliveryAddressRequest);
    yield takeEvery([constants.CHECK_PROMO_REQUEST, constants.CLEAR_PROMO_CODE_DISCOUNT], checkPromoCode);
    yield takeEvery(constants.ADD_PAYMENT_METHOD_ON_CHECKOUT, addPaymentMethodOnCheckout);
}
