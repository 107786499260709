import React from 'react';
import Icons from 'components/common/Icons/Icons';
import useScreenType from 'hooks/useScreenType';
import history from '../../../history';
import logo from '../../../assets/images/logo.png';
import DoneIcon from '@material-ui/icons/Done';

import {
    BuildMenuBenefitsContainer,
    BenefitTitle,
    LoginBenefitsHeader,
    EasierChefLogoContainer,
    Title,
    Desc,
    BenefitsContent,
    Benefit,
    BenefitRow,
    Arrow,
} from './styles';

const BuildMenuBenefits = ({ cost }) => {
    const { isMobile } = useScreenType();
    const Data = [
        `No commitments, no subscription`,
        'Save money every week on food',
        'Try new recipes every week while we manage groceries for you',
    ];
    return (
        <div>
            <EasierChefLogoContainer>
                <img src={logo} height="30" width="160" alt="Logo" />
            </EasierChefLogoContainer>
            <BuildMenuBenefitsContainer isMobile={isMobile}>
                <div className="container">
                    <LoginBenefitsHeader>
                        {isMobile && (
                            <Arrow onClick={() => history.goBack()}>
                                <Icons name="arrowLeft" size="22" />
                            </Arrow>
                        )}
                        <div>
                            <Title>Join now & eat at:</Title>
                            <Desc>${cost.pricePerServing}/serv.</Desc>
                        </div>
                    </LoginBenefitsHeader>
                    <BenefitsContent>
                        <div className="benefits">
                            {Data.map((data, i) => (
                                <BenefitRow>
                                    <DoneIcon style={{ color: '#fd891b' }} color="#fd891b" />
                                    <Benefit key={i}>{data}</Benefit>
                                </BenefitRow>
                            ))}
                        </div>
                    </BenefitsContent>
                </div>
            </BuildMenuBenefitsContainer>
        </div>
    );
};
export default BuildMenuBenefits;
