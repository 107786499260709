import { call, takeEvery } from 'redux-saga/effects';
import * as constants from '../constants';

import GetAllRecipesApi from './apiCalls/GetAllRecipesAPI';
import SearchRecipesApi from './apiCalls/SearchRecipesAPI';

import { errorNotification } from 'helpers/errorNotification';

export function* getRecipes(payload) {
    try {
        if (window.location.pathname.includes('/categories')) return;
        yield call(GetAllRecipesApi.make, payload);
    } catch (error) {
        const data = payload;
        const text = 'Error in getRecipes saga';
        errorNotification(error, data, text);
    }
}

export function* getSearchRecipes() {
    try {
        yield call(SearchRecipesApi.make);
    } catch (error) {
        const data = {};
        const text = 'Error in getSearchRecipes saga';
        errorNotification(error, data, text);
    }
}

export default function* () {
    yield takeEvery(
        [
            constants.GET_RECIPES_REQUEST,
            constants.LOAD_MORE_RECIPES,
            constants.ADD_CATEGORY,
            constants.DELETE_CATEGORY,
            constants.ADD_ALLERGY,
            constants.FILTER_RECIPES,
        ],
        getRecipes,
    );
    yield takeEvery(constants.SET_RECIPES_SEARCH, getSearchRecipes);
}
