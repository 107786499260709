import * as constants from '../constants';

export const getAllergyRequest = () => ({
    type: constants.GET_ALLERGY_REQUEST,
});
export const fetchingAllergyRequest = () => ({
    type: constants.FETCHING_ALLERGY_REQUEST,
});
export const getAllergySuccess = (data) => ({ type: constants.GET_ALLERGY_SUCCESS, data });
export const getAllergyFailure = (error) => ({ type: constants.GET_ALLERGY_FAILURE, error });

export const toggleAllergy = (allergyId) => ({ type: constants.TOGGLE_ALLERGY, allergyId });
export const addAllergy = (allergyId) => ({ type: constants.ADD_ALLERGY, allergyId });
export const deleteAllergy = (allergyId) => ({ type: constants.DELETE_ALLERGY, allergyId });

export const selectAllergy = (allergies) => ({ type: constants.SELECT_ALLERGY, allergies });
export const clearFlagIsUpdateAllergies = () => ({ type: constants.CLEAR_FLASH_IS_UPDATED_ALLERGIES });
