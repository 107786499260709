import React from 'react';
import PropTypes from 'prop-types';
import Icons from '../Icons/Icons';
import { NavigateContainer, Label } from './styles';
import useScreenType from 'hooks/useScreenType';
import useGoBack from 'hooks/useGoBack';

const NavigatePreviousRoute = ({ label, element, onClick, defaultBackRoute }) => {
    const { isMobile } = useScreenType();

    const { goBack } = useGoBack({ defaultBackRoute, onClick });

    return (
        <NavigateContainer>
            <Icons onClick={goBack} name="arrowLeft" size="25"></Icons>
            {element && element}
            {!element && <Label $isMobile={isMobile}>{label}</Label>}
        </NavigateContainer>
    );
};

NavigatePreviousRoute.propTypes = {
    onClick: PropTypes.func,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default NavigatePreviousRoute;
