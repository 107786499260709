import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#489800',
        },
        secondary: {
            main: '#7bc941',
        },
    },
    typography: {
        useNextVariants: true,
        fontFamily: ['Dosis', 'sans-serif'].join(','),
    },
});

export default theme;
