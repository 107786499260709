import React from 'react';
import Fab from '@material-ui/core/Fab';
import ShareOutlined from '@material-ui/icons/ShareOutlined';
import ReactTooltip from 'react-tooltip';
import useScreenType from 'hooks/useScreenType';
import { customNotification } from 'components/common/Notification';
import styles from './shareCartButton.module.scss';

const ShareCartButton = ({ cartLink }) => {
    const onCopy = () => {
        navigator.clipboard.writeText(cartLink);
        customNotification('success', 'The sharable link is copied!');
    };

    return (
        <div className={styles.shareButtons}>
            <Fab
                aria-label="Social Button Copy"
                className={styles.fab}
                data-tip="Copied"
                data-for="tooltip"
                data-event="tap focus mouse"
            >
                <ShareOutlined size={24} onClick={onCopy} />
                {/* <ReactTooltip
                    id="tooltip"
                    type="success"
                    className={styles.tooltip}
                    globalEventOff="mouse"
                    delayHide={700}
                    place={isMobile ? 'left' : 'bottom'}
                ></ReactTooltip> */}
            </Fab>
        </div>
    );
};

export default ShareCartButton;
