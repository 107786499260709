import React from 'react';
import Icons from '../Icons/Icons';
import useScreenType from 'hooks/useScreenType';

import { PrimaryButtonContainer, ServingsIcon, Title } from './styles';

const Primary = ({ disabled, state, size, title = 'Add all Groceries', onClick, onAdd, onRemove, color }) => {
    const { isMobile } = useScreenType();
    return (
        <PrimaryButtonContainer
            disabled={disabled}
            state={state}
            size={size}
            onClick={state !== 'Active' ? onClick : null}
            isMobile={isMobile}
            color={color}
        >
            <ServingsIcon state={state} onClick={onRemove}>
                <Icons name="minus" size="18" />
            </ServingsIcon>
            <Title color={state === 'Active' ? '#FFFFFF' : '#233747'}>{title}</Title>
            <ServingsIcon state={state} onClick={onAdd}>
                <Icons name="plus" size="18" />
            </ServingsIcon>
        </PrimaryButtonContainer>
    );
};

export default Primary;
