import ApiBaseRequest from '../networkRequests/ApiBaseRequest';
import store from 'store/store';
import * as actions from 'store/allergy/allergy.actions';

class GetAllergiesRequest extends ApiBaseRequest {
    constructor() {
        super('GET');
    }

    shouldCall = () => {
        const state = store.getState().allergyReducer;
        if (state.isLoadedAllergyList || state.loading) {
            return false;
        }
        return true;
    };

    beforeCall = () => {
        store.dispatch(actions.fetchingAllergyRequest());
    };

    generateUrl = () => '/allergies';

    afterCall = ({ response }) => {
        store.dispatch(actions.getAllergySuccess(response.data));
    };
}

export default new GetAllergiesRequest();
