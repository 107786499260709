export const trackUserActivity = (activity) => {
    let userLogs = window.sessionStorage.getItem('userActivityLogs')
        ? JSON.parse(window.sessionStorage.getItem('userActivityLogs'))
        : [];

    userLogs.unshift(activity);
    window.sessionStorage.setItem('userActivityLogs', JSON.stringify(userLogs));
};

export const getUserActivityLogs = () => JSON.parse(window.sessionStorage.getItem('userActivityLogs'));

export const resetUserActivityLogs = () => {
    window.sessionStorage.setItem('userActivityLogs', JSON.stringify([]));
};
