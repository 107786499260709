import React from 'react';
import { IconButton } from '@material-ui/core';
import { FavouriteIcon } from './styles';
import Icons from '../Icons/Icons';

const Favourite = ({ disabled, onClick, active }) => {
    return (
        <IconButton disabled={disabled} onClick={onClick}>
            {active ? (
                <Icons name="favoriteActive" size="48" />
            ) : (
                <FavouriteIcon disabled={disabled}>
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M29.8933 19.0736C29.5528 18.7329 29.1485 18.4627 28.7036 18.2783C28.2586 18.0939 27.7817 17.999 27.3 17.999C26.8183 17.999 26.3414 18.0939 25.8964 18.2783C25.4515 18.4627 25.0472 18.7329 24.7067 19.0736L24 19.7802L23.2933 19.0736C22.6055 18.3858 21.6727 17.9994 20.7 17.9994C19.7273 17.9994 18.7945 18.3858 18.1067 19.0736C17.4189 19.7614 17.0325 20.6942 17.0325 21.6669C17.0325 22.6396 17.4189 23.5724 18.1067 24.2602L18.8133 24.9669L24 30.1536L29.1867 24.9669L29.8933 24.2602C30.234 23.9197 30.5042 23.5154 30.6886 23.0705C30.873 22.6255 30.9679 22.1486 30.9679 21.6669C30.9679 21.1852 30.873 20.7083 30.6886 20.2633C30.5042 19.8184 30.234 19.4141 29.8933 19.0736Z"
                            fill="#909DA6"
                            stroke="#909DA6"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M8 1H40V-1H8V1ZM47 8V40H49V8H47ZM40 47H8V49H40V47ZM1 40V8H-1V40H1ZM8 47C4.13401 47 1 43.866 1 40H-1C-1 44.9706 3.02944 49 8 49V47ZM47 40C47 43.866 43.866 47 40 47V49C44.9706 49 49 44.9706 49 40H47ZM40 1C43.866 1 47 4.13401 47 8H49C49 3.02944 44.9706 -1 40 -1V1ZM8 -1C3.02944 -1 -1 3.02944 -1 8H1C1 4.13401 4.13401 1 8 1V-1Z"
                            fill="#909DA6"
                        />
                    </svg>
                </FavouriteIcon>
            )}
        </IconButton>
    );
};

export default Favourite;
