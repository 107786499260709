import React, { useState, useEffect } from 'react';
import useTheme from 'hooks/useTheme';
import useScreenType from 'hooks/useScreenType';
import { useSelector } from 'react-redux';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import TypographyD from '../common/TypographyD/TypographyD';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import IngredientList from './IngredientList';
import './styles.scss';

const IngredientDetails = ({}) => {
    const [expanded, setExpanded] = React.useState(false);
    const color = useTheme();
    const { isTabletOrMobile } = useScreenType();
    const groceries = useSelector((state) => state.recipeReducer.Cart.groceries);
    const ingredientsToCost = useSelector((state) => state.recipeReducer.Cart.ingredientsToCost);
    const [totalGroceries, setTotalGroceries] = useState(0);

    const viewIcon = () => {
        return (
            <div className="viewMore">
                <div className="view">{expanded ? 'View less' : 'View more'}</div>
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </div>
        );
    };

    useEffect(() => {
        setTotalGroceries(ingredientsToCost?.length);
    }, [groceries, ingredientsToCost]);

    return (
        <Accordion
            className="container ingredient-detail-accordion"
            expanded={expanded}
            onChange={(event, newExpanded) => {
                setExpanded(newExpanded ? true : false);
            }}
            style={isTabletOrMobile ? { padding: expanded ? '10px 0 0' : '10px 20px 0' } : { padding: 0 }}
        >
            <AccordionSummary expandIcon={viewIcon()} aria-controls="content" id="header" className="accordion-groceries">
                <div className="heading">
                    <TypographyD className="heading__text" type={'h6'} color={color.grey1}>
                        {totalGroceries} Groceries
                    </TypographyD>
                </div>
            </AccordionSummary>

            <AccordionDetails className="accordion-detail">
                <IngredientList />
            </AccordionDetails>
        </Accordion>
    );
};

export default IngredientDetails;
