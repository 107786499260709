import React from 'react';
import Icons from '../Icons/Icons';
import { LinksContainer } from './styles';
const Links = (props) => {
    const { label, size, type, showIcon = true, onClick = () => {}, name = 'arrowRight' } = props;
    return (
        <div>
            <LinksContainer {...props}>
                {size ? (
                    <>
                        <span>{label}</span>
                        {type !== 'tertiary' && showIcon && <div>{prepareSize(size, name)}</div>}
                    </>
                ) : (
                    <>
                        {type !== 'tertiary' && showIcon && <div>{prepareSize(name)}</div>}
                        <span>{label}</span>
                    </>
                )}
            </LinksContainer>
        </div>
    );
};
function prepareSize(size, name) {
    switch (size) {
        case 'large':
            return <Icons name="plus" size="20" />;
        case 'medium':
            return <Icons name={name} size="20" />;
        default:
            return <Icons name="close" size="12" />;
    }
}
export default Links;
