import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './Image.module.scss';

const Image = ({ keyImage, image, onClick, style }) => (
    <div
        key={keyImage}
        style={{
            background: `url('${image.url}') no-repeat center center/cover`,
        }}
        className={cn(styles.imageContainer, style)}
        alt="recipe"
    >
        <div role="button" tabIndex={-1} onClick={onClick} className={cn({ [styles.overlayForMobileClicking]: onClick })} />
        <div className={styles.overlay} />
    </div>
);

Image.propTypes = {
    image: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    style: PropTypes.string,
};

Image.defaultProps = {
    keyImage: '',
    onClick: null,
    style: '',
};

export default Image;
