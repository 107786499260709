import styled, { css } from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

export const MinimumContainer = styled(Dialog)`
    & > div:nth-child(3) {
        & > div:nth-child(1) {
            min-width: 590px;
            min-height: 264px;
            border-radius: 16px;
        }
    }

    ${(props) =>
        props.$isTabletOrMobile &&
        css`
            & > div:nth-child(3) {
                position: relative;

                & > div:nth-child(1) {
                    width: 100%;
                    min-width: unset;
                    max-width: unset;
                    margin: 0;
                    margin-top: auto;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    border-radius: 16px 16px 0 0;
                }
            }
        `};

    ${(props) =>
        props.$isMobile &&
        css`
            & > div:nth-child(3) {
                & > div:nth-child(1) {
                    min-height: 224px;
                }
            }
        `};
`;

export const MinimumContent = styled(DialogContent)`
    padding: 0 !important;
`;

export const HeaderContainer = styled.div`
    position: relative;

    & > button {
        position: absolute;
        top: 24px;
        left: 24px;
        color: #0d2120;
    }

    ${(props) =>
        props.isMobile &&
        css`
            & > button {
                display: none;
            }
        `};
`;

export const HeaderTitle = styled.div`
    text-align: center;
    padding: 40px 0 12px;
    color: #0d2120;
    letter-spacing: -0.69px;
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;

    ${(props) =>
        props.isMobile &&
        css`
            font-size: 20px;
        `};
`;

export const ContentContainer = styled.div`
    text-align: center;
    color: #4f5f6c;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.685714px;
    max-width: 412px;
    margin: 0 auto;

    & > span {
        font-weight: 500;
    }

    ${(props) =>
        props.isMobile &&
        css`
            font-size: 12px;
        `};
`;

export const FooterContainer = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 24px;
    max-width: 358px;
    margin: 0 auto;
    justify-content: space-between;
`;

export const ButtonClose = styled.div`
    & > button {
        border: 1px solid #e5eaed;
        color: #4f5f6c;
        width: 150px;
        height: 40px;
    }
`;

export const ButtonShow = styled.div`
    & > button {
        width: 175px;
        height: 40px;
    }
`;
