import styled, { css } from 'styled-components';

export const PageWrapper = styled.div`
    max-width: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    button {
        margin-bottom: 20px;
    }
    .dialog-wrapper {
        background: #f00;
    }
`;

export const ErrorWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .icon-error {
        width: 56px;
        height: 56px;
        border: 2px solid #ff6b6b;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .error-description {
        p {
            width: 80%;
            margin: 16px auto 32px;
            line-height: 146%;
            ${({ isMobile }) =>
                isMobile &&
                css`
                    width: 87%;
                `}
        }
    }
    button {
        width: 171px;
        height: 42px;
    }
`;

export const LoadingWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .MuiCircularProgress-root {
        margin-bottom: 16px;
        svg {
            .MuiCircularProgress-circle {
                stroke: #fd891b;
                stroke-width: 2px;
            }
        }
    }
`;

export const SuccessWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .icon-check {
        width: 56px;
        height: 56px;
        border: 2px solid #82bd73;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            path {
                stroke-width: 1.5px;
            }
        }
    }
    .sub-child-txt {
        line-height: 150%;
        width: 60%;
        margin: 0 auto;
        ${({ isMobile }) =>
            isMobile &&
            css`
                width: 80%;
            `}
    }
`;
