import React from 'react';
import { TertiaryButtonContainer } from './styles';

const Tertiary = ({ disabled, title = 'Show Results', size, onClick, isActive = false }) => {
    return (
        <TertiaryButtonContainer isActive={isActive} disabled={disabled} size={prepareSize(size)} onClick={onClick}>
            {title}
        </TertiaryButtonContainer>
    );
};

function prepareSize(size) {
    switch (size) {
        case 'large':
            return {
                width: '327px',
                height: '62px',
                padding: '16px 40px',
                fontSize: '20px',
            };
        case 'medium':
            return {
                width: '246px',
                height: '48px',
                padding: '12px 16px',
                fontSize: '16px',
            };
        case 'small':
            return {
                width: '110px',
                height: '40px',
                padding: '8px 24px',
                fontSize: '16px',
            };
        default:
            return {
                width: '100%',
                height: '40px',
                padding: '8px 24px',
                fontSize: '16px',
            };
    }
}

export default Tertiary;
