import styled, { css } from 'styled-components';
const size = {
    large: {
        'font-size': '20px',
    },
    medium: {
        'font-size': '14px',
    },
    default: {
        'font-size': '16px',
    },
};
const type = {
    primary: {
        color: '#233747e0',
        '&:hover': {
            color: '#000000',
        },
    },
    secondary: {
        color: '#82BD73',
        '&:hover': {
            color: '#01AB75',
        },
    },
    tertiary: {
        color: '#909DA6',
        '&:hover': {
            color: '#4F5F6C',
        },
    },
};
export const LinksContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0px;
    font-size: 12px;
    cursor: pointer;
    & > div {
        display: flex;
        margin: 0 5px;
    }
    pointer-events: ${({ disabled }) => disabled && 'none'};
    opacity: ${({ disabled }) => disabled && '0.3'};
    ${(props) =>
        props.size &&
        css`
            ${size[props.size]}
        `};
    ${(props) =>
        props.type &&
        css`
            ${type[props.type]}
        `};
`;
