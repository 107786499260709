import * as constants from '../constants';

const initialState = {
    loading: false,
    error: '',
    ingredientsList: null,
    isLoadedIngredientsList: false,
    selectedIngredients: [],
    ingredientSubstituteSuccess: false,
    isSubstitutionOn: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case constants.GET_INGREDIENTS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case constants.GET_INGREDIENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                ingredientsList: action.data.ingredients,
                isLoadedIngredientsList: true,
            };
        case constants.GET_INGREDIENTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case constants.SELECT_INGREDIENT:
            return {
                ...state,
                selectedIngredients: action.ingredients,
            };
        case constants.SET_MANUAL_INGREDIENT_SUBSTITUTION:
            return {
                ...state,
                ingredientSubstituteSuccess: false,
                isSubstitutionOn: true,
            };
        case constants.SET_MANUAL_INGREDIENT_SUBSTITUTION_SUCCESS:
            return {
                ...state,
                ingredientSubstituteSuccess: true,
            };
        case constants.SET_SYSTEM_DEFINE_SUSBTITUTES:
            return {
                ...state,
                isSubstitutionOn: action.payload,
            };
        default:
            return state;
    }
}
