import findIndex from 'lodash/findIndex';
import uniqBy from 'lodash/uniqBy';

import * as constants from '../constants';
import { preparePricePayload, prepareFreeServingPayload } from 'helpers/cart';
import { prepareFreeServings, emptyCostPayload } from 'utility/cartUtil';
import { prepareIngredientSubstituteList } from 'utility/groceryUtil';

import { USER_WANT_SELECT_MORE_RECIPES } from '../actions/recipe.action';

const bulkTypeLocal = localStorage.getItem('bulkType');

export const selectedRecipesLength = (selected) => {
    return selected.length;
};

const setSelectedRecipes = (selectedRecipes, recipe) => {
    if (selectedRecipes.length) {
        if (findIndex(selectedRecipes, (r) => r._id === recipe._id) === -1) {
            return [recipe, ...selectedRecipes];
        } else {
            return selectedRecipes;
        }
    } else {
        return [recipe];
    }
};

const setServingRecipe = (recipeId, servings, selectedRecipes) => {
    return selectedRecipes.map((recipe) => {
        if (recipe._id === recipeId) {
            recipe.customServings = servings;
        }
        return recipe;
    });
};

const initialState = {
    loading: false,
    error: '',
    servings: 4,
    selectedServings: 4,
    leftovers: false,
    diet: localStorage.getItem('dietPlan') ? localStorage.getItem('dietPlan') : 'everything',
    bulkType: bulkTypeLocal ? bulkTypeLocal : 'LOW_COST',
    dinnersCount: 3,
    currentProgressStep: 0,
    nestedProgressStep: 0,
    currentRecipe: null,
    userWantSelectMoreRecipes: false,
    checkSelectedRecipesChange: false,
    isUpdateDiet: false,
    updatedRecipeServings: [],
    itemNoneRequired: 0,
    isEditing: false,
    pagination: {
        currentPage: 1,
        nextPage: 1,
        totalPage: 1,
        limit: 2,
    },
    Cart: {
        list: {
            freeServingsList: [],
            totalAmountOfFreeServings: 0,
        },
        pricePayload: emptyCostPayload,
        removedIngredientList: [],
        noneRequiredProductList: [],
        selectedRecipes: [],
        selectedRecipesLength: 0,
        groceries: { requiredProducts: [], nonRequiredProducts: [], substitutes: [] },
        ingredientsToCost: [],
        ingredientSubstitutes: [],
        avgNutrition: {},
        deliveryInfo: {},
    },
};

export default function(state = initialState, action) {
    switch (action.type) {
        case constants.SET_SERVINGS:
            return {
                ...state,
                servings: action.servings,
                selectedServings: action.selectedServings,
            };

        case constants.SET_LEFTOVERS:
            return {
                ...state,
                leftovers: action.payload,
            };

        case constants.SET_DINNERS_COUNT:
            return {
                ...state,
                dinnersCount: action.dinnersCount,
            };

        case constants.SET_SELECTED_RECIPE_SERVINGS: {
            return {
                ...state,
                Cart: {
                    ...state.Cart,
                    selectedRecipes: setServingRecipe(action.recipeId, action.servings, state.Cart.selectedRecipes),
                },
            };
        }

        case constants.SET_SELECTED_RECIPE_SERVINGS_SUCCESS: {
            const {
                data: { cost, groceries },
            } = action.payload;
            return {
                ...state,
                Cart: {
                    ...state.Cart,
                    groceries,
                    pricePayload: {
                        ...preparePricePayload(cost),
                    },
                },
                checkSelectedRecipesChange: true,
            };
        }

        case USER_WANT_SELECT_MORE_RECIPES:
            return {
                ...state,
                userWantSelectMoreRecipes: true,
            };

        case constants.SET_DIET:
            return {
                ...state,
                diet: action.payload,
                isUpdateDiet: true,
            };
        case constants.CLEAR_IS_UPDATE_DIET:
            return {
                ...state,
                isUpdateDiet: false,
            };

        case constants.SET_BULK_TYPE: {
            localStorage.setItem('bulkType', action.bulkType);
            return { ...state, bulkType: action.bulkType, loading: true };
        }

        case constants.SET_BULK_TYPE_SUCCESS:
            return {
                ...state,
                Cart: {
                    ...state.Cart,
                    pricePayload: {
                        ...preparePricePayload(action.data),
                    },
                },
            };

        case constants.SET_CURRENT_RECIPE_SERVINGS: {
            const currentRecipe = {
                ...state.currentRecipe,
                servings: action.payload,
            };
            return {
                ...state,
                currentRecipe: currentRecipe,
            };
        }

        case constants.DELETE_RECIPE: {
            let updatedSelectedRecipes = [];
            if (action.mealTypeId)
                updatedSelectedRecipes = state.Cart.selectedRecipes.filter((r) => {
                    if (r._id == action.recipeId) return r.mealTypeId !== action.mealTypeId;
                    else return true;
                });
            else updatedSelectedRecipes = state.Cart.selectedRecipes.filter((r) => r._id !== action.recipeId);
            return {
                ...state,
                Cart: {
                    ...state.Cart,
                    selectedRecipes: updatedSelectedRecipes,
                },
            };
        }
        case constants.SELECTED_RECIPES: {
            return {
                ...state,
                Cart: {
                    ...state.Cart,
                    selectedRecipes: action.data,
                    selectedRecipesLength: selectedRecipesLength(action.data),
                },
            };
        }
        case constants.ADD_RECIPE_SUCCESS: {
            const {
                data: { groceries, cost, avgNutrition, recipes },
            } = action.data;
            return {
                ...state,
                Cart: {
                    ...state.Cart,
                    groceries,
                    selectedRecipesLength: selectedRecipesLength(recipes || []),
                    pricePayload: {
                        ...preparePricePayload(cost),
                    },
                    avgNutrition,
                },
                checkSelectedRecipesChange: true,
            };
        }
        case constants.DELETE_RECIPE_SUCCESS: {
            const {
                selectedRecipes,
                data: { groceries, cost, avgNutrition },
            } = action.data;
            return {
                ...state,
                Cart: {
                    ...state.Cart,
                    groceries,
                    pricePayload: {
                        ...preparePricePayload(cost),
                    },
                    selectedRecipes,
                    selectedRecipesLength: selectedRecipesLength(selectedRecipes),
                    avgNutrition,
                },
                checkSelectedRecipesChange: true,
            };
        }

        case constants.GET_RECIPE_BY_ID_SUCCESS:
            return {
                ...state,
                currentRecipe: {
                    ...action.recipe,
                    defaultServings: action.recipe.servings,
                },
                loading: false,
            };

        case constants.GET_RECIPE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case constants.GET_RECIPE_SUCCESS: {
            const currentRecipe = { category: action.category, recipe: action.recipe };
            return {
                ...state,
                currentRecipe,
                loading: false,
            };
        }

        case constants.GET_RECIPE_FAILURE:
            return {
                ...state,
                error: action.error,
            };

        case constants.GET_GROCERIES_REQUEST:
            return {
                ...state,
            };

        case constants.GET_GROCERIES_SUCCESS: {
            return {
                ...state,
                Cart: {
                    ...state.Cart,
                    groceries: action.payload.groceries,
                },
            };
        }

        case constants.GET_GROCERIES_FAILURE:
            return {
                ...state,
                error: action.error,
            };

        case constants.CLEAR_STATE_LOCAL_STORAGE: {
            localStorage.removeItem('bulkType');
            localStorage.removeItem('selectedExtraProducts');
            localStorage.removeItem('Cart');
            return {
                ...state,
                requiredGroceries: [],
                bulkType: 'LOW_COST',
                Cart: {
                    list: {
                        freeServingsList: [],
                        totalAmountOfFreeServings: 0,
                    },
                    pricePayload: emptyCostPayload,
                    groceries: { requiredProducts: [], nonRequiredProducts: [], substitutes: [] },
                    selectedRecipes: [],
                    selectedRecipesLength: 0,
                    avgNutrition: {},
                },
            };
        }

        case constants.RESET_PRODUCT_AMOUNT_SUCCESS: {
            const {
                data: { cost },
            } = action.payload;
            return {
                ...state,
                Cart: {
                    ...state.Cart,
                    pricePayload: {
                        ...preparePricePayload(cost),
                    },
                },
            };
        }

        case constants.SET_RECIPE_SERVINGS: {
            const { recipeId, servings } = action.data;
            const recipes = state.Cart.selectedRecipes.map((r) => (r._id === recipeId ? { ...r, customServings: servings } : r));
            return {
                ...state,
                Cart: {
                    ...state.Cart,
                    selectedRecipes: recipes,
                },
            };
        }
        case constants.GET_CART: {
            const { pricePayload } = action.data;
            return {
                ...state,
                Cart: {
                    ...state.Cart,
                    pricePayload: {
                        ...preparePricePayload(pricePayload),
                    },
                },
            };
        }
        case constants.SET_ITEM_NONE_REQUIRED: {
            return {
                ...state,
                itemNoneRequired: action.payload,
            };
        }

        case constants.GET_CART_DETAILS_SUCCESS: {
            const {
                recipes,
                nonRequiredIngredients,
                removedIngredients,
                cost,
                groceries,
                ingredientsToCost,
                ingredientSubstitutes,
                avgNutrition,
                deliveryInfo,
                extraProducts,
            } = action.data;

            return {
                ...state,
                Cart: {
                    ...state.Cart,
                    groceries,
                    ingredientsToCost,
                    ingredientSubstitutes,
                    selectedRecipes: recipes,
                    selectedRecipesLength: selectedRecipesLength(recipes || []),
                    noneRequiredProductList: uniqBy(
                        [...(state.Cart.noneRequiredProductList || []), ...nonRequiredIngredients],
                        'ingredient',
                    ),
                    removedIngredientList: uniqBy(
                        [...(state.Cart.removedIngredientList || []), ...removedIngredients],
                        'ingredient',
                    ),
                    pricePayload: {
                        ...preparePricePayload(cost),
                    },
                    deliveryInfo,
                    extraGroceries: extraProducts,
                    avgNutrition,
                },
            };
        }

        case constants.ADDED_NON_REQUIRED_PRODUCT_LIST:
            return {
                ...state,
                Cart: {
                    ...state.Cart,
                    noneRequiredProductList: action.data,
                },
            };

        case constants.REMOVED_INGREDIENT_LIST:
            return {
                ...state,
                Cart: {
                    ...state.Cart,
                    removedIngredientList: action.data,
                },
            };

        case constants.GET_COMMON_INGREDIENTS_SUCCESS:
            const freeServingsList = prepareFreeServings(action.commonIngredients);
            return {
                ...state,
                Cart: {
                    ...state.Cart,
                    list: prepareFreeServingPayload(freeServingsList),
                },
            };

        case constants.ADD_EXTRA_PRODUCT_SUCCESS:
        case constants.DELETE_EXTRA_PRODUCT_SUCCESS:
            return {
                ...state,
                Cart: {
                    ...state.Cart,
                    extraGroceries: action.data.productList,
                    pricePayload: preparePricePayload(action.data.cost),
                },
            };
        case constants.SET_IS_EDITING:
            return {
                ...state,
                isEditing: action.data,
            };

        case constants.CHECK_PROMO_SUCCESS:
            return {
                ...state,
                Cart: {
                    ...state.Cart,
                    pricePayload: { ...state.Cart.pricePayload, ...action.data },
                },
            };

        case constants.SET_MANUAL_INGREDIENT_SUBSTITUTION:
            return {
                ...state,
                Cart: {
                    ...state.Cart,
                    ingredientSubstitutes: uniqBy(
                        [action.ingredientSubstitutes, ...state.Cart.ingredientSubstitutes],
                        'ingredient',
                    ),
                },
            };

        case constants.SET_MANUAL_INGREDIENT_SUBSTITUTION_SUCCESS:
            const ingredientSubstitutes = prepareIngredientSubstituteList(action.data.ingredientsToCost);
            return {
                ...state,
                Cart: {
                    ...state.Cart,
                    ingredientsToCost: action.data.ingredientsToCost,
                    avgNutrition: action.data.avgNutrition,
                    ingredientSubstitutes,
                    pricePayload: {
                        ...preparePricePayload(action.data.cost),
                    },
                },
            };

        case constants.GET_RECIPES_SUCCESS: {
            if (action.data.pagination) {
                return {
                    ...state,
                    pagination: action.data.pagination,
                };
            }
        }

        case constants.FILTER_RECIPES:
            return {
                ...state,
                pagination: { ...state.pagination, nextPage: 1 },
            };

        case constants.LOADING_RECIPE_ITEM_PAGE:
            return {
                ...state,
                loading: true,
            };

        case constants.LOADED_RECIPE_ITEM_PAGE:
            return {
                ...state,
                loading: false,
            };

        case constants.CART_DELIVERY_INFO_SUCCESS:
            const { cost, deliveryInfo } = action.data;
            return {
                ...state,
                Cart: {
                    ...state.Cart,
                    pricePayload: {
                        ...preparePricePayload(cost),
                    },
                    deliveryInfo: deliveryInfo,
                },
            };
        case constants.SET_INGREDIENTS_TO_COST: {
            return {
                ...state,
                Cart: {
                    ...state.Cart,
                    ingredientsToCost: action.cost,
                },
            };
        }
        case constants.SET_WAIVEOFF_DELIVERY_FEE:
            return {
                ...state,
                Cart: {
                    ...state.Cart,
                    pricePayload: {
                        ...state.Cart.pricePayload,
                        waiveOffDeliveryFee: true,
                    },
                },
            };

        default:
            return state;
    }
}
