import { apiRequest } from 'helpers/request';

async function getUser(headers) {
    return apiRequest({ url: '/auth/me', method: 'get', headers });
}

async function getUserInfo(headers) {
    return apiRequest({ url: `/users/me?shopperInfo=1`, method: 'get', headers });
}

async function editUserInfo(data, id, headers) {
    return apiRequest({ url: `/users/${id}`, method: 'put', headers, data });
}

async function getDietList(headers) {
    return apiRequest({ url: '/diets', method: 'get', headers });
}

async function changePassword(data, headers) {
    return apiRequest({ url: '/auth/changePassword', method: 'put', headers, data });
}

async function getOrderHistory(headers) {
    return apiRequest({ url: '/users/orders?page=0&perPage=1000', method: 'get', headers });
}

async function getPaymentMethods() {
    return apiRequest({ url: '/users/payment-methods', method: 'get' });
}
async function addPaymentMethod(id) {
    return apiRequest({ url: `/users/payments/${id}`, method: 'post' });
}

async function updatePaymentMethod(id, data) {
    return apiRequest({ url: `/users/payments/${id}`, method: 'put', data });
}

async function deletePaymentMethod(id) {
    return apiRequest({ url: `/users/payments/${id}`, method: 'delete' });
}

async function getFavorite(headers) {
    return apiRequest({ url: '/recipe-favorites', method: 'get', headers });
}

async function createRecipeFavorite(headers, recipeId) {
    return apiRequest({ url: `/recipe-favorites/${recipeId}`, method: 'post', headers });
}
async function deleteRecipeFavorite(headers, recipeId) {
    return apiRequest({ url: `/recipe-favorites/${recipeId}`, method: 'delete', headers });
}

export default {
    getUser,
    getUserInfo,
    editUserInfo,
    getDietList,
    changePassword,
    getOrderHistory,
    getPaymentMethods,
    addPaymentMethod,
    updatePaymentMethod,
    deletePaymentMethod,
    getFavorite,
    createRecipeFavorite,
    deleteRecipeFavorite,
};
