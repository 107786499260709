import React from 'react';
import useScreenType from 'hooks/useScreenType';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Icons from 'components/common/Icons/Icons';
import IngredientItem from 'components/common/IngredientGroceryManage';
import IngredientEdit from 'components/common/IngredientPantryEdit';
import Tooltips from 'components/common/Controls/Tooltips/Tooltips';

import styles from './ingredientList.module.scss';

const IngredientList = ({ product, index, isEdit = false }) => {
    const { isMobile } = useScreenType();

    return (
        <div className={styles.ingredientListManagement}>
            <Accordion defaultExpanded={true} classes={{ root: styles.accordion }}>
                <AccordionSummary
                    classes={{
                        root: styles.accordionSummary,
                        expandIcon: styles.expandIcon,
                        expanded: styles.expanded,
                        content: styles.content,
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div className={styles.accordionHeader}>
                        <div className={styles.nameHeader}>
                            <div className={styles.name}>{product.category}</div>
                        </div>
                        {!isMobile && (
                            <div className={styles.subTitleHeader}>
                                <div onClick={(e) => e.stopPropagation()}>
                                    <div>Groceries you have</div>
                                    <Tooltips label="Review the amount of leftovers you have to get smarter recipe suggestions.">
                                        <Icons name="help" size="16"></Icons>
                                    </Tooltips>
                                </div>
                                <div onClick={(e) => e.stopPropagation()}>
                                    <div>Est. Expiry Date</div>
                                    <Tooltips label="We'll remove expired groceries for you from this list.">
                                        <Icons name="help" size="16"></Icons>
                                    </Tooltips>
                                </div>
                            </div>
                        )}
                    </div>
                </AccordionSummary>
                <AccordionDetails classes={{ root: styles.accordionDetails }}>
                    {product.userPantryItem.map((ingredient, index) => (
                        <div key={index} className={styles.ingredientItem}>
                            {isEdit ? <IngredientEdit ingredient={ingredient} /> : <IngredientItem ingredient={ingredient} />}
                        </div>
                    ))}
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default IngredientList;
