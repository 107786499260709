import styled, { css } from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

export const ShareRecipeDialogueContainer = styled(Dialog)`
    & > div:nth-child(3) {
        & > div:nth-child(1) {
            width: 518px;
            // width: 533px;
            min-height: 274px;
            background: #ffffff;
            border-radius: 16px;
            margin: 0;

            ${(props) =>
                props.$isMobile &&
                css`
                    width: 100%;
                    max-width: unset;
                    max-height: 685px;
                    border-radius: 20px 20px 0 0;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                `};
        }
    }
`;

export const ShareRecipeDialogueContent = styled(DialogContent)`
    padding: 0 !important;
    overflow-y: hidden !important;
    height: 100%;
`;

export const imglogowrapper = styled.div`
    display: flex;
    justify-content: center;
`;

export const HeaderContainer = styled.div`
    background: #233747;
    display: flex;
    justify-content: space-between;
    padding: 22px 24px;
    height: 165px;
    align-items: flex-start;
    & > button {
        color: #fff;
    }

    ${(props) =>
        props.isMobile &&
        css`
            & > button {
                display: block;
            }
        `};
`;

export const ContentContainer = styled.div`
    text-align: center;
    height: 100%;
    ${(props) =>
        props.isMobile &&
        css`
            max-width: unset;
            padding: 0;
        `};
`;

export const SubTitle = styled.div`
    color: #233747;
    letter-spacing: -0.685714px;
    font-size: 16px;
    line-height: 150%;

    ${(props) =>
        props.isMobile &&
        css`
            display: none;
            padding-top: 0;
            padding-bottom: 0px;
        `};
`;

export const ShowCheckbox = styled.div`
    display: flex;
    padding: 12px 0 20px 11px;

    & > label {
        & > span:nth-child(1) {
            padding: 0;
        }
        & > span:nth-child(2) {
            color: #bfc8cd;
            letter-spacing: -0.4px;
            font-size: 12px;
            line-height: 150%;
            padding-left: 6px;
        }
    }
`;

export const UnCheckedContainer = styled.div`
    width: 11px;
    height: 11px;
    border: 1px solid #bfc8cd;
`;

export const CheckedContainer = styled.div`
    width: 11px;
    height: 11px;
    background: #82bd73;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;

    & > div {
        display: flex;
    }
`;

export const RecipeName = styled.div`
    font-family: 'Work Sans';
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: pre-line;
    -webkit-line-clamp: 2;
    overflow: hidden;
    font-weight: 500 !important;
    font-size: 14px;
    width: 100px;
    text-align: left;
    font-weight: 400;
    color: #0d2120;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 14px;
    line-height: 17px;
    letter-spacing: -0.69px;
    margin-top: 9px;
        ${(props) =>
            props.isMobile &&
            css`
                width: unset;
                width: fit-content;
                margin-top: 0px;
            `};
`;

export const ImageContainer = styled.img`
    float: left;
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 100px;
    margin-right: 12px;
`;

export const CardContainer = styled.div`
    width: 208px;
    height: 85px;
    left: 100px;
    top: 100px;
    float: left;
    background: #f9f9f9;
    // box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.08);
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    padding: 10px;
    ${(props) =>
        props.isMobile &&
        css`
            width: 100%;
            display: flex;
            margin: 0 20px;
        `};
`;
