import get from 'lodash/get';
import * as constants from '../constants';
const initialState = {
    isFetched: false,
    isFetching: false,
    nutritionPrograms: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case constants.GET_NUTRITION_PROGRAMS_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case constants.GET_NUTRITION_PROGRAMS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                isFetched: true,
                nutritionPrograms: action.nutritionPrograms,
            };
        default:
            return state;
    }
}
