import { Component } from 'react';
import { node } from 'prop-types';
import * as Sentry from '@sentry/browser';
import { logFrontEndError } from '../services/logging.service';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import { pushError } from './slack';

class ErrorBoundary extends Component {
    static propTypes = {
        children: node.isRequired,
    };

    async componentDidCatch(error, errorInfo) {
        const serializableError = JSON.parse(JSON.stringify(error, Object.getOwnPropertyNames(error)));
        const userDetails = getUserDetails();
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        const chunkCSSFailedMessage = /Loading CSS chunk [\d]+ failed/;

        if (chunkFailedMessage.test(error.message) || chunkCSSFailedMessage.test(error.message)) {
            window.location.reload();
            return;
        }

        pushError({
            text: 'Error while rendering UI',
            data: { 'Error Info': errorInfo, userId: userDetails.username },
            error,
        });

        // await logFrontEndError({
        //     error: serializableError,
        //     userId: userDetails.username,
        // });

        Sentry.withScope((scope) => {
            Object.keys(errorInfo).forEach((key) => {
                scope.setExtra(key, errorInfo[key]);
            });
            Sentry.captureException(error);
        });
    }

    render() {
        return this.props.children;
    }
}

function getUserDetails() {
    const token = Cookies.get('token');
    if (token) {
        try {
            return jwt_decode(token);
        } catch (e) {
            console.error('Failed to parse user info');
        }
    }
    return { username: 'anonymous' };
}

export default ErrorBoundary;
