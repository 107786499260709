import { call, put, select, takeEvery } from 'redux-saga/effects';
import * as constants from '../constants';
import * as nutritionActions from '../actions/nutritionPrograms.actions';
import * as mealTypesActions from '../actions/mealTypes.actions';
import nutritionProgramServerAPI from 'services/nutritionProgram.service';
import mealTypesServerAPI from 'services/mealTypes.service';
import BuildMenuResultAPI from './BuildMenuResultAPI';
import { errorNotification } from '../../helpers/errorNotification';

export const selectIsFetchedNutritionPrograms = () =>
    select(({ nutritionProgramsReducer }) => nutritionProgramsReducer.isFetched);

export function* getNutritionPrograms(data) {
    try {
        const isFetched = yield selectIsFetchedNutritionPrograms();
        if (!isFetched) {
            const response = yield call(nutritionProgramServerAPI.getNutritionPrograms, {
                handle: data.data.handle,
            });
            if (response.data) {
                yield put(nutritionActions.getNutritionProgramSuccess(response.data.nutritionGoalPrograms));
            }
        }
    } catch (error) {
        const text = 'Error in getNutritionProgram';
        errorNotification(error, text);
    }
}

export function* getMealTypes() {
    try {
        const isFetched = yield select(({ mealTypesReducer }) => mealTypesReducer.isFetched);
        if (!isFetched) {
            const response = yield call(mealTypesServerAPI.getMealTypes);
            if (response.data) {
                yield put(mealTypesActions.getMealTypesSuccess(response.data));
            }
        }
    } catch (error) {
        const text = 'Error in getMealTypes ';
        errorNotification(error, text);
    }
}

export function* getBuildMenuPageResult(payload) {
    try {
        yield call(BuildMenuResultAPI.make, payload);
    } catch (error) {
        const text = 'Error in getBuildMenuPageResult';
        errorNotification(error, text);
    }
}

export default function*() {
    yield takeEvery(constants.GET_NUTRITION_PROGRAMS_REQUEST, getNutritionPrograms);
    yield takeEvery(constants.GET_MEAL_TYPES_REQUEST, getMealTypes);
    yield takeEvery(constants.GET_BUILD_MENU_RECIPES, getBuildMenuPageResult);
}
