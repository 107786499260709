import BaseRecipesApi from '../recipes/apiCalls/BaseRecipesAPI';
import store from 'store/store';
import * as actions from './category.actions';
import { prepareRecipesCategoryWise } from 'utility/recipeUtil';
import { queryFilterRecipes } from 'helpers/queryFilterRecipes';
class CategoryRecipesAPI extends BaseRecipesApi {
    shouldCall = () => {
        return true;
        const state = store.getState();
        const {
            categoryReducer: { pagination },
            orderMenuReducer: { fetching },
        } = state;
        if (pagination.nextPage > pagination.totalPage || pagination.totalCount == 0) {
            return false;
        }
        return true;
    };

    prepareQuery = ({ categoryId }) => {
        const state = store.getState();
        const {
            allergyReducer: { selectedAllergies: allergies },
            recipeReducer: { diet },
            categoryReducer: { pagination },
            orderMenuReducer: { recipesFilter, filterInfo },
            influencerReducer: { data: influencer, isDefaultInfluencer },
        } = state;
        const query = queryFilterRecipes({
            selectedCategories: [categoryId],
            selectedAllergies: allergies,
            diet,
            pagination,
            influencer,
            isDefaultInfluencer,
            recipesFilter,
            filterInfo,
            recipeLimit: 100,
        });
        return query;
    };

    afterCall = ({ response }) => {
        const {
            data: { recipes, categories, totalCount },
            pagination,
        } = response.data;

        store.dispatch(
            actions.fetchRecipeCategoriesSuccess({
                categories,
                recipes,
                pagination,
                totalCount,
            }),
        );
    };
}

export default new CategoryRecipesAPI();
