import BaseRecipesApi from '../recipes/apiCalls/BaseRecipesAPI';
import store from 'store/store';
import * as actions from './buildMenu.actions';
import { queryFilterRecipes } from 'helpers/queryFilterRecipes';

class BuildMenuResultAPI extends BaseRecipesApi {
    shouldCall = ({ forceCall }) => {
        if (forceCall) return true;
        return false;
    };

    prepareQuery = () => {
        const state = store.getState();
        const {
            buildMenuReducer: {
                selectedNutritionProgram,
                allergies,
                selectedMealTypes,
                selectedDiet: diet,
                cookingTime,
                difficultyLevel,
            },
            influencerReducer: { data: influencer, isDefaultInfluencer },
        } = state;
        const query = queryFilterRecipes({
            nutritionProgram: selectedNutritionProgram,
            selectedAllergies: allergies.map((allergy) => allergy._id),
            diet,
            influencer,
            isDefaultInfluencer,
            mealTypes: Object.keys(selectedMealTypes),
            recipeLimit: 100,
            cookingTime,
            difficultyLevel,
        });
        return query;
    };

    afterCall = ({ response }) => {
        const {
            data: { recipes, categories, totalCount },
            pagination,
        } = response.data;
        store.dispatch(
            actions.getBuildMenuRessultSuccess({
                categories,
                recipes,
                pagination,
                totalCount,
            }),
        );
    };
}

export default new BuildMenuResultAPI();
