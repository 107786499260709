import { call, put, select, takeLatest } from 'redux-saga/effects';
import * as constants from '../constants';
import * as actions from '../actions/ingredient.actions';
import * as actionsOrderMenu from '../actions/orderMenu.actions';
import serverAPI from 'services/ingredient.service';
import cartAPI from 'services/cart.service';
import { errorNotification } from 'helpers/errorNotification';
import { preparePayloadFromLocalStorage } from 'helpers/cart';

export const selectIsLoadedIngredientsList = () => select(({ ingredientReducer }) => ingredientReducer.isLoadedIngredientsList);
export function* getIngredientsRequest({ payload }) {
    try {
        const isLoadedIngredientsList = yield selectIsLoadedIngredientsList();
        if (!isLoadedIngredientsList) {
            const response = yield call(serverAPI.getIngredients, payload);
            if (response) {
                yield put(actions.getIngredientsSuccess(response.data));
            }
        }
    } catch (error) {
        const data = { payload };
        const text = 'Error in getIngredientsRequest saga';
        errorNotification(error, data, text);
        yield put(actions.getIngredientsFailure(error));
    }
}

export function* setIngredientSubstitute({ ingredientSubstitutes, isSubstitutionOn }) {
    const cart = yield select((state) => state.recipeReducer.Cart);
    try {
        const { data } = yield call(cartAPI.addItemToCart, preparePayloadFromLocalStorage(cart));
        // yield put(actionsOrderMenu.getCommonIngredientsRequest(true));
        yield put(actions.setManualIngredientSubstituteSuccess(data));
    } catch (error) {
        console.log(error);
        customNotification('error', error.error);
    }
}

export default function* () {
    yield takeLatest(constants.GET_INGREDIENTS_REQUEST, getIngredientsRequest);
    yield takeLatest(constants.SET_MANUAL_INGREDIENT_SUBSTITUTION, setIngredientSubstitute);
}
