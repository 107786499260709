import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import history from '../history';
import { showLoginPopup } from 'store/actions/loginModal.action';

export default () => {
    const dispatch = useDispatch();
    const url = new URL(window.location);
    const loginMode = url.searchParams.get('loginmode');
    const loggedInUser = useSelector((state) => state.authenReducer.loggedInUser);
    const { isLoginPopupOpen, accountInfo } = useSelector((state) => state.loginModalReducer);
    useEffect(() => {
        if (isLoginPopupOpen && !loginMode) {
            dispatch(showLoginPopup(false));
        } else if (loginMode) {
            if (loggedInUser) {
                url.searchParams.delete('loginmode');
                window.history.pushState({}, '', url);
            } else dispatch(showLoginPopup(true));
        }
    }, [loginMode, loggedInUser]);
};
