import { useSelector } from 'react-redux';

export default () => {
    const featureFlags = useSelector((state) => state.featureFlagsReducer.data);
    const getvalue = (field) => {
        return featureFlags.filter((item) => item.name === field).map((item) => item.enabled)[0];
    };
    const isFacebookLoginEnabled = getvalue('isFacebookLoginEnabled');
    const isGoogleLoginEnabled = getvalue('isGoogleLoginEnabled');
    const isContentForUSEnabled = getvalue('isContentForUSEnabled');
    const isIngredientSubstituteEnabled = getvalue('isIngredientSubstituteEnabled');
    const isCuratedBoxesEnabled = getvalue('isCuratedBoxesEnabled');
    return {
        isFacebookLoginEnabled,
        isGoogleLoginEnabled,
        isContentForUSEnabled,
        isIngredientSubstituteEnabled,
        isCuratedBoxesEnabled,
    };
};
