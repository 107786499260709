import { call, put, select, takeEvery } from 'redux-saga/effects';
import * as constants from '../constants';
import * as actions from '../actions/location.actions';
import serverAPI from 'services/location.service';
import { errorNotification } from 'helpers/errorNotification';
export const selectIsLoadedLocations = () => select(({ locationReducer }) => locationReducer.isloadedLocations);
function* getLocations() {
    try {
        const isLoadedLocations = yield selectIsLoadedLocations();
        if (!isLoadedLocations) {
            const response = yield call(serverAPI.getLocations);
            yield put(actions.getLocationsSuccess(response.data));
        }
    } catch (error) {
        const data = {};
        const text = 'Error in getLocations saga';
        errorNotification(error, data, text);
    }
}
export default function* () {
    yield takeEvery(constants.GET_LOCATIONS_REQUEST, getLocations);
}
