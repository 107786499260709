import * as constants from '../constants';

export const setSelectedNutritionProgram = (nutritionProgram) => ({
    type: constants.SET_SELECTED_NUTRITION_PROGRAM,
    nutritionProgram,
});

export const setSelectedMealTypes = (mealType) => ({
    type: constants.SET_SELECTED_MEAL_TYPE,
    mealType,
});

export const setExpandedRecipes = (data, expand) => ({
    type: constants.SET_EXPANDED_RECIPES,
    data,
    expand,
});

export const setSelectedDiet = (diet) => ({
    type: constants.SET_SELECTED_DIET,
    diet,
});

export const setCaloriesGoal = (cal) => ({
    type: constants.SET_CALORIES_GOAL,
    cal,
});

export const setSelectedAllergies = (allergies) => ({
    type: constants.SET_SELECTED_ALLERGIES,
    allergies,
});

export const setSelectedDislikes = (ingredients) => ({
    type: constants.SET_SELECTED_DISLIKES,
    ingredients,
});

export const setCookingTimeFilter = (time) => ({
    type: constants.SET_COOKING_TIME_FILTER,
    time,
});

export const setDifficultyLevel = (difficulty) => ({
    type: constants.SET_DIFFICULTY_LEVEL,
    difficulty,
});

export const getBuildMenuRecipes = (forceCall = false) => ({
    type: constants.GET_BUILD_MENU_RECIPES,
    forceCall,
});

export const removeBuildMenuSelectedData = () => ({
    type: constants.SET_BUILD_MENU_INITIAL_STATE,
});

export const getBuildMenuRessultSuccess = (result) => ({
    type: constants.GET_BUILD_MENU_RESULT_SUCCESS,
    result,
});

export const setExpandedMealTypes = (mealTypes) => ({
    type: constants.SET_EXPANDED_MEAL_TYPES,
    mealTypes,
});

export const setCurrentMealTypeStep = (step) => ({
    type: constants.SET_CURRENT_MEAL_TYPE_STEP,
    step,
});

export const setExpandedFirstTime = (flag) => ({
    type: constants.SET_EXPANDED_FIRST_TIME,
    flag,
});
export const setReviewedMealTypes = (mealTypes) => ({
    type: constants.SET_REVIEWED_MEAL_TYPES,
    mealTypes,
});

export const setMealTypesInCart = (mealTypes) => ({
    type: constants.SET_MEAL_TYPES_IN_CART,
    mealTypes,
});

export const setNumberOfPeople = (noOfPeople) => ({
    type: constants.SET_NUMBER_OF_PEOPLE,
    noOfPeople,
});

export const setNumberOfDays = (noOfDays) => ({
    type: constants.SET_NUMBER_OF_DAYS,
    noOfDays,
});

export const setQuestionStep = (step) => ({
    type: constants.SET_QUESTION_STEP,
    step,
});
