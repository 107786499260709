import { apiRequest } from 'helpers/request';

async function getRecipes(query) {
    return apiRequest({ url: '/recipes/all', query });
}

async function getRecipesToPrint(query) {
    return apiRequest({ url: '/recipes/print', query });
}
function getMenu(orderId) {
    return apiRequest({ url: `/orders/menu/${orderId}` });
}
function getCommonIngredients(data, query) {
    return apiRequest({ url: `/recommendation/commonIngredientsRecipe`, method: 'post', data, query });
}
async function getUnusedIngredientsRecipe(query) {
    return apiRequest({ url: `/recommendation/unusedIngredientsRecipe`, query, method: 'get' });
}
export default {
    getRecipes,
    getRecipesToPrint,
    getMenu,
    getCommonIngredients,
    getUnusedIngredientsRecipe,
};
