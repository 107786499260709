import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import useScreenType from 'hooks/useScreenType';
import useTheme from 'hooks/useTheme';
import PropTypes from 'prop-types';
import history from '../../../history';
import get from 'lodash/get';

import { ButtonWithIcon } from '../Buttons';
import TypographyD from '../TypographyD/TypographyD';
import Icons from '../Icons/Icons';

import { pushLearnqEvent } from 'helpers/trackEvents';
import { Events } from 'constants/events.js';
import { prepareRecipePayload } from 'helpers/klaviyoEvent';
import { getProgressStep } from 'helpers/progressStep';

import {
    updateStateReviewProductsPopup,
    updateStateMinimumPopup,
    updateNoActionStatusPopup,
    showSummaryDrawer,
    showMobileSummaryPopup,
} from 'store/actions/ui-settings.actions';
import { createOrder } from 'store/actions/order.actions';

import { showLoginPopup } from 'store/actions/loginModal.action';

import { ButtonWrapper, MobileBarWrapper } from '../MobileBar/styles';

const prepareButtonAsPerCurrentStep = (
    step,
    isMobileBarExpanded,
    isMobile,
    showNoActionPopupOpen,
    isNoStatusPopupOpen,
    isMobileSummaryPopupOpen,
    pricePayload,
    pathname,
    groceriesPlan,
    search,
    disabled,
) => {
    switch (step) {
        case 0:
            // if (pathname === '/groceries-plan') {
            //     return { content: 'Proceed', nextPath: groceriesPlan == 'delivery' ? '/groceries' : '/groceries/print' };
            // }
            return {
                content: 'Review & Checkout',
                nextPath: '/groceries',
            };
        case 1: {
            return {
                content: isMobile
                    ? isMobileSummaryPopupOpen
                        ? 'Checkout'
                        : 'Review My Cart'
                    : showNoActionPopupOpen || isNoStatusPopupOpen
                    ? 'Go to Checkout'
                    : 'Checkout',
                nextPath: '/checkout',
            };
        }
        case 2:
            return {
                content: isMobile
                    ? disabled && isMobileSummaryPopupOpen
                        ? `Continue Checkout`
                        : `Place Order $${get(pricePayload, 'totalPrice', '0.00').toFixed(2)}`
                    : `Place Order`,
                nextPath: 'completeCheckout',
            };

        default:
            return {
                content: !isMobileBarExpanded && isMobile ? 'Groceries' : 'Finalize Groceries',
                nextPath: '/groceries',
            };
    }
};

const ProgressButton = ({
    recipesLength,
    selectedRecipes,
    isAnyPantryItemAddedInCart,
    updateStateReviewProductsPopup,
    itemNoneRequired,
    disabled,
    pricePayload,
    updateStateMinimumPopup,
    handleNext: handleNextClick,
    isEditing,
    groceries,
    isMobileBarExpanded,
    updateNoActionStatusPopup,
    removedIngredientCount,
    showNoActionPopupOpen,
    isNoStatusPopupOpen,
    showSummaryDrawer,
    showSummaryDraw,
    showMobileBarExpanded,
    showMobileSummaryPopup,
    isMobileSummaryPopupOpen,
    groceriesPlan,
    showLoginPopup,
    loggedInUser,
}) => {
    const {
        location: { pathname, search },
    } = history;
    const { isTabletOrMobile } = useScreenType();
    const color = useTheme();
    const currentStep = prepareButtonAsPerCurrentStep(
        getProgressStep(pathname),
        isMobileBarExpanded,
        isTabletOrMobile,
        showNoActionPopupOpen,
        isNoStatusPopupOpen,
        isMobileSummaryPopupOpen,
        pricePayload,
        pathname,
        groceriesPlan,
        search,
        disabled,
    );
    const influencerHandle = useSelector((state) => state.influencerReducer.handle);
    const [isLessThan, setIsLessThan] = useState(false);

    const handleNext = () => {
        if (currentStep.nextPath === 'completeCheckout') {
            if (!disabled) {
                handleNextClick();
            }
            if (currentStep.content === 'Continue Checkout') showMobileSummaryPopup(false);
            return;
        }

        if (pathname === '/groceries' || pathname === '/min-order') {
            if (showNoActionPopupOpen) {
                if (pathname === '/groceries' && !showSummaryDraw && isTabletOrMobile) {
                    updateNoActionStatusPopup(false);
                    showMobileSummaryPopup(true);
                    return showSummaryDrawer(true);
                }
                updateNoActionStatusPopup(false);
                // if (!isNoStatusPopupOpen) return updateNoActionStatusPopup(true);
                // return;
            }
            if (get(pricePayload, 'subTotal', 0) < 10) {
                return updateStateMinimumPopup(true);
            }
            if (!isAnyPantryItemAddedInCart && itemNoneRequired === 0 && groceries.nonRequiredProducts.length) {
                const length = groceries.nonRequiredProducts.filter((product) => !product.fromUserPantry).length;
                if (length > 0) return updateStateReviewProductsPopup(true);
            }
            pushLearnqEvent(
                Events.EVENT_STARTED_CHECKOUT,
                selectedRecipes.map((item) => prepareRecipePayload(item)),
                influencerHandle,
            );
            history.push(currentStep.nextPath);
        } else {
            if (currentStep.nextPath !== '/checkout') {
                if (currentStep.nextPath === '/groceries') {
                    if (!loggedInUser) {
                        const url = new URL(window.location);
                        url.searchParams.set('loginmode', 'email');
                        window.history.pushState({}, '', url);
                        return showLoginPopup(true);
                    }
                }
                history.push(currentStep.nextPath);
            }
        }
    };

    useEffect(() => {
        if (pathname === '/min-order') {
            if (pricePayload && pricePayload.subTotal < 10) {
                setIsLessThan(true);
            } else {
                setIsLessThan(false);
            }
        }
    }, [pathname, pricePayload]);

    if (isTabletOrMobile) {
        return (
            <div className="mobile-wrapper" onClick={handleNext}>
                <ButtonWrapper
                    currentStep={currentStep}
                    disabled={
                        recipesLength === 0 || (disabled && currentStep.content != 'Continue Checkout') || isLessThan || isEditing
                    }
                    isCheckout={currentStep.nextPath === 'completeCheckout'}
                >
                    <div>
                        <Icons name="basket" size="16" />
                        <TypographyD color={color.white} type="h5">
                            {currentStep.content}
                        </TypographyD>
                    </div>

                    <div className="collapse-price-per-serving">
                        <span>
                            {currentStep.nextPath == '/checkout' && isMobileSummaryPopupOpen
                                ? `$${get(pricePayload, 'subTotal', '0.00').toFixed(2)}`
                                : `$${get(pricePayload, 'pricePerServing', '0.00').toFixed(2)} / serv.`}
                        </span>
                    </div>
                </ButtonWrapper>
            </div>
        );
    }

    return (
        <ButtonWithIcon
            title={currentStep.content}
            disabled={recipesLength === 0 || (disabled && currentStep.content != 'Continue Checkout') || isLessThan || isEditing}
            onClick={handleNext}
        />
    );
};

ProgressButton.propTypes = {
    selectedRecipes: PropTypes.array.isRequired,
    recipesLength: PropTypes.number.isRequired,
    currentProgressStep: PropTypes.number.isRequired,
    setCurrentProgressStep: PropTypes.func,
    handleNext: PropTypes.func.isRequired,
    isEditing: PropTypes.bool.isRequired,
    groceries: PropTypes.object,
    removedIngredientCount: PropTypes.number.isRequired,
    showNoActionPopupOpen: PropTypes.bool.isRequired,
    isNoStatusPopupOpen: PropTypes.bool.isRequired,
};

ProgressButton.defaultProps = {
    handleNext: () => {},
    groceries: {},
};

const mapStateToProps = ({
    userProgressReducer: { currentProgressStep },
    recipeReducer: {
        Cart: { selectedRecipesLength, pricePayload, groceries, selectedRecipes, removedIngredientList },
        itemNoneRequired,
        isEditing,
    },
    uiSettingsReducer: {
        isAnyPantryItemAddedInCart,
        showNoActionPopupOpen,
        isNoStatusPopupOpen,
        showSummaryDraw,
        isMobileSummaryPopupOpen,
        groceriesPlan,
    },
    authenReducer: { loggedInUser },
}) => ({
    currentProgressStep,
    selectedRecipes,
    recipesLength: selectedRecipesLength,
    isAnyPantryItemAddedInCart: isAnyPantryItemAddedInCart,
    itemNoneRequired: itemNoneRequired,
    pricePayload: pricePayload,
    isEditing,
    groceries,
    removedIngredientCount: (removedIngredientList || []).length,
    showNoActionPopupOpen,
    isNoStatusPopupOpen,
    showSummaryDraw,
    isMobileSummaryPopupOpen,
    groceriesPlan,
    loggedInUser,
});

export default connect(mapStateToProps, {
    updateStateReviewProductsPopup,
    updateStateMinimumPopup,
    updateNoActionStatusPopup,
    showSummaryDrawer,
    showMobileSummaryPopup,
    handleNext: createOrder,
    showLoginPopup,
})(ProgressButton);
