import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import history from '../../../history';
import useScreenType from 'hooks/useScreenType';
import useTheme from 'hooks/useTheme';

import { getProgressStep } from 'helpers/progressStep';
import { prepareRecipeListBySlice } from 'utility/recipeUtil';

import TypographyD from 'components/common/TypographyD/TypographyD';

import Servings from '../Controls/Servings/Servings';

import {
    SummaryWrapper,
    RecipeWrapper,
    TitleContainer,
    ImageContainer,
    NameContainer,
    NoDataContainer,
    RecipeContainer,
    RecipeContent,
} from './styles';
import { formatRecipeImages } from 'helpers/formatRecipeImages';

const Summary = ({
    recipes,
    showMore,
    defaultServings,
    changeServings,
    handleDeleteRecipe,
    getCommonIngredientsRequest,
    setServingsRecipe,
    handleCloseModal,
    mealTypes,
}) => {
    const currentStep = getProgressStep(history.location.pathname);
    const { isMobile, isTabletOrMobile, isDesktopOrLaptop } = useScreenType();
    const [selectedRecipesByMealType, setSelectedRecipesByMealType] = useState(
        prepareRecipeListBySlice(isDesktopOrLaptop, recipes, currentStep, showMore),
    );
    const [noOfRecipes, setNoOfRecipes] = useState(0);

    useEffect(() => {
        if (!Object.values(mealTypes).length) return;
        const mealTypesHash = {};
        Object.values(mealTypes).map(m => {
            mealTypesHash[m._id] = {
                ...m,
                recipes: [],
                servings: 0,
            };
        });
        recipes.map(recipe => {
            const mealTypeId = recipe.mealTypeId ? recipe.mealTypeId : recipe.mealTypes[0];
            if (!mealTypesHash[mealTypeId]['recipes']) {
                mealTypesHash[mealTypeId]['recipes'] = [];
                mealTypesHash[mealTypeId]['servings'] = 0;
            }
            mealTypesHash[mealTypeId]['recipes'].push(recipe);
            mealTypesHash[mealTypeId]['servings'] += recipe.customServings || defaultServings;
        });

        setSelectedRecipesByMealType(Object.values(mealTypesHash).sort((a, b) => a.order - b.order));
        setNoOfRecipes(prepareRecipeListBySlice(isDesktopOrLaptop, recipes, currentStep, showMore));
    }, [recipes, showMore, mealTypes]);

    const recipeDetail = ({ ingredientsAmount, totalIngredients, mainImage, name, _id, images }) => (
        <RecipeWrapper>
            {(isTabletOrMobile || currentStep > 1) && (
                <ImageContainer>
                    <img src={formatRecipeImages(images)} />
                </ImageContainer>
            )}
            <NameContainer isMobile={isMobile} showImage={isTabletOrMobile || currentStep > 1} isTablet={isTabletOrMobile}>
                <div
                    onClick={() => {
                        history.location.pathname !== `/recipes/${_id}` && history.push(`/recipes/${_id}`);
                        if (isMobile || isTabletOrMobile) {
                            handleCloseModal();
                        }
                    }}
                >
                    {name}
                </div>
                {/* <div>{ingredientsAmount || totalIngredients} Ingredients</div> */}
            </NameContainer>
        </RecipeWrapper>
    );
    let recipesRemaining = noOfRecipes;
    return (
        <SummaryWrapper isMobile={isTabletOrMobile} isDesktop={isDesktopOrLaptop}>
            {/* {isTabletOrMobile ? (
                <TypographyD type="bodySmall" weight="500" color={color.grey1}>
                    Menu
                </TypographyD>
            ) : (
                selectedRecipesByMealType &&
                selectedRecipesByMealType.recipes &&
                selectedRecipesByMealType.recipes.length > 0 && (
                    <TitleContainer isMobile={isMobile}>
                        <span>Meal</span>
                        <span>Servings</span>
                    </TitleContainer>
                )
            )} */}
            <RecipeContainer showMore={showMore} currentStep={currentStep}>
                {selectedRecipesByMealType && selectedRecipesByMealType.length ? (
                    selectedRecipesByMealType.map((mealType, i) => {
                        if (!mealType.recipes || !mealType.recipes.length || recipesRemaining <= 0) return null;
                        let showRecipes = mealType.recipes.length;
                        if (mealType.recipes.length > recipesRemaining) {
                            showRecipes = recipesRemaining;
                            recipesRemaining = 0;
                        } else {
                            recipesRemaining -= mealType.recipes.length;
                        }
                        return (
                            <div style={{ marginTop: 10 }}>
                                <TitleContainer isMobile={true}>
                                    <span>{mealType.name}</span>
                                    <span>{mealType.servings} Servings</span>
                                </TitleContainer>
                                {mealType.recipes.slice(0, showRecipes).map((r, j) => (
                                    <RecipeContent key={j}>
                                        {recipeDetail(r)}
                                        <Servings
                                            value={r.customServings || defaultServings}
                                            recipe={r}
                                            changeServings={changeServings}
                                            setServingsRecipe={setServingsRecipe}
                                            handleDeleteRecipe={handleDeleteRecipe}
                                            // getCommonIngredientsRequest={getCommonIngredientsRequest}
                                            defaultServings={defaultServings}
                                            mealTypeId={r.mealTypeId}
                                        />
                                    </RecipeContent>
                                ))}
                            </div>
                        );
                    })
                ) : (
                    <NoDataContainer>Add your first recipe</NoDataContainer>
                )}
            </RecipeContainer>
        </SummaryWrapper>
    );
};

Summary.propTypes = {
    recipes: PropTypes.array.isRequired,
    showMore: PropTypes.bool.isRequired,
    defaultServings: PropTypes.number.isRequired,
    changeServings: PropTypes.func.isRequired,
    handleDeleteRecipe: PropTypes.func.isRequired,
    mealTypes: PropTypes.array.isRequired,
};

Summary.defaultProps = {
    recipes: [],
    showMore: false,
    mealTypes: [],
};

export default Summary;
