import React, { useEffect, useRef, useState } from 'react';
import { InputContainer, ErrorMessage, InputElement, BeforeInput, AfterInput } from './styles';
import MaskedInput from 'react-text-mask';
import { useSelector } from 'react-redux';

const Input = ({
    size = 'large',
    width = '100%',
    height = '56px',
    type = 'text',
    fontSize = '16px',
    mask = null,
    placeHolder = '',
    className = '',
    value = '',
    error = null,
    errorSize = '12px',
    disabled = false,
    onChange = () => {},
    beforeEl = null,
    afterEl = null,
    name = '',
    onBlur = () => {},
    ...rest
}) => {
    const loggedInUser = useSelector((state) => state.authenReducer.loggedInUser);
    const [show, setShow] = useState(!loggedInUser);
    const inputEl = useRef(null);

    useEffect(() => {
        if (mask && value) {
            setShow(true);
        }
    }, [value]);

    useEffect(() => {
        if (show && inputEl.current && value) {
            inputEl.current.inputElement.focus();
        }
    }, [show]);

    return (
        <InputContainer width={width} size={size}>
            <InputElement size={size} height={height} fontSize={fontSize} isError={!!error} disabled={disabled}>
                {beforeEl && <BeforeInput>{beforeEl}</BeforeInput>}
                {mask && show ? (
                    <MaskedInput
                        placeholder={placeHolder}
                        className={className}
                        value={value}
                        type={type}
                        mask={mask}
                        name={name}
                        disabled={disabled}
                        onChange={onChange}
                        onBlur={onBlur}
                        ref={inputEl}
                        {...rest}
                    />
                ) : (
                    <input
                        placeholder={placeHolder}
                        className={className}
                        value={value}
                        type={type}
                        name={name}
                        mask={mask}
                        disabled={disabled}
                        onChange={onChange}
                        onBlur={onBlur}
                        {...rest}
                    />
                )}

                {afterEl && <AfterInput>{afterEl}</AfterInput>}
            </InputElement>
            {error && <ErrorMessage errorSize={errorSize}>{error}</ErrorMessage>}
        </InputContainer>
    );
};

export default Input;
