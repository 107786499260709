import styled, { css } from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

export const SummaryContainer = styled(Dialog)`
    & > div:nth-child(3) {
        & > div:nth-child(1) {
            max-width: 876px;
            width: 844px;
            max-height: 740px;
            min-height: unset;
            overflow-y: hidden;
            border-radius: 16px;
        }
    }

    ${(props) =>
        props.$isTabletOrMobile &&
        css`
            & > div:nth-child(3) {
                & > div:nth-child(1) {
                    max-width: 876px;
                    max-height: 740px;
                    overflow-y: hidden;
                    max-width: unset;
                    min-height: 100%;
                    margin: 0;
                    border-radius: unset;
                    width: unset;
                }
            }
        `};
`;

export const SummaryContent = styled(DialogContent)`
    padding: 0 !important;
    overflow-y: unset !important;

    ${(props) =>
        props.$isTabletOrMobile &&
        css`
            height: 100vh;
        `};
`;

export const HeaderContainer = styled.div`
    position: sticky;
    top: 0;
    display: flex;
    padding: 28px 24px;
    border-bottom: 1px solid #e5eaed;
    z-index: 10;
    background: #ffffff;
    min-height: 20px;

    & > button:nth-child(1) {
        position: absolute;
        top: 23px;
        left: 20px;
        color: #0d2120;
    }
    & > div:nth-child(2) {
        position: absolute;
        display: flex;
        right: 23px;
        color: #233747;
        font-size: 16px;
        line-height: 124%;
        font-weight: 500;
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
`;

export const SummaryTittle = styled.div`
    margin: 0 auto;
    color: #233747;
    font-weight: 500;
    font-size: 16px;
    line-height: 124%;
`;

export const ContentContainer = styled.div`
    height: 579px;
    overflow-y: auto;

    ${(props) =>
        !props.isDesktopOrLaptop &&
        css`
            height: calc(100% - 164px);
        `};
`;

export const ListRecipes = styled.div`
    padding: 0 44px 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    ${(props) =>
        props.isMobile &&
        css`
            padding: 0;
        `};
`;

export const RecipeCardContainer = styled.div`
    padding: 32px 12px 0;
    width: calc(100% / 3);
    padding: 37px 12px 11px;

    ${(props) =>
        !props.isDesktopOrLaptop &&
        css`
            width: 50%;
            display: flex;
            justify-content: center;
        `};
    ${(props) =>
        props.isMobile &&
        css`
            width: 100%;
        `};
`;

export const FooterContainer = styled.div`
    padding: 20px 32px;
    border-top: 1px solid #e5eaed;
    position: sticky;
    bottom: 0;
    z-index: 10;
    background: #ffffff;
    min-height: 88px;

    ${(props) =>
        props.isMobile &&
        css`
            display: flex;
            align-items: center;
            justify-content: space-between;
        `};
`;

export const ServingContainer = styled.div`
    display: flex;
    width: 100%;

    & > span:nth-child(2) {
        margin-left: auto;

        ${(props) =>
            !props.isDesktopOrLaptop &&
            css`
                margin: unset;
                margin-left: auto;
            `};
    }
    ${(props) =>
        props.isMobile &&
        css`
            flex-direction: row;
        `};
`;

export const Serving = styled.span`
    color: #233747;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    align-items: center;
    margin-top: 12px;
`;

export const ReviewButton = styled.span`
    color: #233747;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    align-items: center;
`;

export const ListExtraProducts = styled.div`
    padding: 0 56px 24px;

    ${(props) =>
        props.isMobile &&
        css`
            padding: 48px 24px 24px;
        `};
`;

export const ExtraProductTitle = styled.div`
    color: #0d2120;
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
    padding-bottom: 16px;
    border-bottom: 1px solid #eff5f9;
`;

export const ExtraValue = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
    width: calc(50% - 30px);
    justify-content: space-between;

    ${(props) =>
        !props.isDesktopOrLaptop &&
        css`
            width: calc(100% - 112px);
        `};
`;

export const ExtraProductContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 12px 0;
    border-bottom: 1px solid #eff5f9;

    ${(props) =>
        !props.isDesktopOrLaptop &&
        css`
            flex-direction: column;
        `};
`;

export const Product = styled.div`
    display: flex;
    align-items: center;
    width: 50%;

    ${(props) =>
        !props.isDesktopOrLaptop &&
        css`
            width: 100%;
            align-items: flex-start;
        `};
`;

export const Image = styled.div`
    width: 88px;
    min-width: 88px;
    height: 56px;
    border-radius: 8px;
    background: url(${({ image }) => image}) #d8d8d8 no-repeat center center/cover;
`;

export const Name = styled.div`
    padding-left: 24px;
    font-weight: 500;
    font-size: 16px;
    line-height: 124%;
    color: #233747;
`;

export const Quantity = styled.div`
    color: #233747;
    font-size: 14px;
    line-height: 146%;
    width: 40%;
`;

export const Price = styled.div`
    color: #0d2120;
    font-size: 16px;
    line-height: 19px;
    width: 20%;
    text-align: right;
`;

export const ButtonRemove = styled.div`
    cursor: pointer;
    color: #aab5bc;
`;

export const Notification = styled.div`
    padding-top: 40px;
    width: 100%;
    text-align: center;
`;

export const AddRecipesButton = styled.div`
    & > div {
        & > div {
            justify-content: center;
        }
    }
`;
