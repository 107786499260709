import styled, { css } from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

export const ExtraContainer = styled(Dialog)`
    & > div:nth-child(3) {
        & > div:nth-child(1) {
            max-width: 1024px;
            max-height: 740px;
            overflow: hidden;
            border-radius: 16px;
        }
    }

    ${(props) =>
        props.$isMobile &&
        css`
            & > div:nth-child(3) {
                position: relative;

                & > div:nth-child(1) {
                    max-width: unset;
                    margin: 0;
                    border-radius: unset;
                    width: 100%;
                    max-height: unset;
                    height: 80%;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    border-radius: 20px 20px 0px 0px;
                }
            }
        `};
`;

export const ExtraContent = styled(DialogContent)`
    padding: 0 !important;
    overflow-y: unset;

    ${(props) =>
        props.$isMobile &&
        css`
            height: 100%;
        `};
`;

export const HeaderContainer = styled.div`
    position: sticky;
    top: 0;
    display: flex;
    padding: 12px 32px;
    border-bottom: 1px solid #e5eaed;
    z-index: 10;
    background: #ffffff;
    min-height: 72px;
    align-items: center;
    justify-content: center;

    & > button {
        position: absolute;
        top: 22px;
        left: 25px;
        color: #0d2120;
    }

    ${({ isMobile }) =>
        isMobile &&
        css`
            min-height: 104px;

            & > button {
                display: none;
            }

            & > div {
                font-weight: 500;
                font-size: 20px;
                text-align: center;
                line-height: 150%;
                max-width: 259px;
            }
        `}
`;

export const ContentContainer = styled.div`
    max-height: 483px;
    margin-top: 20px;
    overflow-y: scroll;
    padding: 0 32px;
    display: flex;
    flex-wrap: wrap;

    & > div {
        min-height: 120px;
        align-items: center;
    }

    ${(props) =>
        props.isMobile &&
        css`
            display: grid;
            grid-template-columns: repeat(2, 50%);
            max-height: unset;
            height: calc(100% - 208px);
            padding: 0 20px;

            & > div {
                min-height: auto;
                height: fit-content !important;
            }
        `};
`;

export const FooterContainer = styled.div`
    padding: 20px 32px;
    border-top: 1px solid #e5eaed;
    position: sticky;
    bottom: 0;
    z-index: 10;
    background: #ffffff;
    min-height: 88px;
    display: flex;
    align-items: center;

    ${(props) =>
        props.isMobile &&
        css`
            min-height: 104px;
        `};
`;

export const ReviewTitle = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 124%;
    color: #0d2120;
`;

export const ReviewButton = styled.div`
    width: 100%;
    margin: auto;
    max-width: 312px;

    & > button {
        width: 100%;
        min-height: 48px;
        height: unset;
        padding: 15px 20px;
        @media all and (max-width: 375px) {
            font-size: 13px;
        }
    }
`;
