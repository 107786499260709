import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useOnClickOutside from 'hooks/useClickOutside';
import useTheme from 'hooks/useTheme';

import Icons from 'components/common/Icons/Icons';

import {
    DropdownServingsContainer,
    DropdownContentContainer,
    DropdownItem,
    Lines,
    DropdownInput,
    Subtitle,
    DropdownTitle,
} from './styles';

const DropdownControl = ({
    onSelect,
    value,
    inputValue: servingValue,
    isOpen,
    setIsOpen,
    disabled,
    options,
    onApply,
    isFilterOrders = false,
    width,
    itemColor,
    itemFontSize = '14px',
    changeOnHover = true,
    align = '',
    title,
}) => {
    const color = useTheme();
    const ref = useRef();

    useOnClickOutside(ref, () => {
        if (isOpen) {
            setIsOpen();
        }
    });

    const [inputValue, setInputValue] = useState();
    useEffect(() => {
        setInputValue(value ? '' : inputValue);
    }, [servingValue]);

    const onChangeValueInput = (value) => {
        if (!isNaN(value)) {
            setInputValue(value);
        }
    };

    return (
        <DropdownServingsContainer ref={ref} width={width || 'max-content'}>
            <DropdownContentContainer open={isOpen} isFilterOrders={isFilterOrders} width={width || '210px'} align={align}>
                {title && (
                    <DropdownTitle onClick={() => setIsOpen()}>
                        <div>{title}</div>
                        <Icons name="arrowUp" color="#82bd73" size="10" />
                    </DropdownTitle>
                )}
                {options &&
                    options.map((item, index) => (
                        <div key={index}>
                            <div className="dropdown-item" onClick={() => onSelect(item)}>
                                <DropdownItem
                                    isSubtitle={item.subtitle}
                                    color={itemColor || color.grey2}
                                    fontSize={itemFontSize}
                                    active={value && item.value === value.value && true}
                                    changeOnHover={changeOnHover}
                                >
                                    {item.label}
                                </DropdownItem>
                                {item.subtitle && <Subtitle>{item.subtitle}</Subtitle>}
                            </div>
                            <Lines color={color} />
                        </div>
                    ))}
                {!isFilterOrders && (
                    <DropdownInput color={color} disabled={disabled}>
                        <div>
                            <input
                                value={inputValue || ''}
                                onChange={(e) => onChangeValueInput(e.target.value)}
                                placeholder="Enter the number"
                            />
                            <span onClick={() => onApply(inputValue)}>Apply</span>
                        </div>
                    </DropdownInput>
                )}
            </DropdownContentContainer>
        </DropdownServingsContainer>
    );
};

DropdownControl.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.string,
        }),
    ).isRequired,
    onSelect: PropTypes.func.isRequired,
    value: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.string,
    }),
    isOpen: PropTypes.bool.isRequired,
    setIsOpen: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    onApply: PropTypes.func,
    inputValue: PropTypes.number,
};

DropdownControl.defaultProps = {
    disabled: false,
};

export default DropdownControl;
