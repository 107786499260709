import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import findIndex from 'lodash/findIndex';
import { checkIsExtraProductSelected } from 'utility/extraProductUtil';
import debounce from 'lodash/debounce';

import { addExtraProduct, deleteExtraProduct } from 'store/actions/extraProduct.actions';

import LongText from 'components/common/LongText';
import { Primary } from 'components/common/Buttons';
import { CartContainer, CartImages, Price, Name } from './styles';

const stylesName = {
    lineHeight: '18px',
    color: '#0d2120',
    fontSize: '12px',
    minHeight: '36px',
    fontWeight: 400,
};

const ExtraProductCart = ({ extraProduct }) => {
    const dispatch = useDispatch();
    const selectedExtraProducts = useSelector((state) => state.extraProductReducer.selected);

    const [state, setState] = useState('InActive');
    const [totalServings, setTotalServings] = useState(1);

    const { image, name, price, quantity, units, value } = extraProduct;

    const selectExtraProductValue = () => {
        const extraProductIndex = findIndex(selectedExtraProducts, (c) => c._id === extraProduct._id);
        if (extraProductIndex !== -1) return selectedExtraProducts[extraProductIndex].value;
        else return 0;
    };

    useEffect(() => {
        if (checkIsExtraProductSelected(selectedExtraProducts, extraProduct)) {
            setState('Active');
            setTotalServings(selectExtraProductValue());
        } else {
            setState('InActive');
        }
    }, [checkIsExtraProductSelected(selectedExtraProducts, extraProduct)]);

    const handleAdd = () => {
        dispatch(addExtraProduct(extraProduct));
        setTotalServings(totalServings + 1);
    };

    const handleRemove = () => {
        if (totalServings - 1 === 0) {
            setState('InActive');
        }
        setTotalServings(totalServings - 1);
        dispatch(deleteExtraProduct(extraProduct._id));
    };

    const handleOnClick = () => {
        setState('Active');
        setTotalServings(1);
        dispatch(addExtraProduct(extraProduct));
    };

    return (
        <CartContainer>
            <CartImages image={image}></CartImages>
            <Price>{`$${price}`}</Price>
            <LongText text={`${name}, ${quantity} ${units}`} classes={stylesName} maxLine={2} />
            <Primary
                state={state}
                size="medium"
                title={state === 'Active' ? `${totalServings} quantity` : 'Add to Cart'}
                onClick={debounce(handleOnClick, 1000, { leading: true })}
                disabled={false}
                onAdd={debounce(handleAdd, 1000, { leading: true })}
                onRemove={debounce(handleRemove, 1000, { leading: true })}
            />
        </CartContainer>
    );
};

export default ExtraProductCart;
