import React, { useState } from 'react';
import useTheme from 'hooks/useTheme';
import { PieChart as Pie } from 'react-minimal-pie-chart';
import TypographyD from '../common/TypographyD/TypographyD';

import './styles.scss';

const labels = ['protein', 'fat', 'carbs'];
const colorsMap = {
    protein: '#82bd73a6',
    fat: '#43746c',
    carbs: '#40845ac7',
};

const getChartData = (data) => {
    let chartData = [];
    Object.keys(data).forEach((item) => {
        if (labels.includes(item.toLowerCase())) {
            const percentage = ((data[item].value * (item === 'fat' ? 9 : 4)) / data.calories.value) * 100;
            chartData.push({
                value: percentage,
                color: colorsMap[item],
            });
            // chartData.push({ value: data[item].value, color: colorsMap[item] });
        }
    });

    return chartData;
};
const formatData = (data, daily = {}) => {
    const formattedData = [];
    Object.keys(data).forEach((item) => {
        if (labels.includes(item.toLowerCase())) {
            const percentage = Math.round(((data[item].value * (item === 'fat' ? 9 : 4)) / data.calories.value) * 100);
            formattedData.push({
                name: item,
                value: `${item in daily ? daily[item] : ''} ${data[item].unit}  (${percentage}%)`,
                color: colorsMap[item],
                dailyPercentage: Math.round(data[item].dailyPercentage),
            });
            // formattedData.push({ name: item, value: `${data[item].value} ${data[item].unit}`, color: colorsMap[item] });
        }
    });
    return formattedData;
};

const PieChart = ({ nutrition, dailyNutrition, expanded }) => {
    const color = useTheme();
    return (
        <>
            <div className="data">
                <Pie
                    className="data__chart"
                    data={getChartData(nutrition)}
                    radius={Pie.defaultProps.radius - 6}
                    segmentsStyle={{ transition: 'stroke .3s' }}
                    segmentsShift={1}
                />
                <div className="data__details">
                    {formatData(nutrition, dailyNutrition).map((data, index) => (
                        <div key={index} className="data__details--container">
                            <div className="colorBlock" style={{ backgroundColor: data.color }} />
                            <TypographyD className="nameBlock" type={'h6'} color={color.grey1}>
                                {data.name}
                            </TypographyD>
                            <TypographyD className="valueBlock" type={'h6'} color={color.grey1}>
                                {data.value}
                            </TypographyD>
                        </div>
                    ))}
                </div>
            </div>
            <TypographyD className="heading__text small" type={'h6'} color={color.grey2}>
                These values represent the average nutrition info of all recipes in your shopping cart.
            </TypographyD>
        </>
    );
};

export default PieChart;
