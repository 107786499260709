import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useOnClickOutside from 'hooks/useClickOutside';

import { CustomIcon, PopUp } from 'components/common/Buttons';
import { SearchField } from 'components/common/Fields';

import { updateStateAddIngredientPopup } from 'store/actions/ui-settings.actions';
import Navigate from 'components/common/NavigatePreviousRoute';

import styles from './groceryHeader.module.scss';

const GroceryHeader = ({ userPantryItems, setUserPantryView }) => {
    const dispatch = useDispatch();
    const ref = useRef();

    const ingredientDefaultEdit = useSelector((state) => state.uiSettingsReducer.ingredientDefaultEdit);

    const [openSearch, setOpenSearch] = useState(false);
    const [searchText, setSearchText] = useState('');

    useOnClickOutside(ref, () => !searchText && setOpenSearch(false));

    useEffect(() => {
        if (searchText) {
            let newUserPantryItems = [];
            newUserPantryItems = userPantryItems.filter(
                (userPantry) => userPantry.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1,
            );
            setUserPantryView(newUserPantryItems);
        } else {
            setUserPantryView(userPantryItems);
        }
    }, [userPantryItems]);

    const handleSearch = (e) => {
        if (e.target.value) {
            let newUserPantryItems = [];
            newUserPantryItems = userPantryItems.filter(
                (userPantry) => userPantry.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1,
            );
            setUserPantryView(newUserPantryItems);
        } else {
            setUserPantryView(userPantryItems);
        }
        setSearchText(e.target.value);
    };

    const handleClear = () => {
        setUserPantryView(userPantryItems);
        setSearchText('');
    };

    return (
        <div className={styles.groceryHeader}>
            <div>
                <div>
                    <Navigate label="Your Digital Pantry" defaultBackRoute="/" />
                </div>
                <div className={styles.headerSubTitle}>
                    Your groceries from the order will be added here once it is delivered.
                </div>
            </div>
            <div className={styles.buttonGroup}>
                <PopUp
                    onClick={() => dispatch(updateStateAddIngredientPopup(true, ingredientDefaultEdit))}
                    title="Add ingredients"
                />
                {openSearch ? (
                    <div ref={ref}>
                        <SearchField value={searchText} onChange={handleSearch} onClear={handleClear} />
                    </div>
                ) : (
                    <CustomIcon
                        name={'search'}
                        colorObj={{
                            hover: '#AAB5BC',
                            pressed: '#82BD73',
                        }}
                        size="medium"
                        onClick={() => setOpenSearch(true)}
                    />
                )}
            </div>
        </div>
    );
};

export default GroceryHeader;
