import { apiRequest } from 'helpers/request';

async function getGroceries(query) {
    return apiRequest({ url: '/recipes/groceries', query });
}

async function getRecipe(id, query) {
    return apiRequest({ url: `/recipes/${id}`, query });
}

async function getRecipeFilter(query) {
    return apiRequest({ url: `/recipes/filters`, query });
}

export default {
    getRecipe,
    getGroceries,
    getRecipeFilter,
};
