export default {
    name: 'v1.3',
    up: () => {
        let deliveryAddress = localStorage.getItem('deliveryAddress');
        if (deliveryAddress) {
            deliveryAddress = JSON.parse(deliveryAddress);
            if (deliveryAddress && deliveryAddress.addressLine1) {
                localStorage.setItem(
                    'deliveryAddress',
                    JSON.stringify({ ...deliveryAddress, lineOne: deliveryAddress.addressLine1 }),
                );
            }
        }
    },
};
