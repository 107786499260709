import React from 'react';
import Icons from '../Icons/Icons';
import styles from './Footer.module.scss';
import useScreenType from 'hooks/useScreenType';
import config from 'config';
import { useSelector } from 'react-redux';
import history from '../../../history';
const Footer = () => {
    const { isMobile, isTabletOrMobile } = useScreenType();
    const url = config[process.env.REACT_APP_ENV].FRONTEND_DOMAIN;
    const isDefaultInfluencer = useSelector((state) => state.influencerReducer.isDefaultInfluencer);
    const selectedRecipes = useSelector((state) => state.recipeReducer.Cart.selectedRecipes);
    const influencer = useSelector((state) => state.influencerReducer.handle);
    const style = (styles) => {
        if (
            history.location.pathname === '/recipes/favorites' ||
            history.location.pathname === '/' ||
            history.location.pathname === `/${influencer}`
        ) {
            return styles.footerUnPadding;
        } else {
            if (
                history.location.pathname === '/recipes' ||
                history.location.pathname === `/${influencer}/recipes` ||
                selectedRecipes.length > 0
            ) {
                return styles.footer;
            }
            return styles.footerUnPadding;
        }
    };
    return (
        <div className={style(styles)}>
            <div className={styles.container}>
                <div className={styles.footerTop}>
                    <div
                        className={styles.logo}
                        onClick={() => {
                            if (!isDefaultInfluencer) {
                                history.push(`/${influencer}`);
                            } else {
                                history.push(`/`);
                            }
                        }}
                    >
                        <Icons name="logo2" />
                    </div>
                    <div className={styles.message}>
                        <p>Making meal planning and grocery shopping convenient.</p>
                    </div>
                    <div className={styles.icons}>
                        <a className={styles.instagram} href="https://www.instagram.com/easierchef">
                            <Icons name="instagram" />
                        </a>
                        <a className={styles.facebook} href="https://www.facebook.com/easierchef">
                            <Icons name="facebook" />
                        </a>
                        <a className={styles.frame} href="https://twitter.com/easierchef">
                            <Icons name="frame" />
                        </a>
                    </div>
                    <div className={styles.bottom}>
                        <a className={styles.label1} href={`${url}/tos`}>
                            {isMobile ? 'ToS' : 'Terms of Service'}
                        </a>
                        <a className={styles.label1} href={`${url}/privacy`}>
                            Privacy Policy
                        </a>
                        <a className={styles.label2} href={`${url}/delivery`}>
                            Delivery
                        </a>
                    </div>
                </div>
            </div>
            <div className={styles.containerBottom}>
                <div className={styles.footerBottom}>
                    <div className={styles.footerBottomLeft}>© EasierChef, All Rights Reserved</div>
                    <div className={styles.footerBottomRight}>
                        <a className={styles.service} href={`${url}/tos`}>
                            Terms of Service
                        </a>
                        <a className={styles.privacy} href={`${url}/privacy`}>
                            Privacy Policy
                        </a>
                        <a className={styles.privacy} href={`${url}/delivery`}>
                            Delivery
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Footer;
