import * as constants from '../constants';
import findIndex from 'lodash/findIndex';

const removePantryItems = (ids, userPantryItems) => {
    ids.forEach((id) => {
        userPantryItems = userPantryItems.filter((userPantry) => userPantry.id !== id);
    });
    return userPantryItems;
};

const listUserPantryRemove = (id, ids) => {
    if (!ids.length) {
        return [id];
    } else {
        if (findIndex(ids, (i) => i === id) !== -1) {
            return ids.filter((i) => i !== id);
        } else {
            return [...ids, id];
        }
    }
};

const listIngredientsEdit = (ingredientsEdit, ingredient) => {
    if (ingredientsEdit) {
        if (findIndex(ingredientsEdit, (i) => i.id === ingredient.id) !== -1) {
            return [...ingredientsEdit.filter((i) => i.id !== ingredient.id), ingredient];
        } else {
            return [...ingredientsEdit, ingredient];
        }
    } else {
        return [ingredient];
    }
};

const initialState = {
    userPantryItems: null,
    userPantryItemsUpdate: [],
    userPantryItemsRemove: [],
    error: null,
    isEditing: false,
    isLoading: false,
    ingredientsEdit: null,
    isFetched: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case constants.GET_USER_PANTRY_REQUEST:
            return {
                ...state,
                error: null,
                isLoading: true,
            };
        case constants.GET_USER_PANTRY_SUCCESS:
            return {
                ...state,
                userPantryItems: action.data,
                isLoading: false,
                isFetched: true,
            };
        case constants.GET_USER_PANTRY_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            };
        case constants.ADD_USER_PANTRY_REQUEST:
            return {
                ...state,
                error: null,
            };
        case constants.ADD_USER_PANTRY_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case constants.SET_USER_ITEM_PANTRY_UPDATE:
            return {
                ...state,
                userPantryItemsUpdate: action.ingredients,
            };
        case constants.UPDATE_USER_PANTRY_ITEMS:
            return {
                ...state,
                userPantryItems: action.ingredients,
                isEditing: true,
            };
        case constants.UPDATE_USER_PANTRY_SUCCESS:
            return {
                ...state,
                isEditing: false,
            };
        case constants.SET_USER_ITEM_PANTRY_REMOVE:
            return {
                ...state,
                isEditing:
                    listUserPantryRemove(action.id, state.userPantryItemsRemove).length !== 0 ||
                    state.userPantryItemsUpdate.length !== 0
                        ? true
                        : false,
                userPantryItemsRemove: listUserPantryRemove(action.id, state.userPantryItemsRemove),
            };
        case constants.CLEAR_USER_ITEM_PANTRY_REMOVE:
            return {
                ...state,
                isEditing: state.userPantryItemsUpdate.length !== 0 ? true : false,
                userPantryItemsRemove: [],
            };
        case constants.REMOVE_USER_PANTRY_SUCCESS:
            return {
                ...state,
                isEditing: false,
                userPantryItems: removePantryItems(action.ids, state.userPantryItems),
            };
        case constants.SET_INGREDIENTS_EDIT:
            return {
                ...state,
                ingredientsEdit: listIngredientsEdit(state.ingredientsEdit, action.ingredient),
            };
        case constants.CLEAR_INGREDIENTS_EDIT:
            return {
                ...state,
                ingredientsEdit: null,
            };
        default:
            return state;
    }
}
