import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as constants from '../constants';
import * as actionsOnboarding from '../actions/onboarding.actions';
import * as actionsUser from '../actions/user.actions';
import { errorNotification } from 'helpers/errorNotification';
import Cookies from 'js-cookie';
import serverAPIUser from '../../services/user.service';
import serverAPIUserPantry from '../../services/usePantry.service';

export const selectSteps = () => select(({ onboardingReducer }) => onboardingReducer.steps);
export function* updatedPayloadOnboarding() {
    try {
        const token = Cookies.get('token');
        const headers = {
            Authorization: token,
        };
        const response = yield call(serverAPIUser.getUser, headers);
        const userResponse = yield call(serverAPIUser.getUserInfo, response.data._id, headers);
        const countryCode = yield select((state) => state.configReducer.config.countryCode);
        yield put(actionsUser.getUserSuccess(userResponse.data));

        const steps = yield selectSteps();

        const diets = steps.filter((step) => step.name === 'CHOOSE_DIETS');
        const allergies = steps.filter((step) => step.name === 'CHOOSE_ALLERGIES');
        const serving = steps.filter((step) => step.name === 'CHOOSE_SERVINGS');
        const groceries = steps.filter((step) => step.name === 'CHOOSE_GROCERIES');

        let newUser = {
            ...userResponse.data,
            shopperInfo: {
                ...userResponse.data.shopperInfo,
                allergies: allergies[0].info.allergies,
                preferences: diets[0].info.diets
                    ? {
                          noOfServingsPerRecipe: serving[0].info.servings,
                          diet: diets[0].info.diets,
                      }
                    : {
                          noOfServingsPerRecipe: serving[0].info.servings,
                      },
            },
        };
        yield call(serverAPIUserPantry.addUserPantry, headers, { data: groceries[0].info.groceries, countryCode });
        yield put(actionsUser.editUserInfoRequest(newUser, true));
        yield put(actionsOnboarding.updatedPayloadOnboardingSuccess());
    } catch (error) {
        const data = {};
        const text = 'Error in updatedPayloadOnboarding saga';
        errorNotification(error, data, text);
    }
}

export default function* () {
    yield takeLatest(constants.UPDATED_PAYLOAD_ONBOARDING, updatedPayloadOnboarding);
}
