import React from 'react';
import { useSelector } from 'react-redux';
import history from '../history';

const influencerRoutes = {
    '/build-menu': '/build-menu',
    '/recipes': '/recipes',
    '/meal-plan': '/meal-plan',
    '/': '/',
};
const useGoBack = ({ defaultBackRoute, onClick = () => {} }) => {
    const influencer = useSelector((state) => state.influencerReducer.handle);
    const isDefaultInfluencer = useSelector((state) => state.influencerReducer.isDefaultInfluencer);

    const goBack = React.useCallback(() => {
        if (history.length > 2) {
            history.goBack();
        } else if (defaultBackRoute) {
            if (!isDefaultInfluencer)
                if (influencerRoutes[defaultBackRoute]) history.push(`/${influencer}${defaultBackRoute}`);
                else history.push(defaultBackRoute);
            else history.push(defaultBackRoute);
        } else {
            history.push('/');
        }
        onClick();
    }, []);

    return {
        goBack,
    };
};

export default useGoBack;
