import styled, { css } from 'styled-components';

export const NavigateContainer = styled.div`
    display: flex;
    align-items: center;

    & > div:nth-child(1) {
        cursor: pointer;
    }
`;

export const Label = styled.div`
    color: #0d2120;
    font-weight: bold;
    font-size: 32px;
    line-height: 150%;
    padding-left: 8px;
    text-align: start;
    ${(props) =>
        props.$isMobile &&
        css`
            font-size: 24px;
            line-height: 113%;
        `};
`;
