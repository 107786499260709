import styled, { css } from 'styled-components';

export const CartContainer = styled.div`
    width: 171px;

    & > button {
        width: 171px;
        height: 42px;
    }
`;

export const CartImages = styled.div`
    width: 171px;
    height: 99px;
    background: url(${({ image }) => image}) #d8d8d8 no-repeat center center/cover;
    border-radius: 4px;
`;

export const Price = styled.div`
    padding-top: 4px;
    color: #0d2120;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
`;

export const Name = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-height: 18px;
    max-height: 36px;
    color: #0d2120;
    font-size: 12px;
    padding-bottom: 4px;
    height: 36px;
`;
