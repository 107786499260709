import React from 'react';
import PropTypes from 'prop-types';

import { AutocompleteInputDropdown, TextFieldInputDropdown } from './styles';

const InputDropdown = ({ options, placeholder, value, onChange, disabled }) => {
    return (
        <AutocompleteInputDropdown
            id="combo-box-demo"
            options={options}
            value={value}
            onChange={onChange}
            disabled={disabled}
            getOptionLabel={(option) => option.name || option}
            renderInput={(params) => <TextFieldInputDropdown {...params} placeholder={placeholder} variant="outlined" />}
        />
    );
};

InputDropdown.propTypes = {
    options: PropTypes.array.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

InputDropdown.defaultProps = {
    value: '',
    placeholder: '',
    disabled: false,
};

export default InputDropdown;
