const BRAND_PREFERENCES = [
    {
        name: 'Low cost',
        value: 'LOW_COST',
        description: 'Some no-name, some branded',
        priceCoefficient: 1,
        available: true,
    },
    {
        name: 'Premium',
        value: 'PREMIUM',
        description: 'More brands, less no-name',
        priceCoefficient: 1.08,
        available: true,
    },
    {
        name: 'Organic',
        value: 'ORGANIC',
        description: 'Organic focus, more brands, less no-name',
        priceCoefficient: 1.12,
        available: true,
    },
];

const getAvailableBrandPreferences = () => BRAND_PREFERENCES.filter((type) => type.available);
const getDefaultBrandPreference = () => BRAND_PREFERENCES.find((type) => type.value === 'default');
const getBrandPreferenceNameByValue = (value) => BRAND_PREFERENCES.find((type) => type.value === value).name;
const getBrandPreferencePriceCoeficient = (value) => BRAND_PREFERENCES.find((type) => type.value === value).priceCoefficient;

export {
    BRAND_PREFERENCES,
    getAvailableBrandPreferences,
    getDefaultBrandPreference,
    getBrandPreferencePriceCoeficient,
    getBrandPreferenceNameByValue,
};
